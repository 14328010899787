import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//import connectToStores from 'alt-utils/lib/connectToStores';
import CardBase from './CardBase';
import {gettext} from 'i18n';
import ConsumptionReadingCard from './ConsumptionReadingCard';
import utils from '../utils/utils';
//let {Colors, Spacing, Typography} = mui.Styles;
//const ImmutabilityHelper =mui.Utils.immutabilityHelper;
//let ImmutabilityHelper = require('material-ui/lib/utils/immutability-helper');

//@connectToStores
export default class ConsumptionChangesCard extends CardBase {
  /*
  static getStores() {
    return [];
  }

  static propTypes = {

  }

  static getPropsFromStores() {
    return Store.getState();
  }
  */
  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.setInitialStyles();
  }

  componentWillReceiveProps() {
    this.setState({edit: false});
  }

  setNormalMode() {
    this.setState({edit: false});
  }

  setEditMode() {
    this.setState({edit: true});
  }

  render() {
    if (this.state.edit) {
      const data = {
        premiseId: this.props.premiseId,
        month: this.props.reportData.month,
        year: this.props.reportData.year,
        value: this.props.reportData.currentPeriod.consumption
      };
      return <ConsumptionReadingCard data={data} onCancel={this.setNormalMode.bind(this)} />;
    }

    let values = {};

    if (this.props.reportData) {
      values = {
        consumption: this.props.reportData.currentPeriod.consumption,
        consumptionPreviously: this.props.reportData.referencePeriod.consumption,
        consumptionPerResident: this.props.reportData.consumptionPerResident,
        change: this.props.reportData.change,
        changePerResident: Math.round(this.props.reportData.changePerResident * 1000 / 30),
        changePercentages: this.props.reportData.changePercent
      };
    }

    let mergedRootStyles = this.state.mergedRootStyles;
    let cardtextGood = Object.assign({}, mergedRootStyles.cardtext1);
    let cardtextBad = Object.assign({}, cardtextGood);
    cardtextGood.color = this.state.palette.primary3Color;
    cardtextBad.color = this.state.palette.accent1Color;

    const row = (label, value, unit, isNeutral) => {
      let style = mergedRootStyles.cardtext1
      if (!isNeutral) style = value < 0 ? cardtextGood : cardtextBad
      return (
        <mui.CardText className="clearfix" style={Object.assign({lineHeight:'30px', padding: '10px 15px'}, mergedRootStyles.cardtext1)}>{label.toUpperCase()}
          <span style={Object.assign({float:'right'}, style)}><span style={{fontSize:'20px'}}>{utils.displayFloatAutoDecimals(value, 2)}</span> {unit}</span>
        </mui.CardText>
      );
    }

    const date = " " + this.props.reportData.month + "/" + this.props.reportData.year;
    return (
      <div style={mergedRootStyles.cardSize}>
        <mui.Card style={mergedRootStyles}>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
            <div style={mergedRootStyles.cardSize}>
              <div style={mergedRootStyles.headerSize}>
                <span style={mergedRootStyles.title}>{gettext('title_change_in_consumption').toUpperCase() + date}</span>
                {this.props.manual && <mui.Avatar className="clickable" style={mergedRootStyles.button} icon={<i className="mdi mdi-pen" onClick={this.setEditMode.bind(this)}/>} />}
              </div>
              <hr style={mergedRootStyles.line}/>
              {row(gettext('ui_consumption_previously'), values.consumptionPreviously, gettext('unit_m3'), 1)}
              {row(gettext('ui_consumption'), values.consumption, gettext('unit_m3'), 1)}
              {values.consumptionPerResident && row(gettext('ui_consumption_liters_per_resident'), values.consumptionPerResident, gettext('unit_liters'), 1)}
              {row(gettext('ui_change'), values.change, gettext('unit_m3'))}
              {row(gettext('ui_change') + ' %', values.changePercentages, '%')}
              {this.props.serviceType === 'fiksuvesi' && row(gettext('ui_change_liters_per_resident'), values.changePerResident, gettext('unit_liters'))}
            </div>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
        </mui.Card>
      </div>
    );

  }
}
