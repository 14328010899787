import alt from "../utils/alt";
import WaterCompaniesActions from "../actions/WaterCompaniesActions";
import _ from 'lodash';

class WaterCompaniesStore {
  constructor() {
    this.bindActions(WaterCompaniesActions);
    this.state = {
      wcompanies: [],
      newWCompany: {
        currency: 'eur'
      }
    };
  }

  onQuery(data) {
    this.setState({ wcompanies: data });
  }

  onChange(obj) {
    console.log("onChange: ", obj)
    if (_.isEmpty(obj)) {
      this.state.newWCompany = { currency: 'eur' };
    } else {
      _.assign(this.state.newWCompany, obj)
    }
    this.setState({ newWCompany: this.state.newWCompany });
  }
}

//module.exports = (alt.createStore(WaterCompaniesStore));
export default alt.createStore(WaterCompaniesStore);
