import connectToStores from 'alt-utils/lib/connectToStores';
import { gettext } from 'i18n';
import * as mui from 'material-ui';
import HeatingCompanyActions from '../actions/HeatingCompanyActions';
import PremiseActions from '../actions/PremiseActions';
import UserActions from '../actions/UserActions';
import WaterCompaniesActions from '../actions/WaterCompaniesActions';
import AppBar from '../components/AppBar';
import DateInput from '../components/DateInput';
import SelectBox from '../components/SelectBox';
import TextInput from '../components/TextInput';
import ViewLoader from '../components/ViewLoader';
import HeatingCompanyStore from '../stores/HeatingCompanyStore';
import LoginStore from '../stores/LoginStore';
import PremisesStore from '../stores/PremisesStore';
import WaterCompaniesStore from '../stores/WaterCompaniesStore';
import BaseView from './BaseView';
//import UserStore from '../stores/UserStore';
import serialize from 'form-serialize';
import _ from 'lodash';
import Alarmv2Actions from '../actions/Alarmv2Actions';
import ApiKeyActions from '../actions/ApiKeyActions';
import AddDeviceLinkDialog from '../components/AddDeviceLinkDialog';
import AddMeterValueDialog from '../components/AddMeterValueDialog';
import AddSmartDialog from '../components/AddSmartDialog';
import AlarmLimitsDialog from '../components/AlarmLimitsDialog';
import ConfirmationDialog from '../components/ConfirmationDialog';
import EditCustomCalcDialog from '../components/EditCustomCalcDialog';
import EditDeviceinfosDialog from '../components/EditDeviceinfosDialog';
import MoveDeviceDialog from '../components/MoveDeviceDialog';
import AlarmStoreV2 from '../stores/AlarmStorev2';
import ApiKeyStore from '../stores/ApiKeyStore';
import Utils from '../utils/utils';
import React from 'react';
import { ButtonGroup, Checkbox, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import history from '../utils/history';


import Icon from '@material-ui/core/Icon';

import AlarmActions from '../actions/Alarmv2Actions';
import HourLimitInfoDialog from '../components/HourLimitInfoDialog';
import PremiseEditLogDialog from '../components/premiseEditLogDialog';

@connectToStores
export default class NewPremiseView extends BaseView {

  constructor(props) {
    super(props);
    this.state = { data: null, deviceInstallationDateEdited: false };
  }

  static getStores() {
    return [WaterCompaniesStore, HeatingCompanyStore, LoginStore, PremisesStore, AlarmStoreV2, ApiKeyStore];
  }

  static getPropsFromStores() {
    return {

      ...WaterCompaniesStore.getState(),
      ...HeatingCompanyStore.getState(),
      ...LoginStore.getState(),
      ...PremisesStore.getState(),
      //log:PremisesStore.getState().log,
      //users: UserStore.getState().users,
      alarmReceivers: AlarmStoreV2.getState().alarmReceivers,
      nightlyhourrundata: AlarmStoreV2.getState().nightlyhourrundata,
      apikeys: ApiKeyStore.getState()
    }
  }

  componentDidMount() {
    //console.log("NewPremisesView - componentDidMount:", this.props);
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.setState({
        edit: true
      });
      //PremiseActions.queryById(this.props.params.id);
      PremiseActions.queryDeviceWithLinks(this.props.match.params.id);
    } else {
      PremiseActions.setPremise({})
    }
    this.setState({ saving: false });

    PremiseActions.beginEdit();
    WaterCompaniesActions.query();
    HeatingCompanyActions.query();
    UserActions.query();

    Alarmv2Actions.resetAlarmData();
    if (this.props.match.params.id) {
      ApiKeyActions.byPremise(this.props.match.params.id);
    }

  }

  componentWillUnmount() {
    PremiseActions.reset();
  }

  componentWillReceiveProps(props) {
    const data = props;
    if (data && data.premise) {
      if (!data.premise.alarms) data.premise.alarms = { email: true, sms: false };
      if (!data.premise.language) data.premise.language = 'fi-fi';
      if (props.premise !== this.state.data && data.premise.devices && data.premise.devices.length) {
        Alarmv2Actions.alarmsReceivers(data.premise.devices[0], 'low')
        Alarmv2Actions.alarmsReceivers(data.premise.devices[0], 'normal')
        Alarmv2Actions.alarmsReceivers(data.premise.devices[0], 'high')
        Alarmv2Actions.alarmsReceivers(data.premise.devices[0], 'collate')
      }
      this.setState({ data: data.premise });
    }
  }

  setAutomaticValues(data) {
    data = data || { ...this.state.data };
    if (data.propertyType === 'association') {
      if (data.apartmentCount && !data.residentCount) {
        data.residentCount = Math.round(data.apartmentCount * 1.75);
      }
    }
    if (data.serviceType === 'monitoring') {
      data.consumptionSource = 'auto';
    }
    PremiseActions.setPremise(data);
  }

  onChange(value, id, textField) {
    PremiseActions.setPremise({ [id]: value });

    if (textField.isMandatory() && !value) {
      textField.setErrorText(gettext('ui_mandatory_field'));
    } else {
      textField.setErrorText('')
    }
  }

  onSelectionChange(value, id, selectBox) {
    let premise = { [id]: value.value };

    if (id === 'heatingType') {
      premise.districtHeatingCompany = null;
      HeatingCompanyActions.query({ heatingType: value.value });
    }

    if (selectBox.isMandatory() && !value.value) {
      selectBox.setErrorText(gettext('ui_mandatory_field'));
    } else {
      selectBox.setErrorText('')
    }

    this.setAutomaticValues(premise);
  }

  onCheckboxChange(event, checked) {
    if (event.target.name.indexOf('.')) {
      const t = event.target.name.split('.');
      if (this.state.data[t[0]]) PremiseActions.setPremise(Object.assign(this.state.data[t[0]], { [t[1]]: checked }));
      else PremiseActions.setPremise({ [t[0]]: { [t[1]]: checked } });
    } else {
      PremiseActions.setPremise({ [event.target.name]: checked });
    }
  }

  onRadioButtonChange(event, selected) {
    let data = Object.assign(this.state.data, { [event.target.name]: selected, noHistory: false })
    this.setAutomaticValues(data);
  }

  onDateChange(name, date) {
    if (name === 'deviceInstallationDate') {
      this.setState({ deviceInstallationDateEdited: true })
    }
    PremiseActions.setPremise(Object.assign(this.state.data, { [name]: date }))
  }

  onExcludedDevicesChange(event) {
    //console.log(event.target.name , event.target.checked)

    let ex = this.state.data.excludedDevices || []
    if (event.target.checked) {
      if (!_.includes(ex, event.target.name)) ex.push(event.target.name)
    } else {
      ex = _.remove(ex, function (n) {
        return n !== event.target.name;
      });

    }
    PremiseActions.setPremise(Object.assign(this.state.data, { excludedDevices: ex }))
  }

  onAddHistoryItem() {
    const date = this.state.newHistoryDate;
    const value = Utils.stringToNumber(this.state.newHistoryValue);
    if (date && !isNaN(value)) {
      let data = this.state.data || {};
      data.history = data.history || [];
      data.history.push({ time: date.toISOString(), value });
      this.setState({
        newHistoryDate: Utils.addMonthToDate(date, 1),
        newHistoryValue: ''
      });

      PremiseActions.setPremise(data);
      this.refs.historyValueInput.focus();
      setTimeout(() => window.scroll(0, 10000000), 0);
    }
  }

  onRemoveHistoryItem(index) {
    let data = this.state.data || [];
    data.history = data.history || [];
    data.history.splice(index, 1);
    PremiseActions.setPremise(data);
  }

  removeDevice(device) {
    this.deviceToRemove = device;
    this.refs.confirmationDialog.open();
  }

  removeDeviceAccepted() {
    this.refs.confirmationDialog.close();

    let premiseDevices = this.state.data.devices;
    const index = premiseDevices.indexOf(this.deviceToRemove);
    if (index > -1) premiseDevices.splice(index, 1);

    PremiseActions.removeDevice({ _id: this.state.data._id, devices: premiseDevices }, this.deviceToRemove);
    this.deviceToRemove = null;
  }

  deactivateDeviceAccepted() {
    this.refs.confirmationDialog.close();

    let premiseDevices = this.state.data.devices;
    const index = premiseDevices.indexOf(this.deviceToRemove);
    if (index > -1) premiseDevices.splice(index, 1);

    PremiseActions.deactivateDevice({ _id: this.state.data._id, devices: premiseDevices }, this.deviceToRemove);
    this.deviceToRemove = null;
  }

  addMeterValue(device) {
    PremiseActions.getEarliestValue(device);
    this.deviceToAddValue = device;
    this.refs.addMeterValueDialog.open(device);
  }

  addMeterValueAccepted(value, timestamp) {
    PremiseActions.setMeterValue(this.deviceToAddValue, value, timestamp)
    this.refs.addMeterValueDialog.close();
  }

  AlarmLimitsOpen(deviceId) {
    PremiseActions.getDevice(deviceId);
    this.refs.alarmLimitsDialog.open(deviceId);
  }

  AlarmLimitsOpenLimitHelper(deviceId) {
    AlarmActions.getDeviceHourArray(deviceId, this.state.data.country);
    this.refs.hourlimitinfodialog.open();
  }

  AlarmLimitsClose() {
    PremiseActions.resetAlarmLimits();
    this.refs.alarmLimitsDialog.close();
  }

  addSmartOpen() {
    //console.log(this.refs.addSmartDialog)
    this.refs.addSmartDialog.open();
  }

  editLogOpen() {
    PremiseActions.getChangesLog(this.props.match.params.id)
    this.refs.premiseeditlogdialog.open();
  }

  addSmartClose() {
    setTimeout(
      () => {
        PremiseActions.queryDeviceWithLinks(this.props.match.params.id)
      },
      2000)

    this.refs.addSmartDialog.close();
  }

  addSmart(data) {
    PremiseActions.addnewSmartDevice(data, this.state.data._id)
    this.addSmartClose()
  }

  editDeviceinfos(device, devicesInfo) {
    this.deviceToAddValue = device;
    let apikeys = this.props.apikeys || []
    PremiseActions.getDevice(device);
    this.refs.editDeviceinfosDialog.open(device, devicesInfo, apikeys);
  }

  editDeviceinfosAccepted(customernumber, locationNumber, meterSerial, alias, isSubmeter, measured, customIDStr, disabledAlarms) {
    let data = this.state.data;

    let newDisabledAlarms = [];
    if (disabledAlarms.length) {
      for (const alarm of disabledAlarms) {
        newDisabledAlarms.push(alarm.value);
      }
    }
    let alarmData={
      deviceId:this.props.device.id,
      disabledAlarms:newDisabledAlarms
    }

    PremiseActions.saveDisabledAlarmsSettings(alarmData);

    let devicesinfo = {};
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.wcCustomerNumber'] = customernumber;
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.wcLocationNumber'] = locationNumber;
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.alias'] = alias;
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.isSubmeter'] = isSubmeter;
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.measured'] = measured;
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.meterSerial'] = meterSerial
    devicesinfo['devicesinfo.' + this.deviceToAddValue + '.customIDStr'] = customIDStr

    devicesinfo._id = this.state.data._id;

    this.refs.editDeviceinfosDialog.close();
    if (!data.devicesinfo) data.devicesinfo = {};
    if (!data.devicesinfo[this.deviceToAddValue]) data.devicesinfo[this.deviceToAddValue] = {};
    data.devicesinfo[this.deviceToAddValue].wcCustomerNumber = customernumber
    data.devicesinfo[this.deviceToAddValue].wcLocationNumber = locationNumber;
    data.devicesinfo[this.deviceToAddValue].alias = alias;
    data.devicesinfo[this.deviceToAddValue].meterSerial = meterSerial;
    data.devicesinfo[this.deviceToAddValue].isSubmeter = isSubmeter;
    data.devicesinfo[this.deviceToAddValue].measured = measured;
    data.devicesinfo[this.deviceToAddValue].customIDStr = customIDStr;
    PremiseActions.editDeviceIfo(devicesinfo);
    this.setState(data)
  }

  moveDeviceToPremise(device) {
    this.deviceToMove = device;
    this.refs.moveDeviceDialog.open();
  }

  moveDeviceAccepted(newPremise) {
    let newPremiseDevices = newPremise.devices || [];
    if (newPremiseDevices.indexOf(this.deviceToMove) === -1) {
      newPremiseDevices.push(this.deviceToMove);
    }
    let premiseDevices = this.state.data.devices;
    const index = premiseDevices.indexOf(this.deviceToMove);
    if (index > -1) premiseDevices.splice(index, 1);

    this.refs.moveDeviceDialog.close();
    const data = { oldPremiseId: this.state.data._id, newPremiseId: newPremise._id };
    const event = { action: 'move_device', source: this.props.user.username, data: { deviceId: this.deviceToMove, toPremise: newPremise._id } }
    PremiseActions.moveDevice(this.deviceToMove, data, event);
    this.deviceToMove = null;
  }

  save(data) {
    data = Object.assign(this.state.data, data)
    let formError = false;
    _.map(this.refs, (field) => {
      if (field !== this.refs.confirmationDialog && field !== this.refs.premiseForm) {
        if (field.setErrorText) {
          if (field.isMandatory && field.isMandatory() && !data[field.getName()]) {
            field.setErrorText(gettext('ui_mandatory_field'));
            formError = true;
          } else {
            field.setErrorText(null);
          }
        }
      }
    });
    if (formError) {
      //console.log('ERROR SAVE', this.state.data);
    } else {
      this.setState({ saving: true });
      PremiseActions.edit(data);
    }
  }

  getLinkedDeviceId = (item) => {
    let r = null;
    let ld = this.props.linkedPremises ? this.props.linkedPremises[item.premiseId] : null;
    const di = ld ? ld.devicesinfo : {};
    Object.keys(di)
      .forEach(k => {
        if (item.deviceAlias === di[k].alias) {
          r = k;
        }
      });

    return r;
  }

  removeDeviceLink = (item) => {
    let data = this.state.data;

    for (var i = data.linkedDevices.length - 1; i >= 0; i--) {
      if (data.linkedDevices[i] === item) {
        data.linkedDevices.splice(i, 1);
        break;
      }
    }
    this.setState({ data });
    PremiseActions.setPremise(data);
  }

  removeCalculation = (calc) => {
    let data = this.state.data;
    delete data.fns[calc];
    this.setState({ data });
    PremiseActions.setPremise(data);
  }

  addCalculation = (calc) => {
    if (calc === 'hotwaterConsumption') {
      this.editHwCalculation('');
    }
    if (calc === 'consumption') {
      this.editConCalculation('');
    }
  }

  getVariables = () => {
    const variables = [];
    if (this.state.data.devicesinfo) {
      Object.keys(this.state.data.devicesinfo).forEach((t) => {
        if (this.state.data.devicesinfo[t].alias) {
          variables.push(this.state.data.devicesinfo[t].alias);
        }
      });
    }
    if (this.state.data.linkedDevices) {
      this.state.data.linkedDevices.forEach((t) => variables.push(`${t.deviceAlias}@${t.premiseId}`));
    }
    return variables;
  }

  removeConCalculation = () => this.removeCalculation('consumption')
  removeHwCalculation = () => this.removeCalculation('hotwaterConsumption')
  addConCalculation = () => this.addCalculation('consumption')
  addHwCalculation = () => this.addCalculation('hotwaterConsumption')

  editConCalculation = () => {
    const fn = (this.state.data.fns && this.state.data.fns.consumption) ? this.state.data.fns.consumption.fn : ''
    this.refs.editCustomCalcDialog.open(
      gettext('Total consumption'),
      'consumption',
      fn,
      this.getVariables())
  }

  editHwCalculation = () => {
    const fn = (this.state.data.fns && this.state.data.fns.hotwaterConsumption) ? this.state.data.fns.hotwaterConsumption.fn : ''
    this.refs.editCustomCalcDialog.open(
      gettext('Hot water consumption'),
      'hotwaterConsumption',
      fn,
      this.getVariables());
  }

  calculationAccepted = (type, value) => {
    let data = this.state.data;
    if (!data.fns) data.fns = {};
    data.fns[type] = { fn: value };
    this.setState({ data });
    this.refs.editCustomCalcDialog.close();
    PremiseActions.setPremise(data);
  }

  addDeviceLink = () => {
    this.refs.addDeviceLinkDialog.open();
  }

  linkAccepted = (premiseId, alias) => {
    let data = this.state.data;
    if (!data.linkedDevices) {
      data.linkedDevices = [];
    }
    data.linkedDevices.push({ premiseId, deviceAlias: alias });
    this.setState({ data });
    this.refs.addDeviceLinkDialog.close();
    PremiseActions.setPremise(data);
    PremiseActions.queryPremiseLinks.defer(data.linkedDevices);
  }

  checkPhoneNum = (n) => {
    if (n.indexOf('+35810') > -1 || n.indexOf('+35820') > -1) return 'red';
    return 'black';
  }

  render() {
    let alarmReceivers = this.props.alarmReceivers ? this.props.alarmReceivers : { low: [], normal: [], high: [], collate: [] };
    let showDeviceUnlink = (this.props.root || this.props.role === 'admin') ? true : false;
    let disableEditButtons = this.props.root ? false : true;
    let serviceLevel = this.state && this.state.data && this.state.data.serviceLevelSettings && this.state.data.serviceLevelSettings.level ? gettext(this.state.data.serviceLevelSettings.level) : gettext('default(not defined)');

    if (this.props.error) {
      let error = <div className="container"><h4>Error: {this.props.error}</h4></div>
      return (
        <div>
          <AppBar title={this.state.edit ? gettext('ui_edit_premise') : gettext('title_Uusi_Kohde')} />
          {error}
        </div>
      )
    }

    if (!this.props.wcompanies
      || !this.props.hcompanies
      || !this.props.heatingTypes
      || !this.state.data) {
      return (
        <div>
          <AppBar title={this.state.edit ? gettext('ui_edit_premise') : gettext('title_Uusi_Kohde')} />
          <ViewLoader blue={true} />
        </div>
      )
    }

    let propertyTypes = [
      { value: 'association', label: gettext('ui_housing_association') },
      { value: 'public', label: gettext('ui_public') },
      { value: 'retail', label: gettext('ui_retail') },
      { value: 'office', label: gettext('ui_office') },
      { value: 'residential', label: gettext('ui_residential') },
      { value: 'hospitality', label: gettext('ui_hospitality') },
      { value: 'logistics', label: gettext('ui_logistics') }
    ];
    let heatingTypes = this.props.heatingTypes.map((type) => ({ value: type.value, label: gettext('heatingtype_' + type.value) })).sort((a, b) => a.label.localeCompare(b.label));

    let waterCompanies = this.props.wcompanies.map((elem) => { return { value: elem._id, label: elem.name } });
    let heatCompanies = this.props.hcompanies ? this.props.hcompanies.map((elem) => { return { value: elem._id, label: elem.name } }) : [];

    let data = Object.assign({}, this.state.data)
    let historyData = data.history || [];
    let devices = data.devices ? data.devices : [];
    let linkedDevices = data.linkedDevices ? data.linkedDevices : [];
    let gatewayDevices = data.gatewayDevices ? data.gatewayDevices : [];
    let gateways = gatewayDevices.length ? true : false
    let consumptionFn = data.fns ? data.fns.consumption : null;
    let hotwaterConsumptionFn = data.fns ? data.fns.hotwaterConsumption : null;

    let devicesinfo = data.devicesinfo ? data.devicesinfo : {};

    const byGroup = (group) => (x) => x.grouptag.type === group;
    const toListItems = (x) => ({ value: x._id, label: x.name });

    let groupTags = []

    const byRole = (role) => (x) => x.role === role;

    const earliestData = this.props.earliestData ? this.props.earliestData : null;

    const languages = [{ label: gettext('Suomi'), value: 'fi-fi' }, { label: gettext('Englanti'), value: 'en-us' }, { label: gettext('Ruotsi'), value: 'sv-se' }, { label: gettext('Hollanti'), value: 'nl-nl' }];
    let submitForm = (e) => {
      e.preventDefault();
      //console.log('submitForm');
      let data = serialize(this.refs.premiseForm, { hash: true })
      this.save(data)
    }

    let customReporting = []
    let excludedDevices = data.excludedDevices ? data.excludedDevices : [];
    devices.forEach(devId => {
      customReporting.push(
        <FormControlLabel
          control={
            <Checkbox
              checked={_.includes(excludedDevices, devId)}
              onChange={this.onExcludedDevicesChange.bind(this)}
              name={devId}
              color="secondary"
            />
          }
          label={devId}
        />
      )
    });


    let DisabledAlarms = this.props.premise && this.props.premise.alarms && this.props.premise.alarms.disabledAlarms ?
      this.props.premise.alarms.disabledAlarms.map((o) => ({ label: gettext(o), value: o })) :
      []
      
    return (
      <div>
        <ConfirmationDialog ref="confirmationDialog"
          title={gettext('ui_remove_device_title')}
          text={gettext('ui_remove_device_desc')}
          confirmButtonText={gettext('ui_unlink')}
          secondaryConfirmButtonText={gettext('deactivate')}
          onAccepted={this.removeDeviceAccepted.bind(this)}
          onSecondaryAccepted={this.deactivateDeviceAccepted.bind(this)} />
        <MoveDeviceDialog ref="moveDeviceDialog"
          title={gettext('ui_moveDevice_title')}
          text={gettext('ui_moveDevice_desc')}
          moveDeviceButtonText={gettext('ui_move_device')}
          onAccepted={this.moveDeviceAccepted.bind(this)}
        />
        <AddMeterValueDialog ref="addMeterValueDialog"
          title={gettext('ui_addMeterValue_title')}
          text={gettext('ui_addMeterValue_desc')}
          addMeterValueText={gettext('ui_addMeterValue_title')}
          onAccepted={this.addMeterValueAccepted.bind(this)}
          earliestData={earliestData} />
        <EditDeviceinfosDialog ref="editDeviceinfosDialog"
          title={gettext('ui_editDeviceinfos_title')}
          device={this.props.device}
          onAccepted={this.editDeviceinfosAccepted.bind(this)}
        />

        <AddDeviceLinkDialog ref="addDeviceLinkDialog"
          onAccepted={this.linkAccepted}
        />

        <EditCustomCalcDialog ref="editCustomCalcDialog" onAccepted={this.calculationAccepted} />
        <AlarmLimitsDialog ref="alarmLimitsDialog"
          device={this.props.device}
          root={this.props.root}
          newHighflowLimits={this.props.newHighflowLimits}
          newWeekEndHighflowLimits={this.props.newWeekEndHighflowLimits}
          recalculateHighFlow={(dId) => { PremiseActions.highflowRecalculation(dId) }}
          recalculateWeekEndHighFlow={(dId) => { PremiseActions.weekendHighflowRecalculation(dId) }}
          loading={this.props.loading}
          onClose={() => { this.AlarmLimitsClose() }} />

        <AddSmartDialog ref="addSmartDialog"
          devices={devices}
          device={this.props.device}
          root={this.props.root}
          recalculateHighFlow={(dId) => { PremiseActions.highflowRecalculation(dId) }}
          loading={this.props.loading}
          onClose={() => { this.addSmartClose() }}
          onAdd={(i) => this.addSmart(i)} />

        <PremiseEditLogDialog ref="premiseeditlogdialog" log={this.props.log} />

        <HourLimitInfoDialog ref="hourlimitinfodialog" dialogData={this.props.nightlyhourrundata} />

        <AppBar title={this.state.edit ? gettext('ui_edit_premise') : gettext('title_Uusi_Kohde')} />

        {!this.state.data && <ViewLoader blue={true} />}
        {this.state.data &&
          <div className="container">
            <form ref="premiseForm">
              <div className="floating-buttons">
                <div className="container">
                  <div className="wrapper">
                    <ButtonGroup variant="contained" color="primary" aria-label="button group">
                      <Button onClick={() => this.editLogOpen()}>{gettext('Changes Log')}</Button>
                      <Button onClick={() => this.addSmartOpen()}>{gettext('add-smart')}</Button>
                      <Button onClick={() => history.goBack()}>{gettext('ui_cancel')}</Button>
                      <Button onClick={submitForm.bind(this)}>{gettext('ui_save')}</Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
              <br /><br />
              <h6>{gettext('ui_property_data')}</h6>
              <div className="Inputgroup">
                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <TextInput defaultValue={this.state.data.name} required name="name" label={gettext('ui_property_name')} />
                  </div>



                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('Parent Account')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.parentAccount || '-'}</div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('ui_property_type')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{gettext(this.state.data.propertyType || 'NOT DEFINED')}</div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('ui_businessId')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.businessId || '-'}</div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('systemGuide')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.systemGuide || '-'}</div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('contract_terminating_date')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.terminationDate ? Utils.displayDate(this.state.data.terminationDate) : '-'}</div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('contract_end_date')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.endDate ? Utils.displayDate(this.state.data.endDate) : '-'}</div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-9">
                    <TextInput defaultValue={this.state.data.address} required name="address" label={gettext('ui_address')} />
                  </div>
                </div>
                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-3">
                    <TextInput defaultValue={this.state.data.zipCode} required name="zipCode" label={gettext('ui_zipCode')} />
                  </div>
                  <div className="col-xs-12 col-sm-4">
                    <TextInput defaultValue={this.state.data.postOffice} required name="postOffice" label={gettext('ui_postOffice')} />
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <TextInput defaultValue={this.state.data.country} name="country" label={gettext('ui_country_code')} />
                  </div>
                </div>

                <hr />

                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <TextInput
                      defaultValue={this.state.data.apartmentCount}
                      id="apartmentCount"
                      label={gettext('ui_apartment_count')}
                      onChange={this.onChange.bind(this)}
                      onBlur={() => this.setAutomaticValues()} />
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <TextInput
                      defaultValue={this.state.data.residentCount}
                      name="residentCount"
                      label={gettext('ui_resident_count')} />
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <TextInput defaultValue={this.state.data.constructionYear} name="constructionYear" label={gettext('ui_construction_year')} />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <TextInput defaultValue={this.state.data.floorArea} name="floorArea" label={gettext('ui_floor_area')} />
                  </div>
                </div>

                <hr />

                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-6">
                    <SelectBox value={waterCompanies.filter(({ value }) => value === this.state.data.waterCompany)} name="waterCompany" options={waterCompanies} label={gettext('ui_water_company')} onSelectionChange={this.onSelectionChange.bind(this)} />
                  </div>
                </div>
                {/* Commented ou. Moved in device info settings
                  this.state.data.waterCompany === "5632467076e18b0100d71eea" &&
                  (<div className="row top-xs">
                    <div className="col-xs-12 col-sm-6">
                      <TextInput defaultValue={this.state.data.hsyCustomerNumber} ref="hsyCustomerNumber" name="hsyCustomerNumber" label={gettext('hsyCustomerNumber')} />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <TextInput defaultValue={this.state.data.hsyLocationNumber} ref="hsyLocationNumber" name="hsyLocationNumber" label={gettext('hsyLocationNumber')} />
                    </div>
                  </div>
                  )
                */}
                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-6">
                    <SelectBox value={heatingTypes.filter(({ value }) => value === this.state.data.heatingType)} name="heatingType" options={heatingTypes} label={gettext('ui_energy_type')} onSelectionChange={this.onSelectionChange.bind(this)} />
                  </div>
                </div>
                <div className="row top-xs">
                  <div className="col-xs-12 col-sm-6">
                    {
                      this.state.data.heatingType &&
                      <SelectBox
                        value={heatCompanies.filter(({ value }) => value === this.state.data.districtHeatingCompany)}
                        name="districtHeatingCompany"
                        label={gettext('ui_heating_company')}
                        options={heatCompanies}
                        onSelectionChange={this.onSelectionChange.bind(this)} />
                    }
                  </div>
                </div>
              </div>
            </form>

            <h6>{gettext('ui_stakeholders')}</h6>
            <div className="Inputgroup">
              {this.state.data.interestGroups && this.state.data.interestGroups.map((e, i) => (
                <div key={i} className="InputElem" style={{ marginBottom: '24px' }}>
                  <div className="InputLabel">{gettext(this.state.data.interestGroups[i].type)}</div>
                  <div className="row middle-xs">
                    <div className="col-xs-12 col-sm-12">
                      {`${this.state.data.interestGroups[i].name} (${this.state.data.interestGroups[i].email || ''} ${this.state.data.interestGroups[i].phone || ''})`}
                      {`  ${gettext('language')}:${this.state.data.interestGroups[i].language}`} {`  ${gettext('reports')}:${(this.state.data.interestGroups[i].reports !== undefined) ? this.state.data.interestGroups[i].reports : true}`}
                    </div>
                  </div>
                </div>
              ))
              }
              <hr />
            </div>


            <h6>{gettext('ui_fiksuvesi_contact_persons')}</h6>
            <div className="Inputgroup">
              <div className="InputElem" >
                <div className="InputLabel">{gettext('CRM Owner')}</div>
                <div className="row middle-xs">
                  <div className="col-xs-12 col-sm-6">
                    {this.state.data.owner || '-'}
                  </div>
                </div>
              </div>
            </div>

            <h6>{gettext('ui_settings')}</h6>
            <div className="Inputgroup">
              <div className="row">
                <div className="col-xs-6 col-sm-6">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <SelectBox
                      value={languages.filter(({ value }) => value === this.state.data.language)}
                      options={languages}
                      name="language"
                      label={gettext('ui_language')}
                      style={{ width: '80%' }}
                      onSelectionChange={this.onSelectionChange.bind(this)} />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="InputElem" >
                      <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('Unit')}</div>
                      <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.unit || '-'}</div>
                    </div>
                  </div>

                </div>

                <div className="col-xs-6 col-sm-6" >
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 settingsHeader">{gettext('ui_alarm_routing')}</div>
                    <div className="col-xs-6 col-sm-6">
                      <mui.Checkbox defaultChecked={!!(this.state.data.alarms && this.state.data.alarms.sms)} name="alarms.sms" label={gettext('ui_alarm_sms')} onCheck={this.onCheckboxChange.bind(this)} />
                    </div>
                    <div className="col-xs-6 col-sm-6">
                      <mui.Checkbox defaultChecked={!!(this.state.data.alarms && this.state.data.alarms.email)} name="alarms.email" label={gettext('ui_alarm_email')} onCheck={this.onCheckboxChange.bind(this)} />
                    </div>
                    <div className="col-xs-12 col-sm-12" style={{ fontSize: '12px', color: '#0D429B', marginTop: '10px' }}>{gettext('alarm_routing_info')}<br /></div>
                    <div className="col-xs-12 col-sm-12">
                      <hr />

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {alarmReceivers.normal && <h6>{gettext('ui_alarms_receivers')}</h6>}
            {alarmReceivers.normal && <div className="Inputgroup">
              <h6 style={{ fontSize: '12' }}>{gettext('priority low')}</h6>
              <br />{alarmReceivers.low && alarmReceivers.low.length === 0 && '-'}
              {alarmReceivers.low &&
                alarmReceivers['low'].map((item, index) => {
                  let sms = item.sms !== false ? item.sms : null
                  return (<div key={"rec_l" + index}>
                    {item.mail && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_email')}: <i>{item.mail}</i></span>}
                    {sms && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_sms')}: <i style={{ color: this.checkPhoneNum(item.sms) }}>{item.sms}</i></span>}
                    {item.language && <span style={{ marginRight: '15px' }}>{gettext('ui_language')}: <i>{item.language}</i></span>}
                  </div>)
                })
              }
              <br /><h6 style={{ fontSize: '12' }}>{gettext('priority normal')}</h6>
              <br />{alarmReceivers.normal && alarmReceivers.normal.length === 0 && '-'}
              {alarmReceivers.normal &&
                alarmReceivers['normal'].map((item, index) => {
                  let sms = item.sms !== false ? item.sms : null
                  return (<div key={"rec_n" + index}>
                    {item.mail && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_email')}: <i>{item.mail}</i></span>}
                    {sms && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_sms')}: <i style={{ color: this.checkPhoneNum(item.sms) }}>{item.sms}</i></span>}
                    {item.language && <span style={{ marginRight: '15px' }}>{gettext('ui_language')}: <i>{item.language}</i></span>}
                  </div>)
                })
              }
              <br /><h6 style={{ fontSize: '12' }}>{gettext('priority high')}</h6>
              <br />{alarmReceivers.high && alarmReceivers.high.length === 0 && '-'}
              {alarmReceivers.high &&
                alarmReceivers['high'].map((item, index) => {
                  let sms = item.sms !== false ? item.sms : null
                  return (<div key={"rec_h" + index}>
                    {item.mail && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_email')}: <i>{item.mail}</i></span>}
                    {sms && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_sms')}: <i style={{ color: this.checkPhoneNum(item.sms) }}>{item.sms}</i></span>}
                    {item.language && <span style={{ marginRight: '15px' }}>{gettext('ui_language')}: <i>{item.language}</i></span>}
                  </div>)
                })
              }
              <br /><h6 style={{ fontSize: '12' }}>{gettext('collate')}</h6>
              <br />{alarmReceivers.collate && alarmReceivers.collate.length === 0 && '-'}
              {alarmReceivers.collate &&
                alarmReceivers['collate'].map((item, index) => {
                  let sms = item.sms !== false ? item.sms : null
                  return (<div key={"rec_c" + index}>
                    {item.mail && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_email')}: <i>{item.mail}</i></span>}
                    {sms && <span style={{ marginRight: '15px' }}>{gettext('ui_alarm_sms')}: <i>{item.sms}</i></span>}
                    {item.language && <span style={{ marginRight: '15px' }}>{gettext('ui_language')}: <i>{item.language}</i></span>}
                  </div>)
                })
              }
            </div>}


            <h6>{gettext('ui_service_type')}</h6>
            <div className="Inputgroup">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <RadioGroup name="serviceType" value={this.state.data.serviceType} onChange={this.onRadioButtonChange.bind(this)}>
                    <FormControlLabel value="fiksuvesi" control={<Radio />} label={gettext('ui_fiksuvesi_service')} />
                    <FormControlLabel value="monitoring" control={<Radio />} label={gettext('ui_consumption_monitoring')} />
                    <FormControlLabel value="vertointegration" control={<Radio />} label={gettext('Verto Integration (no reports)')} />
                  </RadioGroup>
                </div>
                <div className="col-xs-12 col-sm-5">
                  {this.state.data.serviceType === 'fiksuvesi' &&
                    (<div>
                      <DateInput value={this.state.data.installationDate ? this.state.data.installationDate : ''} label={gettext('ui_installation_date')} name="installationDate" onChange={this.onDateChange.bind(this)} />
                      <mui.Checkbox defaultChecked={!!this.state.data.savingsGuarantee} name="savingsGuarantee" label={gettext('ui_savings_guarantee_valid')} onCheck={this.onCheckboxChange.bind(this)} />
                    </div>
                    )}
                </div>
              </div>
            </div>

            <h6>{gettext('ui_service_level')}</h6>
            <div className="Inputgroup">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <span style={{ color: '#0D429B' }} >{gettext('ui_service_level') + ': '} </span><span style={{ fontSize: '0.9em' }} >{gettext(serviceLevel)}</span>
                </div>
              </div>
            </div>


            <h6>{gettext('ui_devices')}</h6>
            <div className="Inputgroup">
              <table className="table">
                <tbody>
                  {devices.map((item, index) => {
                    let serial = (devicesinfo && devicesinfo[item] && devicesinfo[item].meterSerial) ? gettext('ui_serialnumber') + ": " + devicesinfo[item].meterSerial : gettext('ui_serialnumber') + ": -";
                    let wcCustomer = (devicesinfo && devicesinfo[item] && devicesinfo[item].wcCustomerNumber) ? devicesinfo[item].wcCustomerNumber : "";
                    let wcLocation = (devicesinfo && devicesinfo[item] && devicesinfo[item].wcLocationNumber) ? devicesinfo[item].wcLocationNumber : "";
                    let alias = (devicesinfo && devicesinfo[item] && devicesinfo[item].alias) ? devicesinfo[item].alias : "";
                    let wcText = wcCustomer || wcLocation ? wcCustomer + "/" + wcLocation : ""
                    let unreliable = devicesinfo && devicesinfo[item] && devicesinfo[item].unreliable === true ? true : false;
                    let submeter = devicesinfo && devicesinfo[item] && devicesinfo[item].isSubmeter === true ? true : false;
                    let measured = devicesinfo && devicesinfo[item] && devicesinfo[item].measured && devicesinfo[item].measured > -1 ? devicesinfo[item].measured : 0;
                    let customIDStr = (devicesinfo && devicesinfo[item] && devicesinfo[item].customIDStr) ? devicesinfo[item].customIDStr : "";

                    const measuredColorTable = ['submeterIconCold', 'submeterIconHot', 'submeterIconUndefined']
                    let measuredColor = devicesinfo && devicesinfo[item] && devicesinfo[item].measured != null && devicesinfo[item].measured > -1 ? measuredColorTable[devicesinfo[item].measured] : 'submeterIconUndefined';

                    //console.log(item, unreliable, devicesinfo[item])
                    return (
                      <tr key={item + '_' + index}>
                        <td>
                          {unreliable && <i className="premisesIconsWarning mdi mdi-alert-box" title={gettext('unreliable reading')} />}

                          ID: {submeter && <Icon className={measuredColor} title={gettext('submeter')} fontSize='small'>control_point_duplicate</Icon>}{item} <br />
                          <i style={{ fontSize: '11px' }}>{serial}</i>
                          <br />
                          <i style={{ fontSize: '11px' }}>{wcText}</i>
                          <br />
                          <i style={{ fontSize: '11px' }}>{`${gettext("Meter variable")}: ${alias}`}</i>
                        </td>
                        <td><Button variant='outlined' onClick={this.editDeviceinfos.bind(this, item, devicesinfo[item])} >{gettext('ui_edit_deviceinfos')}</Button></td>
                        <td>{showDeviceUnlink && <Button variant='outlined' onClick={this.removeDevice.bind(this, item)} >{gettext('ui_remove_device')}</Button>}</td>
                        <td>{showDeviceUnlink && <Button variant='outlined' onClick={this.moveDeviceToPremise.bind(this, item)} >{gettext('ui_move_device')}</Button>}</td>
                        <td><Button variant='outlined' onClick={this.addMeterValue.bind(this, item)} >{gettext('ui_addMeterValue_title')}</Button></td>
                        <td><Button variant='outlined' onClick={this.AlarmLimitsOpen.bind(this, item)} >{gettext('ui_AlarmLimits')}</Button></td>
                        <td><Button variant='outlined' onClick={this.AlarmLimitsOpenLimitHelper.bind(this, item)} >{gettext('Limit helper')}</Button></td>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>

            {gateways && <h6>{gettext('Gateway devices')}</h6>}
            {gateways && <div className="Inputgroup">
              <ul>
                {gatewayDevices.map((item, index) => {
                  return (<li>{item}</li>)
                })}
              </ul>
            </div>
            }


            <h6>{gettext('Linked devices')}</h6>
            <div className="Inputgroup">
              <table className="table">
                <thead>
                  <tr>
                    <th>{gettext('ui_device')}</th>
                    <th>{gettext('ui_premise')}</th>
                    <th>{gettext('Meter variable')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {linkedDevices.map((item, index) => {
                    return (
                      <tr key={item.deviceAlias + '_' + index}>
                        <td>{`${item.deviceAlias} (${this.getLinkedDeviceId(item)})`}</td>
                        <td>{(this.props.linkedPremises && this.props.linkedPremises[item.premiseId]) ? this.props.linkedPremises[item.premiseId].name : item.premiseId}</td>
                        <td>{`${item.deviceAlias}@${item.premiseId}`}</td>
                        <td><Button disabled={disableEditButtons} variant='outlined' onClick={() => this.removeDeviceLink(item)} >{gettext('Remove device link')}</Button></td>
                      </tr>)
                  })}
                </tbody>
              </table>
              <div className="row">
                <Button disabled={disableEditButtons} variant='outlined' onClick={this.addDeviceLink} >{gettext('Add device link')}</Button>
              </div>
            </div>

            <h6>{gettext('Custom reporting')}</h6>
            <div className="Inputgroup">
              <div className="InputLabel" style={{ color: "#0D429B", fontSize: "12px" }} >{gettext('EXCLUDE DEVICE FROM REPORT')}</div>
              {customReporting}
            </div>

            <h6>{gettext('Custom consumption calculation')}</h6>
            <div className="Inputgroup">
              {consumptionFn &&
                <div className="row">
                  <div className="col-xs-9">
                    <h6>{gettext('Consumption')}</h6>
                    <p style={{ fontSize: '14', fontWeight: 100, fontStyle: 'italic' }}>
                      {consumptionFn.fn}
                    </p>
                  </div>
                  <div className="col-xs-3" style={{ marginTop: 30 }}>
                    <Button disabled={disableEditButtons} style={{ marginRight: 10 }} variant='outlined' onClick={this.removeConCalculation} >{gettext('Remove')}</Button>
                    <Button disabled={disableEditButtons} variant='outlined' onClick={() => this.editConCalculation()} >{gettext('Edit')}</Button>
                  </div>
                </div>
              }
              {hotwaterConsumptionFn &&
                <div className="row start-xs">
                  <div className="col-xs-9">
                    <h6>{gettext('Hot water consumption')}</h6>
                    <p style={{ fontSize: '14', fontWeight: 100, fontStyle: 'italic' }}>
                      {hotwaterConsumptionFn.fn}
                    </p>
                  </div>
                  <div className="col-xs-3" style={{ marginTop: 30 }}>
                    <Button disabled={disableEditButtons} style={{ marginRight: 10 }} variant='outlined' onClick={this.removeHwCalculation} >{gettext('Remove')}</Button>
                    <Button disabled={disableEditButtons} variant='outlined' onClick={() => this.editHwCalculation()} >{gettext('Edit')}</Button>
                  </div>
                </div>
              }
              {(!hotwaterConsumptionFn && !consumptionFn) &&
                <div className="row">
                  <div className="col-xs-12">
                    <p>{gettext("Premise's consumption is sum of it's devices. If you want to add different calculation click below.")}</p>
                  </div>
                </div>
              }
              <div className="row start-xs">
                {!consumptionFn && <div className="col-xs-4">
                  <Button disabled={disableEditButtons} variant='outlined' onClick={this.addConCalculation} >{gettext('Add consumption calculation')}</Button>
                </div>}
                {!hotwaterConsumptionFn && <div className="col-xs-5">
                  <Button disabled={disableEditButtons} variant='outlined' onClick={this.addHwCalculation} >{gettext('Add hot water consumption calculation')}</Button>
                </div>}
              </div>
            </div>

            <h6>{gettext('ui_consumption_data')}</h6>
            <div className="Inputgroup">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <RadioGroup name="consumptionSource" value={this.state.data.consumptionSource} onChange={this.onRadioButtonChange.bind(this)}>
                    <FormControlLabel value="auto" control={<Radio />} label={gettext('ui_automatic_consumption_monitoring')} />
                    <FormControlLabel disabled={this.state.data.serviceType !== 'fiksuvesi'} value="manual" control={<Radio />} label={gettext('ui_manual_data_input')} />
                    <FormControlLabel disabled={true} value="external" control={<Radio />} label={gettext('ui_external_source')} />
                  </RadioGroup>
                </div>
                <div className="col-xs-12 col-sm-5">
                  {this.state.data.consumptionSource === 'auto' &&
                    <div className="InputElem">
                      {this.state.data.deviceInstallationDate && <div className="InputLabel" style={{ color: "#0D429B", fontSize: "12px" }} >{gettext('ui_monitoring_installation_date')}</div>}
                      {this.state.data.deviceInstallationDate && <div style={{ padding: "0.5em 0.75em" }} >{this.state.data.deviceInstallationDate ? Utils.displayDate(this.state.data.deviceInstallationDate) : '-'}</div>}
                      {(!this.state.data.deviceInstallationDate || this.state.deviceInstallationDateEdited) && <DateInput value={this.state.data.deviceInstallationDate} label={gettext('ui_monitoring_installation_date')} name="deviceInstallationDate" onChange={this.onDateChange.bind(this)} />}
                    </div>
                    /*<DateInput value={this.state.data.deviceInstallationDate} label={gettext('ui_monitoring_installation_date')} name="deviceInstallationDate" onChange={this.onDateChange.bind(this)} />*/
                  }
                </div>
              </div>
            </div>


            <h6>{this.state.data.serviceType === 'fiksuvesi' ? gettext('ui_consumption_history_before_fiksuvesi') : gettext('ui_consumption_history')}</h6>
            {/*this.state.data.serviceType === 'fiksuvesi' &&
              <div>
                <br />
                <mui.Checkbox
                  defaultChecked={this.state.data.noHistory}
                  name="sendReport"
                  label={gettext('consumption history not avaliable')}
                  onCheck={(e, checked) => {
                    let data = this.state.data
                    data.noHistory = checked
                    PremiseActions.setPremise(data);
                  }} />
              </div>
                */}

            {this.state.data.serviceType !== 'fiksuvesi' &&
              <p>{gettext('If premise history is not supplied, consumption of the first complete month is used as a comparison period')}</p>
            }
            {this.state.data.noHistory ?
              <p>{gettext('Consumption of the first complete month is used as a comparison period')}</p> :
              <table className="table">
                <thead>
                  <tr>
                    <th>{gettext('ui_timestamp')}</th>
                    <th>{gettext('ui_consumption')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {historyData.sort((a, b) => a.time.localeCompare(b.time)).map((item, index) => {
                    const date = new Date(item.time);
                    return (
                      <tr key={item.time + item.value + index}>
                        <td>{`${date.getMonth() + 1} / ${date.getFullYear()}`}</td>
                        <td>{item.value} {gettext('unit_m3')}</td>
                        <td><Button variant='outlined' onClick={() => this.onRemoveHistoryItem(index)} >{gettext('ui_remove')}</Button></td>
                      </tr>
                    );
                  })}
                  <tr className="bottom">
                    <td>
                      <DateInput
                        type="month"
                        value={this.state.newHistoryDate ? this.state.newHistoryDate : ''}
                        label={gettext('ui_month')}
                        onChange={(nill, date) => this.setState({ newHistoryDate: date })} />
                    </td>
                    <td>
                      <TextInput
                        label={gettext('ui_consumption') + ' (' + gettext('unit_m3') + ')'}
                        value={this.state.newHistoryValue}
                        onChange={(value) => this.setState({ newHistoryValue: value })}
                        onEnterKeyDown={this.onAddHistoryItem.bind(this)} />
                    </td>
                    <td>
                      <Button
                        variant='contained'
                        onClick={this.onAddHistoryItem.bind(this)} >{gettext('ui_add')}</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            }
          </div>
        }
      </div>
    );

  }
}
