import Jed from 'jed';
//import AppStore from '../stores/AppStore';

var langs = {
  'en-us': require('i18n/locale/en-us.po'),
  'fi-fi': require('i18n/locale/fi-fi.po')
};

var jed;
var locale;

export function setLocale(newLocale) {
  if (newLocale === 'en-us' || newLocale === 'fi-fi') {
    //console.log("langs:",langs);
    jed = new Jed(langs[newLocale]);
    locale = newLocale;
    //console.log("JED "+newLocale+" :",jed);
  }
}

export function currentLocale() {
  return locale;
}

export function gettext(key, ...args) {
  //console.log("gettext",key);
  //console.log("-> ",jed.translate(key).fetch());
  return jed.translate(key).fetch(args);
}

setLocale('fi-fi');
