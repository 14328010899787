import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '../components/AppBar';
import PremiseActions from '../actions/PremiseActions';
import ReportActions from '../actions/ReportActions';
import PremisesStore from '../stores/PremisesStore';
import { gettext } from 'i18n';
import connectToStores from 'alt-utils/lib/connectToStores';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';
import SearchFilter from '../components/SearchFilter';
import PremisesSummary from '../components/PremiseSummaryCard';
import history from '../utils/history';
import _ from 'lodash';
import ViewLoader from '../components/ViewLoader';
import moment from 'moment';
import Utils from '../utils/utils';
import LoginStore from '../stores/LoginStore';
import SendPDFDialog from '../components/SendReportDialog';
import ReportStore from '../stores/ReportStore';
import MonthSelectionDialog from '../components/MonthSelectionDialog';
import 'rc-pagination/assets/index.css';
import '../styles/pagination.css'
import Pagination from 'rc-pagination';
import InfoDialog from '../components/InfoDialog';
import LatestImagesDialog from '../components/LatestImagesDialog';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import Icon from '@material-ui/core/Icon';
import ServiceRequestInfoDialog from '../components/serviceRequestInfoDialog';

@connectToStores
export default class PremisesView extends React.Component {

  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme);
    this.state = {
      muiTheme: muiTheme,
      menuRefs: [],
    };
  }

  static getStores() {
    return [PremisesStore, LoginStore, ReportStore];
  }

  static getPropsFromStores() {
    return {
      ...PremisesStore.getState(),
      login: LoginStore.getState(),
      eventHistory: ReportStore.getState().reportHistory,
      dialogError: ReportStore.getState().dialogError,
      reportGenerationErr: ReportStore.getState().queryError,
    };
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  static propTypes = {
    error: PropTypes.string,
    data: PropTypes.array,
    login: PropTypes.object,
    reportGenerationErr: PropTypes.object,
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }
  componentDidMount() {
    this.initialQuery = setTimeout(() => {
      PremiseActions.query(this.props.mongoQuery)
    }, 100);
    setTimeout(()=>{
      PremiseActions.getDistinctCountries();
    },200);
  }

  componentWillUnmount() {
    PremiseActions.reset();
  }

  // TODO linter says these are not used
  /*componentWillReceiveProps(props) {
    const getIds = (x) => x._id;
    //let loadMoreData = false;

    if (props.data) {
      if (!this.props.data) {
        loadMoreData = true;
      } else {
        const ids1 = props.data.map(getIds).sort();
        const ids2 = this.props.data.map(getIds).sort();
        loadMoreData = !_.isEqual(ids1, ids2);
      }
    }
  }*/

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.reportGenerationErr !== this.props.reportGenerationErr) {
      console.log('report generation ready-> forceupdate');
      PremiseActions.query.defer(this.props.mongoQuery);
    }
  }

  onRowSelection(item) {
    history.push('/report/' + item._id);
  }

  onserviceRequestSelection(item) {
    this.refs.serviceRequestInfoDialog.open(item);

  }

  onPageChange(current, pageSize) {
    this.props.mongoQuery.skip = (current - 1) * pageSize;
    PremiseActions.query.defer(this.props.mongoQuery);
  }

  sortBy(sb/*, e*/) {

    let keys = this.props.mongoQuery && this.props.mongoQuery.sort && Object.keys(this.props.mongoQuery.sort)
    let sortKey
    if (keys && keys[0]) {
      sortKey = keys[0]
    }
    if (sb === sortKey && this.props.mongoQuery.sort[sb] > 0) {
      this.props.mongoQuery.sort = { [sb]: -1 }
    } else {
      this.props.mongoQuery.sort = { [sb]: 1 }
    }

    PremiseActions.query.defer(this.props.mongoQuery);

    /*if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({sortParams: {sortBy: sb, order: "desc"}});
    } else {
      this.setState({sortParams: {sortBy: sb, order: "asc"}});
    }*/
  }

  onFilterChange(filters) {
    if (Object.keys(filters).length === 0) return;
    PremiseActions.reset();
    let query = Object.assign({}, filters);
    let start, end;

    if (query.month) {
      const month = query.month.match(/(\d+-\d+).*/)[1];
      start = month;
      end = month;
      delete query.month;
    }

    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }

    this.props.mongoQuery.query = query;
    //this.props.mongoQuery.skip = 0
    this.props.mongoQuery.start = start
    this.props.mongoQuery.end = end

    PremiseActions.query(this.props.mongoQuery);
  }

  onRemoveFilterItem(filters) {
    if (Object.keys(filters).length === 0) {
      PremiseActions.reset();
      this.props.mongoQuery.query = {}
      //this.props.mongoQuery.skip = 0
      delete this.props.mongoQuery.start
      delete this.props.mongoQuery.end

      PremiseActions.query(this.props.mongoQuery);
    }
  }

  downloadPdf(id) {
    const premise = this.props.data.find((p) => p._id === id);
    if (premise) {
      const url = Utils.pdfReportUrl(
        premise.name,
        id,
        'latest',
        LoginStore.getState().token
      );
      window.location = url;
    }
  }

  sendPdf(premiseId, reportId) {
    this.setState({
      sendPdfDialog: {
        premise: premiseId,
        report: reportId
      }
    });
    this.refs.sendDialog.open();
    ReportActions.eventHistory(reportId);
  }

  onSendReport(receiverList) {
    const pdfIds = this.state.sendPdfDialog;
    ReportActions.resetErrors();
    ReportActions.sendReport(pdfIds.report, pdfIds.premise, receiverList, (err) => {
      if (!err) {
        this.refs.sendDialog.close();
      }
    });
  }

  onCancelSendReport() {
    ReportActions.resetErrors();
    this.refs.sendDialog.close();
  }

  showLatestImagesDialog(devices, i) {
    if (devices && devices.length) {
      PremiseActions.getLatestImages(devices);
      this.refs.latestImagesDialog.open(devices);
    } else {
      console.warn("No devices found.");
    }
    this.handleCloseMenuClick(null, i);
  }

  handleOpenMenuClick(e, i) {
    let menuRefs = this.state.menuRefs;
    menuRefs[i] = e.target;
    this.setState({ menuRefs });
  }

  handleCloseMenuClick(e, i) {
    let menuRefs = this.state.menuRefs;
    menuRefs[i] = null;
    this.setState({ menuRefs });
  }

  onGenerateMonthSelected(month) {
    let u = this.refs.generateReportMonthSelectionDialog.dialogData
    //console.log(month, u)
    u.reportGenerating = 1;
    this.forceUpdate()
    this.refs.generateReportMonthSelectionDialog.close();
    ReportActions.generateAutomaticReport(u._id, month)

  }

  render() {
    let loading = false;
    let data = [];
    if (this.props.data === null) {
      loading = true;
    } else {
      loading = false;
      data = this.props.data;
    }
    const showGenerateButtons = this.props.login.user && this.props.login.role === 'admin' || this.props.login.user && this.props.login.role === 'salesperson';

    var countries=[];
    if (this.props.countries) {
      countries = this.props.countries.map((country, i) => { return { value: country, label: country } });
    } 
    const filterReportMonths = [];
    let date = Utils.lastFullMonthStr();
    while (!date.match(/2014.*/)) {
      filterReportMonths.push({
        value: date,
        label: moment(date).format('MM/YYYY')
      });
      date = Utils.addMonthToDateStr(date, -1);
    }

    let filters = [
      { type: 'name', prop: "name", operator: '%', text: gettext('ui_name'), value: '' },
      { type: 'manager', prop: "name", operator: '%', text: gettext('ui_manager'), value: '', elem: 'interestGroups', additionalElem: { type: 'manager' } },
      { type: 'maintenance', prop: "name", operator: '%', text: gettext('maintenance'), value: '', elem: 'interestGroups', additionalElem: { type: 'maintenance' } },
      { type: 'address', prop: "address", operator: '%', text: gettext('ui_address'), value: '' },
      { type: 'zipCode', prop: "zipCode", operator: '-%', text: gettext('ui_zipCode'), value: '' },
      { type: 'postOffice', prop: "postOffice", operator: '%', text: gettext('ui_postOffice'), value: '' },
      { type: 'parentAccount', prop: "parentAccount", operator: '%', text: gettext('Parent Account'), value: '' },
      { type: 'country', prop: "country", operator: '=', text: gettext('ui_country_code'), options: countries},
      { type: 'owner', prop: "owner", operator: '%', text: gettext('CRM Owner'), value: '' },
      { type: 'propertyType', prop: "propertyType", operator: '%', text: gettext('Property type'), value: '' },
      { type: 'waterCompany', prop: "waterCompany", operator: '=', text: gettext('Water Company'), value: '' },
      { type: 'districtHeatingCompany', prop: "districtHeatingCompany", operator: '=', text: gettext('Heating Company'), value: '' },
      
      /*this.state.currentMonth ? null : {
        type: 'month',
        prop: "month",
        text: gettext('ui_report_date'),
        options: filterReportMonths,
        onFilterChange: this.onReportDateChange.bind(this),
        onFilterRemove: this.onReportDateClear.bind(this)
      },*/
      this.state.source ? null : {
        type: 'source',
        prop: "consumptionSource",
        text: gettext('ui_consumption_data'),
        operator: '%',
        options: [
          {
            value: 'auto',
            label: gettext('ui_automatic')
          },
          {
            value: 'manual',
            label: gettext('ui_manual_input')
          }
        ]
      },
      {
        type: 'serviceType',
        prop: "serviceType",
        text: gettext('ui_service_type'),
        operator: '%',
        options: [
          {
            value: 'fiksuvesi',
            label: gettext('ui_fiksuvesi_service')
          },
          {
            value: 'monitoring',
            label: gettext('ui_consumption_monitoring')
          }
        ]
      },
      {
        type: 'savingsGuarantee',
        prop: "savingsGuarantee",
        text: gettext('ui_savings_guarantee'),
        operator: '=',
        options: [
          {
            value: 'true',
            label: gettext('ui_valid')
          },
          {
            value: 'false',
            label: gettext('ui_not_valid')
          }
        ]
      },
      { type: 'devices', prop: "devices", operator: '%', text: gettext('ui_deviceId'), value: '' },
      {
        type: 'terminationDate',
        prop: "terminationDate",
        text: gettext('contract_terminating_date_set'),
        operator: '$e',
        options: [
          {
            value: 1,
            label: gettext('yes')
          },
          {
            value: 0,
            label: gettext('no')
          }
        ]
      },
      {
        type: 'endDate',
        prop: "endDate",
        text: gettext('contract_end_date_set'),
        operator: '$nullcheck',
        options: [
          {
            value: 1,
            label: gettext('yes')
          },
          {
            value: 0,
            label: gettext('no')
          }
        ]
      },
      //Extra handler in premises api
      {type: 'deviceState', prop: "deviceState",text: gettext('Device State'), operator: '=',
        options: [
          {
            value: 'delivered',
            label: 'Delivered'
          },
          {
            value: 'active',
            label: 'Active'
          },
          {
            value: 'returned',
            label: 'Returned'
          },
          {
            value: 'smart-device',
            label: 'Smart Meter'
          }
        ]
      },
      {type: 'adaptiveAlarms', prop: "adaptiveAlarms",text: gettext('Adaptive Highflow limits'), operator: '=',
        options: [
          {
            value: false,
            label: 'no'
          },
          {
            value: true,
            label: 'yes'
          },

        ]
      }
    ].filter((f) => !!f);

    //let iconButtonElement = (<mui.IconButton style={{ height: '42px', padding: '0px' }}><mui.FontIcon color="black" className="mdi mdi-menu"></mui.FontIcon></mui.IconButton>);

    let generateAutomaticReport = (u) => {
      this.refs.generateReportMonthSelectionDialog.open(u);
    }

    const createMenuItems = (u, i) => {
      let items = [];
      const automatic = u.consumptionSource === 'auto';
      if (automatic) {
        items.push(<MenuItem key="analyze" onClick={() => history.push('/analyze/' + u._id)} >{gettext("ui_analyze")}</MenuItem>);
      }
      items.push(<MenuItem key="report" onClick={() => this.onRowSelection(u)} >{gettext("ui_report")}</MenuItem>);
      items.push(<MenuItem key="alarms" onClick={() => history.push('/alarms/' + u._id)} >{gettext("ui_alarms") + " v2"}</MenuItem>);
      items.push(<MenuItem key="events" onClick={() => history.push('/events?premiseId=' + u._id + '&cols=[action,timestamp,source,data]&title=' + encodeURIComponent(u.name))} >{gettext("ui_events")}</MenuItem>);
      items.push(<MenuItem key="edit" onClick={() => history.push('/premises/edit/' + u._id)} >{gettext("ui_edit")}</MenuItem>);
      if (showGenerateButtons && automatic) {
        items.push(<MenuItem key="generate" onClick={() => generateAutomaticReport(u)} >{gettext("ui_generate_report")}</MenuItem>);
      }
      if (u.latestReport) {
        //items.push(<MenuItem key="download" primaryText={gettext("ui_download_report %s/%s",u.latestReport.month, u.latestReport.year )} onClick={() => this.downloadPdf(u._id)} />);
        items.push(<MenuItem key="send" onClick={() => this.sendPdf(u._id, u.latestReport._id)} >{gettext("ui_send_report %s/%s", u.latestReport.month, u.latestReport.year)}</MenuItem>);
      }
      if (u.apartments && Object.keys(u.apartments).length) {
        items.push(<MenuItem key="temperature" onClick={() => history.push(`/temperature?premiseId=${u._id}&datapointIds=00017850`)} >{"Lämpötilat"}</MenuItem>);
      }
      if (u.devices && u.devices.length) {
        items.push(<MenuItem key="latestimages" onClick={() => this.showLatestImagesDialog(u.devices, i)} >{gettext("latest_images")}</MenuItem>);
      }
      //gettext('ui_meter_reading %s', meter.id)
      return items;
    }

    let rows = data.map((premise, i) => {


      let lText = '-' //gettext('ui_loading')
      let changePercent = lText,
        change = lText,
        changeEuro = lText;
      if (!premise.loading && premise.totalSavings) {
        let totalSavings = premise.totalSavings
        changeEuro = totalSavings.changeEuro
        change = totalSavings.change
        changePercent = totalSavings.changePercent
      }

      let devicesCount = premise.devices && premise.devices.length || 0;
      let states = [];
      let Delivered = 0;
      let DeliveredIds= ''
      let Active= 0;
      let ActiveIds=''
      let Returned = 0;
      let ReturnedIds=''
      let Others = 0;
      let OthersIds ='';
      let SmartMeters = 0;
      let SmartMetersIds ='';
      let unknownDevice = 0;
      let unknownIds ='';
      let Errors = 0;
      let ErrorsIds ='';
      let Gateways = 0;
      let GatewaysIds = '';
      
      let devices = premise.devices || []
      let devicesInfo = premise.devicesinfo || {} 
 

      for (let index in devices) {
        let d = devices[index]
        let s = devicesInfo[d] && devicesInfo[d].state ? devicesInfo[d].state : "stateError";
        if(s==="delivered"){
          ++Delivered;
          DeliveredIds = `${DeliveredIds} ${d}` 
        } else if(s==="active"){
          ++Active;
          ActiveIds = `${ActiveIds} ${d}`
        } else if(s==="returned"){
          ++Returned;  
          ReturnedIds = `${ReturnedIds} ${d}`
        } else if(s==="smartMeter"){
          ++SmartMeters;
          SmartMetersIds = `${SmartMetersIds} ${d}` 
        } else if(s==="unknownDevice"){
          ++unknownDevice;
          unknownIds = `${unknownIds} ${d}` 
        }else if(s="stateError") {
          ++Errors;
          ErrorsIds =  `${ErrorsIds} ${d}` 
        }else {
        ++Others;
        OthersIds =`${OthersIds} ${d}`
       }
      }
      Gateways = premise.gatewayDevices && premise.gatewayDevices.length ? premise.gatewayDevices.length : 0
      GatewaysIds = premise.gatewayDevices && premise.gatewayDevices.length ? premise.gatewayDevices.toString() : ''
      const isDelivered = (states.indexOf('delivered') > -1)
      let devicesCountCss = devicesCount ? "numberbadge" : "numberbadge numberbadgeNoEntry";
      let unreliable = devicesCount && premise.devicesinfo && _.findKey(premise.devicesinfo, { 'unreliable': true }) ? true : false;
      let deliveredtext = `${Delivered} Delivered Device(s) ${DeliveredIds}`
      let activetext = `${Active} Active Device(s) ${ActiveIds}`
      let othertext = `${Others} Other state Device(s) ${OthersIds}`
      let returnedtext = `${Returned} Returned state Device(s) ${ReturnedIds}`
      let smartmeterstext = `${SmartMeters} Smart meter(s) ${SmartMetersIds}`
      let unkonwntext = `${unknownDevice} Unknown Device(s) ${unknownIds}`
      let errortext = `${Errors} State Error Device(s) ${ErrorsIds}`
      let gatewaytext = `${Gateways} Gateway Device(s) ${GatewaysIds} `

      return (
        <tr key={i} >
          <td className="pointer">
            {(premise.passive) && <Icon onClick={() => this.refs.passiveInfoDialog.open()} title={gettext('Premise is marked passive')} className="premisesIcons">domain_disabled</Icon>}
            {unreliable && <Icon className="premisesIconsWarning" title={gettext('unreliable reading')} fontSize='small' color="#ffd037">error_outline</Icon>}
            {premise.adaptiveAlarmLimits && <Icon className="premisesIconsBlue" title={gettext('Adaptive Highflow Alarm Limits')} fontSize='small'>av_timer</Icon>}
            {(premise.activeServiceRequests && premise.activeServiceRequests.length > 0) && <Icon onClick={() => this.onserviceRequestSelection(premise)} title={gettext('Premise has service request')} className="premisesIcons" >error_outline</Icon>} <span onClick={() => this.onRowSelection(premise)}>{premise.name} <br /> {premise.address} {premise.zipCode} {premise.postOffice} {premise.country} </span> </td>
          <td>
            {false && <span className={devicesCountCss} style={isDelivered ? { backgroundColor: 'yellow', color: 'black' }: {}}>{devicesCount} </span>}
            {(Delivered > 0) && <span data-tooltip={deliveredtext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: 'yellow', color: 'black' }}>{Delivered} </span>}
            {(Active > 0) && <span data-tooltip={activetext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: '#96C62F', color: 'white'}}>{Active} </span>}
            {(Returned > 0) && <span data-tooltip={returnedtext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: '#0060FF', color: 'white' }}>{Returned} </span>}
            {(Others > 0) && <span data-tooltip={othertext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: 'red', color: 'white' }}>{Others} </span>}
            {(SmartMeters > 0) && <span data-tooltip={smartmeterstext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: 'black', color: 'white' }}>{SmartMeters} </span>}
            {(unknownDevice > 0) && <span data-tooltip={unkonwntext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: '#DB57DE', color: 'yellow' }}>{unknownDevice} </span>}
            {(Errors > 0) && <span data-tooltip={errortext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: 'white', color: 'red' }}>{Errors} </span>}
            {(Gateways > 0) && <span data-tooltip={gatewaytext} data-tooltip-position="bottom" className={devicesCountCss} style={{backgroundColor: 'grey', color: 'yellow' }}>{Gateways} </span>}
            </td>
          <td>{premise.deviceAttachDate ? Utils.displayDate(premise.deviceAttachDate) : '-'}</td>  
          <td>{typeof changePercent === 'number' ? -changePercent : changePercent}</td>
          <td>{changeEuro}</td>
          <td>{typeof change === 'number' ? -change : change}</td>
          <td>{premise.reportGenerating ? gettext('ui_generating') : ((premise.latestReport && premise.latestReport.month && premise.latestReport.year) ? premise.latestReport.month + " / " + premise.latestReport.year : "-")}</td>
          <td>{premise.reportSent ? Utils.displayDate(premise.reportSent) : '-'}</td>
          <td>
            <IconButton
              aria-label="More"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => { this.handleOpenMenuClick(e, i) }}
            >
              <MenuIcon />
            </IconButton>
            <div>
              <Menu
                open={this.state.menuRefs[i]?this.state.menuRefs[i]:false}
                anchorEl={this.state.menuRefs[i]}
                onClose={(e) => this.handleCloseMenuClick(e, i)}
              >
                {createMenuItems(premise, i)}
              </Menu>
            </div>
          </td>
        </tr>
      )
    })

    let table = '';
    if (data.length > 0 && !loading) {
      table = (
        <table className="table customHeight">
          <thead>
            <tr>
              <th onClick={this.sortBy.bind(this, 'name')}>{gettext('ui_premise')}</th>
              <th>{gettext('ui_devices')}</th>
              <th onClick={this.sortBy.bind(this, 'deviceAttachDate')}>{gettext('Device Attach Date')}</th>
              <th onClick={this.sortBy.bind(this, 'totalSavings.changePercent')}>{gettext('ui_saving_precent')}</th>
              <th onClick={this.sortBy.bind(this, 'totalSavings.changeEuro')}>{gettext('ui_total_saving_eur')}</th>
              <th onClick={this.sortBy.bind(this, 'totalSavings.change')}>{gettext('ui_saving_qubic')}</th>
              <th onClick={this.sortBy.bind(this, 'latestReport')}>{gettext('ui_latest_report')}</th>
              <th onClick={this.sortBy.bind(this, 'reportSent')}>{gettext('ui_report_sent')}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    } else if (!loading) {
      table = 'Ei tuloksia';
    }
    const newPremiseButton = this.props.login.root ? (
      <div>
        <i className="mdi mdi-plus-box" />
        {gettext('ui_add_new')}
      </div>
    ) : null;

    const error = <div className="container"><h4>Error: {this.props.error}</h4></div>
    const sendHistory = this.props.eventHistory ? this.props.eventHistory : [];
    let interestGroups = [];
    if (this.state.sendPdfDialog) {
      const premise = this.props.data.find((p) => p._id === this.state.sendPdfDialog.premise);
      if (premise && premise.interestGroups) {
        interestGroups = premise.interestGroups;
      }
    }

    let count, averageSavingsPercent, totalSavingsEuro, totalSavings, totalDevicesCount
    if (this.props.totalSavings) {
      const tc = this.props.totalSavings;
      count = tc.count || 0;
      totalDevicesCount = tc.devicescount || 0;
      averageSavingsPercent = tc.averageSavingsPercent * -1 || 0;
      totalSavingsEuro = tc.totalSavingsEuro || 0;
      totalSavings = tc.totalSavings * -1 || 0;
    }

    const content = (
      <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
        <div className="row top-xs">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <SearchFilter menuitems={filters} filters={this.props.mongoQuery.query} onChange={this.onFilterChange.bind(this)} onRemoveFilterItem={this.onRemoveFilterItem.bind(this)} />
            <Pagination
              defaultPageSize={this.props.mongoQuery.limit}
              current={this.props.mongoQuery.skip / this.props.mongoQuery.limit + 1}
              pageSize={this.props.mongoQuery.limit}
              onChange={this.onPageChange.bind(this)}
              total={count}
              locale={{
                items_per_page: gettext('/ sivu'),
                jump_to: gettext('Mene sivulle'),
                page: '',
                prev_page: gettext('Edellinen sivu'),
                next_page: gettext('Seuraava sivu'),
                prev_5: gettext('Edelliset 5 sivua'),
                next_5: gettext('Seuraavat 5 sivua'),
                prev_3: gettext('Edelliset 3 sivua'),
                next_3: gettext('Seuraavat 3 sivua'),
              }}
              showLessItems
              showQuickJumper />
          </div>
        </div>
        <div className="row top-xs" style={{ paddingBottom: loading ? 0 : '90px' }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">{table}</div>
        </div>
        {loading && <ViewLoader blue={true} />}
        <div className="footer" >
          <div style={{ width: '100%', maxWidth: '1280px', maxHeight: '85px' }}>
            <PremisesSummary count={count} totalDevicesCount={totalDevicesCount} averageSavingPros={averageSavingsPercent} totalSavingEur={totalSavingsEuro} totalSaving={totalSavings} />
          </div>
        </div>
      </div>
    )
    return (
      <div>
        <MonthSelectionDialog ref="generateReportMonthSelectionDialog"
          title={gettext('ui_select_report_generation_month')}
          text={gettext('ui_generate_report_info_text')}
          onAccepted={this.onGenerateMonthSelected.bind(this)} />
        <AppBar
          title={gettext('title_kohteet')}
          actionBtn={newPremiseButton}
          onActionBtnClick={() => history.push('/newpremise')} />
        {this.props.error ? error : content}
        <SendPDFDialog ref="sendDialog"
          error={this.props.dialogError}
          sendHistory={sendHistory}
          onSendReport={this.onSendReport.bind(this)}
          onCancel={this.onCancelSendReport.bind(this)}
          interestGroups={interestGroups} />
        <InfoDialog ref="passiveInfoDialog"
          title={gettext('ui_passive_title')}
          text={gettext('ui_passive_premise_is_marked_as_passive')} />
        <ServiceRequestInfoDialog ref="serviceRequestInfoDialog"
          title={gettext('ui_open_servicerequests')} />
        <LatestImagesDialog ref="latestImagesDialog" data={this.props.latestImages} autoScrollBodyContent role={this.props.login.role} />
      </div>
    );
  }
}
