import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import BaseView from './BaseView';
//import { PropTypes } from 'react-proptypes'
import AppBar from '../components/AppBar';
import { gettext } from 'i18n';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
const MyRawTheme = require('../components/Theme');


export default class ConfiguratorView extends BaseView {
  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme
    };
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
    //history: PropTypes.any.isRequired
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentWillMount() {
  }

  

  render() {


    return (
      <div>
      <AppBar title={gettext('Configurator view')}/>
      <h6>Configurator logged in</h6>
     </div>
    );

  }
}
