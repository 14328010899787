import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
import moment from 'moment'

let RA;

function path(path) {
  var b = new UrlBuilder()
  return b.path(path)
}

class ReportActions {
  constructor() { this.generateActions('reset', 'resetErrors'); }

  query(premiseId, reportDate, query) {

    return (dispatch) => {
      return Promise.all([
        api.get(path('premises').id(premiseId).filter({ latestReport: 0, totalSavings: 0 })),
        api.get(path('reports').query(query))
      ]).then(arr => {
        let premise = arr[0] && arr[0].data
        let reports = arr[1] && arr[1].data

        const installDate = moment(premise.installationDate).format('YYYY-MM-DD');
        reportDate = moment(reportDate).format('YYYY-MM');
        if (premise.serviceType === 'fiksuvesi') {
          return api.get(path('premises').id(premiseId + '/totalsavings').start(installDate).end(reportDate)).then(savingsRes => {
            let totalSavings = savingsRes.data
            return { premise, reports, totalSavings };
          })
        }
        return { premise, reports }
      }).then(res => {
        //setTimeout(() => {
          dispatch(res);
        //})
      }).catch((err) => {
        console.error(err.stack || err);
        dispatch({ error: err.data });
      });
    }
  }

  totalSavings(premiseId, start, end) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.get(b.path('premises').id(premiseId + '/totalsavings').start(start).end(end)).then((savingsRes) => {
        let totalSavings = savingsRes.data
        dispatch({ totalSavings });
      }).catch((err) => {
        console.error(err.stack || err);
        dispatch({ error: err.data });
      });
    }
  }

  generateManualReport(premiseId, date, value) {
    return (dispatch) => {
      let year, month;
      let dt = new Date(date)
      if (!date) {
        dt = new Date()
        dt.setUTCMonth(dt.getUTCMonth() - 1) // previous month
      }
      year = dt.getUTCFullYear();
      month = dt.getUTCMonth() + 1;
      date = dt.toISOString()

      var b = new UrlBuilder();
      return api.post(b.path('reports/manual'), { premiseId, year, month, value }).then((res) => {
        return RA.query(premiseId, date, { premiseId });
      }).catch((err) => {
        console.error(err);
        dispatch({ data: null , err: err.response.data });
      })
    }
  }

  generateAutomaticReport(premiseId, date) {
    return (dispatch) => {
      let year, month;
      let timeZone = 2;
      let dt = new Date(date)
      if (!date) {
        dt = new Date()
        dt.setUTCMonth(dt.getUTCMonth() - 1) // previous month
      }
      year = dt.getUTCFullYear();
      month = dt.getUTCMonth() + 1;
      date = dt.toISOString()
      const shortForm = (d) => d.match(/(\d+-\d+).*/)[1];
      var b = new UrlBuilder();
      return api.post(b.path('reports'), { premiseId, year, month, timeZone }).then((res) => {
        return this.getSavings(premiseId, shortForm(date), shortForm(date))
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ data: null, err: err.response.data });
      });
    }
  }

  generateReport(premiseId, date) {
    return function (dispatch) {
      let year, month;
      let timeZone = 2;
      let dt = new Date(date)
      if (!date) {
        dt = new Date()
        dt.setUTCMonth(dt.getUTCMonth() - 1) // previous month
      }
      year = dt.getUTCFullYear();
      month = dt.getUTCMonth() + 1;

      var b = new UrlBuilder();
      return api.post(b.path('reports'), { premiseId, year, month, timeZone }).then((res) => {
        dispatch({ data: res, err: null });
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ data: null, err: err.response.data });
      });
    }
  }

  sendReport(reportId, premiseId, receiverlist, cb) {
    return (dispatch) => {
      var b = new UrlBuilder();
      return api.post(b.path('reports/' + reportId + '/send'), { receiverlist, premiseId }).then((res) => {
        dispatch({ sendResponse: res });
        if (cb) cb()
      }).catch((err) => {
        console.log(err.stack || err)
        if (cb) cb(err);
        dispatch({ data: null, err });
      });
    }
  }

  deleteReport(reportId) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.delete(b.path('reports/' + reportId)).then((res) => {
        dispatch({ sendResponse: res });
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ data: null, err });
      });
    }
  }

  eventHistory(reportId) {
    return (dispatch) => {
      var q = { $or: [{ action: 'send_report_v2' }, { action: 'create_report' }, { action: 'send_report' }, { action: 'create_report_manual' }], reportId: reportId }
      var b = new UrlBuilder()
      return api.get(b.path('events').query(q)).then((res) => {
        dispatch(res.data);
      }).catch((err) => {
        console.error(err.stack || err);
        dispatch(null);
      });
    }
  }

  generatePdfs(premiseName, premiseId, reportDate) {
    return (dispatch) => {
      let month = moment(reportDate).month() + 1;
      let year = moment(reportDate).year();
      var b = new UrlBuilder()
      return api.post(b.path('reports/createpdffiles'), { month, year, premiseId, premiseName }).then((res) => {
        dispatch({ response: res });
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ data: null, err });
      });
    }
  }

  generatePdfReport2(premiseId, reportDate) {
    return (dispatch) => {
      let month = moment(reportDate).month() + 1;
      let year = moment(reportDate).year();
      var b = new UrlBuilder();
      return api.get(b.path('reports/generatepdfreport2/' + premiseId + '/' + year + '/' + month)).then((res) => {
        dispatch( res );
      }).catch((err) => {
        console.log(err.stack || err);
        dispatch( { data: null, err } );
      });
    }
  }
}


RA = (alt.createActions(ReportActions));
export default RA;
