import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class AlarmLimitsDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.state = {
      //muiTheme
      dialogOpen: false,
    };
  }

  getChildContext() {
    return { /*muiTheme: this.state.muiTheme*/ };
  }

  open() {
    this.setState({ dialogOpen: true });
    //this.refs.dialog.show();
  }

  close() {
    this.setState({ dialogOpen: false });
    //this.refs.dialog.dismiss();
  }

  render() {
    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    let title = this.props.title ? this.props.title : gettext('ui_AlarmLimits');
    let settings = this.props.device && this.props.device.settings || { alarms: {} };
    let metaAlarms = this.props.device && this.props.device.meta && this.props.device.meta.alarms && this.props.device.meta.alarms.weekendHighFlowLimits || null;
    const error = (this.props.newHighflowLimits && !this.props.newHighflowLimits.highFlowLowLimit) ? this.props.newHighflowLimits : null;
    const lowLimit = this.props.newHighflowLimits.highFlowLowLimit || (settings && settings.alarms && settings.alarms.highFlowLowLimit) || '-';
    const midLimit = this.props.newHighflowLimits.highFlowMidLimit || (settings && settings.alarms && settings.alarms.highFlowMidLimit) || '-';
    const highLimit = this.props.newHighflowLimits.highFlowHighLimit || (settings && settings.alarms && settings.alarms.highFlowHighLimit) || '-';
    
    if(this.props.newWeekEndHighflowLimits){
      metaAlarms = this.props.newWeekEndHighflowLimits.weekendHighFlowLimits
    }

    const hourAlarmLimit = settings && settings.alarms && settings.alarms.hourAlarmLimit || null;
    let limitStyle = { paddingLeft: '20px' };
    if (error != null) limitStyle.color = 'red'
    else if (this.props.newHighflowLimits && this.props.newHighflowLimits.highFlowLowLimit) limitStyle.color = 'green';
    return (
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}>
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="row around-md around-xs" >
            <div className="col-xs-4 col-md-4" >

              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-12 col-md-12">
                  <h4>{gettext('high_flow_limits')}</h4>
                  <div >
                    {(lowLimit && !this.props.loading && !error) ?
                      (
                        <table className="highflowtable">
                          <tr><td>Low:</td><td>{`${lowLimit} l/min`}</td></tr>
                          <tr><td>Mid:</td><td>{`${midLimit} l/min`}</td></tr>
                          <tr><td>High:</td><td>{`${highLimit} l/min`}</td></tr>
                        </table>
                      ) : (<div>{error}</div>)
                    }
                    {this.props.loading && <div><CircularProgress /></div>}
                  </div>
                </div>
              </div>
              <div className="row start-md start-sx">
                <div className="col-xs-7 col-md-7">
                  {this.props.root && <Button variant='outlined' secondary={true} onClick={() => { this.props.recalculateHighFlow(this.props.device.id) }} > {gettext('calculate')} </Button>}
                </div>
              </div>
            </div>
            <div className="col-xs-4 col-md-4" >
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-12 col-md-12">
                  <h4>{gettext('high_flow_weekend_limits')}</h4>
                  <div >
                    {(lowLimit && !this.props.loading && !error) ?
                      (
                        <table className="highflowtable">
                          <tr><td>Low:</td><td>{`${metaAlarms ? metaAlarms.highFlowLowLimit : '-'} l/min`}</td></tr>
                          <tr><td>Mid:</td><td>{`${metaAlarms ? metaAlarms.highFlowMidLimit : '-'} l/min`}</td></tr>
                          <tr><td>High:</td><td>{`${metaAlarms ? metaAlarms.highFlowHighLimit : '-'} l/min`}</td></tr>
                        </table>
                      ) : (<div>{error}</div>)
                    }
                    {this.props.loading && <div><CircularProgress /></div>}
                  </div>
                  <div className="row start-md start-sx">
                <div className="col-xs-12 col-md-12">
                  {this.props.root && <Button variant='outlined' secondary={true} onClick={() => { this.props.recalculateWeekEndHighFlow(this.props.device.id) }} > {gettext('re-calculate weekend')} </Button>}
                </div>
              </div>

            </div>
            </div>      

            </div>

            <div className="col-xs-4 col-md-4" >

              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-9 col-md-9">
                  <h4>{gettext('alarm_limits')}</h4>
                  <div style={{ paddingLeft: '20px' }}> {settings && settings.alarms && settings.alarms.minRunNightLimit ? `${settings.alarms.minRunNightLimit} l/min` : `${gettext('default')}` }</div>
                  <h4>{gettext('Device Hour Alarm Limit')}</h4>
                  <div style={{ paddingLeft: '20px' }}> {hourAlarmLimit? `${hourAlarmLimit} l/h` : `-` }</div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} >{gettext('ui_close')}</Button>
        </DialogActions>
      </Dialog >
    );

  }
}

