//let mui = require('material-ui');
//let Colors = mui.Styles.Colors;
import * as Colors from 'material-ui/styles/colors';
//let ColorManipulator = mui.Utils.ColorManipulator;
import * as ColorManipulator from 'material-ui/utils/colorManipulator';
import * as Spacing from 'material-ui/styles/spacing';
//let Typography = require('material-ui/lib/styles/typography');

const theme = {
  spacing: Spacing,
  fontFamily: 'Montserrat, sans-serif',
  palette: {
    primary1Color: '#002458', //Blueberry x
    primary1ColorDarken: '#0D429B', //x
    //primary1ColorDarken: ColorManipulator.darken(fiksublue, 0.2),
    primary2Color: '#EDF8FF',//Mist
    primary3Color: '#96C62F',//Green x
    accent1Color: '#02C1D8',//Coral
    accent2Color: '#EDF8FF',//Mist  '#2CB4DF',//Sky
    accent3Color: '#57514D',//Soil
    textColor: Colors.darkBlack,
    alternateTextColor: Colors.white,
    canvasColor: Colors.white,
    borderColor: Colors.grey300,
    disabledColor: ColorManipulator.fade(Colors.darkBlack, 0.3)
  },
  fiksuvesi:{
    mainContainer:{
      backgroundColor: 'white',
      height: '100%',
      minHeight: '700px',
    },
  }
};

export default theme;