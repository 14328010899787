import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//import connectToStores from 'alt-utils/lib/connectToStores';
import CardBase from './CardBase';
import {gettext} from 'i18n';
import Currencies from '../utils/currencies';

//let ImmutabilityHelper = require('material-ui/lib/utils/immutability-helper');

//@connectToStores
export default class CostEffectCard extends CardBase {
  /*
  static getStores() {
    return [];
  }

  static propTypes = {

  }

  static getPropsFromStores() {
    return Store.getState();
  }
  */
  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      mergedRootStyles:{}
    };
  }

  componentWillMount() {
    this.setInitialStyles();
  }

  render() {
    //console.log("CostEffectCard:",Currencies,this);
    const curr = this.props.reportData.currency || 'eur';
    let savedWaterCost = this.props.reportData ? this.props.reportData.savedWaterCost : '';
    let savedEnergyCost = this.props.reportData ? this.props.reportData.savedEnergyCost : '';
    let muutosyhteensa =  Math.round((savedWaterCost + savedEnergyCost) * 100) / 100;

    let waterprice = this.props.reportData ? this.props.reportData.currentPeriod.waterprice : 0;

    let vesimaksu = waterprice;
    let energiahinta = this.props.reportData ? this.props.reportData.currentPeriod.energyprice : 0;

    let mergedRootStyles = this.state.mergedRootStyles;
    let color = (savedWaterCost >= 0) ? this.state.palette.primary3Color : this.state.palette.accent1Color;
    let cardtext3_right = Object.assign({},mergedRootStyles.cardtext3?mergedRootStyles.cardtext3:{});
    console.log(typeof(mergedRootStyles.cardtext3));
    cardtext3_right.fontSize = '18px';
    cardtext3_right.color = color;
    cardtext3_right.borderColor = color;
    cardtext3_right.paddingBottom = '8px';

    let cardtext1 = Object.assign({},mergedRootStyles.cardtext1);
    cardtext1.lineHeight = '30px';
    cardtext1.maxWidth = '22em'
    cardtext1.textTransform = 'uppercase';

    let summarytext =  {
      color: color,
      lineHeight:'30px',
      paddingTop:'0px',
      paddingBottom:'0px',
    };

    let plusSign = (muutosyhteensa<0) ? '+' : '';
    const totalColor = (muutosyhteensa > 0) ? this.state.palette.primary3Color : this.state.palette.accent1Color;

    const Row = (props) => <tr><th style={cardtext1}>{props.label}</th><td style={cardtext3_right}>{props.value}</td></tr>;
    const TotalRow = (props) => (
      <tr>
        <th style={{...cardtext1, color: totalColor}}>
          {props.label}
        </th>
        <td style={{...cardtext3_right, paddingTop: '8px'}}>
          {props.value}
        </td>
      </tr>
    );

    const totalChangeStyle = {
      color: totalColor,
      borderTop: '2px solid white',
      borderBottom: '2px solid white',
      borderColor: totalColor,
      marginTop: '10px'
    };
    const date = " " + this.props.reportData.month + "/" + this.props.reportData.year;

    return (
      <div style={mergedRootStyles.cardSize}>
        <mui.Card style={mergedRootStyles}>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
          <div style={mergedRootStyles.cardSize}>
            <div style={mergedRootStyles.headerSize}>
              <span style={mergedRootStyles.title}>{gettext('ui_cost_effect').toUpperCase() + date}</span>
            </div>
            <hr style={mergedRootStyles.line}/>
            <table className="card-table">
              <tbody>
                <Row label={gettext('title_vesiJaJatemaksut')} value={plusSign + (-savedWaterCost) + Currencies(curr)} />
                <Row label={gettext('title_laskennallinenmuutos_lammityskuluissa')} value={plusSign + (-savedEnergyCost) + Currencies(curr)} />
              </tbody>
            </table>
            <table className="card-table" style={totalChangeStyle}>
              <tbody>
                <TotalRow label={gettext('ui_summary_of_change')} value={plusSign + (-muutosyhteensa) + Currencies(curr)} />
              </tbody>
            </table>
            <mui.CardText style={mergedRootStyles.cardtext4}>
              <center><br/>
              {gettext('title_kayttovedenkustannukset')}<br/>
              {gettext('title_vesiJaJatevesimaksu')} {vesimaksu}{Currencies(curr)} / {gettext('unit_m3')}<br/>
              {gettext('title_energiahinta')} {energiahinta}{Currencies(curr)} / {gettext('unit_MWh')}
            </center>
          </mui.CardText>
        </div>
        <div style={mergedRootStyles.cardbackgrounds.header}/>
        </mui.Card>
      </div>
    );
  }
}

