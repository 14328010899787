import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
import Select from 'react-select/async';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class MoveDeviceDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }


  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.dialogData = null;
    this.state = {
      search: "",
      //muiTheme: muiTheme,
      confirmation: false,
      newPremise: {},
      dialogOpen: false,
    };
  }

  getChildContext() {
    return { /*muiTheme: this.state.muiTheme*/ };
  }


  open(data) {
    this.setState({
      search: "",
      confirmation: false,
      newPremise: {},
      dialogOpen: true,
    });
    this.dialogData = data;
    //this.refs.dialog.show();
  }

  dialogData() {
    return this.data;
  }

  close() {
    this.setState({
      search: "",
      confirmation: false,
      newPremise: {},
      dialogOpen: false,
    });
    //this.refs.dialog.dismiss();
  }

  onPremiseSelect = (premise) => {
    return this.setState({ confirmation: true, newPremise: this._premiseMap[premise.value] })
  }

  onSearch = async (search) => {
    if (!search) {
      return [];
    }
    let b = new UrlBuilder();
    const s = { $regex: search.label || search, $options: 'i' };
    const query = { $or: [{ name: s }, { address: s }] };

    const body = {
      query,
      projection: { name: 1, address: 1, _id: 1 }
    };
    const premiseMap = {};
    this._premiseMap = premiseMap;
    try {
      let result = await api.post(b.path('premises/find'), body);
      const options = result.data.map((r) => {
        premiseMap[r._id] = r;
        return { value: r._id, label: `${r.name} ${r.address}` }
      })
      return options;
    } catch (err) {
      console.log("onSearch error:", err);
      return [];
    }
  }


  render() {

    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }
    let title = this.props.title ? this.props.title : gettext('ui_confirm_title');
    let acceptButton = this.props.moveDeviceButtonText ? this.props.moveDeviceButtonText : gettext('ui_confirm_button')
    const confirmationTitle = this.state.confirmation ? ("Haluatko varmasti siirtää laitteen kohteeseen: " + this.state.newPremise.name) : null;
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          {!this.state.confirmation && <div style={{ minHeight: '300px' }}>
            {this.props.text}

            <div className="row">
              <div className="col-xs-12 col-md-12">
                <Select
                  name="premise-search"
                  placeholder={gettext('Search premise')}
                  //value={this.state.selectedPremise}
                  loadOptions={this.onSearch}
                  onChange={this.onPremiseSelect}
                />
              </div>
            </div>
          </div>}
          {this.state.confirmation &&
            <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>{confirmationTitle}</span>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => (this.close())} >
            {gettext('ui_cancel')}
          </Button>
          {this.state.confirmation &&
              <Button
                color='secondary'
                onClick={() => (this.props.onAccepted(this.state.newPremise))} >
                {acceptButton}
              </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }
}

export default MoveDeviceDialog;