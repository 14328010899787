import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import connectToStores from 'alt-utils/lib/connectToStores';
import CardBase from './CardBase';
import {gettext} from 'i18n';
import * as Colors from 'material-ui/styles/colors';
//let ImmutabilityHelper = require('material-ui/lib/utils/immutability-helper');

export default class PremiseSummaryCard extends React.Component {

  setInitialStyles() {
    let palette = this.context.muiTheme.rawTheme.palette;
    //let fiksuvesiStyle = this.context.muiTheme.rawTheme.fiksuvesi
    let mergedRootStyles = Object.assign({},{//ImmutabilityHelper.merge({
      borderRadius: '2px',
      color: Colors.white,
      background: 'linear-gradient(to bottom, ' + palette.primary1Color + ' ,' + palette.primary1ColorDarken + ')',
      textAlign: 'center',
      valueText: {
        lineHeight: '40px',
        fontSize: '35px',
        fontWeight: '400',
      },
      unitText: {
        lineHeight: '18px',
        fontSize: '18px',
        fontWeight: '300'
      },
      titleText: {
        lineHeight: '28px',
        fontSize: '16px',
      },
      line: {
        borderRightStyle: 'inset',
        borderRightColor: palette.primary1Color,
        borderRightWidth: '1px',
        marginTop: '8px',
        marginBottom: '5px'
      }
    });//, fiksuvesiStyle.summarycard);


    this.setState({
      muiTheme: this.context.muiTheme,
      mergedRootStyles: mergedRootStyles,
      palette: palette
    });
  }

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.setInitialStyles();
  }

  render() {
    let mergedRootStyles = this.state.mergedRootStyles;

    const count = this.props.count
    const averageSavingsPercent = this.props.averageSavingPros && parseFloat(this.props.averageSavingPros).toFixed(2)
    const totalSavingsEuro = this.props.totalSavingEur && parseFloat(this.props.totalSavingEur).toFixed(0)
    const totalSavings = this.props.totalSaving && parseFloat(this.props.totalSaving).toFixed(0)
    const totalDevicesCount = this.props.totalDevicesCount

    return (
      <div style={mergedRootStyles.cardSize}>
      <mui.Card style={mergedRootStyles}>
        <div style={mergedRootStyles.cardSize}>
          <div className="row">
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={mergedRootStyles.line}>
              <div style={mergedRootStyles.valueText} >{count}/{totalDevicesCount}<span style={mergedRootStyles.unitText}>{gettext('unit_kpl')}</span> </div>
              <div style={mergedRootStyles.titleText}>{gettext('ui_select_premises_count') }</div>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={mergedRootStyles.line}>
              <div style={mergedRootStyles.valueText} >{averageSavingsPercent}<span style={mergedRootStyles.unitText}>{gettext('unit_pros')}</span> </div>
              <div style={mergedRootStyles.titleText}>{ gettext('ui_average_save_precent') }</div>
            </div>
            {/*<div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={mergedRootStyles.line}>
             <div style={mergedRootStyles.valueText} >{totalSavingsEuro}<span style={mergedRootStyles.unitText}>{gettext('unit_eur')}</span> </div>
             <div style={mergedRootStyles.titleText}>{ gettext('ui_total_saving_eur') }</div>
            </div>*/}
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{marginTop: '8px',marginBottom: '5px'}}>
              <div style={mergedRootStyles.valueText} >{totalSavings}<span style={mergedRootStyles.unitText}>{gettext('unit_m3')}</span> </div>
              <div style={mergedRootStyles.titleText}>{ gettext('ui_saving_qubic') }</div>
            </div>
          </div>
        </div>
      </mui.Card>
      </div>
    );
  }
}
