import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'

/**
 * Business logic, that normally goes to external file.
 */
async function query(q, usage) {
    var b = new UrlBuilder()
    /*
    ### example usage ###
    b.path('data')
      .id(opts.id)
      .start(opts.start && opts.start.toISOString())
      .end(opts.end && opts.end.toISOString())
      .query(q)
      .limit(opts.limit)
      .skip(opts.skip)
      .sort({timestamp: -1})
     */
    try {
      let res = usage ? await api.get(b.path('apikeys').query(q).usage(usage)) : await api.get(b.path('apikeys').query(q))
      return (res);
    } catch (err) {
      console.error("query failed:", err);
      return ({});
    }
    //return api.get(b.path('apikeys').query(q))
}

/**
 * ApiKeyActions class
 */
class ApiKeyActions {
  constructor() {
    this.generateActions(
      'change',
      'addPremiseToEdited',
      'removePremiseFromEdited',
      'premiseDataChange',
      'addCsvReceiver',
      'removeCsvReceiver',
      'clear'
    );
  }

  query(q, usage) {
    return async (dispatch) => {
      try {
        let res = await query(q, usage);
        dispatch({ data: res.data, query: q});
      } catch (err) {
        console.error(err.stack);
        dispatch({ error: err });
      }
    }
  }

  byPremise(id) {
    return async (dispatch) => {
      var b = new UrlBuilder()
      try {
        let res = await api.get(b.path('apikeys/bypremise/' + id));
        dispatch({ data: res.data, query: id });
      } catch (err) {
        console.error(err.stack);
        dispatch({ error: err });
      }
    }
  }

  editApiKey(data) {
    return async (dispatch) => {
      var b = new UrlBuilder();
      const body = Object.assign({}, data);
      //body.premises = body.premises.map(x => x._id);
      delete body.latestUse 
      try {
        let res = await api.post(b.path('apikeys'), body);
        await this.query();
      } catch (err) {
        console.error(err.stack);
        dispatch({ error: err });
      }
    }
  }

  removeApiKey(data) {
    return async (dispatch) => {
      var b = new UrlBuilder();
      try {
        let res = await api.delete(b.path('apikeys').id(data._id));
        await this.query();
      } catch (err) {
        console.error(err.stack);
        dispatch({ error: err });
      }
    }
  }

  createNewApiKey() {
    return async (dispatch) => {
      var b = new UrlBuilder();
      try {
        let res = await api.get(b.path('apikeys/generate'));
        dispatch({ key: res.data });
      } catch (err) {
        console.error(err.stack);
        dispatch({ error: err });
      }
    }
  }

  getPremises() {
    return async (dispatch) => {
      const body = {
        query: {},
        projection: { name: 1, address: 1, _id: 1 },
        //limit: 10
      };
      var b = new UrlBuilder();
      try {
        let res = await api.post(b.path('premises/find'), body);
        dispatch({ data: res.data });
      } catch (error) {
        dispatch({ error });
      }
    }
  }

  getApiPartners(q) {
    return async (dispatch) => {
      var b = new UrlBuilder();
      try {
        let res = await api.get(b.path('apipartners').query(q));
        dispatch({ data: res.data });
      } catch (error) {
        dispatch({ error });
      }
    }
  }
}

export default alt.createActions(ApiKeyActions);
