import React from 'react';
import PropTypes from 'prop-types';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from './TextInput';
import SelectBox from './SelectBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default class AddSmartDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      settings:{DevEUI:"", Serial:"", Manufacturer:"Axioma F1", Model:"F1", serviceLevel:"daily", precision:"0.001", replace:false, replaceDevice:"select"}
    };
  }

  getChildContext() {
    return { };
  }

  open() {
    this.setState({ dialogOpen: true });
  }

  close() {
    this.setState({ dialogOpen: false });
  }

  onChange(value,id) {
    let settings = this.state.settings
    settings[id] = value
    this.setState({ settings })
  }

  onReplaceChange(event) {
    let settings = this.state.settings
    settings.replace = event.target.checked
    this.setState({ settings })
  }

  onAdd(){
    
    if(this.state.settings.DevEUI && this.state.settings.Serial){
      //Hack for default value. Make better solutions
       let s =  _.clone(this.state.settings)
       if(s.Manufacturer === 'Axioma F1' ) s.Manufacturer = 'Axioma'
       if(s.replaceDevice === 'select' ) delete s.replaceDevice
       this.props.onAdd(s)
    }
    
  }

  onSelectionChange(value, id, selectBox) {
   let settings = this.state.settings
   if(id==='Manufacturer'){
    settings.Manufacturer = value.value.split(" ")[0]
    settings.Model = value.value.split(" ")[1]
    settings.serviceLevel = "daily"
   } else {
    settings[id] = value.value
   }
   this.setState({ settings })

  }

  render() {
    let title = this.props.title ? this.props.title : gettext('ui_add_smartmeter');
    let options = [{ value: 'minute', label: 'minute' }, { value: 'hourlevel', label: 'hourlevel' }, { value: 'daily', label: 'daily' }, { value: 'monthly', label: 'monthly' } ]
    let devices = this.props.devices || []

    let deviceoptions = devices.map(d => {
      return { value: d, label: d}
    })

    return (
      
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}>
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="Inputgroup">
            <div className="row top-xs">
              <div className="col-xs-12 col-md-12" >
                <TextInput ref="DevEUI" required={true} type="text" id="DevEUI" label={gettext('DevEUI')} onChange={this.onChange.bind(this)} />
                <TextInput ref="Serial" required={true} type="text" id="Serial" label={gettext('Serial')} onChange={this.onChange.bind(this)} />
              </div>
            </div>

            <div className="row top-xs">
              <div className="col-xs-6 col-md-6" >
              <SelectBox
                        value={{ value: this.state.settings.Manufacturer, label: this.state.settings.Manufacturer }}
                        name="Manufacturer"
                        label={gettext('Manufacturer')}
                        options={ [
                          { label:"Lora devices", options:[
                          { value: 'Axioma F1', label: 'Axioma F1'}, 
                          { value: 'Axioma W1', label: 'Axioma W1'}, 
                          { value: 'Bmeters LR3', label: 'Bmeters LR3'},
                          ]},
                          { label:"wmbus devices", options:[
                          { value: 'Bmeters Hydrodigit', label: 'BMeters Hydrodigit'},
                          { value: 'Kamstrup Multical21', label: 'Kamstrup Multical21'},
                          { value: 'Diehl Hydrus', label: 'Diehl Hydrus'}
                          ]}

                         ] }
                        onSelectionChange={this.onSelectionChange.bind(this)} />
              <TextInput ref="Model" value={this.state.settings.Model} required={false} type="text" id="Model" label={gettext('Model')} onChange={this.onChange.bind(this)} />          
                {/*<TextInput defaultValue="Axioma" ref="Manufacturer" required={false} type="text" id="Manufacturer" label={gettext('Manufacturer')} onChange={this.onChange.bind(this)} />
                <TextInput ref="Model" required={false} type="text" id="Model" label={gettext('Model')} onChange={this.onChange.bind(this)} />*/}
              </div>
            </div>
          </div>

          <div className="Inputgroup">
          <div className="row top-xs">
            <div className="col-xs-6 col-md-6" >
              <div class="InputElem"><div class="InputLabel">Replace old device</div></div>
            <FormControlLabel
              control={<Switch checked={this.state.settings.replace} onChange={this.onReplaceChange.bind(this)} id="replace" name="replace" />}
              label="Replace"
            />
            {this.state.settings.replace && <SelectBox
                        value={{ value: this.state.settings.replaceDevice, label: this.state.settings.replaceDevice }}
                        name="replaceDevice"
                        label={gettext('device')}
                        options={deviceoptions}
                        onSelectionChange={this.onSelectionChange.bind(this)} />
            }
            </div>
          </div>
          </div>

          <div className="Inputgroup">
            <div className="col-xs-6 col-md-6" >
              <SelectBox
                value={{ value: this.state.settings.serviceLevel, label: this.state.settings.serviceLevel }}
                name="serviceLevel"
                label={gettext('Servicelevel')}
                options={ options }
                onSelectionChange={this.onSelectionChange.bind(this)} />
              <TextInput ref="precision" value={this.state.settings.precision} required={false} type="float" id="precision" label={gettext('precision')} onChange={this.onChange.bind(this)} />
            </div>
          </div>

          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onAdd.bind(this) }  disabled={this.state.settings.DevEUI && this.state.settings.Serial ? false : true}>{gettext('ui_add')}</Button>
          <Button onClick={this.props.onClose}>{gettext('ui_close')}</Button>
        </DialogActions>
      </Dialog >
    );

  }
}

