import alt from "../utils/alt";
import api from "../utils/api";
import LoginActions from "../actions/LoginActions";
import history from '../utils/history'

const USER_STORAGE_KEY = "fiksuvesi";

class LoginStore {
  constructor() {
    /*this.bindListeners({
      onLogin: LoginActions.login
    });*/
    this.bindActions(LoginActions);
    //this.registerAsync(LoginActions.login);
    this.user = null;
    this.token = null;
    this.error = null;
    this.role = null;

    let user = null;
    try {
      user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
    } finally {
      if (user) {
        this.root = true ;
        this.user = user;
        this.token = user.token;
        this.role = user.role;
        api.updateToken(user.token);
      }
    }
  }

  saveUser(data) {
    console.log("saveUser:",data);
    if (data.ok) {
      this.storeUser(data.user);
      if (data.redirect) {
        this.redirectTo(data.redirect);
      } else {
        this.redirectToHome();
      }
    } else {
      //this.error = data && data.error && data.error.message;
      this.clearUser();
      this.error = data.error;
      this.redirectToLogin();
    }
  }

  storeUser(user) {
    this.root = true;
    this.user = user;
    this.token = user.token;
    this.role = user.role;
    this.error = null;
    api.updateToken(user.token);
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  }

  loadLocalUser() {
    if (!process.env.BROWSER) {
      return;
    }

    var user;
    try {
      user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
    } finally {
      if (user) {
        console.log(user);
        this.storeUser(user);
      }
    }
  }

  clearUser() {
    this.user = null;
    api.updateToken(null);
    localStorage.removeItem(USER_STORAGE_KEY);
    sessionStorage.clear();
  }

  redirectTo(route) {
    history.push(route);
  }

  redirectToHome() {
    console.log("redirectToHome",this);
    history.push('/premises');
  }

  redirectToLogin() {
    history.replace('/login');
  }

  onLogin(data) {
    console.log("onLogin",data);
    this.saveUser.bind(this)(data);
  }

  onLogout() {
    this.error = null;
    this.clearUser();
    setTimeout(this.redirectToLogin);
  }
}

//module.exports = (alt.createStore(LoginStore));
export default alt.createStore(LoginStore);
