var React = require('react');
var ReactDOM = require('react-dom');
import App from './components/App';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTheme,ThemeProvider } from '@material-ui/core/styles';

import 'flexboxgrid';

const theme = createTheme({
  palette: {
    primary: { main: '#002458' }, //'#51AFE6'
    secondary: { main: '#02C1D8' }, //'#EB5282'
  },
  typography: { useNextVariants: true },
});

function boot() {
  var content = document.getElementById('content');
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    , content)
}

boot();
