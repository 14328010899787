"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import PremisesStore from '../stores/PremisesStore';
import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import PremiseActions from '../actions/PremiseActions';
import SearchFilter from '../components/SearchFilter';
import { gettext } from 'i18n';
import _ from 'lodash';
import TextInput from '../components/TextInput';
import SelectBox from '../components/SelectBox';
import ViewLoader from '../components/ViewLoader';
import LoginStore from '../stores/LoginStore';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import MyRawTheme from '../components/Theme';

//new Material UI
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {Dialog, DialogTitle, Grid, Chip, Tooltip, TextField, Button, InputLabel, FormControl, Select, MenuItem  } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import {red,green,yellow} from '@material-ui/core/colors';
import moment from 'moment';
import utils from '../utils/utils'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const styles = {
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useDialogStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const getStateColor = (state) => {
  switch (state) {
    case "active":
      return green[500]
      break;
    case "delivered":
      return yellow[500]
      break;
    default:
      return red[500]
      break;
  }
}

const StyleChip = withStyles({
  root: {
    colorPrimary:'salmon'
  }
})(Chip);

const InfoDialogX = (props) => {
  const classes = useDialogStyles();
  const {selectedPremise, open } = props;
  const [additionalValue, setAdditionalValue] = React.useState('');
  const [additionalDevice, setAdditionalDevice] = React.useState('');
  const [commentClass, setCommentClass] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(null);

  if(!selectedPremise) {return <div/>} 

  let states = selectedPremise.deviceStates
  let devices = selectedPremise.devices || []
  let devicesInfo = selectedPremise.devicesinfo
  
  const handleChange = (event) => {
    setAdditionalValue(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const AddPressed = () => {
    let deviceIds = _.map(additionalDevice, 'value')
    let jobs = []
    for (let index = 0; index < deviceIds.length; index++) {
     const deviceId = deviceIds[index];
     jobs.push({deviceId:deviceId, info:additionalValue, commentClass, premiseId:selectedPremise.premiseid, estimatedinstallationDate:selectedDate})
    }
    props.onAddInfoRow(jobs)
    setSelectedDate(null)
    setAdditionalDevice('')
    setAdditionalValue('')
    setCommentClass('')
  }

  const handleDeviceSelect2 = (event,value) => {
    setAdditionalDevice(value)
};

const handleCommentClassChange = (event) => {
  setCommentClass(event.target.value);
};

  const serviceRequests = selectedPremise.servicerequests && selectedPremise.servicerequests.devices ? selectedPremise.servicerequests.devices : []
  let devsArray = []
  let infos = []

  const devs = _.mapKeys(states, function(value, key) {
    let color =  getStateColor(value)
    devsArray.push(<Tooltip placement="top" key={key} title={value}><Chip key={key} label={key} color='primary' style={{backgroundColor:color}}/></Tooltip>)
   })
   
   //only devices currently linked to the premise
   if(devicesInfo && devices) {
   devices.forEach(device => {
    let infoArr = devicesInfo[device] && devicesInfo[device].deliveryStatusInfo ? devicesInfo[device].deliveryStatusInfo : []
    infoArr.forEach(info => {
      info.deviceId = device
      
      infos.push(info)
    })
   });
   //sortedinfos =  _.sortBy(infos, ['timestamp'], ['desc'])
   infos =  _.orderBy(infos, [o =>  ( moment(o.timestamp) *-1)])
  }


  let deviceInfoSelectOptions = devices.map((d) => {
    let color = selectedPremise && selectedPremise.deviceStates && selectedPremise.deviceStates[d] ? getStateColor(selectedPremise.deviceStates[d]) : 'grey'
    return {label:d, value:d, color}
  })
  //console.log("PROPSIT", props)
  return (
    
    <Dialog aria-labelledby="dialog-title" open={open} onClose={props.onClose} fullWidth={true} maxWidth='lg'>
      <DialogTitle id="dialog-title">Premise info</DialogTitle>
      <div className={classes.root}>
      <Grid container spacing={1}>
      <Grid item xs={4}><Typography variant="subtitle2" >Name:</Typography></Grid>
      <Grid item xs={8}><Typography variant="body1" >{selectedPremise.name}</Typography></Grid>

      <Grid item xs={4}><Typography variant="subtitle2" >Delivery time:</Typography></Grid>
      <Grid item xs={8}><Typography variant="body1" >{selectedPremise.deliveryTime} days</Typography></Grid>

      <Grid item xs={4}><Typography variant="subtitle2" >Device attached:</Typography></Grid>
      <Grid item xs={8}><Typography variant="body1" >{selectedPremise.deviceAttachDate}</Typography></Grid>

      <Grid item xs={4}><Typography variant="subtitle2" >Device activated:</Typography></Grid>
      <Grid item xs={8}><Typography variant="body1" >{selectedPremise.deviceInstallationDate}</Typography></Grid>
      <Grid item xs={4}><Typography variant="subtitle2" >Devices active:</Typography></Grid>
      <Grid item xs={8}><Typography variant="body1" >{`${selectedPremise.devicesActive}/${selectedPremise.devicesCount}`}</Typography></Grid>
      <Grid item xs={4}><Typography variant="subtitle2" >Devices:</Typography></Grid>
        <Grid item xs={8}>
          {devsArray}
          </Grid>
      {serviceRequests.length ? <Grid item xs={12}><Typography variant="subtitle2" >Open Service Requests:</Typography></Grid>: ''}
      
      {serviceRequests.length ? <Grid item xs={12}>
      <Table size="small" >
          <TableHead>
          <TableRow>
            <TableCell>Device Id</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Class</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>User</TableCell>
          </TableRow>
          </TableHead>
      {serviceRequests.map((r) => (
                    <TableRow key={r.deviceId + '_sr'} >
                      <TableCell >{r.deviceId}</TableCell>
                      <TableCell >{r.fixed ? utils.getDateString(r.fixed.timestamp) : utils.getDateString(r.created.timestamp) }</TableCell>
                      <TableCell>{r.fixed ? "Fixed" : "Created"}</TableCell>
                      <TableCell >{r.fixed ? r.fixed.ackClass : r.created.serviceRequestClass}</TableCell>
                      <TableCell >{r.fixed ? r.fixed.comment : r.created.comment}</TableCell>
                      <TableCell >{r.fixed ? r.fixed.user : r.created.user}</TableCell>
                    </TableRow>
                  ))}
     </Table>
     </Grid> : ''}
     <Grid item xs={12}><hr></hr></Grid>
     <Grid item xs={12}><Typography variant="subtitle2" >Additional info:</Typography></Grid> 
      <Grid item xs={12}>
      <Table size="small" >
              <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Device Id</TableCell>
            <TableCell>Comment class</TableCell>
            <TableCell>Estimated Installation</TableCell>
            <TableCell>Info</TableCell>
            <TableCell>User</TableCell>
          </TableRow>
        </TableHead>
                <TableBody>
                  {infos.map((info) => (
                    <TableRow key={info.timestamp} >
                      <TableCell >{utils.getDateString(info.timestamp)}</TableCell>
                      <TableCell >{info.deviceId}</TableCell>
                      <TableCell >{info.commentClass}</TableCell>
                      <TableCell >{info.estimatedinstallationDate ? utils.getDateOnlyString(info.estimatedinstallationDate) :'-'}</TableCell>
                      <TableCell >{info.value}</TableCell>
                      <TableCell >{info.user}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

      </Grid>
      <Grid item xs={4}>
      <FormControl className={classes.formControl}>
      <Autocomplete
        multiple
        id="device-info-select"
        fullWidth={true}
        options={deviceInfoSelectOptions}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <React.Fragment>
            <span style={{color:option.color}}>{option.label}</span>
            
          </React.Fragment>
        )}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={(e,v)=>handleDeviceSelect2(e,v)}
        value={additionalDevice || []}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
          label={gettext('DeviceId')}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
      )}
    />
    </FormControl>
      </Grid>

      <Grid item xs={4}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="comment-class-label">Comment class</InputLabel>
        <Select
          labelId="comment-class-label"
          label="Comment class"
          id="comment-class"
          value={commentClass}
          onChange={handleCommentClassChange}
          fullWidth={true}
        >
          <MenuItem value={"Customer problem"}>Customer problem</MenuItem>
          <MenuItem value={"Device problem"}>Device problem</MenuItem>
          <MenuItem value={"Resources"}>Resources</MenuItem>
          <MenuItem value={"Slow customer"}>Slow customer</MenuItem>
          <MenuItem value={"Technical issue"}>Technical issue</MenuItem>
          <MenuItem value={"Electricity needed"}>Electricity needed</MenuItem>
          <MenuItem value={"Weak network"}>Weak network</MenuItem>
          <MenuItem value={"Meter in a well"}>Meter in a well</MenuItem>
          <MenuItem value={"Not to be installed"}>Not to be installed</MenuItem>
          <MenuItem value={"Installation scheduled"}>Installation scheduled</MenuItem>
        </Select>
      </FormControl>
</Grid>
<MuiPickersUtilsProvider utils={DateFnsUtils}>
<Grid item xs={4}>
        <KeyboardDatePicker
          disableToolbar
          disablePast
          variant="inline"
          format="dd.MM.yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Estimated install Date"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
</Grid>
</MuiPickersUtilsProvider>
      <Grid item xs={7}>
        <TextField
        id="standard-multiline-flexible"
        label="info text"
        multiline
        maxRows={4}
        value={additionalValue}
        onChange={handleChange}
        fullWidth={true}
      />
      </Grid>
      <Grid item xs={2}>
      <Button  variant="contained" color="primary" onClick={()=>AddPressed()}><span style={{color:'white'}}>{gettext('add')}</span></Button>
      </Grid> 
      <Grid item xs={12}>
      {props.error ?<span style={{color:red}}>{props.error}</span>:<span style={{padding:'10px'}} > </span>}
      </Grid> 

      </Grid>
      </div>
      
    </Dialog>
  );
}


@connectToStores
class DeliveryStatusView extends BaseView {

  constructor(props) {
    super(props);
    this.state = {
      sortBy: '_id',
      order: 'asc',
      errors: {},
      dialogopen: false,
      selectedpremise:null
    };
  }

  static getStores() {
    return [PremisesStore, LoginStore];
  }

  static getPropsFromStores() {
    return {
      ...PremisesStore.getState(),
      ...LoginStore.getState()
    }
  }

  static propTypes = {
    deliveryStatus: PropTypes.array
  }

  componentDidMount() {
    //this.initialQuery = setTimeout(PremisesActions.getDeliveryStatus, 100);
    
    //Load all or NOT
    //PremiseActions.getDeliveryStatus()
    setTimeout(()=>{
      PremiseActions.getDistinctCountries();
    },200);
  }

  sortBy(sb, e) {
    if (sb === this.state.sortBy && this.state.order === "asc") {
      this.setState({ sortBy: sb, order: "desc"  });
    } else {
      this.setState({ sortBy: sb, order: "asc"  });
    }
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }
    //console.log(filters)
    PremiseActions.getDeliveryStatus(filters)
    this.setState({ filters});
    //UserActions.query(filters);
  }

  openPremise(premise){
    //console.log(premise)
    this.setState({ selectedpremise:premise.premiseid, dialogopen:true });

  }

/*
        {
        states.map((device) => (
          {
            let deviceId = Object.keys(device)[0]   
            return <Chip label={deviceId} />
          }
        ))
        }
*/
  Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const [sortBy, setSortBy] = React.useState('name');
    const [order, setOrder] = React.useState('asc');
    const [premises, setPremises] = React.useState(row.premises);

    function setsorter(sb, e) {
      if (sb === sortBy && order === 'asc') {
        setSortBy(sb)
        setOrder('desc')
        setPremises(_.orderBy(premises, [sortBy.toString()], ['desc']))
      } else {
        setSortBy(sb)
        setOrder('asc')
        setPremises(_.orderBy(premises, [sortBy.toString()], ['asc']))
      }

      setPremises(_.orderBy(premises, [sortBy.toString()], [order]))
    }

    const classes = useRowStyles();
    <Icon className="premisesIcons" >error_outline</Icon>

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell 
          style={{fontSize:'0.95rem'}}
          key="parentAccount" 
          component="th" 
          scope="row">{row.parentAccount}
          </TableCell>
          <TableCell align="right" style={{fontSize:'0.95rem'}}>{row.deliveryTime}</TableCell>
          <TableCell align="right" style={{fontSize:'0.95rem'}}>{row.devicesOnline}</TableCell>
          <TableCell align="right" style={{fontSize:'0.95rem'}}>{row.devicesCount}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small" >
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sortBy === "name" ? order : false}>
                <TableSortLabel
                active={sortBy === "name"}
                direction={sortBy === "name" ? order : 'asc'}
                onClick={()=> setsorter("name")}>
                Name
              </TableSortLabel>
              </TableCell>
              <TableCell>
                Info
              </TableCell>
              <TableCell align="right">
              <TableSortLabel
                active={sortBy === "deliveryTime"}
                direction={sortBy === "deliveryTime" ? order : 'asc'}
                onClick={()=> setsorter("deliveryTime")}>
                Delivery time
              </TableSortLabel>  
              </TableCell>
              <TableCell align="right">
              <TableSortLabel
                active={sortBy === "devicesOnline"}
                direction={sortBy === "devicesOnline" ? order : 'asc'}
                onClick={()=> setsorter("devicesOnline")}>
                Devices Online %
              </TableSortLabel> 
                </TableCell>
              <TableCell align="right">
              <TableSortLabel
                active={sortBy === "devicesCount"}
                direction={sortBy === "devicesCount" ? order : 'asc'}
                onClick={()=> setsorter("devicesCount")}>
                Devices count
              </TableSortLabel> 
                </TableCell>
            </TableRow>
          </TableHead>
                  <TableBody>
                    {
                    premises.map( (premise) =>   { 
                      let devicesInfo = premise.devicesinfo
                      let devices = premise.devices
                      let infos = []
                      if(devicesInfo && devices) {
                        devices.forEach(device => {
                         let infoArr = devicesInfo[device] && devicesInfo[device].deliveryStatusInfo ? devicesInfo[device].deliveryStatusInfo : []
                         infoArr.forEach(info => {
                           info.deviceId = device
                           infos.push(info)
                         })
                        });
                        infos =  _.orderBy(infos, [o =>  ( moment(o.timestamp) *-1)])
                       }

                      let tx = infos.length ? `${infos[0].timestamp}-${infos[0].value}` : ""
                      let infoFound = infos.length ? <Tooltip placement="top" title={tx}><Icon style={{fontSize:'0.95rem'}}>warning_outline</Icon></Tooltip> : ""
                      return (
                      <TableRow key={premise.name} onClick={() => props.self.openPremise(premise)} >
                        <TableCell component="th" scope="row">
                          {premise.servicerequests ? <Tooltip placement="top" title="Active service request"><Icon style={{fontSize:'0.95rem'}}>error_outline</Icon></Tooltip>: ""}
                          {premise.name}
                          </TableCell>
                          <TableCell>
                            {infoFound}
                          </TableCell>
                        <TableCell align="right">{premise.deliveryTime}</TableCell>
                        <TableCell align="right">{premise.devicesOnline}</TableCell>
                        <TableCell align="right">{premise.devicesCount}</TableCell>
                      </TableRow>
                    )}
                    
                    )
                    }
                  </TableBody>
                </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  handleAddInfoRow(e) {
    PremiseActions.savingInfo()
    PremiseActions.addInfoRow(e)

    setTimeout(() => {
      if(!this.props.savingError){
        PremiseActions.getDeliveryStatus(this.state.filters)
        //this.setState({ dialogopen:false });
      }
    }, 1000 + 1000*e.length)
    
  }

  handleDialogClose() {
    this.setState({ selectedpremise:null, dialogopen:false });
  }

  render() {

    //console.log("MAIN PORPS", this.props)
    var countries=[];
    if (this.props.countries) {
      countries = this.props.countries.map((country, i) => { return { value: country, label: country } });
    } 
    
    let filters = [
      {
        type: 'name',
        prop: "name",
        operator: '%',
        text: gettext('ui_name'),
        value: ''
      },
      {
        type: 'parentAccount',
        prop: "parentAccount",
        operator: '%',
        text: gettext('Parent Account'),
        value: ''
      },
      { type: 'manager', prop: "name", operator: '%', text: gettext('ui_manager'), value: '', elem: 'interestGroups', additionalElem: { type: 'manager' } },
      { type: 'maintenance', prop: "name", operator: '%', text: gettext('maintenance'), value: '', elem: 'interestGroups', additionalElem: { type: 'maintenance' } },
      { type: 'address', prop: "address", operator: '%', text: gettext('ui_address'), value: '' },
      { type: 'zipCode', prop: "zipCode", operator: '-%', text: gettext('ui_zipCode'), value: '' },
      { type: 'postOffice', prop: "postOffice", operator: '%', text: gettext('ui_postOffice'), value: '' },
      { type: 'country', prop: "country", operator: '=', text: gettext('ui_country_code'), options: countries},
      { type: 'owner', prop: "owner", operator: '%', text: gettext('CRM Owner'), value: '' },
    ];

    let orderedDeliveryStatus = _.orderBy(this.props.deliveryStatus, [this.state.sortBy.toString()], [this.state.order]);
 
    const rows = orderedDeliveryStatus.map(r=>{
    return {
      parentAccount:r._id,
      deliveryTime:r.deliveryTime,
      devicesOnline:r.devicesOnline,
      devicesCount:r.devicesCount,
      premises: r.premises,
      updated: r.updated
    }
    })
    
    let selectedPremise = null
    
    if(this.state.selectedpremise){
    selectedPremise = _.find(_.flatMap(this.props.deliveryStatus, 'premises'),  { 'premiseid': this.state.selectedpremise })
    }

    return (
      <div>
        <AppBar
          title={gettext('Delivery Status')} />

        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
        <SearchFilter localStorage="userFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} />

        <Box component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sortDirection={this.state.sortBy === "_id" ? this.state.order : false} >
              <TableSortLabel
                active={this.state.sortBy === "_id"}
                direction={this.state.sortBy === "_id" ? this.state.order : 'asc'}
                onClick={()=>this.sortBy("_id")}>
                Parent Account
            </TableSortLabel>
            </TableCell>

              <TableCell align="right" sortDirection={this.state.sortBy === "deliveryTime" ? this.state.order : false} >
              <TableSortLabel
                active={this.state.sortBy === "deliveryTime"}
                direction={this.state.sortBy === "deliveryTime" ? this.state.order : 'asc'}
                onClick={()=>this.sortBy("deliveryTime")}>
                Delivery time (days)
            </TableSortLabel>
            </TableCell>

            <TableCell align="right" sortDirection={this.state.sortBy === "devicesOnline" ? this.state.order : false} >
              <TableSortLabel
                active={this.state.sortBy === "devicesOnline"}
                direction={this.state.sortBy === "devicesOnline" ? this.state.order : 'asc'}
                onClick={()=>this.sortBy("devicesOnline")}>
                Devices Online %
            </TableSortLabel>
            </TableCell>

            <TableCell align="right" sortDirection={this.state.sortBy === "devicesCount" ? this.state.order : false} >
              <TableSortLabel
                active={this.state.sortBy === "devicesCount"}
                direction={this.state.sortBy === "devicesCount" ? this.state.order : 'asc'}
                onClick={()=>this.sortBy("devicesCount")}>
                Devices count
            </TableSortLabel>
            </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <this.Row key={row.parentAccount + row.updated} row={row} self={this} />
            ))}
          </TableBody>
        </Table>
      </Box>

          {!this.props.loading ? null : <ViewLoader blue />}
          <InfoDialogX
            selectedPremise={selectedPremise}
            open={this.state.dialogopen} onClose={()=>this.handleDialogClose()} 
            onAddInfoRow={(e)=>this.handleAddInfoRow(e)} 
            saving={this.props.saving} 
            error={this.props.savingError} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DeliveryStatusView);