import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
import MonthPicker from './MonthPicker';
import moment from 'moment';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

class MonthSelectionDialog extends React.Component {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.dialogData = null;
    this.state = {
      //muiTheme: muiTheme,
      month: moment().add(-1, 'month'),
      dialogOpen: false,
    };
  }

  static contextTypes = {
    //muiTheme: PropTypes.object
  }
  static childContextTypes = {
    //muiTheme: PropTypes.object
  }
  getChildContext() {
    //return { muiTheme: this.state.muiTheme };
    return {}
  }

  open(data) {
    this.dialogData = data;
    //this.refs.dialog.show();

    // Reset month on dialog open
    this.setState({
      month: moment().add(-1, 'month'),
      dialogOpen: true,
    })
  }

  dialogData() {
    return this.dialogData;
  }

  close() {
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false })
  }

  updateMonth(t, e) {
    //return date month start from 0
    this.setState({ month: moment(e).add(1, 'month') });
  }

  render() {

    /*let dialogStyle = {
      //backgroundColor: this.state.muiTheme.rawTheme.palette.accent2Color,
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      borderTop: '4px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px'
    }*/


    let title = this.props.title ? this.props.title : gettext('ui_confirm_title');
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}>
        <DialogTitle>{title.toUpperCase()}</DialogTitle>
        <DialogContent>
          {this.props.text}
          <MonthPicker onChange={this.updateMonth.bind(this)} date={this.state.month} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
          <Button
            color='primary'
            onClick={() => (this.props.onAccepted(this.state.month))} >{gettext('ui_ok')}</Button>
        </DialogActions>
      </Dialog >
    );

  }
}

export default MonthSelectionDialog
