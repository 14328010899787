import React from 'react';
import PropTypes from 'prop-types';
import {gettext} from 'i18n';

export default class Pager extends React.Component {
  static contextTypes = {
    router: PropTypes.func
  }

  static propTypes = {
    count: PropTypes.number,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
  }

  render() {
    let count = this.props.count
    let limit = this.props.limit || 50
    let skip = this.props.skip

    let pageIndex = skip / limit
    let pageCount = Math.ceil(count / limit)

    let pages = [];

    let pageChangeFn = index => this.props.onPageChange(index * limit);

    let previousFn = () => {
      console.log(skip, limit, count)
      if (pageIndex - 1 >= 0) {
        this.props.onPageChange(skip - limit)
      }
    }

    let nextFn = () => {
      console.log(skip, limit, count)
      if (pageIndex + 1 <= pageCount - 1) {
        this.props.onPageChange(skip + limit)
      }
    }

    for (let i = 0; i < pageCount; i++) {
      pages.push(
        <li
          key={i}
          className={i === pageIndex && 'active'}
            onClick={pageChangeFn.bind(this, i)}>
            <a>{i + 1}</a>
        </li>
      )
    }

    //let style = {display: 'inline-block', margin: "20px 0 20px 10px", lineHeight:'36px', verticalAlign: 'top', color:'#337ab7'}

    return (
      <div>
        <ul className="pagination">
          <li className={pageIndex <= 0 && 'disabled'}><a onClick={previousFn}><span>«</span></a></li>
          {pages}
          <li className={pageIndex >= pageCount - 1 && 'disabled'}><a onClick={nextFn}><span>»</span></a></li>
        </ul>
        {/*<div style={style}>{gettext('Premise count:') + ' ' + count}</div>*/}
      </div>
    );

  }
}
