"use strict";

import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
import BaseView from './BaseView';
import connectToStores from 'alt-utils/lib/connectToStores';
import AppBar from '../components/AppBar';
import SearchFilter from '../components/SearchFilter';
import DateInput from '../components/DateInput';
import WaterCompaniesStore from '../stores/WaterCompaniesStore'
import WaterCompaniesActions from '../actions/WaterCompaniesActions'
import PremiseActions from '../actions/PremiseActions';
import PremisesStore from '../stores/PremisesStore';
import TextInput from '../components/TextInput';
import SelectBox from '../components/SelectBox';
//import { PropTypes  } from 'react-proptypes'
import { gettext } from 'i18n';
import _ from 'lodash';
import ViewLoader from '../components/ViewLoader';
//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';
import Currencies from '../utils/currencies';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = {
  dialogTitle: {
    color: MyRawTheme.palette.primary1Color
  }
}

@connectToStores
class WaterCompaniesView extends BaseView {
  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme,
      //filters: {text: 'waterprice', value: 604}, // TODO this is not used anywhere?
      sortParams: { sortBy: 'name', order: 'asc' },
      newWCompany: {},
      dialogOpen: false
    };
  }

  static getStores() {
    return [WaterCompaniesStore,PremisesStore];
  }

  static getPropsFromStores() {
    return {...WaterCompaniesStore.getState(), countries:PremisesStore.getState().countries};
  }

  static propTypes = {
    companies: PropTypes.array,
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentWillMount() {
    this.initialQuery = setTimeout(WaterCompaniesActions.query, 100);
  }

  componentDidMount() {
    setTimeout(()=>{ PremiseActions.getDistinctCountries();},200);
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }
    WaterCompaniesActions.query(filters);
  }

  _onDialogCancel() {
    //this.refs.dialog.dismiss();
    WaterCompaniesActions.change({});
    this.setState({ dialogOpen: false });
  }

  _onDialogSubmit() {
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
    WaterCompaniesActions.editWCompany(this.props.newWCompany);
    WaterCompaniesActions.change({});
  }

  openDialog(data) {
    console.log("OpenDialog: ", data)
    //if (this.refs.dialog) {
    if (data) WaterCompaniesActions.change(data);
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
    //}//
  }

  onChange(value, id, textField) {
    console.log("onChange: ", value, id)
    if (id === 'meterCount') {
      value = Number(value)
    }
    if (textField.isMandatory() && !value) {
      textField.setErrorText(gettext('ui_pakollinen_kentta'));
    } else {
      textField.setErrorText(null)
    }
    var v = {};
    v[id] = value;
    WaterCompaniesActions.change(v);
  }

  onSelectionChange(selection, id, selectBox) {
    WaterCompaniesActions.change({ [id]: selection.value });
  }

  sortBy(sb, e) {
    if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({ sortParams: { sortBy: sb, order: "desc" } });
    } else {
      this.setState({ sortParams: { sortBy: sb, order: "asc" } });
    }
  }

  onDateChange(name, date) {
    var v = {};
    v[name] = date;
    WaterCompaniesActions.change(v);
  }


  render() {

    let loading = this.props.wcompanies.length === 0;
    const { classes } = this.props;
    var countries=[];
    if (this.props.countries) {
      countries = this.props.countries.map((country, i) => { return { value: country, label: country } });
    } 
    
    let filters = [
      { type: 'name', prop: "name", operator: '%', text: gettext('ui_name'), value: '' },
      { type: 'country', prop: "country", operator: '=', text: gettext('ui_country_code'), options: countries},
      { type: 'waterpriceover', prop: "waterprice", operator: ">", text: gettext('ui_waterprice_over'), value: '', component: 'number' },
      { type: 'waterpriceunder', prop: "waterprice", operator: "<", text: gettext('ui_waterprice_under'), value: '', component: 'number' },
      { type: 'beforeDate', prop: "updated", operator: "<=", text: gettext('ui_updated_before'), value: '', component: 'datePicker' }];

    let dialogStyle = {
      backgroundColor: this.state.muiTheme.rawTheme.palette.accent2Color,
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      borderTop: '4px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px'
    }

    let dialogBoxStyle = {
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px',
      width: '100%',
      margin: '5px'
    }

    let customerSystem = [
      { label: "Kulutusweb", value: "Kulutusweb" },
      { label: "Synerall", value: "Synerall" },
      { label: "Solteq", value: "Solteq" },
      { label: "Kolibri", value: "Kolibri" },
      { label: "Abilita", value: "Abilita" },
      { label: "Vesitieto", value: "Vesitieto" },
      { label: "KeyAqua", value: "KeyAqua" },
      { label: "Ähtärin energia", value: "Ähtärin energia" },
      { label: "Muu", value: "Muu" }
    ]

    let dialog = (
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle >
          <span style={{ color: MyRawTheme.palette.primary1Color }}>{(this.props.newWCompany.name) ? this.props.newWCompany.name : gettext('ui_editWCompany').toUpperCase()}</span>
        </DialogTitle>
        <DialogContent>
          <div style={dialogStyle}>
            <div className="row">
              <div className="col-md-12 col-xs-12"><TextInput value={this.props.newWCompany.name} id="name" mandatory={true} label={gettext('ui_name')} onChange={this.onChange} /></div>
            </div>
            <div className="row">
              <div className="col-md-8 col-xs-12"><TextInput value={this.props.newWCompany.address} id="address" label={gettext('ui_address')} onChange={this.onChange} /></div>
              <div className="col-md-4 col-xs-12"><TextInput value={this.props.newWCompany.zipCode} id="zipCode" label={gettext('ui_zipCode')} onChange={this.onChange} /></div>
            </div>
            <div className="row">
              <div className="col-md-4 col-xs-12"><TextInput value={this.props.newWCompany.postOffice} id="postOffice" label={gettext('ui_postOffice')} onChange={this.onChange} /></div>
              <div className="col-md-4 col-xs-12"><TextInput value={this.props.newWCompany.municipality} id="municipality" label={gettext('ui_municipality')} onChange={this.onChange} /></div>
              <div className="col-md-3 col-xs-12"><SelectBox value={countries.filter(({ value }) => value === this.props.newWCompany.country)} name="country" options={countries} label={gettext('ui_country')} onSelectionChange={this.onSelectionChange.bind(this)} /></div>
            </div>
            <div className="row">
              <div className="col-xs-12" style={{ color: this.state.muiTheme.rawTheme.palette.primary1Color, margin: '5px', fontSize: '18px' }}>{gettext('ui_changePrice')}</div>
            </div>
            <div className="row around-xs" style={dialogBoxStyle} >
              <div className="col-md-4 col-xs-12"><TextInput value={this.props.newWCompany.waterprice} id="waterprice" label={gettext('ui_waterprice')} onChange={this.onChange} /></div>
              <div className="col-md-4 col-xs-12">
                <SelectBox value={Currencies('all').filter(({ value }) => value === this.props.newWCompany.currency)} name="currency" options={Currencies('all')} label={gettext('ui_currency')} onSelectionChange={this.onSelectionChange.bind(this)} />
              </div>
              <div className="col-md-4 col-xs-12"><DateInput value={this.props.newWCompany.updated} label={gettext('ui_updated')} name="updated" onChange={this.onDateChange.bind(this)} /></div>
            </div>
            <div className="row" style={{ paddingTop: "1em" }}>
              <div className="col-md-4">
                <SelectBox value={customerSystem.filter(({ value }) => value === this.props.newWCompany.customerSystem)} name="customerSystem" options={customerSystem} label={gettext('ui_customerSystem')} onSelectionChange={this.onSelectionChange.bind(this)} />
              </div>
              <div className="col-md-4"><TextInput type='Number' value={this.props.newWCompany.meterCount} id="meterCount" label={gettext('ui_meterCount')} onChange={this.onChange} /></div>
              <div className="col-md-4"><DateInput value={this.props.newWCompany.contacted} label={gettext('ui_contacted')} name="contacted" onChange={this.onDateChange.bind(this)} /></div>
            </div>
            <div className="row">
            <div className="col-md-12 col-xs-12"><TextInput value={this.props.newWCompany.url} id="url" label={gettext('ui_waterUrl')} onChange={this.onChange} /></div>
              <div className="col-md-6">
                <TextInput inputType="textarea" rows="3" value={this.props.newWCompany.invoicingInfo} id="invoicingInfo" label={gettext('ui_invoicing')} onChange={this.onChange} />
              </div>
              <div className="col-md-6">
                <TextInput inputType="textarea" rows="3" value={this.props.newWCompany.digitalization} id="digitalization" label={gettext('ui_digitalization')} onChange={this.onChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextInput inputType="textarea" rows="10" value={this.props.newWCompany.contactInfo} id="contactInfo" label={gettext('ui_contactInfo')} onChange={this.onChange} />
              </div>
              <div className="col-md-6">
                <TextInput inputType="textarea" rows="10" value={this.props.newWCompany.memo} id="memo" label={gettext('ui_wcMemo')} onChange={this.onChange} />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onDialogCancel.bind(this)} color='primary' >{gettext('ui_cancel').toUpperCase()}</Button>
          <Button onClick={this._onDialogSubmit.bind(this)} color='primary' >{gettext('ui_submit').toUpperCase()}</Button>
        </DialogActions>
      </Dialog >
    )

    var rows = _.map(_.orderBy(this.props.wcompanies, [this.state.sortParams.sortBy], [this.state.sortParams.order]), (u, i) => {
      let date = new Date(u.updated);
      date = (date.toString() !== 'Invalid Date') ? (`${date.getMonth() + 1} / ${date.getFullYear()}`) : "-";
      return (
        <tr className="pointer" key={u.name + i} onClick={() => this.openDialog(u)}>
          <td>{u.name}</td>
          <td>{u.address}</td>
          <td>{u.country}</td>
          <td>{u.waterprice}</td>
          <td>{date}</td>
        </tr>
      )
    })

    const newCompanyButton = (
      <div>
        <i className="mdi mdi-plus-box" />
        {gettext('ui_add_new')}
      </div>
    );
    //console.log("WaterCompaniesView:", this, MyRawTheme);
    return (
      <div>
        <AppBar
          title={gettext('title_Vesiyhtiot')}
          actionBtn={newCompanyButton}
          onActionBtnClick={() => this.openDialog()}
        />
        <div>
          <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
            <SearchFilter localStorage="waterCompanyFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} />
            <table className="table">
              <thead>
                <tr>
                  <th id="tableheader" onClick={this.sortBy.bind(this, 'name')}>{gettext('ui_companyName')}</th>
                  <th onClick={this.sortBy.bind(this, 'address')}>{gettext('ui_address')}</th>
                  <th onClick={this.sortBy.bind(this, 'country')}>{gettext('ui_country')}</th>
                  <th onClick={this.sortBy.bind(this, 'waterprice')}>{gettext('ui_waterprice')}</th>
                  <th onClick={this.sortBy.bind(this, 'updated')}>{gettext('ui_updated')}</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {loading && <ViewLoader blue />}
          </div>
        </div>
        {dialog}
      </div>
    );

  }
}

export default withStyles(styles)(WaterCompaniesView);