import React from 'react';
import PropTypes from 'prop-types';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import LinkIcon from 'material-ui/svg-icons/content/redo';

import moment from 'moment';

export default class ServiceRequestInfoDialog extends React.Component {

  constructor(props) {
    super(props);
   
    this.dialogData = null;
    this.state = {
      dialogOpen: false,
    };
  }

  open(data) {
    this.dialogData = data;
    this.setState({ dialogOpen: true });
  }

  dialogData() {
    return this.data;
  }

  close() {
    this.setState({ dialogOpen: false });
  }


  render() {

    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }
    let activeServiceRequests = this.dialogData && this.dialogData.activeServiceRequests || []
    let text = []
    for (let i = 0; i< activeServiceRequests.length; i++) {
      const e = activeServiceRequests[i];
      let Description = e.Description ? e.Description : "-"
      let Category = e.Category ? e.Category : "-"
      let serviceRequestClass = e.created && e.created.serviceRequestClass ? e.created.serviceRequestClass : "-"
      let user = e.created && e.created.user ? e.created.user : "-"
      let timestamp = e.created && e.created.timestamp ? moment(e.created.timestamp ).format('YYYY-MM-DD HH:mm') : "-"
      text.push(<div key={i}> <b>{gettext('Device Id')} :</b> {e.deviceId}<br/> <b>{gettext('Status')} :</b> {e.Status}<br/><b>{gettext('Description')} :</b> {Description}<br/><b>{gettext('Category')} :</b> {gettext(Category)}<br/><b>{gettext('timestamp')}:</b>{timestamp}<br/><Link color="secondary" target="_blank" href={e.SF_case_url} >Open in Salesforce<LinkIcon/></Link><hr/></div>)
      //text.push(<div key={i}><b>{gettext('status')} :</b> created<br/><b>{gettext('ui_comment')} :</b> {comment}<br/><b>{gettext('service_class')} :</b> {gettext(serviceRequestClass)}<br/><b>{gettext('ui_username')} :</b> {user}<br/> <b>{gettext('timestamp')} :</b> {timestamp}<br/></div>)
    }

    let title = this.props.title ? this.props.title : gettext('ui_confirm_title');
    let showClose = this.props.showClose !== undefined ? this.props.showClose : true
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div style={dialogStyle}>
          {text}
          </div>
        </DialogContent>
        <DialogActions>
          {showClose && <Button onClick={() => (this.close())} >{gettext('ui_close')}</Button>}
        </DialogActions>
      </Dialog>
    );

  }
}


