import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import SearchFilter from '../components/SearchFilter';

import ApiPartnerStore from '../stores/ApiPartnerStore'
import ApiPartnerActions from '../actions/ApiPartnerActions'

import { gettext } from 'i18n';
import _ from 'lodash';
import ViewLoader from '../components/ViewLoader';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PlusIcon from '@material-ui/icons/AddBox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField, Typography, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup } from '@material-ui/core';
import LoginStore from '../stores/LoginStore';
import utils from '../utils/utils'
import history from '../utils/history';
import KeyIcon from '@material-ui/icons/VpnKey'; 
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Select, FormControl, InputLabel,MenuItem } from '@material-ui/core';
//import Box from '@material-ui/core/Box';


@connectToStores
class ApiPartnerView extends BaseView {
  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme,
      filters: {},
      sortParams: { sortBy: 'name', order: 'asc' },
      newApiPartner: {},
      dialogOpen: false,
      openError: false,
      additional: "",
      textfieldErrorName:"",
      textfieldErrorOwner:""

    };
  }

  static getStores() {
    return [ApiPartnerStore, LoginStore];
  }

  static getPropsFromStores() {
    return { ...ApiPartnerStore.getState(), ...LoginStore.getState() }
  }

  static propTypes = {
    companies: PropTypes.array,
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentDidMount() {
    this.initialQuery = setTimeout(ApiPartnerActions.query, 100);
  }

  componentDidUpdate(prevProps) {
    //Show error
    if (prevProps.error !== this.props.error) {
      if (this.props.error) {
        this.setState({ openError: true })
      } else {
        this.setState({ openError: false })
      }
    }
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }
    ApiPartnerActions.query(filters);
  }

  _onDialogCancel() {
    ApiPartnerActions.change({});
    this.setState({ dialogOpen: false, textfieldErrorName:'',textfieldErrorOwner:'' });
  }

  _onDialogSubmit() {
    let formError = false;
    this.setState({textfieldErrorName:'',textfieldErrorOwner:'' })

    if(!this.props.newApiPartner.name || this.props.newApiPartner.name === '' || (this.props.newApiPartner.name && this.props.newApiPartner.name.length < 3)){
      this.setState({textfieldErrorName:gettext('Min lenght 3')})
      formError = true
    }
    if(!this.props.newApiPartner.owner || this.props.newApiPartner.owner === ''){
      this.setState({textfieldErrorOwner:gettext('ui_pakollinen_kentta')})
      formError = true
    }

    if (!formError) {
      ApiPartnerActions.editApiPartner(this.props.newApiPartner);
      ApiPartnerActions.change({});
      this.setState({ dialogOpen: false });
    }
  }

  openDialog(data) {
    if (data) {
      ApiPartnerActions.change(data);
    }
    else {
      ApiPartnerActions.createNewApiPartnerKey();
    }
    this.setState({ dialogOpen: true, additional:''});
  }

  onChange(e) {
 
    this.setState({textfieldErrorName:'',textfieldErrorOwner:'' })

    if(e.target.id === 'name' && e.target.value === ''){
      this.setState({textfieldErrorName:gettext('ui_pakollinen_kentta')})
    }
    if(e.target.id === 'owner' && e.target.value === ''){
      this.setState({textfieldErrorOwner:gettext('ui_pakollinen_kentta')})
    }

    var v = {};
    v[e.target.id] = e.target.value;
    ApiPartnerActions.change(v)
  }

  handleAdditionalChange(e) {
    this.setState({ additional: e.target.value });
  }

  onSelectionChange(e) {
    var v = {};
    v[e.target.name] = e.target.value;
    ApiPartnerActions.change(v);
  }

  sortBy(sb, e) {
    if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({ sortParams: { sortBy: sb, order: "desc" } });
    } else {
      this.setState({ sortParams: { sortBy: sb, order: "asc" } });
    }
  }

  AddPressed() {
    let memo = this.props.newApiPartner.memo || []
    let user = this.props.user && this.props.user.username ? this.props.user.username : "-"
    memo.push({ timestamp: new Date().toISOString(), text: this.state.additional, user })
    ApiPartnerActions.change({ memo })
  }

  DeleteMemo(index){
    let memo = this.props.newApiPartner.memo || []
    console.log("remove index", index)
    memo.splice(index, 1);
    ApiPartnerActions.change({ memo })
  }

  render() {

    let loading = this.props.apiPartners.length === 0;
    let errorMsg = this.props.error

    let filters = [
      { type: 'name', prop: 'name', operator: "%", text: gettext('ui_name'), value: '' },
      { type: 'owner', prop: 'owner', operator: "%", text: gettext('owner'), value: '' },
    ];

    let dialogStyle = {
      backgroundColor: this.state.muiTheme.rawTheme.palette.accent2Color,
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      borderTop: '4px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px'
    }
    let dialogBoxStyle = {
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px',
      width: '100%',
      margin: '5px'
    }
    const partnerTypes = [
      { value: 'DataTransferPartner', label: gettext('Data Transfer Partner') },
      { value: 'ApiFeePartner', label: gettext('Api Fee Partner') },
      { value: 'ResellerPartner', label: gettext('Reseller Partner') },
    ];

    const languages = [
      { value: 'en-us', label: gettext('en-us') },
      { value: 'fi-fi', label: gettext('fi-fi') },
      { value: 'sv-se', label: gettext('sv-se') },
      { value: 'nl-nl', label: gettext('nl-nl') },
      { value: 'de-de', label: gettext('de-de') },
      { value: 'fr-fr', label: gettext('fr-fr') }
    ];



    //console.log('--props--',this.props.newApiPartner)
    //console.log('--state--',this.state)
    let Memos = this.props.newApiPartner.memo || []

    let dialog = (
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color }}>{(this.props.newApiPartner.name) ? this.props.newApiPartner.name : gettext('Edit Api Partner').toUpperCase()}</span>
        </DialogTitle>
        { this.state.dialogOpen && this.props.newApiPartner && this.props.newApiPartner.partnerKey && <DialogContent>
          <div style={dialogStyle}>
            <div className="row around-xs" style={dialogBoxStyle}>
              <div className="col-md-12 col-xs-12">
                <TextField
                  id="name"
                  ref="name"
                  helperText={this.state.textfieldErrorName}
                  error={!!this.state.textfieldErrorName}
                  label={gettext('ui_name')}
                  value={this.props.newApiPartner.name}
                  onChange={(e) => this.onChange(e,this)}
                  fullWidth={true}
                  required={true}
                  variant="outlined"
                />
                <br/><br/>
              </div>
              <div className="col-md-6 col-xs-12">
                <FormControl fullWidth={true} variant="outlined">
                <InputLabel id="select-partnerType-label">{gettext('partnerType')}</InputLabel>
                <Select
                  labelId="select-partnerType-label"
                  label={gettext('partnerType')}
                  name="partnerType"
                  value={this.props.newApiPartner.partnerType}
                  onChange={(e) => this.onSelectionChange(e)}
                >
                  {partnerTypes.map((v)=>(<MenuItem value={v.value}>{v.label}</MenuItem>))}
                </Select>
              </FormControl>
              
              </div>
              <div className="col-md-6 col-xs-12">
               
                <TextField
                  id="owner"
                  ref="owner"
                  label={gettext('Smartvatten owner')}
                  value={this.props.newApiPartner.owner}
                  onChange={(e) => this.onChange(e)}
                  fullWidth={true}
                  required={true}
                  error={!!this.state.textfieldErrorOwner}
                  helperText={this.state.textfieldErrorOwner}
                  variant="outlined"
                />                
              </div>
             
              <div className="col-xs-12 col-md-6">
                <br/>
                <div className="InputElem" >
                  <div className="InputLabel" style={{color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('PartnerKey')}</div>
                  <div style={{ padding: "0.5em 0.75em" }} >{this.props.newApiPartner.partnerKey ? this.props.newApiPartner.partnerKey : '-'}</div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6" >
              <br/>
                <div className="InputElem" >
                  <div className="InputLabel" style={{ color: "#0D429B", textTransform: "uppercase", fontSize: "12px" }} >{gettext('created')}</div>
                  <div style={{ padding: "0.5em 0.75em" }} >{this.props.newApiPartner.created ? utils.getDateString(this.props.newApiPartner.created) : '-'}</div>
                </div>
              </div>
            </div>

            <div className="row around-xs" style={dialogBoxStyle}>
              <div className="col-md-6 col-xs-12">
                <TextField
                  id="supportContactEmail"
                  ref="supportContactEmail"
                  label={gettext('support contact email')}
                  value={this.props.newApiPartner.supportContactEmail}
                  onChange={(e) => this.onChange(e)}
                  fullWidth={true}
                  variant="outlined"
                />
                </div>
              <div className="col-md-6 col-xs-12">
                 <FormControl fullWidth={true} variant="outlined">
                <InputLabel id="select-language-label">{gettext('Api Key language')}</InputLabel>
                <Select
                  labelId="select-language-label"
                  label={gettext('Api Key language')}
                  name="language"
                  value={this.props.newApiPartner.language}
                  onChange={(e) => this.onSelectionChange(e)}
                >
                  <MenuItem value={'en-us'}>{gettext('en-us') }</MenuItem>
                  <MenuItem value={'fi-fi'}>{gettext('fi-fi') }</MenuItem>
                  <MenuItem value={'sv-se'}>{gettext('sv-se') }</MenuItem>
                  <MenuItem value={'nl-nl'}>{gettext('nl-nl') }</MenuItem>
                  <MenuItem value={'de-de'}>{gettext('de-de') }</MenuItem>
                  <MenuItem value={'fr-fr'}>{gettext('fr-fr') }</MenuItem>
                </Select>
              </FormControl>
              </div>
            </div>
            <div className="row around-xs" style={dialogBoxStyle}>
              <div className="col-md-6 col-xs-12">
                <TextField
                  id="partnerContactemail"
                  ref="partnerContactemail"
                  label={gettext('Partner contact email')}
                  value={this.props.newApiPartner.partnerContactemail}
                  onChange={(e) => this.onChange(e)}
                  fullWidth={true}
                  variant="outlined"
                />
                </div>
              <div className="col-md-6 col-xs-12">
                <TextField
                  id="partnerContactPhone"
                  ref="partnerContactPhone"
                  label={gettext('partner contact phone')}
                  value={this.props.newApiPartner.partnerContactPhone}
                  onChange={(e) => this.onChange(e)}
                  fullWidth={true}
                  variant="outlined"
                />
                </div>
            </div>

            <div className="row" style={dialogBoxStyle}>
              <div className="col-md-12 col-xs-12">
                <Typography variant="subtitle2" >{gettext('MEMOS')}</Typography>
              </div>
              <div className="col-md-12 col-xs-12">
                {/*MEMOS*/}
                <Table size="small" >
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      <TableCell>Memo</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Memos.map((info,index) => (
                      <TableRow key={info.timestamp} >
                        <TableCell >{utils.getDateString(info.timestamp)}</TableCell>
                        <TableCell >{info.text}</TableCell>
                        <TableCell >{info.user}</TableCell>
                        <TableCell ><Button  onClick={() => this.DeleteMemo(index)} startIcon={<DeleteIcon/>}></Button></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="col-md-12 col-xs-12">
                <br /><br />
              </div>

              <div className="col-md-10 col-xs-12">
                <TextField
                  id="standard-multiline-flexible"
                  label={gettext('Memo')}
                  multiline
                  maxRows={8}
                  value={this.state.additional}
                  onChange={(e) => this.handleAdditionalChange(e)}
                  fullWidth={true}
                />
              </div>
              <div className="col-md-2 col-xs-12">
                <Button variant="contained" color="primary" onClick={() => this.AddPressed()}><span style={{ color: 'white' }}>{gettext('add')}</span></Button>
              </div>
            </div>

          </div>
          <br/><br/><br/>
        </DialogContent>}
        <DialogActions>
          <Button onClick={this._onDialogCancel.bind(this)} color='primary'>{gettext('ui_cancel').toUpperCase()}</Button>
          <Button onClick={this._onDialogSubmit.bind(this)} color='primary'>{gettext('ui_save').toUpperCase()}</Button>
        </DialogActions>
      </Dialog>
    )

    var rows = _.map(_.orderBy(this.props.apiPartners, [this.state.sortParams.sortBy], [this.state.sortParams.order]), (u, i) => {
      let type = _.find(partnerTypes, { 'value': u.partnerType });
      let partnerTypeLabel = type && type.label ? type.label : '-'
      return (
        <tr className="pointer" key={u.name + i} onClick={() => this.openDialog(u)}>
          <td>{u.name}</td>
          <td>{u.partnerKey}</td>
          <td>{partnerTypeLabel}</td>
          <td>{u.owner}</td>
        </tr>
      )
    });

    return (
      <div>
        <AppBar title={gettext('Api Partners')}
        />
        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
        <div className="row">
          <div className="col-xs-8 col-md-8 align-self-end" style={{ paddingRight: 0 }}>
            <SearchFilter localStorage="ApiPartnerFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} />
          </div> 
        <div className="col-xs-4 col-md-4 end-xs" style={{ borderBottom: '3px solid grey' }}>
        <ButtonGroup disableElevation variant='outlined' size="small" >
          <Button startIcon={<KeyIcon/>}  onClick={() => history.push('/apikeys')}>
              {gettext('ApiKeys')}
          </Button>
          <Button startIcon={<AddIcon/>}  onClick={() => this.openDialog()}>{gettext('ui_add_new')}</Button>
          </ButtonGroup>
        </div>
          </div>  
          <table className="table">
            <thead>
              <tr>
                <th onClick={this.sortBy.bind(this, 'name')}>{gettext('Name')}</th>
                <th onClick={this.sortBy.bind(this, 'partnerKey')}>{gettext('Partner Key')}</th>
                <th onClick={this.sortBy.bind(this, 'partnerType')}>{gettext('Partner Type')}</th>
                <th onClick={this.sortBy.bind(this, 'owner')}>{gettext('Smartvatten Owner')}</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {loading && <ViewLoader blue />}
        </div>
        {dialog}
        <Snackbar open={this.state.openError} autoHideDuration={6000} onClose={(e, r) => { this.setState({ openError: false }) }}>
          <Alert onClose={(e, r) => { this.setState({ openError: false }) }} severity="error" sx={{ width: '100%' }}>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
    );

  }
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ApiPartnerView;