// in src/restricted.js
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import history from './history';

export default function wrap(BaseComponent){
    class Restricted extends Component {
        componentWillMount() {
            this.checkAuthentication(this.props);
        }
        componentWillReceiveProps(nextProps) {
            if (nextProps.location !== this.props.location) {
                this.checkAuthentication(nextProps);
            }
        }
        checkAuthentication(nextState, replaceState) {
             if (nextState.location.pathname !== '/login' && !localStorage.fiksuvesi) {
               //replaceState({ nextPathname: nextState.location.pathname }, '/login')
               history.replace('/login');
             } else if (localStorage.fiksuvesi && JSON.parse(localStorage.fiksuvesi).role === 'configurator' &&
               nextState.location.pathname !== '/login' &&
               nextState.location.pathname !== '/configurator' &&
               _.findIndex(nextState.routes, ['path', 'analyze/:id']) < 0 &&
               _.findIndex(nextState.routes, ['path', 'analyze/:id/:device']) < 0) {
               console.log(JSON.parse(localStorage.fiksuvesi).role, nextState, _.findIndex(nextState.routes, ['path', 'analyze/:id/:device']))
               //replaceState({ nextPathname: nextState.location.pathname }, '/configurator')
               history.replace('/configurator')
             } else {
               this.checkIsAdminAllowedOnly(nextState, replaceState);
             }
          }
          checkIsAdminAllowedOnly(nextState, replaceState) {
            if (nextState.location.pathname === '/users'/* || nextState.location.pathname === '/apikeys'*/) {
              if (localStorage.fiksuvesi && JSON.parse(localStorage.fiksuvesi).role !== 'admin') {
                //replaceState({ nextPathname: nextState.location.pathname }, '/premises')
                history.replace('/login');
              }
            }
          }
        /*checkAuthentication(params) {
            const { history } = params;
            checkCredentials()
                .catch(e => history.replace({ pathname: '/login' }));
        }*/
        render() {
            return <BaseComponent {...this.props} />;
        }
    }
    return withRouter(Restricted);
}