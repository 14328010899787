import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioButtonGroup, DatePicker, RaisedButton, Checkbox } from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import { gettext } from 'i18n';
import { VictoryScatter, VictoryLine, VictoryChart, VictoryAxis, VictoryTooltip, VictoryLegend } from 'victory';
// import SampleChart from '../components/SampleChart';
// import DeviceHistoryTable from '../components/DeviceHistoryTable'
import AnalyzeActions from '../actions/AnalyzeActions';
import AnalyzeStore from '../stores/AnalyzeStore';
import PremiseActions from '../actions/PremiseActions';
import connectToStores from 'alt-utils/lib/connectToStores';
import moment from 'moment';
// import Utils from '../utils/utils';
//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

import LoginStore from '../stores/LoginStore';
import PremisesStore from '../stores/PremisesStore';
import queryString from 'query-string';

const randomColors = [];
for (let i = 0; i < 50; i++) { randomColors[i] = "#" + ((1 << 24) * Math.random() | 0).toString(16) }

const _limit = 100;

const hexadecimal = /^[0-9A-F]+$/i;

function isMongoId(str) {
  if (!str) return false;
  return hexadecimal.test(str) && str.length === 24;
}

@connectToStores
export default class TemperatureView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme,
      mineData: null,
      selectedApartments: [],
      startDate: moment("2019-02-19"),
      endDate: moment("2019-02-20")
    };

    // this.onChange = this.onChange.bind(this);
  }

  static getStores() {
    return [LoginStore, AnalyzeStore, PremisesStore];
  }

  static getPropsFromStores() {
    return {
      ...LoginStore.getState(),
      ...AnalyzeStore.getState(),
      ...PremisesStore.getState()
    };
  }

  componentDidMount() {
    // AnalyzeStore.listen(this.onChange);
    console.log("TemperatureView did mount:",this);
    const q = queryString.parse(this.props.location.search);//this.props.match.params;
    if (!q.premiseId) console.error('premise ID missing!');
    if (this.state.apartments && this.state.apartments.length) AnalyzeActions.mineTemperature(this.state.apartments, q.hours || 24); // this.state.startDate, this.state.endDate);
    if (q.premiseId) {
      PremiseActions.queryById(q.premiseId);
    }
  }

  componentWillUnmount() {
    // AnalyzeStore.unlisten(this.onChange);
    PremiseActions.reset();
  }

  datapointIdsFromApartments(apartments) {
    let dIds = [];
    apartments.forEach(ap => {
      let match = this.props.premise.apartments.find(a => (a.apartment === ap));
      if (match) {
        dIds = dIds.concat(match.sensors.map(s => (s.id)));
      }
    })
    return dIds;
  }

  apartmentsChange(c, e) {
    let value = e.target.value;
    console.log(c, value);
    let state = this.state
    if (c) {
      state.selectedApartments.push(value);
      let dIds = this.datapointIdsFromApartments(state.selectedApartments);
      AnalyzeActions.resetTemperatures(dIds);
      AnalyzeActions.mineTemperature(dIds);
      this.setState({ selectedApartments: state.selectedApartments });
    } else {
      state.selectedApartments = state.selectedApartments.filter(sa => (sa !== value));
      AnalyzeActions.resetTemperatures(this.datapointIdsFromApartments(state.selectedApartments));
      this.setState({ selectedApartments: state.selectedApartments });
    }
  }

  render() {
    console.log(this.state.selectedApartments);
    let graph = <div style={{ height: "70vh" }}></div>;
    let checkBoxes = 'loading apartments';
    if (this.props.premise && this.props.premise.apartments) {
      let aps = this.props.premise.apartments;
      checkBoxes =
        aps.map(a => (<Checkbox
          defaultChecked={this.state.selectedApartments.indexOf(a.apartment) > -1}
          onCheck={(event, checked) => this.apartmentsChange(checked, event)}
          value={a.apartment}
          label={a.apartment}
          key={a.apartment}
          style={{ display: 'inline-flex', width: "200px" }}
        />))
    }
    const datasets = [];
    let legends = [];
    if (this.props.mineData && Object.keys(this.props.mineData).length) {

      Object.keys(this.props.mineData).forEach((dId, i) => {
        let set = this.props.mineData[dId].map(d => ({ y: d.value, x: new Date(d.timestamp) }));
        set.color = randomColors[i];
        legends.push({ name: dId, symbol: { fill: randomColors[i] } });
        datasets.push(set);
      })
    }

    graph = <VictoryChart
      scale={{ x: "time" }}
      // domain={{ y: [5, 60] }}
      padding={{ top: 50, left: 50, right: 20, bottom: 50 }}
    // animate={{ duration: 1000 }}
    // containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryAxis
        tickFormat={t => moment(t).format('HH:mm')}
        label="Time"
        tickCount={20}
        style={{
          label: { fontSize: 4 },
          tickLabels: { fontSize: 5 },
          ticks: { stroke: "grey", size: 3 },
          grid: { stroke: 'lightgrey', strokeWidth: 0.25, strokeDasharray: [3] }
        }} />
      <VictoryAxis dependentAxis
        label="Temperature °C"
        tickCount={10}
        style={{
          label: { fontSize: 8 },
          tickLabels: { fontSize: 6 }, ticks: { stroke: "grey", size: 4 },
          grid: { stroke: 'lightgrey', strokeWidth: 0.3, strokeDasharray: [5] }
        }} />
      <VictoryLegend x={50} y={0}
        title="Legend"
        centerTitle
        orientation="horizontal"
        gutter={10}
        style={{ border: { stroke: "black" }, title: { fontSize: 8 }, labels: { fontSize: 6 } }}
        data={legends}
      />

      {datasets.length >= 1 && datasets.map((ds, i) => {
        return (
          <VictoryLine
            key={ds}
            interpolation="basis"
            size={1}
            // labels={(d) => d.label}
            // labelComponent={<VictoryTooltip />}
            style={{
              data: { stroke: ds.color, strokeWidth: '1px' },
              // parent: { border: "1px solid #ccc" }
            }}
            data={ds} />)
      })}
    </VictoryChart>;



    return (
      <div>
        <AppBar title={'Lämpötilanäyttö'} backBtn />
        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
          <div className="row top-xs">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {graph}
            </div>
          </div>
          <div className="row top-xs">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {checkBoxes}
            </div>
          </div>
        </div>
      </div >
    );
  }
}
