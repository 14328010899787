import alt from "../utils/alt";
import ApiKeyActions from "../actions/ApiKeyActions";
import _ from 'lodash';

/**
 * A scaffold for a new apikey object
 */
const INITIAL_APIKEY_OBJ =
  {
    acl: {
      'data/hour': ["GET"],
      'data/month': ["GET"],
      'data/meters': ["GET"]
    },
    premises: []
  };
 

const newApiKeyObj = (data = {}) => _.assign(_.cloneDeep(INITIAL_APIKEY_OBJ), data);

/*
 * Helpers
 */
const byId = id => premise => premise._id === id;
const byPropString = prop => (a, b) => a[prop].localeCompare(b[prop]);

/**
 * class ApiKeyStore
 */
class ApiKeyStore {
  constructor() {
    this.bindActions(ApiKeyActions);

    this.apiKeys = null
    this.newApiKey = newApiKeyObj();
    this.premises = [];
    this.apiPartners = [];
  }

  onQuery(payload) {
    if (payload.error) {
      this.error = payload.error
      return
    }
    this.apiKeys = payload.data.map(obj => {
      if (Array.isArray(obj.premises)) {
        obj.premises.forEach((item, i) => {
          if (item === null) obj.premises.splice(i, 1);
        })
        obj.premises = obj.premises.sort(byPropString('name'));
      }
      return obj;
    });
  }

  onByPremise(payload) {
    if (payload.error) {
      this.error = payload.error
      return
    }
    
    this.apiKeys = payload.data.map(obj => {
      if (Array.isArray(obj.premises)) {
        obj.premises.forEach((item, i) => {
          if (item === null) obj.premises.splice(i, 1);
        })
        obj.premises = obj.premises.sort(byPropString('name'));
      }
      return obj;
    });
  }

  onChange(obj) {
    if (_.isEmpty(obj)) {
      this.newApiKey = newApiKeyObj();
    } else {
      _.assign(this.newApiKey, _.cloneDeep(obj));
    }
  }

  onPremiseDataChange(obj) {
    this.newApiKey.premises.forEach((pre, ind) => {
      if (pre._id === obj.premiseId) this.newApiKey.premises[ind][obj.key] = obj.value;
    })
  }

  onCreateNewApiKey(payload) {
    if (payload.error) {
      this.error = payload.error
      return
    }
    this.newApiKey = newApiKeyObj({ key: payload.key });
  }

  onGetPremises(premises) {
    if (premises.error) {
      this.error = premises.error;
      return;
    }
    this.premises = premises.data.map(p => ({
      _id: p._id,
      name: p.name
    }));
  }

  onGetApiPartners(partners) {
    if (partners.error) {
      this.error = partners.error;
      return;
    }
    this.apiPartners = partners.data.map(p => ({
      _id: p._id,
      name: p.name,
      partnerKey:p.partnerKey,
    }));
  }

  onAddPremiseToEdited(id) {
    if (this.newApiKey.premises.indexOf(byId(id)) < 0) {
      const premise = this.premises.find(byId(id));
      if (premise) {
        this.newApiKey.premises.push(premise);
      }
    }
  }

  onRemovePremiseFromEdited(id) {
    this.newApiKey.premises = this.newApiKey.premises.filter(p => p._id !== id);
  }

  onAddCsvReceiver(email) {
    if (!this.newApiKey.csvReceivers) this.newApiKey.csvReceivers = [];
    this.newApiKey.csvReceivers.push(email);
  }

  onRemoveCsvReceiver(email) {
    const index = this.newApiKey.csvReceivers.indexOf(email);
    if (index > -1) this.newApiKey.csvReceivers.splice(index, 1);
  }

  onClear(){
    this.apiKeys = null
  }
}

//module.exports = alt.createStore(ApiKeyStore);
export default alt.createStore(ApiKeyStore);
