import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Bar} from "react-chartjs-2";
import _ from 'lodash';

export default class HourLimitInfoDialog extends React.Component {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.dialogData = null;
    this.state = {
      //muiTheme: muiTheme
      dialogOpen: false,
    };
  }

  open(data) {
    this.dialogData = data;
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
  }

  dialogData() {
    return this.data;
  }

  close() {
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
  }


  render() {

    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    let title = this.props.title ? this.props.title : gettext('Hourly run data 14 days');
    let showClose = this.props.showClose !== undefined ? this.props.showClose : true
    let hour3 = 3
    let hour4 = 4
    let hour5 = 5

    let data3 = this.props.dialogData && this.props.dialogData[hour3] && this.props.dialogData[hour3].hours ?  _.map(this.props.dialogData[hour3].hours,(e)=>_.round(e,2) ) : []
    let data4 = this.props.dialogData && this.props.dialogData[hour4] && this.props.dialogData[hour4].hours ?  _.map(this.props.dialogData[hour4].hours,(e)=>_.round(e,2) ) : []
    let data5 = this.props.dialogData && this.props.dialogData[hour5] && this.props.dialogData[hour5].hours ?  _.map(this.props.dialogData[hour5].hours,(e)=>_.round(e,2) ) : []

    let all = data3.concat(data4,data5)
    let max =  _.max(all)||0

    let text = max>0 ? `The largest night (2:00-5:00) hour consumption is ${max} l/h in latest 14 days.`: ''

    var chartData = {
      labels: ['day 1','day 2','day 3','day 4','day 5','day 6','day 7','day 8','day 9', 'day 10', 'day 11', 'day 12','day 13','day 14'],
      datasets: [{
        data: data3,
        label: gettext("Hour 2:00-3:00"),
        stack: 'Stack 3',
        backgroundColor: "rgba(33,150,243,0.5)",
        //borderColor: this.state.muiTheme.rawTheme.palette.primary1Color,
        //pointColor: "rgba(151,187,205,1)",
        //borderWidth: 2,
        //pointStrokeColor: "#fff",
        //pointHighlightFill: "#fff",
        //pointHighlightStroke: "rgba(151,187,205,1)"
      },
      {
        data: data4,
        label: gettext("Hour 3:00-4:00"),
        stack: 'Stack 4',
        backgroundColor: "rgba(33,150,243,0.7)",
      },
      {
        data: data5,
        label: gettext("Hour 4:00-5:00"),
        stack: 'Stack 5',
        backgroundColor: "rgba(33,150,243,0.9)",
      }
    ]
    };

    let options = {
      animation: false,
      tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value %> l/hour",
      tooltipFillColor: "rgba(33,150,243,0.8)",
      multiTooltipTemplate: "<%= value %> l/hour",
      responsive: true,
      scales:{
        yAxes:[{
          ticks:{
            beginAtZero:true
          }
        }]
      }
    };

    let graph = data3.length ? (<Bar data={chartData} options={options} redraw={true} />) : "Ei historiatietoja";
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div style={dialogStyle}>
            {this.props.text}
            <i>{text}</i><br/><br/>
            {graph}
          </div>
        </DialogContent>
        <DialogActions>
          {showClose && <Button onClick={() => (this.close())} >{gettext('ui_close')}</Button>}
        </DialogActions>
      </Dialog>
    );

  }
}


