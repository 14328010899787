"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import DeviceStore from '../stores/DeviceStore';
//import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import deviceActions from '../actions/DeviceActions';
import SearchFilter from '../components/SearchFilter';
//import {PropTypes} from 'react-proptypes'
import {gettext} from 'i18n';
import _ from 'lodash';
import ViewLoader from '../components/ViewLoader';
import LoginStore from '../stores/LoginStore';
import moment from 'moment';
import InfoDialog from '../components/InfoDialog';
import history from '../utils/history';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

@connectToStores
export default class DevicesContractEndView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme: muiTheme,
      errors: {},
      device:null,
      dialogOpen:false,
    };
  }

  static getStores() {
    return [LoginStore, DeviceStore];
  }

  static getPropsFromStores() {
    return {
      ...DeviceStore.getState(),
      ...LoginStore.getState()
    }
  }

  static propTypes = {
    devices: PropTypes.array,
  }

  componentWillMount() {
    //this.initialQuery = setTimeout( ()=> deviceActions.getDevices({"$in":['delivered', 'active']}), 100);
    deviceActions.getAllPremiseNames()
    deviceActions.getAllEndDatePremises() 
  }

  loadDeviceEvents(device){
    this.setState({device,dialogOpen:true})
    deviceActions.getDeviceEvents(device)
    //this.refs.deviceInfoDialog.open()

  }

  render() {
    let devices = this.props && this.props.sorted ? this.props.sorted : [];
    let eventsTable = [];

    if (this.props.role === 'admin') {
     
    }

    

    if (this.state.device && this.props.events[this.state.device] && this.props.events[this.state.device].length > 0) {
      var eventrows = _.map(this.props.events[this.state.device], (d) => {
      let premise = d.premiseId && this.props.premiseNames[d.premiseId] || '-'
      let actionInfo = null
      let premiseprefix = ''
      if(d.action === 'move_device') {
        premise = d.data.toPremise ? this.props.premiseNames[d.data.toPremise] : '--'
        premiseprefix = gettext('TO premise: ')
      } else if (d.action === 'device-premise-change' && d.data.oldPremiseId ){
        premise = d.data.oldPremiseId ? this.props.premiseNames[d.data.oldPremiseId] : '--'
        premiseprefix = gettext('FROM premise: ')
      } else if (d.action === 'device-premise-change' && d.data.newPremiseId ){
        premise = d.data.newPremiseId ? this.props.premiseNames[d.data.newPremiseId] : '--'
        premiseprefix = gettext('TO premise: ')
      } else if (d.action === 'device-replace'){
        actionInfo = `replaced by ${d.data.deviceId}`
      }

      
        return (
          <tr className="pointer" key={d._id}
          onClick={()=> window.open(`/events?&cols=[action,timestamp,source,data]&title=${premise}&premiseId=${d.premiseId}`)} >
            <td>{moment(d.timestamp).format('LLL')}</td>
        <td>{d.action}{actionInfo && <div>{actionInfo}</div>}</td>
            <td><i>{premiseprefix}</i>{premise}</td>
            <td>{d.source || '-'}</td>
          </tr>
        )
      })

      eventsTable = (
        <table className="table events">
          <thead>
            <tr><th>{gettext('timestamp')}</th><th>{gettext('action')}</th><th>{gettext('premise')}</th><th>{gettext('source')}</th></tr>
          </thead>
          <tbody>
            {eventrows}
          </tbody>
        </table>
      )
    } else if (this.state.device && this.props.events[this.state.device] && this.props.events[this.state.device].length === 0) {
      eventsTable = <h3 style={{ textAlign: 'center' }}>{gettext('no_events')}</h3>;
    }

    var rows = _.map(devices, (u) => {
      let installed = u.installed ? "installed" : "uninstalled";
      if(u.name === 'replaced') installed = 'replaced'

      return (
        <tr className="pointer" key={u._id} onClick={() => this.loadDeviceEvents(u.deviceId)} >
          <td>{u.deviceId}</td>
          <td> {moment(u.endDate).format('YYYY-MM-DD')}</td>
          <td>{u && u.events && u.events[0] && u.events[0].state ? u.events[0].state : '-'}
          {u && u.events && u.events[0] && u.events[0].updated ? ' ->' + moment(u.events[0].updated).format('YYYY-MM-DD') : '-'}</td>
          <td>{u.premise.name}</td>
          <td>{u.managers ? u.managers.toString(): '-'}</td>
          
        </tr>
      )
    })

    return (
      <div>
        <AppBar title={`${gettext('ui_devices')} ( ${gettext('delivered')}, ${gettext('active')} end date past  )`}/>
        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
          <table className="table">
            <thead>
              <tr>
                <th>{gettext('id')}</th>
                <th>{gettext('End Date')}</th>
                <th>{gettext('lifecycle state')}</th>
                <th>{gettext('Premise')}</th>
                <th>{gettext('Property Managers')}</th>
                
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {rows.length ? null : <ViewLoader blue />}
        </div>
        <Dialog open={this.state.dialogOpen}
          maxWidth='md'
          fullWidth={true}
          >
          <DialogTitle>
            {this.state.device || 'Laite'}
          </DialogTitle>
          <DialogContent>
            {eventsTable}
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={()=>this.setState({dialogOpen:false})}>{gettext('ui_close')}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
