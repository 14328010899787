import alt from "../utils/alt";
import ReportActions from "../actions/ReportActions";
import _ from 'lodash';

//import history from '../utils/history'

class RaportStore {
  constructor() {
    this.bindActions(ReportActions);
    /*this.bindListeners({
      onQueryById: PremiseActions.queryById
    });*/
    this.state = {premise:{}, reports: null, queryError:null, reportHistory:[]};
  }

  /*onQueryById(data) {
    this.setState({premise: data});
  }*/

  onQuery(payload) {
    console.log("ReportStore-onQuery",payload);
    if (payload.error) {
      this.setState({error: payload.error})
      return;
    }
    this.setState({
      premise: payload.premise,
      reports: _.orderBy(payload.reports, ['year', 'month'], ['desc', 'desc']),
      totalSavings: payload.totalSavings,
      queryError:null});
  }

  onTotalSavings(payload) {
    if (payload.error) {
      this.setState({error: payload.error})
      return;
    }
    this.setState({totalSavings: payload.totalSavings});
  }

  onEventHistory(data) {
    console.log('onEventHistory', data);
    this.setState({reportHistory:data, queryError:null});
  }

  onReset() {
    this.setState({premise:{}, reports: null, queryError:null, dialogError:null, reportHistory:null, totalSavings: {}});
  }

  onResetErrors() {
    this.setState({queryError:null, dialogError:null});
  }

  onGenerateManualReport(data) {
    this.setState({queryError: data.err});
  }


  onGenerateAutomaticReport(data) {
    console.log("onGenerateAutomaticReport", data);
    this.setState({queryError:data.err});
  }
  onGenerateReport(data) {
    console.log("onGenerateReport", data);
    this.setState({queryError:data.err});
  }

  onSendReport(payload) {
    if (payload.err) {
      this.setState({dialogError: payload.err.response.data});
    }
  }
  onGeneratePdfReport(res) {
    var file=new Blob([res.data],{type: 'application/pdf'});
    var url=URL.createObjectURL(file);
    var win = window.open(url, '_blank');
    win.focus();
  }

}

//module.exports = (alt.createStore(RaportStore));
export default alt.createStore(RaportStore);
