import alt from "../utils/alt";
import HeatingCompanyActions from "../actions/HeatingCompanyActions";
import _ from 'lodash';

class HeatingCompanyStore {
  constructor() {
    this.bindActions(HeatingCompanyActions);
    this.state = {
      hcompanies: [],
      newHCompany: { currency: 'eur' }
    };
  }

  onQuery(data) {
    this.setState({ hcompanies: data });
  }

  onChange(obj) {
    console.log("onChange: ", obj)
    if (_.isEmpty(obj)) {
      this.state.newHCompany = { currency: 'eur' }
    } else {
      _.assign(this.state.newHCompany, obj)
    }
    this.setState({ newHCompany: this.state.newHCompany });
  }
}

//module.exports = (alt.createStore(HeatingCompanyStore));
export default alt.createStore(HeatingCompanyStore);

