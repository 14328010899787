import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import {gettext} from 'i18n';

export default class DateInput extends React.Component {

  static contextTypes = {
    router: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (this.props.value) {
      this.setDate(new Date(this.props.value));
    } else {
      this.setState({
        day: null,
        month: null,
        year: null,
        set: false
      });
    }
  }

  static propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.object,
    multiLine: PropTypes.string,
    ref: PropTypes.string,
    text: PropTypes.string
  }

  componentWillReceiveProps(props) {
    if (props.value) {
      this.setDate(new Date(props.value));
    } else {
      this.setState({
        day: null,
        month: null,
        year: null,
        set: false
      });
    }
  }

  createUTCDate(year, month, date) {
    if (year === undefined) {
      const now = new Date();
      year = now.getFullYear();
      month = now.getMonth() + 1;
      date = this.props.type === 'month' ? 1 : now.getDate()
    }
    const twoDigit = (n) => n < 10 ? '0' + n : n;
    let str = `${year}-${twoDigit(month)}-${twoDigit(date)}T00:00:00.000Z`;
    return new Date(str);
  }

  getRef() {
    return this.props.ref ? this.props.ref : 'input';
  }

  onChange(evt) {
    const name = evt.target.name;
    const value = parseInt(evt.target.value);
    if (!isNaN(value) && value >= evt.target.min && value <= evt.target.max) {
      let state = Object.assign({}, this.state);
      state[name] = value;
      const date = this.createUTCDate(state.year, state.month, state.day);
      this.setDate(date, true);
      this.setState({errorText: null});
    } else {
      this.setState({
        [name]: evt.target.value,
        errorText: gettext('ui_invalid_date')
      });
    }
  }

  onFocus() {
    if (!this.state.set) {
      this.setDate(this.createUTCDate(), true);
    }
  }

  setDate(date, emit) {
    let state = {
      day: this.props.type === 'month' ? 1 : date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      set: true
    };

    if (!isNaN(state.day) && !isNaN(state.month) && !isNaN(state.year)) {
      this.setState(state);
      if (emit && this.props.onChange) {
        this.props.onChange(this.props.name, date);
      }
    }
  }

  render() {

    let {iconName, label, type, ...props} = this.props;

    let icon = iconName ? 'mdi ' + iconName : 'mdi mdi-calendar';
    let labelElem = null;
    if (this.props.label || this.state.errorText) {
      labelElem = (
        <div className="InputLabel">
          {this.props.label + (this.props.required ? '*' : '')}
          {this.state.errorText && <span className="InputError">{this.state.errorText}</span>}
        </div>
      );
    }

    let select = (id, opts) => (
      <select>
        {opts.map((opt) => <option key={opt.value} value={opt.value}>{opt.text}</option>)}
      </select>
    );

    const showDay = type !== 'month';

    return (
      <div className="InputElem">
      {labelElem}
       <div className="Inputaddon">
          <span className="Inputaddonitem"><i className={icon} ></i></span>
          <div className="Inputaddonfield date">
            {showDay && <input
              name="day"
              type="number"
              min="0"
              max="32"
              value={this.state.day}
              placeholder={gettext('_day')}
              onChange={this.onChange.bind(this)}
              onFocus={this.onFocus.bind(this)}
            />}
            {showDay && '.'}
            <input
              name="month"
              type="number"
              min="0"
              max="13"
              value={this.state.month}
              placeholder={gettext('_month')}
              onChange={this.onChange.bind(this)}
              onFocus={this.onFocus.bind(this)}
            />
            {showDay ? '.' : '/'}
            <input
              className="year"
              name="year"
              type="number"
              min="1900"
              max="2100"
              value={this.state.year}
              placeholder={gettext('_year')}
              onChange={this.onChange.bind(this)}
              onFocus={this.onFocus.bind(this)}
            />
          </div>
      </div>
      </div>
    );

  }

  isMandatory() {
    return this.props.mandatory ? true : false;
  }

  getName() {
    if (this.props.name) {
      return this.props.name
    } else if (this.props.id) {
      return this.props.id
    } else {
      return null;
    }
  }

}
