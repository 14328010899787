import React from 'react';
import {gettext} from 'i18n';

import {CardRow, DataRow, DataCard} from '../../components/ReportViewRows';
import {Graphs, Tables, Alarms} from './ReportViewPartials';

import PremiseInfoCard from '../../components/PremiseInfoCard';
import ConsumptionChangesCard from '../../components/ConsumptionChangesCard';
import CostEffectCard from '../../components/CostEffectCard';
import MetersCard from '../../components/MetersCard';
import ConsumptionReadingCard from '../../components/ConsumptionReadingCard';
import CumulativeChangeCard from '../../components/CumulativeChangeCard';

import moment from 'moment';

export default class ManualInputReportView extends React.Component {

  render() {
    const data = this.props;
    const report = data.selectedReport;
    const premise = data.premise;
    const reportDate = moment(data.selectedReportDate);

    return (
      <div>
        <CardRow>
          <PremiseInfoCard premiseData={premise} />
          {
            report &&
            <ConsumptionChangesCard
              serviceType={premise.serviceType}
              reportData={report}
              premiseId={premise._id}
              manual={data.manualInput}
            />
          }
          {
            report &&
            <CostEffectCard reportData={report} />
          }
          {
            report && data.consumptionIsSet ? (
              data.fiksuvesi && (
                <CumulativeChangeCard
                  data={data.totalSavings}
                  message={data.savingsError}
                  serviceTakenUseDate={premise.installationDate}
                />
              )
            ) : (
              <ConsumptionReadingCard
                data={{
                  premiseId: premise._id,
                  month: reportDate.month() + 1,
                  year: reportDate.year()
                }}
              />
            )
          }
        </CardRow>

        <Graphs {...data} />
        <Tables {...data} />
        <Alarms {...data} /> 
      </div>
    );
  }
}
