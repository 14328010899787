import moment from 'moment';
import {gettext} from 'i18n';
//import UrlBuilder from 'utils/urlbuilder';

//import history from 'utils/history'
const UTILS = {
  mongoErrorCodes: {
    dublicateKey: "E11000" // dublicate key in unique indexed field
  },
  changeHandler: function(target) {
    target.prototype.changeHandler = function(key, attr, event) {
      var state = {};
      state[key] = this.state[key] || {};
      state[key][attr] = event.currentTarget.value;
      this.setState(state);
    };
    return target;
  },
  round: function(value, decimals) {
    if (!decimals)
      decimals = 4
    var d = Math.pow(10, decimals)
    return Math.round(value * d) / d
  },
  getDateString: function(timestamp) {
    if(timestamp === null || timestamp===''){
      return '';
    }
    var date = new Date(timestamp);
    var curr_year = date.getFullYear();

    var curr_month = date.getMonth() + 1; //Months are zero based
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }

    var curr_date = date.getDate();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }

    var curr_hour = date.getHours();
    if (curr_hour < 10) {
      curr_hour = "0" + curr_hour;
    }

    var curr_min = date.getMinutes();
    if (curr_min < 10) {
      curr_min = "0" + curr_min;
    }

    var curr_sec = date.getSeconds();
    if (curr_sec < 10) {
      curr_sec = "0" + curr_sec;
    }

    return curr_date + '.' + curr_month + '.' + curr_year + ' ' + gettext("kello")  + ' ' + curr_hour + ':' + curr_min;
  },
  getDateOnlyString: function(timestamp) {
    if(timestamp === null || timestamp===''){
      return '';
    }
    var date = new Date(timestamp);
    var curr_year = date.getFullYear();

    var curr_month = date.getMonth() + 1; //Months are zero based
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }

    var curr_date = date.getDate();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }
    return curr_date + '.' + curr_month + '.' + curr_year;
  },

  doParseFloat: function(str) {
    if (str === undefined)
      return;
    return parseFloat(typeof str === "string"
      ? str.replace(/,/g, '.')
      : str)
  },

  releaseViewForExport: function(id) {
    if (typeof window.callPhantom === 'function') {
      window.callPhantom({
        viewReady: id || true
      });
    }
  },

  datesEqualByMonth: function(date1, date2) {
    const convert = (d) => typeof d === 'string'
      ? moment(d)
      : moment({
        year: d.year,
        month: d.month - 1
      });
    const a = convert(date1);
    const b = convert(date2);
    return a.year() === b.year() && a.month() === b.month();
  },

  displayFloat(n, digits, postfix) {
    let x = parseFloat(n);
    if (x === undefined || x === null || isNaN(x)) {
      return '--';
    }
    return x.toFixed(digits || 0).toString() + (postfix || '');
  },

  displayFloatAutoDecimals(...args) {
    return this.displayFloat(...args).replace(/(\.\d+)0+$/, '$1').replace(/\.0$/, '');
  },

  displayDate(d) {
    return moment(d).format('DD.MM.YYYY');
  },

  shortMonthName: function(month) {
    const ShortMonthNames = {
      1: gettext('january_short'),
      2: gettext('february_short'),
      3: gettext('march_short'),
      4: gettext('april_short'),
      5: gettext('may_short'),
      6: gettext('june_short'),
      7: gettext('july_short'),
      8: gettext('august_short'),
      9: gettext('september_short'),
      10: gettext('october_short'),
      11: gettext('november_short'),
      12: gettext('december_short')
    };
    return ShortMonthNames[month];
  },

  stringToNumber: function(str) {
    str = str.toString().replace(',', '.').replace(/\s+/g, '');
    return parseFloat(str);
  },

  safeFilename: function(name) {
    return (name || '').replace(/[\/\\\?\%\*\:\|"<>\,#]/g, '').replace(/\s/g, '_').replace(/[äå]/g, 'a').replace(/[ÄÅ]/g, 'A').replace(/ö/g, 'o').replace(/Ö/g, 'O');
  },

  addMonthToDate: function(date, months) {
    let dt = new Date(date);
    dt.setUTCMonth(dt.getUTCMonth() + months)
    return dt

    // TODO this does not work, when month is removed 31st day, it return invalid values
    /*let dt = this.addMonthToDateStr(date.toISOString(), months)
    return new Date(dt);*/
  },

  addMonthToDateStr: function(dateStr, months) {
    return this.addMonthToDate(dateStr, months).toISOString()

    /*let dt = new Date(dateStr);
    dt.setUTCMonth(dt.getUTCMonth() + months)
    return dt.toISOString()*/

    // TODO this does not work, when month is removed 31st day, it return invalid values
    /*if (months === undefined) {
      months = 1;
    }
    console.log("addMonthToDateStr1", dateStr)
    let tokens = dateStr.match(/(\d+)-(\d+)(.*)/);
    console.log("tokens", tokens)
    let year = parseInt(tokens[1], 10);
    let month = parseInt(tokens[2], 10) + months;
    while (month > 12) {
      month -= 12;
      year += 1;
    }
    while (month < 1) {
      month += 12;
      year -= 1;
    }
    return year + '-' + (month < 10 ? '0' + month : month) + tokens[3];*/
  },

  lastFullMonthStr: function() {
    let date = this.addMonthToDate(new Date(), -1);
    let month = date.getMonth() + 1;
    return date.getFullYear() + '-' + (month < 10
      ? '0' + month
      : month) + '-01T00:00:00.000Z';
  },

  validateEmail: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  getMeterSerial: function(id, devicesinfo) {
    console.log("devicesinfo", devicesinfo)
    if (devicesinfo && devicesinfo[id] && devicesinfo[id].meterSerial && devicesinfo[id].meterSerial != "") {
      return devicesinfo[id].meterSerial;
    } else {
      return id;
    }
  },
  niceNumberOver(value) {
    const nice = Math.pow(10, Math.ceil(Math.log10(value))); // 1st larger pow 10
    if (value <= nice / 10)
      return nice / 10;
    else if (value <= nice / 5)
      return nice / 5;
    else if (value <= nice / 4)
      return nice / 4;
    else if (value <= nice / 2)
      return nice / 2;
    return nice;
  },
  niceNumberUnder(value) {
    const nice = Math.pow(10, Math.ceil(Math.log10(value))); // 1st larger pow 10
    if (value >= nice)
      return nice;
    else if (value >= nice / 2)
      return nice / 2;
    else if (value >= nice / 4)
      return nice / 4;
    else if (value >= nice / 5)
      return nice / 5;
    else if (value >= nice / 10)
      return nice / 10;
    return value;
  },
  getScale(min, max, ticks) {
    const nmin = UTILS.niceNumberUnder(min);
    const nmax = UTILS.niceNumberOver(max);
    const d = nmax - nmin;
    const spacing1 = UTILS.niceNumberUnder(d / ticks);
    const spacing2 = UTILS.niceNumberOver(d / ticks);
    const t1 = Math.ceil(d / spacing1) + 1;
    const t2 = Math.ceil(d / spacing2) + 1;
    const r1 = {
      min: nmin,
      max: nmax,
      spacing: spacing1,
      ticks: t1
    };
    const r2 = {
      min: nmin,
      max: nmax,
      spacing: spacing2,
      ticks: t2
    };
    r1.min = r1.max - (t1 - 1) * spacing1;
    r2.min = r2.max - (t2 - 1) * spacing2;
    if (Math.abs(t1 - ticks) < Math.abs(t2 - ticks)) {
      r1.range = r1.max - r1.min;
      return r1;
    } else if (Math.abs(t1 - ticks) > Math.abs(t2 - ticks)) {
      r2.range = r2.max - r2.min;
      return r2;
    } else if (Math.abs(nmax - (nmin + t1 * spacing1)) < Math.abs(nmax - (nmin + t2 * spacing2))) {
      r1.range = r1.max - r1.min;
      return r1;
    }
    r2.range = r2.max - r2.min;
    return r2;
  },
  vectorLength(v) {
    if (!v.x && !v.y) {
      return 0;
    }
    return Math.sqrt(v.x * v.x + v.y * v.y);
  },
  normalizeVector(v) {
    const d = UTILS.vectorLength(v);
    if (d) {
      v.x = v.x / d;
      v.y = v.y / d;
    }
    return v;
  },
  pointDistance(x1, y1, x2, y2) {
    if (isNaN(x2)) {
      y2 = y1.y;
      x2 = y1.x;
      y1 = x1.y;
      x1 = x1.x;
    }
    const t = (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
    if (t) {
      return Math.sqrt(t);
    }
    return 0;
  },
  traverseVector(p, v, d) {
    return {
      x: p.x + v.x * d,
      y: p.y + v.y * d
    };
  },
  lineToNVector(A, B) {
    return UTILS.normalizeVector(UTILS.lineToVector(A, B));
  },
  lineToVector(A, B) {
    return {
      x: B.x - A.x,
      y: B.y - A.y
    };
  },
  pointFromLine(p, A, B) {
    const len = UTILS.pointDistance(A, B);
    const v = UTILS.lineToNVector(A, B);
    return Math.min(UTILS.pointDistance(p, UTILS.traverseVector(A, v, Math.min(UTILS.pointDistance(A, p), len))), UTILS.pointDistance(p, UTILS.traverseVector(B, {
      x: -v.x,
      y: -v.y
    }, Math.min(UTILS.pointDistance(B, p), len))));
  },
  msDay: 24 * 60 * 60 * 1000,
  msHour: 60 * 60 * 1000,
  msMinute: 60 * 1000,
  msSecond: 1000,
  msToTime(v, precision) {
    if (v === '-' || !v) {
      return v;
    }
    if (!precision) {
      precision = 9;
    }
    const d = Math.floor(v / 1000 / 60 / 60 / 24);
    v -= d * this.msDay;
    const h = Math.floor(v / 1000 / 60 / 60);
    v -= h * this.msHour;
    const m = Math.floor(v / 1000 / 60);
    v -= m * this.msMinute;
    const s = Math.floor(v / 1000);
    v -= s * this.msSecond;
    let r = '';
    if (d && precision) {
      r = r + d + 'd';
      precision--;
    }
    if (h && precision) {
      if (r) {
        r = r + ' ';
      }
      r = r + h + 'h';
      precision--;
    }
    if (m && precision) {
      if (r) {
        r = r + ' ';
      }
      r = r + m + 'm';
      precision--;
    }
    if (s && precision) {
      if (r) {
        r = r + ' ';
      }
      r = r + s + 's';
      precision--;
    }
    if (!r) {
      r = '0';
    }
    return r;
  }
}

export default UTILS;
export const doParseFloat = UTILS.doParseFloat;
export const changeHandler = UTILS.changeHandler;