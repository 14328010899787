import moment from 'moment';

export default {

  /*
  * Calculates cumulative five year cost effect estimation
  */
  costEffectEstimation: function(consumptionChangePc, referenceHistory, installationDate, reportDate, waterPrice, heatingPrice) {

    const savingCoef = 1 + consumptionChangePc / 100;
    const installDate = moment(installationDate);
    const avgRefConsumption = referenceHistory.reduce((a, b) => a + b, 0) / referenceHistory.length;
    const avgCurrentConsumption = avgRefConsumption * savingCoef;
    const reference = 12 * avgRefConsumption;
    const reportYear = moment(reportDate).year();

    let result = [];
    let totalRef = 0;
    let totalSaved = 0;

    for (let y = 0; y < 5; y++) {
      let saved = 0;

      const year = reportYear + y;
      if (year <= installDate.year()) {
        for (let month = 0; month < 12; month++) {
          const date = moment({year, month});
          saved += date < installDate ? avgRefConsumption : avgCurrentConsumption;
        }
      } else {
        saved = 12 * avgCurrentConsumption;
      }

      const price = (consumption) => (consumption * waterPrice + consumption * 0.4 * 0.058 * heatingPrice) * Math.pow(1.05, y);

      totalRef += price(reference);
      totalSaved += price(saved);

      result.push({
        name: year,
        reference: totalRef,
        estimation: totalSaved
      });
    }

    return result;
  },

  /*
  * Create 12 months of monthly consumption data from an array of reports
  */
  monthlyConsumption: function(reports, date) {
    date = moment(date);
    let result = [];
    for (let i = 0; i < 12; i++) {
      let year = date.year();
      let month = date.month() + 1;

      let referenceValue = null;
      let currentValue = null;

      let report = reports.find((r) => r.year === year && r.month === month);
      if (report) {
        currentValue = report.currentPeriod.consumption;
        referenceValue = report.referencePeriod.consumption;
      }

      report = report || {year, month};

      report.graphValues = [
        referenceValue,
        currentValue
      ];

      result.unshift(report);
      date.subtract(1, 'months');
    }
    return result;
  },

  /*
  *
  */
  arrayOfObjsToObject: function(ary, idKey, valueKey) {
    if (ary) {
      let obj = {};
      ary.forEach((x) => obj[x[idKey]] = x[valueKey]);
      return obj;
    }
    return {};
  }

};
