import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import * as mui from 'material-ui';
import BaseView from './BaseView';
//import { PropTypes } from 'react-proptypes'
import AppBar from '../components/AppBar';
import SearchFilter from '../components/SearchFilter';
import DateInput from '../components/DateInput';
import HeatingCompanyStore from '../stores/HeatingCompanyStore'
import HeatingCompanyActions from '../actions/HeatingCompanyActions'
import PremiseActions from '../actions/PremiseActions';
import PremisesStore from '../stores/PremisesStore';
import { gettext } from 'i18n';
import _ from 'lodash';
import TextInput from '../components/TextInput';
import SelectBox from '../components/SelectBox';
import ViewLoader from '../components/ViewLoader';
import Currencies from '../utils/currencies';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PlusIcon from '@material-ui/icons/AddBox';
//import Box from '@material-ui/core/Box';


@connectToStores
class WarmCompaniesView extends BaseView {
  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme,
      filters: {},
      sortParams: { sortBy: 'name', order: 'asc' },
      newHCompany: {},
      dialogOpen:false,
    };
  }

  static getStores() {
    return [HeatingCompanyStore,PremisesStore];
  }

  static getPropsFromStores() {
    return {...HeatingCompanyStore.getState(), countries:PremisesStore.getState().countries};
  }

  static propTypes = {
    companies: PropTypes.array,
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentDidMount() {
    this.initialQuery = setTimeout(HeatingCompanyActions.query, 100);
  }

  componentDidMount() {
    setTimeout(()=>{ PremiseActions.getDistinctCountries();},200);
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }
    HeatingCompanyActions.query(filters);
  }

  _onDialogCancel() {
    //this.refs.dialog.dismiss();
    HeatingCompanyActions.change({});
    this.setState({dialogOpen:false});
  }

  _onDialogSubmit() {
    //this.refs.dialog.dismiss();
    HeatingCompanyActions.editHCompany(this.props.newHCompany);
    HeatingCompanyActions.change({});
    this.setState({dialogOpen:false});
  }

  openDialog(data) {
    console.log("OpenDialog: ", data)
    //if (this.refs.dialog) {
      if (data) HeatingCompanyActions.change(data);
      //this.refs.dialog.show();
      this.setState({dialogOpen:true});
    //}
  }

  onChange(value, id, textField) {
    console.log("onChange: ", value, id)
    if (textField.isMandatory() && !value) {
      textField.setErrorText(gettext('ui_pakollinen_kentta'));
    } else {
      textField.setErrorText(null)
    }
    var v = {};
    v[id] = value;
    HeatingCompanyActions.change(v);
  }

  onSelectionChange(selection, id, selectBox) {
    console.log("onSelectionChanged",selection,id,selectBox);
    var v = {};
    v[id] = selection.value;
    console.log("v",v);
    HeatingCompanyActions.change(v);
    if (selectBox.isMandatory() && !selection) {
      selectBox.setErrorText(gettext('ui_pakollinen_kentta'));
    } else {
      selectBox.setErrorText(null)
    }
  }

  sortBy(sb, e) {
    if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({ sortParams: { sortBy: sb, order: "desc" } });
    } else {
      this.setState({ sortParams: { sortBy: sb, order: "asc" } });
    }
  }

  onDateChange(name, date) {
    var v = {};
    v[name] = date;
    HeatingCompanyActions.change(v);

  }

  render() {
    
    let loading = this.props.hcompanies.length === 0;
    var countries=[];
    if (this.props.countries) {
      countries = this.props.countries.map((country, i) => { return { value: country, label: country } });
    } 

    let filters = [
      { type: 'name', prop: 'name', operator: "%", text: gettext('ui_name'), value: '' },
      { type: 'country', prop: "country", operator: '=', text: gettext('ui_country_code'), options: countries},
      { type: 'energypriceover', prop: 'energyprice', operator: ">", text: gettext('ui_energypriceover'), value: '', component: 'number' },
      { type: 'energypriceunder', prop: 'energyprice', operator: "<", text: gettext('ui_energypriceunder'), value: '', component: 'number' },
      { type: 'beforeDate', prop: "updated", operator: "<=", text: gettext('ui_updated_before'), value: '', component: 'datePicker' }];

    let dialogStyle = {
      backgroundColor: this.state.muiTheme.rawTheme.palette.accent2Color,
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      borderTop: '4px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px'
    }
    let dialogBoxStyle = {
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px',
      width: '100%',
      margin: '5px'
    }

    let heatingTypes = [
      { value: 'district', label: gettext('ui_district_heating') },
      { value: 'pellet', label: gettext('ui_pellet') },
      { value: 'woodchips', label: gettext('ui_wood_chips') },
      { value: 'geothermal', label: gettext('ui_geothermal') },
      { value: 'gas', label: gettext('ui_natural_gas') },
      { value: 'oil', label: gettext('ui_oil') },
      { value: 'electric', label: gettext('ui_electric') },
    ];

    heatingTypes = heatingTypes.sort((a, b) => a.label.localeCompare(b.label))


    let dialog = (
      <Dialog 
      open={this.state.dialogOpen}
      maxWidth='md'
      fullWidth={true}
      >
        <DialogTitle>
          <span style={{color:MyRawTheme.palette.primary1Color}}>{(this.props.newHCompany.name) ? this.props.newHCompany.name : gettext('ui_editHCompany').toUpperCase()}</span>
        </DialogTitle>
        <DialogContent>
        <div className="" style={dialogStyle}>
          <div className="row">
            <div className="col-md-12 col-xs-12"><TextInput value={this.props.newHCompany.name} id="name" mandatory={true} label={gettext('ui_name')} onChange={this.onChange} /></div>
          </div>
          <div className="row">
            <div className="col-md-5 col-xs-12"><TextInput value={this.props.newHCompany.address} id="address" label={gettext('ui_address')} onChange={this.onChange} /></div>
            <div className="col-md-3 col-xs-12"><TextInput value={this.props.newHCompany.zipCode} id="zipCode" label={gettext('ui_zipCode')} onChange={this.onChange} /></div>
            <div className="col-md-4 col-xs-12"><TextInput value={this.props.newHCompany.postOffice} id="postOffice" label={gettext('ui_postOffice')} onChange={this.onChange} /></div>
          </div>

          <div className="row">
          <div className="col-md-3 col-xs-12"><SelectBox value={countries.filter(({ value }) => value === this.props.newHCompany.country)} name="country" options={countries} label={gettext('ui_country')} onSelectionChange={this.onSelectionChange.bind(this)} /></div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xs-12"><SelectBox label={gettext('ui_heatingType')} value={heatingTypes.filter(({value})=>value===this.props.newHCompany.heatingType)} placeholder={gettext('ui_choose')} name="heatingType" options={heatingTypes} onSelectionChange={this.onSelectionChange.bind(this)} /></div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12"><span style={{ color: this.state.muiTheme.rawTheme.palette.primary1Color, margin: '5px', fontSize: '18px' }}>{gettext('ui_changePrice')}</span></div>
          </div>
          <div className="row around-xs" style={dialogBoxStyle} >
            <div className="col-md-3 col-xs-12"><TextInput value={this.props.newHCompany.energyprice} id="energyprice" unit="€" label={gettext('ui_energyprice') + ' MWh'} onChange={this.onChange} /></div>
            <div className="col-md-3 col-xs-12">
              <SelectBox value={Currencies('all').filter(({value})=>value===this.props.newHCompany.currency)} name="currency" options={Currencies('all')} label={gettext('ui_currency')} onSelectionChange={this.onSelectionChange.bind(this)} />
            </div>
            <div className="col-md-3 col-xs-12"><DateInput value={this.props.newHCompany.updated} label={gettext('ui_updated')} name="updated" onChange={this.onDateChange.bind(this)} /></div>
          </div>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onDialogCancel.bind(this)} color='primary'>{gettext('ui_cancel').toUpperCase()}</Button>
          <Button onClick={this._onDialogSubmit.bind(this)} color='primary'>{gettext('ui_submit').toUpperCase()}</Button>
          </DialogActions>
      </Dialog>
    )

    var rows = _.map(_.orderBy(this.props.hcompanies, [this.state.sortParams.sortBy], [this.state.sortParams.order]), (u,i) => {
      let date = new Date(u.updated);
      date = (date.toString() !== 'Invalid Date') ? (`${date.getMonth() + 1} / ${date.getFullYear()}`) : "-";

      let heatingType = (u.heatingType) ? gettext(u.heatingType) : '';
      //console.log("heatingType:",typeof(heatingType),heatingType);
      if(typeof(heatingType)==='object'){
        heatingType=heatingType.value;
      }
      return (
        <tr className="pointer" key={u.name+i} onClick={() => this.openDialog(u)}>
          <td>{u.name}</td>
          <td>{u.energyprice}</td>
          <td>{heatingType}</td>
          <td>{u.country}</td>
          <td>{date}</td>
        </tr>
      )
    });

    const newCompanyButton = (
      <span>
        {gettext('ui_add_new')}
      </span>
    );
    return (
      <div>
        <AppBar
          title={gettext('title_kaukolampoyhtiot')}
          actionBtn={newCompanyButton}
          onActionBtnClick={() => this.openDialog()}
        />
        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
          <SearchFilter localStorage="heatingCompanyFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} />

          <table className="table">
            <thead>
              <tr>
                <th id="tableheader" onClick={this.sortBy.bind(this, 'name')}>{gettext('ui_companyName')}</th>
                <th onClick={this.sortBy.bind(this, 'energyprice')}>{gettext('ui_energyprice')}</th>
                <th onClick={this.sortBy.bind(this, 'heatingtype')}>{gettext('ui_heatingType')}</th>
                <th onClick={this.sortBy.bind(this, 'country')}>{gettext('ui_country')}</th>
                <th onClick={this.sortBy.bind(this, 'updated')}>{gettext('ui_updated')}</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {loading && <ViewLoader blue />}
        </div>
        {dialog}
      </div>
    );

  }
}

export default WarmCompaniesView;