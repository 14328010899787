import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../utils/utils';
import moment from 'moment';

const M3_DECIMALS = 5; // decimals for cubic metres
const L_DECIMALS = 2; // decimals for metric litres

export default class SampleChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {hlObject:null, tooltipLeft: "0px", tooltipTop: "0px", tooltipBottom:null, tooltipRight:null};
    this.hlObjects = [];
  }

  static propTypes = {
    data: PropTypes.object
  }

  componentWillMount() {
  }

  componentWillUnmount() {
    this._context = null;
    this._canvas = null;
  }

  getMouse(e) {
    if (e && this._canvas) {
      const rect = this._canvas.getBoundingClientRect();
      return { x: e.clientX - rect.left, y: e.clientY - rect.top };
    }
    return null;
  }

  pointerDown() {
  }

  pointerMove(e) {
    if (!this.hlObjects.length) {
      return;
    }
    let hlObject = null;
    const p = this.getMouse(e);
    if (p) {
      for (let i = 0; i < this.hlObjects.length; i++) {
        const o = this.hlObjects[i];
        if (o.type === 'circle' && Utils.pointDistance(p.x, p.y, o.x, o.y) <= o.size) {
          hlObject = o;
          break;
        }
      }
      if (!hlObject) {
        for (let i = 0; i < this.hlObjects.length; i++) {
          const o = this.hlObjects[i];
          if (o.type === 'rect' && o.x1 <= p.x + 0.7 && o.x2 >= p.x - 0.7 && o.y1 <= p.y + 0.7 && o.y2 >= p.y - 0.7) {
            hlObject = o;
            break;
          }
        }
      }
      if (!hlObject) {
        for (let i = 0; i < this.hlObjects.length; i++) {
          const o = this.hlObjects[i];
          if (o.type === 'line' && Utils.pointFromLine(p, {x:o.x1, y:o.y1}, {x:o.x2, y:o.y2}) <= o.size) {
            hlObject = o;
            break;
          }
        }
      }
    }
    if ((hlObject && !this.state.hlObject) || (!hlObject && this.state.hlObject) || (hlObject && this.state.hlObject && hlObject.tip !== this.state.hlObject.tip)) {
      let cw = 0;
      let ch = 0;
      let parent = this._canvas.parentElement;
      while (parent) {
        cw = parent.clientWidth;
        ch = parent.clientHeight;
        const pos = parent && parent.style && parent.style.position;
        if (pos === 'relative' || pos === 'absolute') {
          break;
        } else {
          parent = parent.parentElement;
        }
      }
      let tooltipLeft = null;
      let tooltipRight = null;
      let tooltipTop = null;
      let tooltipBottom = null;
      if (p.x > cw - p.x) {
        tooltipRight = Math.round(cw - p.x);
      } else {
        tooltipLeft = Math.round(p.x);
      }
      if (p.y > ch - p.y) {
        tooltipBottom = Math.round(ch - p.y);
      } else {
        tooltipTop = Math.round(p.y);
      }
      if (tooltipLeft !== null && tooltipTop !== null) {
        tooltipLeft += 8;
        tooltipTop += 8;
      }
      if (tooltipLeft !== null) {
        tooltipLeft = tooltipLeft + 'px';
      }
      if (tooltipRight !== null) {
        tooltipRight = tooltipRight + 'px';
      }
      if (tooltipBottom !== null) {
        tooltipBottom = tooltipBottom + 'px';
      }
      if (tooltipTop !== null) {
        tooltipTop = tooltipTop + 'px';
      }
      this.setState({hlObject, tooltipLeft, tooltipTop, tooltipRight, tooltipBottom});
    }
  }

  pointerUp() {
  }

  renderConsumptionBar(c, view, yScale, xScale, data) {
    c.fillStyle = '#90cbf9';
    c.lineWidth = 1.5;
    for (let i = 0; i < data.length; i++) {
      if (data[i].y) {
        c.beginPath();
        c.strokeStyle = '#0D429B';
        let y1 = view.h - view.bottomMargin - (data[i].y - yScale.min) / yScale.range * view.ch;
        const x1 = view.leftMargin + (data[i].x1 - xScale.min) / xScale.range * view.cw;
        const x2 = view.leftMargin + (data[i].x2 - xScale.min) / xScale.range * view.cw;
        let y2 = view.h - view.bottomMargin + yScale.min / yScale.range * view.ch;
        if (y2 < y1) {
          const t = y2;
          y2 = y1;
          y1 = t;
        }
        c.rect(x1, y1, x2 - x1, y2 - y1);
        c.fill();
        c.stroke();

        const tip = (
          <div>
            <table className="hint-table">
              <tbody>
                <tr>
                  <td style={{padding:"4px"}}>{moment(data[i].timestamp1).format('HH:mm:ss')}</td>
                  <td style={{padding:"4px"}}>{data[i].value1.toFixed(M3_DECIMALS) + "m³"}</td>
                </tr>
                <tr>
                  <td style={{padding:"4px"}}>{moment(data[i].timestamp2).format('HH:mm:ss')}</td>
                  <td style={{padding:"4px"}}>{data[i].value2.toFixed(M3_DECIMALS) + "m³"}</td>
                </tr>
                <tr>
                  <td style={{padding:"4px"}}>kulutus</td>
                  <td style={{padding:"4px"}}><strong>{data[i].y.toFixed(L_DECIMALS)}</strong>l/min</td>
                </tr>
                <tr>
                  <td style={{padding:"4px"}}>aika</td>
                  <td style={{padding:"4px"}}>{Utils.msToTime(moment(data[i].timestamp2).valueOf() - moment(data[i].timestamp1).valueOf())}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        this.hlObjects.push({type: 'rect', x1, y1, x2, y2, tip});
      } else {
        c.strokeStyle = 'rgba(121,255,137,0.75)';
        c.lineWidth = 2.5;
        const y1 = view.h - view.bottomMargin - (data[i].y - yScale.min) / yScale.range * view.ch;
        const x1 = view.leftMargin + (data[i].x1 - xScale.min) / xScale.range * view.cw;
        const x2 = view.leftMargin + (data[i].x2 - xScale.min) / xScale.range * view.cw;
        const y2 = view.h - view.bottomMargin + yScale.min / yScale.range * view.ch;
        c.beginPath();
        c.moveTo(x1, y1);
        c.lineTo(x2, y2);
        c.stroke();
        const start = moment(data[i].timestamp1);
        const end = moment(data[i].timestamp2);
        const tip = (
          <div>
            <p>{"Mittarilukema " + start.format("HH:mm:ss") + " " + data[i].value1.toFixed(M3_DECIMALS) + "m³"}</p>
            <p>{"Mittarilukema " + end.format("HH:mm:ss") + ' ' + data[i].value2.toFixed(M3_DECIMALS) + "m³"}</p>
            <p>{"Kulutus: 0,00l/min"}</p>
            <p>Aika:<strong>{" " + Utils.msToTime(end.valueOf() - start.valueOf())}</strong></p>
          </div>
        );
        this.hlObjects.push({type: 'line', x1, y1, x2, y2, size: 2.5, tip});
      }
    }
  }

  renderConsumptionLine(c, view, yScale, xScale, data) {
    c.strokeStyle = 'rgba(151,187,205,0.5)';
    c.lineWidth = 1.5;
    c.beginPath();
    for (let i = 0; i < data.length; i++) {
      const y = view.h - view.bottomMargin - (data[i].y - yScale.min) / yScale.range * view.ch;
      const x = view.leftMargin + (data[i].x - xScale.min) / xScale.range * view.cw;
      if (i === 0) {
        c.moveTo(x, y);
      } else {
        c.lineTo(x, y);
      }
    }
    c.stroke();

    c.fillStyle = '#2E2EFE';
    for (let i = 0; i < data.length; i++) {
      const y = view.h - view.bottomMargin - (data[i].y - yScale.min) / yScale.range * view.ch;
      const x = view.leftMargin + (data[i].x - xScale.min) / xScale.range * view.cw;
      c.beginPath();
      c.arc(x, y, 5, 0, 2 * Math.PI);
      c.fill();
      const tip = (
        <div>
          <p>{moment(data[i].timestamp).format("HH:mm:ss")}</p>
          <p>{"mittarilukema: " + data[i].value.toFixed(M3_DECIMALS)}</p>
          {i > 0 && <p>{"kulutus: " + ((data[i].value - data[i - 1].value) * 1000).toFixed(L_DECIMALS) + "l"}</p>}
        </div>
      );
      this.hlObjects.push({type: 'circle', x, y, size: 6, tip});
    }
  }

  renderUsedLine(c, view, yScale, xScale, data) {
    c.strokeStyle = 'rgba(151,187,205,0.5)';
    c.lineWidth = 1.5;
    for (let i = 0; i < data.length; i++) {
      const y = view.h - view.bottomMargin - (data[i].y - yScale.min) / yScale.range * view.ch;
      const x = view.leftMargin + (data[i].x - xScale.min) / xScale.range * view.cw;
      data[i].yy = y;
      data[i].xx = x;
      if (i === 0) {
        // c.moveTo(x, y);
      } else {
        if (data[i].y > data[i - 1].y) {
          c.strokeStyle = 'rgba(151,187,205,0.5)';
          c.lineWidth = 1.5;
        } else {
          c.strokeStyle = 'rgba(121,255,137,0.75)';
          c.lineWidth = 2.5;
        }
        c.beginPath();
        c.moveTo(data[i - 1].xx, data[i - 1].yy);
        c.lineTo(x, y);
        c.stroke();
        const start = moment(data[i - 1].timestamp);
        const end = moment(data[i].timestamp);
        const tip = (
          <div>
            <p>{"Mittarilukema " + start.format("HH:mm:ss") + " " + data[i - 1].value.toFixed(M3_DECIMALS) + "m³"}</p>
            <p>{"Mittarilukema " + end.format("HH:mm:ss") + ' ' + data[i].value.toFixed(M3_DECIMALS) + "m³"}</p>
            <p>{"Kulutus: " + (1000 * (data[i].value - data[i - 1].value)).toFixed(L_DECIMALS) + "l"}</p>
            <p>{"Keskikulutus: " + (1000 * (data[i].value - data[i - 1].value) / (end.valueOf() - start.valueOf()) * 1000 * 60).toFixed(L_DECIMALS) + "l/min"}</p>
            <p>{"Aika: " + Utils.msToTime(end.valueOf() - start.valueOf())}</p>
          </div>
        );
        this.hlObjects.push({type: 'line', x1: data[i - 1].xx, y1: data[i - 1].yy, x2: x, y2: y, size: 2.5, tip});
      }
    }

    c.fillStyle = '#90cbf9';
    for (let i = 0; i < data.length; i++) {
      const y = view.h - view.bottomMargin - (data[i].y - yScale.min) / yScale.range * view.ch;
      const x = view.leftMargin + (data[i].x - xScale.min) / xScale.range * view.cw;
      c.beginPath();
      c.arc(x, y, 3, 0, 2 * Math.PI);
      c.fill();
      this.hlObjects.push({type: 'circle', x, y, size: 4,
        tip: (
          <div>
            <p>{moment(data[i].timestamp).format('HH:mm:ss')}</p>
            <p>{'Mittarilukema: ' + data[i].value.toFixed(M3_DECIMALS) + 'm³\n'}</p>
          </div>
        )});
    }
  }

  paint() {
    const c = this._context;
    this.hlObjects = [];
    if (c && this.props) {
      const view = {
        w: parseInt(this._canvas.style.width, 10),
        h: parseInt(this._canvas.style.height, 10),
        leftMargin: 100,
        bottomMargin: 100,
        topMargin: 50,
        rightMargin: 50 }
      view.cw = view.w - view.leftMargin - view.rightMargin;
      view.ch = view.h - view.bottomMargin - view.topMargin;
      c.fillStyle = '#ffffff';
      c.beginPath();
      c.rect(0, 0, view.w, view.h);
      c.fill();
      let data = this.props.data.data;
      if (!data || !data.length) {
        return;
      }

      // process data for used
      if (this.props.data.type === 'used') {
        data = [];
        const d = this.props.data.data;
        for (let i = 0; i < d.length; i++) {
          data.push({x:d[i].x, y:d[i].y, value:d[i].value, timestamp:d[i].timestamp});
          if (i > 0) {
            data[i].y += data[i - 1].y;
          }
        }
      } else if (this.props.data.type === 'consumption') { // process data for consumption bar
        data = [];
        const d = this.props.data.data;
        /*
        data.push({x1: d[i - 1].x, x2: d[i].x, value1: d[i - 1].value, value2: d[i].value, timestamp1: start, timestamp2: end,
          y: (d[i].value - d[i - 1].value) * 1000 / (end.valueOf() - start.valueOf()) * 1000 * 60, value: (d[i].value - d[i - 1].value) * 1000 });
          */
        for (let i = 1; i < d.length; i++) {
          const start = moment(d[i - 1].timestamp);
          const end = moment(d[i].timestamp);
          data.push({x1: d[i - 1].x, x2: d[i].x, value1: d[i - 1].value, value2: d[i].value, timestamp1: start, timestamp2: end,
            y: (d[i].value - d[i - 1].value) * 1000 / (end.valueOf() - start.valueOf()) * 1000 * 60, value: (d[i].value - d[i - 1].value) * 1000 });
        }
      }

      let min = null;
      let max = null;
      for (let i = 0; i < data.length; i++) {
        if (min === null || data[i].y < min) {
          min = data[i].y;
        }
        if (max === null || data[i].y > max) {
          max = data[i].y;
        }
      }

      if (min === max) {
        max += 0.001;
      }

      // yScale -->
      const yScale = Utils.getScale(Math.min(min, 0), max, 10);
      c.textBaseline = 'middle';
      c.textAlign = 'right';
      let v = yScale.min;
      c.fillStyle = '#555555';
      c.strokeStyle = '#555555';
      c.lineWidth = 0.5;
      c.beginPath();
      while (v <= yScale.max + yScale.spacing / 2) {
        let y = view.h - view.bottomMargin - (v - yScale.min) / yScale.range * view.ch;
        c.fillText(v.toFixed(M3_DECIMALS), view.leftMargin - 4, y);
        c.moveTo(view.leftMargin, y);
        c.lineTo(view.w - view.rightMargin, y);
        v += yScale.spacing;
      }

      // xScale -->
      let m = moment(this.props.data.start).startOf('hour');
      const xScale = {min:moment(m).startOf('hour').valueOf(), max:moment(m).startOf('hour').add(1, 'hour').valueOf()}
      xScale.range = xScale.max - xScale.min;
      c.textBaseline = 'top';
      c.textAlign = 'center';
      c.fillStyle = '#555555';
      c.strokeStyle = '#555555';
      c.lineWidth = 0.5;
      let maxc = 0;
      while (m.valueOf() <= xScale.max && maxc < 20) {
        maxc++;
        let x = view.leftMargin + (m.valueOf() - xScale.min) / xScale.range * view.cw;
        c.fillText(m.format('HH:mm'), x, view.h - view.bottomMargin + 4);
        c.moveTo(x, view.topMargin);
        c.lineTo(x, view.h - view.bottomMargin);
        m = m.add(5, 'minutes');
      }
      c.stroke();
      if (this.props.data.type === 'consumptionLine') {
        this.renderConsumptionLine(c, view, yScale, xScale, data);
      } else if (this.props.data.type === 'used') {
        this.renderUsedLine(c, view, yScale, xScale, data);
      } else { // default to consumption
        this.renderConsumptionBar(c, view, yScale, xScale, data);
      }
      if (this.state.hlObject) {
        const hl = this.state.hlObject;
        if (hl.type === 'circle') {
          c.fillStyle = 'rgba(0,0,0,0.3)';
          c.beginPath();
          c.arc(hl.x, hl.y, hl.size * 2, 0, 2 * Math.PI);
          c.fill();
        } else if (hl.type === 'line') {
          c.strokeStyle = 'rgba(0,0,0,0.3)';
          c.lineWidth = hl.size * 2;
          c.beginPath();
          c.moveTo(hl.x1, hl.y1);
          c.lineTo(hl.x2, hl.y2);
          c.stroke();
        } else if (hl.type === 'rect') {
          c.fillStyle = 'rgba(0,0,0,0.3)';
          c.beginPath();
          c.rect(hl.x1, hl.y1, hl.x2 - hl.x1, hl.y2 - hl.y1);
          c.fill();
        }
      }
    }
  }

  render() {
    const style = {width:'1280px', height:'640px', margin:'0px', padding:'0px'}
    const tooltipStyle = {
      position:"absolute",
      backgroundColor:"rgba(0,0,0,0.7)",
      pointerEvents:"none",
      color:"#ffffff",
      zIndex:100,
      width:"auto",
      height:"auto",
      padding:"8px",
      borderRadius:"4px"
    }
    if (this.state.tooltipLeft) {
      tooltipStyle.left = this.state.tooltipLeft
    }
    if (this.state.tooltipRight) {
      tooltipStyle.right = this.state.tooltipRight
    }
    if (this.state.tooltipTop) {
      tooltipStyle.top = this.state.tooltipTop
    }
    if (this.state.tooltipBottom) {
      tooltipStyle.bottom = this.state.tooltipBottom
    }
    return (
      <div style={style}>
        <canvas className="chart-canvas" style={{background:"transparent"}} ref={(canvas)=>{
          let context = null;
          if (canvas) {
            context = canvas.getContext('2d');
            const bsr = context.webkitBackingStorePixelRatio ||
                context.mozBackingStorePixelRatio ||
                context.msBackingStorePixelRatio ||
                context.oBackingStorePixelRatio ||
                context.backingStorePixelRatio || 1;

            const s = (window.devicePixelRatio || 1) / bsr;
            const w = canvas.parentElement.clientWidth;
            const h = canvas.parentElement.clientHeight;
            if (canvas.width !== Math.round(w * s) || canvas.height !== Math.round(h * s)) {
              canvas.width = Math.round(w * s);
              canvas.height = Math.round(h * s);
              canvas.style.width = w + 'px';
              canvas.style.height = h + 'px';
              context = canvas.getContext('2d');
            }
            context.setTransform(s, 0, 0, s, 0, 0);
          }
          this._canvas = canvas;
          this._context = context;
          this.paint();
        }}
        onTouchStart={(e)=>this.pointerDown(e)}
        onMouseDown={(e)=>this.pointerDown(e)}
        onTouchMove={(e)=>this.pointerMove(e)}
        onMouseMove={(e)=>this.pointerMove(e)}
        onTouchEnd={(e)=>this.pointerUp(e)}
        ></canvas>
        <div className="tooltip" hidden={!(this.state.hlObject && this.state.hlObject.tip)} style={tooltipStyle}>
          {this.state.hlObject && this.state.hlObject.tip}
        </div>
      </div>
    );
  }
}
