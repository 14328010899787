import alt from '../utils/alt';
import DebugReportActions from '../actions/DebugReportActions';

class DebugReportStore {
  constructor() {
    this.bindActions(DebugReportActions);
    this.state = {data:[], loading:false};
  }

  onQuery(data) {
    // console.log('received dailydata:', data)
    this.setState({data:data.data, loading:data.loading || false});
  }
}

//module.exports = alt.createStore(DebugReportStore);
export default alt.createStore(DebugReportStore);
