import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class InfoDialog extends React.Component {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.dialogData = null;
    this.state = {
      //muiTheme: muiTheme
      dialogOpen: false,
    };
  }

  open(data) {
    this.dialogData = data;
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
  }

  dialogData() {
    return this.data;
  }

  close() {
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
  }


  render() {

    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }


    let title = this.props.title ? this.props.title : gettext('ui_confirm_title');
    let showClose = this.props.showClose !== undefined ? this.props.showClose : true
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div style={dialogStyle}>
            {this.props.text}
          </div>
        </DialogContent>
        <DialogActions>
          {showClose && <Button onClick={() => (this.close())} >{gettext('ui_close')}</Button>}
        </DialogActions>
      </Dialog>
    );

  }
}


