var config = require('./config').conf;
var qs = require('qs');

var UrlBuilder = function() {
  this.url = config.APISERVER
  /*this.query = "";
  this.limit = "";
  this.start = "";
  this.end = "";
  this.frequency = "";
  this.sort = "";*/
  //config.DATASERVER + '/optiwater/' + id + '/mine?' + 'start=' + start + '&end=' + end + '&frequency=' + frequency + '&offset=2';
}

UrlBuilder.prototype.path = function(path) {
  this.path = path;
  return this;
}

UrlBuilder.prototype.id = function(id) {
  if (!id) throw new Error('id is required')
  this.id = id;
  return this;
}

UrlBuilder.prototype.query = function(query) {
  if (query) this.query = JSON.stringify(query);
  return this;
}

UrlBuilder.prototype.limit = function(limit) {
  if (!isNaN(limit)) this.limit = limit;
  return this;
}

UrlBuilder.prototype.skip = function(skip) {
  if (!isNaN(skip)) this.skip = skip;
  return this;
}

UrlBuilder.prototype.start = function(start) {
  if (start) this.start = start;
  return this;
}

UrlBuilder.prototype.end = function(end) {
  if (end) this.end = end;
  return this;
}

UrlBuilder.prototype.frequency = function(frequency) {
  if (frequency) this.frequency = frequency;
  return this;
}

UrlBuilder.prototype.offset = function(offset) {
  if (offset) this.offset = offset;
  return this;
}

UrlBuilder.prototype.sort = function(sort) {
  if (sort) this.sort = JSON.stringify(sort);
  return this;
}

UrlBuilder.prototype.filter = function(filter) {
  if (filter) this.filter = JSON.stringify(filter);
  return this;
}
UrlBuilder.prototype.usage = function(usage) {
  this.usage = usage;
  return this;
}

UrlBuilder.prototype.toString = function() {
  if (typeof this.path === 'function') throw new Error('path is required')
  var obj = {};
  for (var key in this) {
    if (this.hasOwnProperty(key) && key !== 'url' && key !== 'path' && key !== 'id') {
      obj[key] = this[key];
    }
  }
  var query = qs.stringify(obj)
  var id = typeof this.id !== 'function' ? '/' + this.id : "";
  if (query) return this.url + '/' + this.path + id + '?' + query;
  return this.url + '/' + this.path + id;
}

module.exports = UrlBuilder
