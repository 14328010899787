import React from 'react';
import * as mui from 'material-ui';
import { RadioButton, RadioButtonGroup, DatePicker, RaisedButton } from 'material-ui'
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import { gettext } from 'i18n';
import { Bar } from "react-chartjs-2";
import SampleChart from '../components/SampleChart';
import DeviceHistoryTable from '../components/DeviceHistoryTable'
import PeriodDataTable from '../components/PeriodDataTable'
import AnalyzeActions from '../actions/AnalyzeActions';
import AnalyzeStore from '../stores/AnalyzeStore';
import PremiseActions from '../actions/PremiseActions';
import connectToStores from 'alt-utils/lib/connectToStores';
import moment, { duration } from 'moment';
import Utils from '../utils/utils';

import ViewLoader from '../components/ViewLoader';
import LoginStore from '../stores/LoginStore';
import MonthPicker from '../components/MonthPicker';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { isNumber } from 'util';

const _limit = 100;

const hexadecimal = /^[0-9A-F]+$/i;

function isMongoId(str) {
  if (!str) return false;
  return hexadecimal.test(str) && str.length === 24;
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }} className="col-xs-12">
      {props.children}
    </Typography>
  );
}

@connectToStores
export default class AnalyzeView extends BaseView {

  constructor(props) {
    super(props);
    this.state = {
      devices: null,
      tab: 'analyze',
      mineData: null,
      histogramData: { data: [] },
      startDate: moment().add(-14, 'days').startOf('day'),
      endDate: moment(),
      frequency: 'day',
      hour: new Date().getHours(),
      loaded: false,
      selectedDevices: [],
      sampleReportType: 'consumption',
      showImages: false,
      showData: true,
      currentDevice: null,
      historyHour: null,
      end: moment().startOf('date'),
      liveDataActive: false,
      liveDataStart: 0,
      liveDataMsg: null,
      activating: false,
      openTab: 0,
    };
    this._timerId = null
    this._refreshTimerId = null
    this.onChange = this.onChange.bind(this);
  }

  static getStores() {
    return [LoginStore];
  }

  static getPropsFromStores() {
    return {
      ...LoginStore.getState()
    };
  }

  componentDidMount() {
    AnalyzeStore.listen(this.onChange);
    console.log("AnalyzeView componentDidMount", this);
    this.updateAppBarDevices([{ value: 0, label: gettext('ui_noDevices') }])
    let selectedDevs = [];
    let currentDevice = null;
    if (this.props && this.props.match && this.props.match.params.id) {
      if (isMongoId(this.props.match.params.id)) { // Check is mongoid
        let premiseId = this.props.match.params.id
        PremiseActions.queryById(premiseId, { history: 0 })
      } else { // Then it is deviceid
        currentDevice = this.props.match.params.id;
        selectedDevs = [currentDevice];
        AnalyzeActions.mine(currentDevice, this.state.startDate, this.state.endDate, this.state.frequency);
        //AnalyzeActions.mineHistogram(currentDevice);
        this.updateAppBarDevices(selectedDevs, currentDevice);
      }
    }
    this.setState({ currentDevice });

    if (this.props && this.props.params && this.props.params.device) {
      selectedDevs.push(this.props.params.device);
    }
    const frequency = this.props.match.params.frequency || this.state.frequency
    const startDate = moment(this.props.match.params.startDate || this.state.startDate)
    const endDate = moment(this.props.match.params.endDate || this.state.endDate)
    this.setState({ loaded: false, selectedDevices: selectedDevs, frequency, startDate, endDate });
    if (this._refreshTimerId) {
      clearInterval(this._refreshTimerId);
    }
  }

  componentWillUnmount() {
    AnalyzeActions.resetMeasurements();
    AnalyzeStore.unlisten(this.onChange);
    PremiseActions.reset();
    this.resetLiveData();
  }

  componentDidUpdate() {
    if (this.state.liveDataActive) {
      const t = new Date();
      if (t.getHours() !== this.state.hour) {
        this.setState({ hour: t.getHours() });
      }
      if (!this._refreshTimerId) {
        this._refreshTimerId = setInterval(() => {
          this.refreshGraph();
        }, 30000);
      }
    } else {
      if (this._refreshTimerId) {
        clearInterval(this._refreshTimerId);
        this._refreshTimerId = null;
      }
    }
  }

  refreshGraph() {
    if (this.state.liveDataActive) {
      const now = new Date();
      if (now > this.state.liveDataStart + 1800000) {
        this.resetLiveData();
      } else {
        AnalyzeActions.resetMinedData();
        this.reloadGraphData();
      }
    } else {
      this.resetLiveData();
    }
  }

  resetLiveData() {
    if (this._refreshTimerId) {
      clearInterval(this._refreshTimerId);
      this._refreshTimerId = null;
    }
    AnalyzeActions.resetLiveData();
  }

  onChange(st) {
    let pr = st.premise;

    setTimeout(() => {
      let currentDevice = this.state.currentDevice;
      if (pr && pr.devices && pr.devices.length && !currentDevice) {
        let devices = pr.devices.map(d => { return { value: d, label: d } });

        if (this.props && this.props.params && this.props.params.id && this.props.params.device) {
          currentDevice = this.props.params.device;
        } else {
          currentDevice = pr.devices[0]
        }
        AnalyzeActions.mine(currentDevice, this.state.startDate, this.state.endDate, this.state.frequency);
        //AnalyzeActions.mineHistogram(currentDevice);
        this.updateAppBarDevices(devices, devices[0], pr.devicesinfo);
      } else {
        this.setState(st)
      }
      this.setState({ loaded: st.mineData, loading:st.loading, currentDevice });
    })
  }

  _handleTabsChange(event, tabId) {
    this.setState({ openTab: tabId })
    if (tabId === 1) {
      AnalyzeActions.resetMeasurements();
      this.loadMeasurements(_limit, 0, true)
    }
  }

  updateAppBarDevices(devices, selectedDevice, devicesinfo) {
    this.setState({
      dropdown: {
        items: devices,
        devicesinfo,
        selectedIndex: selectedDevice,
        label: gettext('ui_chooseDevice'),
        emptyText: gettext('ui_noDevices')
      }
    });
  }

  updateHistoryEndDate(t, e) {
    const end = moment(e)
    if (this.state.historyHour) {
      end.hour(this.state.historyHour)
    }
    this.setState({ end });
    this.doUpdate()
  }

  onHistoryHourChange(value) {
    if (!value.length) {
      this.setState({ historyHour: null })
    } else if (value < 24 && value > -1) {
      this.setState({ historyHour: parseInt(value, 10) })
    } else {
      this.setState({ historyHour: 0 })
    }
  }

  doUpdate() {
    if (this._timerId) {
      clearTimeout(this._timerId)
    }
    this.setState({ measurements: [] })
    this.loadMeasurements(_limit, 0, true)
  }

  onShowImagesChange(e, toggle) {
    AnalyzeActions.resetMeasurements.defer();
    this.setState({ showImages: toggle });
    this.doUpdate()
  }

  onShowDataChange(e, toggle) {
    AnalyzeActions.resetMeasurements.defer();
    this.setState({ showData: toggle });
    this.doUpdate()
  }

  frequencyChange(e) {
    if (this._refreshTimerId) {
      clearInterval(this._refreshTimerId);
      this._refreshTimerId = null;
    }
    if (e.target.value !== 'samples' && e.target.value !== 'minute') {
      this.resetLiveData();
    }
    AnalyzeActions.resetMinedData();
    this.setState({ frequency: e.target.value });
    this.reloadGraphData();
  }

  updateStartDate(t, e) {
    AnalyzeActions.resetMinedData();
    this.setState({ startDate: moment(e) });
    this.reloadGraphData();
  }

  updateEndDate(t, e) {
    AnalyzeActions.resetMinedData();
    this.setState({ endDate: moment(e) });
    this.reloadGraphData();
  }

  onHourChange(event) {
    AnalyzeActions.resetMinedData();
    this.setState({ hour: event.target.value });
    this.reloadGraphData();
  }

  reloadGraphData() {
    setTimeout(() => {
      var start = null;
      var end = null;

      if (this.state.frequency === 'samples') {
        start = moment(this.state.endDate).hour(this.state.hour).startOf('hour');
        end = moment(start).add(1, 'hour');
      } else if (this.state.frequency === 'minute') {
        start = moment(this.state.endDate).hour(this.state.hour).startOf('hour');
        end = moment(start).add(1, 'hour');
      } else if (this.state.frequency === 'hour') {
        start = moment(this.state.endDate).startOf('day');
        end = moment(start).add(1, 'day');
      } else if (this.state.frequency === 'day') {
        start = moment(this.state.startDate).startOf('day');
        end = moment(this.state.endDate).endOf('day');
      } else if (this.state.frequency === 'week') {
        start = moment(this.state.startDate).startOf('week');
        end = moment(this.state.endDate).endOf('week');
      } else if (this.state.frequency === 'month') {
        start = this.state.startDate;
        end = this.state.endDate;
        if (end.diff(start) < 0) {
          let temp = moment(end.endOf('month'));
          end = moment(start);
          start = moment(temp.startOf('month'));
        }
      }
      for (let i = 0; i < this.state.selectedDevices.length; i++) AnalyzeActions.mine(this.state.selectedDevices[i], start, end, this.state.frequency);
    });
  }

  loadMeasurements(limit, skip, reload) {
    setTimeout(() => {
      let end = this.state.end.clone().endOf('date')

      let historyHour = this.state.historyHour;
      if (historyHour !== null) {
        end.hour(historyHour);
      }

      // Images or data must be on. If both are off thdevicesen mongo scans all docs
      const { showImages, showData, currentDevice } = this.state;
      if (showImages || showData) {
        AnalyzeActions.measurements({
          id: currentDevice,
          limit,
          skip,
          end,
          reload,
          includeImages: showImages,
          includeData: showData
        });
      }
    });
  }

  loadMore(page) {
    this.loadMeasurements(_limit, _limit * page, false)
  }

  onSelectionChange(e) {
    //console.log("onSelectionChange:",e);
    this.setState({ dropdown: { ...this.state.dropdown, selectedIndex: e.target.value }, currentDevice: e.target.value });
    AnalyzeActions.resetMeasurements();
    AnalyzeActions.resetMinedData();
    this.loadMeasurements(_limit, 0, true);
    if (e) {
      AnalyzeActions.mine(e.target.value, this.state.startDate, this.state.endDate, this.state.frequency);
      if(this.state.openTab === 2)
        AnalyzeActions.getPeriodData(e.target.value)
    }
  }

  onDeviceCbChecked(event, checked) {
    var name = event.target.name;
    let data = this.state;
    var f = data.selectedDevices.indexOf(name);
    if (checked && f === -1) {
      data.selectedDevices.push(name);
      data.currentDevice = name;
    } else if (!checked && f !== -1) {
      data.selectedDevices.splice(f, 1);
    }
    this.setState(data);
    this.reloadGraphData();
  }

  sortData(data) {
    if(!data.labels.length) return data;
    let datasort = data.labels.map((d, i) => {
      return {
        label: d,
        data: data.data[i] || 0
      }
    });
    datasort.sort((a, b) => {
      let aDate=moment(a.label,"DD.MM.YYYY");
      let bDate=moment(b.label,"DD.MM.YYYY");
      if (aDate < bDate)
        return -1;
      if (aDate > bDate)
        return 1;
      return 0;
    })
    let labels=[];
    let dada=[];
    datasort.forEach(i=>{
      labels.push(i.label);
      dada.push(i.data);
    })
    data.labels=labels;
    data.data=dada;
  }

  onActivateLiveData() {
    if (this.state.currentDevice) {
      this.setState({ activating: true, liveDataActive: false, liveDataMsg: null });
      AnalyzeActions.activateLiveData(this.state.currentDevice);
    }
  }

  fn = (mineData, aTitle, dateFn, multiply) => {
    let title = "";
    let data = [];
    let labels = [];

    title = aTitle;
    var md = mineData;
    var devs = this.state.selectedDevices;

    if (devs.length && Object.keys(md) && Object.keys(md).length > 1 && Object.keys(md).length === devs.length) {
      let rdy = 0;
      for (let d = 0; d < md[devs[0]].length; d++) {
        for (let e = 0; e < devs.length; e++) {
          if (md[devs[e]] && md[devs[e]][d]) {
            if (md[devs[e]][d].run) {
              devs.splice(0, 0, (devs.splice(e, 1)[0]));
              rdy = 1;
              break;
            }
          }
        }
        if (rdy) break;
      }
    }
    var f = -1;
    for (var di in devs) {
      var dev = devs[di];
      if (md[dev]) {
        for (var i in md[dev]) {
          var item = md[dev][i];
          if (item && item.date && !isNaN(item.run)) {
            var d = dateFn(item.date);
            f = labels.indexOf(d);
            if (f === -1) {
              f = labels.length;
              data[f] = 0;
              labels.push(d);
            }
            if (multiply) {
              data[f] = Utils.round(data[f] + item.run * multiply);
            } else {
              data[f] = Utils.round(data[f] + item.run);
            }
          }
        }
      }
    }
    return { title, data, labels }
  }

  render() {
    console.log("AnalyzeView-render", this);
    let d = {
      title: "",
      data: [],
      labels: [],
    }

    if (this.state.mineData) {
      let mineData = this.state.mineData;
      switch (this.state.frequency) {
        case 'month':
          d = this.fn(mineData, gettext('Consumption/month'), (d) => moment.months()[moment(d).month()]);
          break;
        case 'week':
          d = this.fn(mineData, gettext('Consumption/week'), (d) => moment(d).isoWeek());
          break;
        case 'day':
          for (var key in mineData) {
            if (mineData.hasOwnProperty(key)) {
              for (var index = 0; index < mineData[key].length; index++) {
                if (mineData[key][index] && mineData[key][index].run && mineData[key][index].run < 0) {
                  console.log('negative hour value removed', mineData[key][index])
                  mineData[key][index].run = 0;
                }
              }
            }
          }
          d = this.fn(mineData, gettext('Consumption/day'), (d) => moment(d).format('D.M.YYYY'));
          break;
        case 'hour':
          for (var key in mineData) {
            if (mineData.hasOwnProperty(key)) {
              for (var index = 0; index < mineData[key].length; index++) {
                if (mineData[key][index] && mineData[key][index].run && mineData[key][index].run < 0) {
                  console.log('negative hour value removed', mineData[key][index])
                  mineData[key][index].run = 0;
                }
              }
            }
          }
          d = this.fn(mineData, gettext("Daily consumption"), (d) => moment(d).format('HH:00'), 1000);
          break;
        case 'minute':
          for (var key in mineData) {
            if (mineData.hasOwnProperty(key)) {
              for (var index = 0; index < mineData[key].length; index++) {
                if (mineData[key][index] && mineData[key][index].run && mineData[key][index].run < 0) {
                  console.log('negative minute value removed', mineData[key][index])
                  mineData[key][index].run = 0;
                }
              }
            }
          }
          d = this.fn(mineData, gettext("Hourly consumption"), (d) => moment(d).format('HH:mm'), 1000);
          break;
        case 'samples':
          const addItem = (item) => {
            d.data.push(item);
          }
          let items = [];
          const newItem = (item) => {
            items.push(item);
          }
          const sums = {};
          for (let di in this.state.selectedDevices) {
            const prop = this.state.selectedDevices[di];
            sums[prop] = 0;
            if (this.state.mineData && this.state.mineData[prop] && this.state.mineData[prop][0] && this.state.mineData[prop][0].data) {
              let t = this.state.mineData[prop][0].data;
              newItem({ timestamp: this.state.mineData[prop][0].startTime, value: this.state.mineData[prop][0].startValue, device: prop });
              for (let i = 0; i < t.length; i++) {
                newItem({ timestamp: t[i].timestamp, value: t[i].value, device: prop });
              }
              const now = moment();
              if (now.isAfter(moment(this.state.mineData[prop][0].endTime))) {
                newItem({ timestamp: this.state.mineData[prop][0].endTime, value: this.state.mineData[prop][0].endValue, device: prop });
              }
            }
          }
          items = items.sort((a, b) => {
            if (a.timestamp < b.timestamp) return -1;
            if (a.timestamp > b.timestamp) return 1;
            return 0;
          });
          const joinedItems = [];
          let idx = 0;
          while (idx < items.length) {
            sums[items[idx].device] = items[idx].value;
            while (idx < items.length - 1 && items[idx + 1].timestamp === items[idx].timestamp) {
              idx++;
              sums[items[idx].device] = items[idx].value;
            }
            let sum = 0;
            for (let p in sums) {
              sum += sums[p];
            }
            joinedItems.push({ timestamp: items[idx].timestamp, value: sum });
            idx++;
          }
          for (let i = 0; i < joinedItems.length; i++) {
            if (i === 0) {
              addItem({ y: 0, x: (new Date(joinedItems[i].timestamp)).valueOf(), timestamp: joinedItems[i].timestamp, value: joinedItems[i].value });
            } else {
              addItem({ y: joinedItems[i].value - joinedItems[i - 1].value, x: (new Date(joinedItems[i].timestamp)).valueOf(), timestamp: joinedItems[i].timestamp, value: joinedItems[i].value });
            }
          }
          break;
        default:
          break;
      }
    }


    let chartData = {};
    let data = this.sortData(d);
    if (this.state.frequency === 'samples') {
      let t = moment(this.state.endDate);
      t.hour(this.state.hour);
      chartData.data = d.data;
      chartData.start = t;
      chartData.end = moment(t).add(1, 'hour');
      chartData.type = this.state.sampleReportType;
    } else {
      chartData = {
        labels: d.labels,
        datasets: [{
          data: d.data,
          label: this.state.frequency === 'hour' || this.state.frequency === 'minute' ? gettext("Consumption") + " l" : gettext("Consumption") + " m³",
          backgroundColor: "rgba(31,59,87,0.9)",//"rgba(33,150,243,0.5)",
          borderColor: "#0D429B",
          pointColor: "rgba(151,187,205,1)",
          borderWidth: 2,
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(151,187,205,1)"
        }]
      };
    }

    let options = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let unit = data.datasets[tooltipItem.datasetIndex].label.slice(8,10)
            let label = data.datasets[tooltipItem.datasetIndex].label.slice(0,7)
            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return  ' ' + label + ': ' + value + ' ' + unit;
          }
        }
      },
      animation: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
    let premise = this.state.premise || {};

    let CheckBoxes = () => {
      if (this.state.loaded) {
        var devs = this.state.dropdown.items;
        let devicesinfo = this.state.dropdown.devicesinfo || {}
        var t = this;
        var retval = devs.map(function (d, i) {
          let unreliable = devicesinfo && devicesinfo[d.value] && devicesinfo[d.value].unreliable ? true : false;
          let label = unreliable ? d.label + " (" + gettext('unreliable reading') + ")" : d.label
          return <mui.Checkbox key={i} defaultChecked={t.state.loaded && t.state.selectedDevices.indexOf(d.value) !== -1} name={d.value} label={label} onCheck={t.onDeviceCbChecked.bind(t)} />
        });
        return <div>{retval}</div>
      }
      return <div>{gettext('ui_loading')}</div>;
    }
    console.log("chartData:", chartData);

    let graph = !this.state.loading ? (this.state.frequency === 'samples' ? <SampleChart data={chartData} /> : <Bar data={chartData} options={options} redraw={true} />) : <ViewLoader blue />;

    //let histoGraph = ''

    let frequencyGroup = <RadioButtonGroup name="frequency" defaultSelected={this.state.frequency} onChange={this.frequencyChange.bind(this)}>
      <RadioButton value="month" label={gettext("Kuukausi")} />
      <RadioButton value="week" label={gettext("Viikko")} />
      <RadioButton value="day" label={gettext("Päivä")} />
      <RadioButton value="hour" label={gettext("Tunti")} />
      <RadioButton value="minute" label={gettext("Minuutti")} />
      <RadioButton value="samples" label={gettext("Näytteenottotaajuus")} />
    </RadioButtonGroup>

    const { measurements, currentDevice, showData, showImages } = this.state;

    let appBarTitle = "";
    if (this.state.loaded) {
      appBarTitle = premise.name ? premise.name : this.state.selectedDevices[0];
    } else {
      appBarTitle = gettext('ui_loading');
    }

    let liveDataMessage = "";
    if (this.state.liveDataMsg) {
      switch (this.state.liveDataMsg) {
        case 'ok':
          liveDataMessage = <div><p>{gettext('ui_live_data_active')}</p><p>{gettext('ui_live_data_updated')} {moment().format('HH:mm:ss')}</p></div>
          break;
        case 'timeout':
          liveDataMessage = <p style={{ color: '#EB3E0F' }}>{gettext('ui_live_data_timeout')}</p>
          break;
        case 'unsupported':
          liveDataMessage = <p style={{ color: '#EB3E0F' }}>{gettext('ui_live_data_unsupported')}</p>
          break;
        default:
          liveDataMessage = <p style={{ color: '#EB3E0F' }}>{gettext('ui_live_data_unknown_error')}</p>
          break;
      }
    }
    const activateDisabled = this.state.activating || !this.state.currentDevice;
    console.log("Render", chartData);
    const { openTab } = this.state;
    return (
      <div>
        <AppBar
          title={gettext('title_analyze') + ' - ' + appBarTitle}
          dropdown={this.state.openTab !== 0 && this.state.dropdown}
          onDropdownChange={this.onSelectionChange.bind(this)}
          backBtn
        />
        <div className="container">
          <div className="row top-xs">

            <div className="col-xs-12 tabContainer" >
              <Tabs value={openTab} onChange={this._handleTabsChange.bind(this)} centered variant="fullWidth">
                <Tab label={gettext('ui_analyze')} />
                <Tab label={gettext('ui_history')} />
                <Tab label={gettext('apidata')} />
              </Tabs>
            </div>
            {openTab === 0 && <TabContainer>
              <h4>{d.title}</h4>
              {graph}
              <div className="row">
                {premise._id && <div className="col-md-3 col-xs-6">
                  {gettext("ui_devices")}
                  <CheckBoxes />
                </div>}
                {this.state.loaded &&
                  <div className="col-md-4 col-xs-6">
                    {gettext("Frequency")}
                    {frequencyGroup}
                  </div>
                }
                {this.state.loaded &&
                  <div className="col-md-5 col-xs-6 ">
                    {gettext('ui_getwithinDate')}
                    <div>
                      <div className="row top-xs">
                        <div className="col-xs-12">
                          {this.state.frequency === 'month' &&
                            <div>
                              <MonthPicker onChange={this.updateStartDate.bind(this)} date={this.state.startDate} /><span> - </span>
                              <MonthPicker onChange={this.updateEndDate.bind(this)} date={this.state.endDate} />
                            </div>}
                          {this.state.frequency !== 'month' && this.state.frequency !== 'hour' && this.state.frequency !== 'minute' && this.state.frequency !== 'samples' &&
                            <span><DatePicker
                              firstDayOfWeek={1}
                              style={{ display: 'inline-block' }}
                              textFieldStyle={{ width: '80px' }}
                              hintText="Alku"
                              formatDate={function (d) {
                                return moment(d).format('D.M.YYYY');
                              }}
                              defaultDate={this.state.startDate.toDate()}
                              onChange={this.updateStartDate.bind(this)} />
                              <div style={{ display: 'inline-block', marginLeft: '8px', marginRight: '8px' }}>-</div></span>
                          }
                          {this.state.frequency !== 'month' &&
                            <DatePicker
                              firstDayOfWeek={1}
                              style={{ display: 'inline-block' }}
                              textFieldStyle={{ width: '80px' }}
                              hintText="Loppu"
                              formatDate={function (d) {
                                return moment(d).format('D.M.YYYY');
                              }}
                              defaultDate={this.state.endDate.toDate()}
                              onChange={this.updateEndDate.bind(this)} />
                          }

                          {(this.state.frequency === 'minute' || this.state.frequency === 'samples') &&
                            <select className="form-control" style={{ marginLeft: 10, width: 100, display: "inline-block" }} value={this.state.hour} onChange={this.onHourChange.bind(this)} disabled={this.state.liveDataActive}>
                              <option value="0">00:00</option>
                              <option value="1">01:00</option>
                              <option value="2">02:00</option>
                              <option value="3">03:00</option>
                              <option value="4">04:00</option>
                              <option value="5">05:00</option>
                              <option value="6">06:00</option>
                              <option value="7">07:00</option>
                              <option value="8">08:00</option>
                              <option value="9">09:00</option>
                              <option value="10">10:00</option>
                              <option value="11">11:00</option>
                              <option value="12">12:00</option>
                              <option value="13">13:00</option>
                              <option value="14">14:00</option>
                              <option value="15">15:00</option>
                              <option value="16">16:00</option>
                              <option value="17">17:00</option>
                              <option value="18">18:00</option>
                              <option value="19">19:00</option>
                              <option value="20">20:00</option>
                              <option value="21">21:00</option>
                              <option value="22">22:00</option>
                              <option value="23">23:00</option>
                            </select>
                          }
                        </div>
                      </div>
                      <div className="row top-xs">
                        <div className="col-xs-12">
                          {(this.state.frequency === 'minute' || this.state.frequency === 'samples') &&
                            <div>
                              <RaisedButton
                                disabled={activateDisabled}
                                label={this.state.activating ? gettext('ui_live_data_activating') : gettext('ui_activate_live_data')}
                                onClick={() => { this.onActivateLiveData() }}
                              />
                            </div>
                          }
                        </div>
                        <div className="col-xs-12">
                          {liveDataMessage}
                          {false && this.state.frequency === 'samples' &&
                            <select className="form-control" style={{ marginLeft: 10, width: 100, display: "inline-block" }} value={this.state.sampleReportType} onChange={(e) => this.setState({ sampleReportType: e.target.value })}>
                              <option value="consumption">Kulutus</option>
                              <option value="used">Kulunut</option>
                            </select>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </TabContainer>}
            {openTab === 1 && <TabContainer>
              <DeviceHistoryTable
                loadMore={this.loadMore.bind(this)}
                hasMore={this.state.hasMore}
                history={measurements}
                deviceId={currentDevice}
                updateEndDate={this.updateHistoryEndDate.bind(this)}
                endDate={this.state.end}
                showImages={showImages}
                onShowImagesChange={this.onShowImagesChange.bind(this)}
                showData={showData}
                onShowDataChange={this.onShowDataChange.bind(this)}
                hour={this.state.historyHour}
                onHourChange={this.onHistoryHourChange.bind(this)}
                onUpdate={() => this.doUpdate()}
              />
            </TabContainer>}
            {openTab === 2 && <TabContainer>
              <PeriodDataTable
                deviceId={currentDevice}
                role={this.props.role}
              />
            </TabContainer>}
          </div>
        </div>
      </div>
    );
  }
}