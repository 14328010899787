import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
import moment from 'moment'

let DA;
class DeviceActions {
  constructor() {
    this.generateActions('reset');
  }

  getDevices(states = "returned") {
    return async (dispatch) => {
      dispatch({ isLoading: true });
      let b = new UrlBuilder();
      let q =
      {
        query: { "events.0.state": states },
        projection: { deviceId: 1, events: 1, name: 1, installed: 1 },
        sort: { "events.0.updated": -1 }
      };
      try {
        let res = await api.post(b.path('device/find'), q);
        dispatch({ devices: res.data, isLoading: false });
      } catch (err) {
        console.error(err.stack);
        dispatch({ err, isLoading: false });
      }

      /*api.post(b.path('device/find'), q)
         .catch(err => {
           console.error(err.stack);
           dispatch({ err, isLoading: false });
         })
         .then(res => {
           dispatch({ devices: res.data, isLoading: false });
         })*/
    }
  }



  getAllEndDatePremises() {
    return async (dispatch) => {
      try {
        let qu = {
          projection: { endDate: 1, devices: 1, name: 1, 'interestGroups.type': 1, 'interestGroups.name': 1, 'interestGroups.email': 1 },
          query: { endDate: { "$lt": { $date: new Date() } }, "devices.0": { $exists: true } }
        };
        let b = new UrlBuilder();
        dispatch({ isLoading: true });
        let pre = await api.post(b.path('premises/find'), qu)
        let ids = []
        let p = pre.data || []
        for (let index = 0; index < p.length; index++) {
          var e = p[index].devices;
          ids = ids.concat(e);
        }

        let bb = new UrlBuilder();
        let q =
        {
          query: { "events.0.state": { $in: ['active', 'delivered'] }, deviceId: { $in: ids } },
          projection: { deviceId: 1, events: 1, name: 1, installed: 1 },
          sort: { "events.0.updated": -1 }
        };

        let dev = await api.post(bb.path('device/find'), q)
        dispatch({ premises: p, devices: dev.data })
      } catch (error) {
        console.log(error.stack || error)
        dispatch({ res: [], error });
      }
    }
  }

  getDeviceEvents(deviceId) {
    return async (dispatch) => {
      let b = new UrlBuilder();
      let q = { "data.deviceId": deviceId, action: { $ne: "hour_data_export" } };
      let q2 = { "data.replacedDeviceId": deviceId, action: { $ne: "hour_data_export" } };
      let q3 = { $or: [{ "data.deviceId": deviceId }, { "data.replacedDeviceId": deviceId }], action: { $ne: "hour_data_export" } };
      try {
        let res = await api.get(b.path('events/?query=' + encodeURI(JSON.stringify(q3))));
        dispatch({ deviceId, res })
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ deviceId, res: [], err });
      }
      //{ $elemMatch: { state:'delivered', timestamp:{$gt:date.toDate()}}},
      /*api.get(b.path('events/?query=' + encodeURI(JSON.stringify(q3))))
        .then((res) => {
          dispatch({ deviceId, res })
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch({ deviceId, res: [], err });
        })*/
    }
  }

  getAllPremiseNames() {
    return async (dispatch) => {
      let q = { "projection": { "name": 1 } };
      let b = new UrlBuilder();
      try {
        let res = await api.post(b.path('premises/find'), q);
        dispatch({ premises: res.data });
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ res: [], err });
      }

      /*api.post(b.path('premises/find'), q)
        .then((res) => {
          dispatch({ premises: res.data })
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: [], err });
        })*/
    }
  }
}

DA = (alt.createActions(DeviceActions));
export default DA;
