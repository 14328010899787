import alt from "../utils/alt";
import PremiseActions from "../actions/PremiseActions";
//import ReportActions from "../actions/ReportActions";
import _ from 'lodash';
import history from '../utils/history'

class PremisesStore {
  constructor() {
    this.bindActions(PremiseActions);

    this.state = {
      error: "",
      data: null,
      log:[],
      deliveryStatus: [],
      premise: null,
      events: null,
      totalSavings: null,
      mongoQuery: {
        skip: 0,
        limit: 50,
        sort: { name: 1 }
      },
      device: {},
      loading: false,
      savingError:"",
      saving:0,
      newHighflowLimits: false,
      newWeekEndHighflowLimits: false,
      countries:[
        "FIN",
      ],
      unvalidPremises:[]
    };

    try {
      var json = JSON.parse(sessionStorage.getItem('premiseQuery'));
      if (json) Object.assign(this.state.mongoQuery, json);
    } catch (e) { }

    delete this.state.mongoQuery.start
    delete this.state.mongoQuery.end
  }

  onReset() {
    this.setState({ data: null, premise: null, error: null, earliestData: null });
  }

  onQueryById(data) {
    if (data.err) {
      this.setState({ error: data.err.data });
      return
    }
    this.setState({ premise: data });
  }

  queryPremiseLinks(data) {
    this.setState({ linkedPremises: data.linkedPremises });
  }

  queryDeviceWithLinks(data) {
    if (data.err) {
      this.setState({ error: data.err.data });
      return
    }
    console.log(data);
    this.setState({ premise: data.premise, linkedPremises: data.linkedPremises });
  }

  onQuery(payload) {
    if (payload.err) {
      this.setState({ error: payload.err.data });
      return
    }
    let data = payload.data;
    let totalSavings = payload.totalSavings;
    let state = { data, totalSavings }
    let mongoQuery = payload.mongoQuery;
    if (mongoQuery && mongoQuery.limit) {
      state.mongoQuery = mongoQuery
      sessionStorage.setItem('premiseQuery', JSON.stringify(mongoQuery));
    }
    //console.log('PremisesStore onQuery', data);
    this.setState(state)
  }

  onBeginEdit(heatingTypes) {
    this.setState({
      saved: false,
      heatingTypes
    });
  }

  onEdit(payload) {
    //console.log('PremisesStore onEdit', payload);
    if (payload.err) {
      this.setState({ error: payload.err.data });
      return
    }

    setTimeout(() => {
      history.goBack()
    })
  }

  onUnLinkDevice(payload) {
    if (payload && !payload.err && payload.deviceId) {
      let premise = this.state.premise;
      _.pull(premise.devices, payload.deviceId);
      this.setState({ premise });
    }
  }

  onGetSavings(data) {
    let premises = this.state.data || [];
    let item = data;
    let p = _.find(premises, { _id: item._id })
    if (p) {
      delete p.loading
      delete p.reportGenerating
      if (item) {
        p.change = item.change
        p.changePercent = item.changePercent
        p.changeEuro = item.changeEuro
        p.changeEuro = item.changeEuro
        p.reportExists = item.reportExists
      }
    }
  }

  onMoveDevice(premise) {
    if (premise.err) {
      this.setState({ error: premise.err.data });
    }
  }

  onRemoveDevice(res) {
    if (res.err) {
      this.setState({ error: res.err.data });
    }
  }

  onDeactivateDevice(res) {
    if (res.err) {
      this.setState({ error: res.err.data });
    }
  }

  onGetEarliestValue(data) {
    this.setState({ earliestData: data && data.earliestData })
  }

  onSetMeterValue(res) {
    if (res.err) console.log(res.err)
  }

  onSetPremise(data) {
    if (!this.state.premise) this.state.premise = {}
    let premise = Object.assign(this.state.premise, data);
    this.setState({ premise });
  }

  onGetEvents(data) {
    this.setState({ events: data });
  }

  onGetEvents2(data) {
    this.setState({ events: data.events, count: data.count, loading: false });
  }

  onLoading() {
    this.setState({ loading: true });
  }

  onResetEvents() {
    this.setState({ events: null });
  }

  onGetDevice(data) {
    this.setState({ device: data });
  }

  onHighflowRecalculation(data) {
    this.setState(data);
  }

  onWeekendHighflowRecalculation(data) {
    this.setState(data);
  }

  onResetAlarmLimits() {
    this.setState({ newHighflowLimits: false, newWeekEndHighflowLimits: false, device: {} });
  }
  onGetLatestImages(latestImages) {
    this.setState({ latestImages });
  }

  onAddnewSmartDevice(res){
    if (res.err) {
      this.setState({ error: res.err.data });
    } else {
      this.setState({ loading:false });
    }
  }

  onGetDeliveryStatus(res){
    if (res.err) {
      this.setState({ deliveryStatus:[], loading:false, error: res.err.data });
    } else {
      res.data.forEach(e => {
        e.updated = new Date().toISOString()
      });
      this.setState({ deliveryStatus:res.data, loading:false });
    }
  }

  onAddInfoRow(res){
    
    if(res.err){
      console.log("onAddInfoRow Store error", res.err.message)
      this.setState({  savingError: "Save Failed", saving:0});
    } else {
      console.log("onAddInfoRow Store ok", res.err)
      this.setState({  savingError: null, saving:0 });
    }
  }

  onSavingInfo(){
    this.setState({ saving:1 });
  }
  onGetDistinctCountries(data){
    this.setState(data);
  }

  onGetChangesLog(payload){
    if (payload.err) {
      this.setState({ error: payload.err.data });
      return
    }
    this.setState({log:payload.data})
  }

  onGetPremisesMissingData(payload){
    if (payload.err) {
      this.setState({ error: payload.err.data });
      return
    }
    console.log("PPPP", payload)
    this.setState({unvalidPremises:payload.data})
  }

}

//module.exports = (alt.createStore(PremisesStore));
export default alt.createStore(PremisesStore);
