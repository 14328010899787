import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async'
import {gettext} from 'i18n';
import DefaultIcon from 'material-ui/svg-icons/navigation/arrow-drop-down-circle';

function isValid(value) {
  return value || value === 0;
}

export default class AsyncSelectBox extends React.Component {
  static contextTypes = {
    router: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    id: PropTypes.string,
    onSelectionChange: PropTypes.func,
    placeholder: PropTypes.string,
    ref: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.object,
    required: PropTypes.bool,
    name: PropTypes.string,
  }

  componentWillMount() {
  }

  getRef() {
    return this.props.ref ? this.props.ref : 'input';
  }

  getInputNode() {
    // TODO ReactDOM is not defined! Is this dead code, since it works?
    return (this.props.children) ?
      this.refs[this._getRef()].getInputNode() : ReactDOM.findDOMNode(this.refs[this._getRef()]);
  }

  render() {
    let label = null;
    if (this.props.label || this.state.errorText) {
      label = (
        <div className="InputLabel">
          {this.props.label + (this.props.required ? '*' : '')}
          {this.state.errorText && <span className="InputError">{this.state.errorText}</span>}
        </div>
      );
    }

    let placeholder = this.props.placeholder ? this.props.placeholder : gettext('ui_valitse');
    const iconStyle= {
      height: 16,
      width: 16,
      color: 'white',
    }
    return (
      <div className="InputElem">
       {label}
       <div className="Inputaddon">
          <span className="Inputaddonitem"><DefaultIcon style={iconStyle} /></span>
           <AsyncSelect className="Select"
              placeholder={placeholder}
              {...this.props}
            />
       </div>
      </div>
    );

  }

  isMandatory() {
    return this.props.required ? true : false;
  }

  getName() {
    if (this.props.name) {
      return this.props.name
    } else if (this.props.id) {
      return this.props.id
    }
    return null;
  }

  setErrorText(newErrorText) {
    this.setState({errorText: newErrorText});
  }

}
