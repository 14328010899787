import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
import TextInput from './TextInput';
import DateInput from './DateInput';
import moment from "moment";

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
//import CircularProgress from '@material-ui/core/CircularProgress';

class AddMeterValueDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.dialogData = null;
    this.state = {
      //muiTheme: muiTheme,
      value: null,
      date: null,
      confirmation: false,
      dialogOpen: false,
    };
  }

  getChildContext() {
    return { /*muiTheme: this.state.muiTheme*/ };
  }

  componentWillReceiveProps(props) {
    let date = props && props.earliestData && props.earliestData.timestamp || moment().startOf('month');
    this.setState({ date })
  }

  open(data) {
    this.dialogData = data;
    this.setState({ value: null, confirmation: false, dialogOpen: true });
    //this.refs.dialog.show();
  }

  close() {
    this.setState({ value: null, dialogOpen: false });
    //this.refs.dialog.dismiss();
  }

  onChange(value) {
    this.setState({ value: parseFloat(value) })
  }

  onDateChange(name, date) {
    this.setState({ date })
  }

  onOk() {
    this.setState({ confirmation: true })
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  render() {
    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    let title = this.props.title ? this.props.title : gettext('ui_confirm_title');
    let acceptButton = this.props.addMeterValue ? this.props.addMeterValue : gettext('ui_confirm_button');
    let confirmationTitle = '';
    if (this.state.confirmation) {
      confirmationTitle = "Arvo: " + this.state.value + ", lisätään laitteeseen " + this.dialogData + ", kuukaudelle: " + moment(this.state.date).format("MM/YYYY");
    }
    return (
      <Dialog open={this.state.dialogOpen} maxWidth='md' fullWidth={true} >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>

        {!this.state.confirmation &&
          <React.Fragment>
            <DialogContent>
              {this.props.text}

              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-4 col-md-4">
                  <DateInput required={true} type="month" id="date" label={gettext('ui_month')} value={this.state.date} onChange={this.onDateChange.bind(this)} />
                </div>
                <div className=" col-xs-8 col-md-8">
                  <TextInput ref="meterValue" required={true} type="number" id="meterValue" label={gettext('ui_meter_value')} onChange={this.onChange.bind(this)} />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
              <Button color='primary' disabled={!this.isNumeric(this.state.value)} onClick={() => (this.onOk())} >{gettext('ui_ok')}</Button>
            </DialogActions>
          </React.Fragment>}
        {this.state.confirmation &&
          <React.Fragment>
            <DialogContent>
              <span style={{  margin: '5px', fontSize: '20px' }}>{confirmationTitle}</span>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
              <Button color='primary' onClick={() => (this.props.onAccepted(this.state.value, this.state.date))} >{acceptButton}</Button>
            </DialogActions>
          </React.Fragment>
        }
      </Dialog >
    );

  }
}

export default AddMeterValueDialog;