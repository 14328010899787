import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
//import connectToStores from 'alt-utils/lib/connectToStores';

import { gettext } from 'i18n';
import _ from 'lodash';
//import { Checkbox } from 'material-ui';
import TextInput from './TextInput';
import utils from '../utils/utils';
import SelectBox from './SelectBox';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import AlertIcon from 'material-ui/svg-icons/alert/warning';

export default class SendReportDialog extends React.Component {

  static contextTypes = {
    //muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme: muiTheme,
      emails: "",
      checkboxes: [],
      selectedLanguage: props.report && props.report.pdf ? Object.keys(props.report.pdf)[0] : 'fi-fi',
      dialogOpen: false,
    };
  }

  static contextTypes = {
    //muiTheme: PropTypes.object
  }
  static childContextTypes = {
    //muiTheme: PropTypes.object
  }
  getChildContext() {
    //return {muiTheme: this.state.muiTheme};
  }

  componentDidMount() {
    let boxes=this.props.interestGroups.map(function (x, i) {
      return(
         x.email?true:false 
      )
    });
    this.setState({checkboxes:boxes});
  }

  open() {
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
  }

  close() {
    this.setState({ checkboxes: [], sending: false, dialogOpen: false })
    //this.refs.dialog.dismiss();
  }

  onSendReport() {
    this.setState({ sending: true })
    let receivers = [];

    if (this.props.interestGroups) {
      this.props.interestGroups.forEach((sh, i) => {
        if (this.state.checkboxes[i] !== false) {
          receivers.push({ email: sh.email, type: 'to', language: sh.language })
        }
      })
    }
    const emails = this.state.emails;
    if (emails && emails.length) {
      let mails = emails.split(',');
      mails.map((e) => {
        if (e.trim().length > 3) {
          receivers.push({ email: e.trim(), type: 'to', language: this.state.selectedLanguage.value });
        }
      });
    }

    if (this.props.onSendReport && receivers.length > 0) {
      this.props.onSendReport(receivers);
    }
  }

  onSelectionChange(value, id, selectBox) {
    this.setState({ selectedLanguage: value })
  }

  onChangeEmail(value, id, textField) {
    let mails = value.split(',');
    let error = '';
    mails.map((e) => {
      if (!utils.validateEmail(e.trim())) {
        error = error + ' ' + e.trim();
      }
    });
    if (error.length > 3) {
      textField.setErrorText(gettext('ui_check_email') + error);
    } else {
      textField.setErrorText(null);
    }
    this.setState({ emails: value });
  }


  render() {
    let dialogStyle = {
      //backgroundColor: this.state.muiTheme.rawTheme.palette.accent2Color,
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '2px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px',
    }
    let sendHistory = this.props.sendHistory ? this.props.sendHistory : [];

    let selectablelanguages = [];
    if (this.props.report && this.props.report.pdf) {
      selectablelanguages = Object.keys(this.props.report.pdf).map((key) => {
        return ({ value: key, label: key })
      });

    }

    let self = this;

    const sending = this.state.sending && !this.props.error

    return (

      <Dialog 
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        {this.props.error && <Snackbar autoHideDuration={6000} open={this.props.error} ref="ErrorBar" message={<span className="error"><AlertIcon style={{ color: 'red', paddingRight:20 }} />{this.props.error}</span>}/>}
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color }}>{gettext('ui_send_pdf').toUpperCase()}</span>
        </DialogTitle>
        <DialogContent>

          <div style={dialogStyle}>
            <div className="row"><div className="col-md-12 col-xs-12">
              <List>
                {
                  self.props.interestGroups.map(function (x, i) {
                    let isChecked = false;
                    if (x.email) {
                      isChecked = self.state.checkboxes[i] !== undefined ? self.state.checkboxes[i] : true
                    }
                    return (<ListItem key={i} >
                        <Checkbox checked={isChecked} onChange={(e, checked) => {
                          console.log("onChange",i);
                          self.state.checkboxes[i] = checked;
                          self.setState({ checkboxes: self.state.checkboxes })
                        }} 
                          color='primary'/>
                        <ListItemText primary={x.name + ' (' + x.language + ')'} secondary={x.email || <span style={{ color: "#DC4E41" }}>{gettext('Email is missing')}</span>}/>
                    </ListItem>
                    )
                  })
                }
              </List>

            </div>
            </div>


            <div className="row">
              <div className="col-md-8">
                <TextInput iconName="mdi-email" label={gettext('ui_email_comma_delimitted')} value={this.state.emails} id="emails" onChange={this.onChangeEmail.bind(this)} />
              </div>
              <div className="col-md-4">
                <SelectBox value={this.state.selectedLanguage}
                  placeholder={gettext('ui_choose_language')}
                  name="selectedLanguage"
                  label={gettext('ui_choose_language')}
                  options={selectablelanguages}
                  onSelectionChange={this.onSelectionChange.bind(this)}
                  clearable={false} />
              </div>
            </div>
          </div>

          <div style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '15px', paddingTop: '10px', marginBottom: '1px' }}>
            {gettext('ui_pdf_send_history').toUpperCase()}
          </div>

          <div style={dialogStyle}>
            <div className="row send-history">
              <div className="col-md-2 header">{gettext('ui_pvm')}</div>
              <div className="col-md-2 header">{gettext('ui_action_type')}</div>
              <div className="col-md-3 header">{gettext('ui_made_by')}</div>
              <div className="col-md-4 header">{gettext('ui_receivers')}</div>
              <div className="col-md-1 header"></div>
            </div>
            {
              sendHistory && sendHistory.map(function (x, i) {
                let res = '';
                let language = '-'
                if (x.data.err) return;
                if (x.action === 'send_report' && x.data && x.data.receivers) {
                  res = x.data.receivers.toString().replace(/,/g, " ");
                } else if (x.action === 'send_report_v2' && x.data && x.data.receivers) {
                  language = x.data.receivers[0] && x.data.receivers[0].language ? x.data.receivers[0].language : 'fi-fi'
                  _.forEach(x.data.receivers, (receiver) => {
                    res += receiver.email;
                  });
                }

                return (
                  <div key={i} className="row middle-xs send-history" style={{ marginTop: '5px', marginBottom: '5px', borderBottom: '1px solid #ccc' }}>
                    <div className="col-md-2 data">{utils.getDateString(x.timestamp)}</div>
                    <div className="col-md-2 data">{gettext(x.action)}</div>
                    <div className="col-md-3 data">{x.source}</div>
                    <div className="col-md-4 data">{res}</div>
                    <div className="col-md-1 data">{gettext(language)}</div>
                  </div>
                )
              })
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={sending}
            variant='outlined'
            onClick={() => (this.props.onCancel())} >{gettext('ui_close')}</Button>
          <Button
            disabled={sending}
            color='primary'
            variant='outlined'
            onClick={() => (this.onSendReport())} >{sending ? gettext('Sending...') : gettext('ui_send_pdf')}</Button>
        </DialogActions>
      </Dialog>
    );

  }
}
