import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
let DBA
class DebugReportActions {
  constructor() {
    this.generateActions('ObjectsQuery', 'change');
  }

  query(q) {
    // console.log('will dailydata query', q);
    return async (dispatch) => {
      dispatch({ data: [], loading:true });
      var b = new UrlBuilder()
      /*
      ### example usage ###
      b.path('data')
        .id(opts.id)
        .start(opts.start && opts.start.toISOString())
        .end(opts.end && opts.end.toISOString())
        .query(q)
        .limit(opts.limit)
        .skip(opts.skip)
        .sort({timestamp: -1})
       */
      try {
        let res = await api.post(b.path('dailydata2/get'), q);
        dispatch(res);
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
      /*api.post(b.path('dailydata2/get'), q)
        .then(res => {
          dispatch(res);
        }).catch(err => {
          console.error(err.stack);
          dispatch(null);
        })*/
    }
  }
}

DBA = (alt.createActions(DebugReportActions));
export default DBA;
