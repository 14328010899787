import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import * as Colors from 'material-ui/styles/colors';
//const Colors=mui.muiTheme.Colors;
// import Select from 'react-select'
import moment from 'moment';
import { gettext } from 'i18n';
//import { PropTypes} from 'react-proptypes';
import connectToStores from 'alt-utils/lib/connectToStores';
import LoginActions from '../actions/LoginActions';
import LoginStore from '../stores/LoginStore';
//import { Divider, Subheader, MenuItem, ListItem, ListItemText } from 'material-ui';
import LanguageSelect from './languageSelectionDialog';
import PremisesIcon from 'material-ui/svg-icons/communication/business';
import NewPremiseIcon from 'material-ui/svg-icons/av/playlist-add';
import AlertIcon from 'material-ui/svg-icons/alert/warning';
import WaterCompaniesIcon from 'material-ui/svg-icons/action/opacity';
import HeatingCompaniesIcon from 'material-ui/svg-icons/social/whatshot';
import UsersIcon from 'material-ui/svg-icons/social/group';
import ApiKeysIcon from 'material-ui/svg-icons/communication/vpn-key';
import ListIcon from 'material-ui/svg-icons/editor/format-list-bulleted';
import ReturnedDevicesIcon from 'material-ui/svg-icons/content/undo';
import UnReturnedDevicesIcon from 'material-ui/svg-icons/content/redo';
import LocalShipping from 'material-ui/svg-icons/maps/local-shipping';
import TrackChanges from '@material-ui/icons/TrackChangesSharp';
import SelectLanguageIcon from 'material-ui/svg-icons/action/language';
import LogoutIcon from 'material-ui/svg-icons/action/exit-to-app';
import MyRawTheme from './Theme';
import history from '../utils/history';
//new Material UI
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DotsIcon from '@material-ui/icons/MoreVert';

import { list } from 'postcss';


const styles = {
  backIcon: {
    color: '#ffffff'
  }
}

@connectToStores
class Appbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
    }

  }
  static contextTypes = {
  }

  static getStores() {
    return [LoginStore];
  }

  static getPropsFromStores() {
    /*return {
      ...PremisesStore.getState(),
      ...LoginStore.getState()
    };*/
    return LoginStore.getState();
  }

  openMenu() {
    this.refs.menu.open();
  }

  goBack() {
    if (typeof this.props.backBtn === 'string') {
      history.pushState(null, this.props.backBtn);
    } else {
      history.goBack();
    }
  }

  render() {
    const { classes } = this.props;
    let rightMenu = null;
    //console.log("AppBar render:",this.props);
    if (this.props.actionBtn) {
      rightMenu = <ActionButton label={this.props.actionBtn} onClick={this.props.onActionBtnClick} />;
    } else if (this.props.dropdown) {
      rightMenu = <DropDownMenu {...this.props.dropdown} onChange={this.props.onDropdownChange} />
    } else if (this.props.contextMenu) {
      rightMenu = <ContextMenu items={this.props.contextMenu} onSelect={this.props.onContextMenuSelect} />
    }
    let xmas = (moment().month() === 11 && moment().date() > 15) || (moment().month() === 0 && moment().date() < 6)

    let cl =  "col-sm-8 "

    if ((moment().month() === 11 && moment().date() > 18) && (moment().month() === 11 && moment().date() < 30)) {
      cl =  "col-sm-8 hat"
    }

    if ((moment().month() === 11 && moment().date() > 29 ) || (moment().month() === 0 && moment().date() < 5)) {
      cl =  "col-sm-8 fireworks"
    }

    return (
      <nav className="navbar" >
        <div className="container">
          <div className="row between-xs">
            <div className="col-sm-2">
              {
                this.props.backBtn
                  ? <BackButton onClick={this.goBack.bind(this)} {...classes} />
                  : <MenuButton onClick={this.openMenu.bind(this)} />
              }
            </div>
            <div className={cl} >
              <h1 >{this.props.title || 'Smartvatten'}</h1>
            </div>
            <div className="col-sm-2 align-right">
              {rightMenu}
            </div>
          </div>
          {!this.props.back && <Menu ref="menu" user={this.props.user} /*premise={this.props.premise}*/ />}
        </div>
      </nav>
    );
  }
}

const BackButton = (props) => (

  <IconButton
    className="icon-button"
    onClick={props.onClick}
  >
    <BackIcon className={props.backIcon} />
  </IconButton>
);

class MenuButton extends React.Component {
  render() {
    return (
      <div className="menu-button" onClick={this.props.onClick}>
        <DotsIcon />
        <span>{this.props.label || gettext('ui_menu')}</span>
      </div>
    );
  }
}

class ActionButton extends React.Component {
  render() {
    return (
      <div className="action-button" onClick={this.props.onClick}>
        {
          typeof this.props.label === 'string'
            ? <span>{this.props.label}</span>
            : this.props.label
        }
      </div>
    );
  }
}

class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    }
  }
  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  open() {
    //this.refs.menu.open();
    this.setState({ drawerOpen: true });
  }

  selectLanguage() {
    //console.log('selectLanguage', this.refs)
    this.refs.languageSelect.open();
    this.setState({ drawerOpen: false });
  }

  onChange(e, selectedIndex, menuItem) {
    if (menuItem.action) {
      menuItem.action();
    } else {
      this.context.history.pushState(null, menuItem.route);
    }
  }

  checkSuperUser(user) {
    const su = ['rami.kaunisto', 'jusa.isopahkala', 'mikael.manderbacka', 'ville.huopana', 'mikko.karjula','juha-pekka.makela','juha.pyykkonen','aki.pikkarainen'];
    for (let i = 0; i < su.length; i++) {
      const u = su[i];
      if (user.indexOf(u) > -1) return true;
    }
    return false;
  }


  render() {

    let superAdminMenu = '';
    if (this.props.user && this.checkSuperUser(this.props.user.username)) {
      superAdminMenu = <MenuItem onClick={() => history.push('/users')} ><ListItemIcon><UsersIcon /></ListItemIcon>{gettext('menu_kayttajat')}</MenuItem>
    }

    let newMenu = (
      <React.Fragment>
        <ListSubheader><span style={{ fontSize: 14, color: MyRawTheme.palette.primary1Color }}>{this.props.user && this.props.user.username}</span></ListSubheader>
        <ListSubheader>{gettext('menu_kohteet')}</ListSubheader>
        <MenuItem onClick={() => history.push('/premises')} ><ListItemIcon><PremisesIcon /></ListItemIcon>{gettext('menu_kohteet')}</MenuItem>
        <MenuItem onClick={() => history.push('/newpremise')} ><ListItemIcon><NewPremiseIcon /></ListItemIcon>{gettext('menu_uusi_kohde')}</MenuItem>
        <MenuItem onClick={() => history.push('/alarms')} ><ListItemIcon><AlertIcon /></ListItemIcon>{gettext('ui_alarms') + ' v2'}</MenuItem>
        <MenuItem onClick={() => history.push('/watercompanies')} ><ListItemIcon><WaterCompaniesIcon /></ListItemIcon>{gettext('menu_vesiyhtiot')}</MenuItem>
        <MenuItem onClick={() => history.push('/heatingcompanies')} ><ListItemIcon><HeatingCompaniesIcon /></ListItemIcon>{gettext('menu_lämpöyhtiot')}</MenuItem>
        <MenuItem onClick={() => history.push('/devices')} ><ListItemIcon><ReturnedDevicesIcon /></ListItemIcon>{gettext('Returned Devices')}</MenuItem>
        <MenuItem onClick={() => history.push('/unreturneddevices')} ><ListItemIcon><UnReturnedDevicesIcon /></ListItemIcon>{gettext('Un-returned Devices')} </MenuItem>
        <MenuItem onClick={() => history.push('/devices/delivered')} ><ListItemIcon><LocalShipping /></ListItemIcon>{gettext('Delivered Devices')} </MenuItem>
        <MenuItem onClick={() => history.push('/deliverystatus')} ><ListItemIcon><TrackChanges /></ListItemIcon>{gettext('Delivery Status')} </MenuItem>
        <MenuItem onClick={() => history.push('/apikeys')} ><ListItemIcon><ApiKeysIcon /></ListItemIcon>{gettext('menu_apiKeys')}</MenuItem>
        <MenuItem onClick={() => history.push('/debugreport')} ><ListItemIcon><ListIcon /></ListItemIcon>{gettext('ui_debug_report')}</MenuItem>
        <MenuItem onClick={() => history.push(encodeURI('/events?action=create_report_simulation&cols=[premiseName,timestamp,data]&title=Raportin simulointi&start=' + moment().startOf('day').format('YYYY-MM-DDTHH') + '&filter=data.err={"$exists": 1}'))} ><ListItemIcon><ListIcon /></ListItemIcon>{gettext('ui_report_simulation')}</MenuItem>
        {(this.props.user && this.props.user.role !== 'configurator') ?
          <React.Fragment>
            <ListSubheader>{gettext('ui_settings')}</ListSubheader>
            <MenuItem onClick={() => this.selectLanguage()} ><ListItemIcon><SelectLanguageIcon /></ListItemIcon>{gettext('language')}</MenuItem>
            {superAdminMenu}
          </React.Fragment> : ''
        }
        <ListSubheader>{gettext('menu_logout')}</ListSubheader>
        <MenuItem onClick={() => LoginActions.logout()} ><ListItemIcon><LogoutIcon /></ListItemIcon>{gettext('menu_logout')}</MenuItem>

      </React.Fragment>
    )

    return (
      <div>
        <LanguageSelect ref="languageSelect" />
        <Drawer
          anchor='left'
          open={this.state.drawerOpen}
          onChange={this.onChange.bind(this)}
          onClose={() => this.setState({ drawerOpen: false })}
        >
          {newMenu}
        </Drawer>
      </div>
    );
  }
}

class DropDownMenu extends React.Component {
  render() {
    //console.log("DropDownMenu",this.props.selectedIndex);
    let selected = this.props.selectedIndex;
    if (selected && selected.value) {
      selected = selected.value;
    }

    const items = this.props.items.map((e) => {
      return (<MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>);
    });
    if (items.length && selected === -1) {
      items.push(<MenuItem key="nullkey" value={-1}>{-1}</MenuItem>);
    }
    else if (!items.length) {
      items.push(<MenuItem key="nullkey" value={-1}>{this.props.emptyText}</MenuItem>);
      selected = -1;
    }
    return (
      <div className="navbar-select-wrapper">
        <Select
          placeholder={!this.props.items || !this.props.items.length ? this.props.emptyText : this.props.label}
          value={selected}
          options={this.props.items}
          onChange={this.props.onChange}
        >
          {items}
        </Select>
      </div>
    );
  }
}

class ContextMenu extends React.Component {
  render() {
    const iconButtonElement = (
      <mui.IconButton>
        <mui.FontIcon
          color="white"
          className="mdi mdi-dots-horizontal" />
      </mui.IconButton>
    );

    return (
      <mui.IconMenu className="icon-button" desktop={true} onItemTouchTap={this.props.onSelect} iconButtonElement={iconButtonElement}>
        {this.props.items.map((item) => {
          if (item.type === 'divider') {
            return <MenuDivider key={incrementKey++} />;
          }
          return (
            <MenuItem
              key={item.key}
              primaryText={item.text}
              itemData={item}
              insetChildren={item.insetChildren ? item.insetChildren : false}
            />
          );
        })}
      </mui.IconMenu>
    );
  }
}

export default withStyles(styles)(Appbar)