import React from 'react';
import PropTypes from 'prop-types';
//import PropTypes from 'prop-types';
//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
const MyRawTheme = require('../components/Theme');

export default class BaseView extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object,
    location: PropTypes.object 
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  getMuiTheme() {
    return  this.context && this.context.muiTheme ? this.context.muiTheme : getMuiTheme(MyRawTheme);
  }

  getChildContext() {
    let theme = this.context.muiTheme ? this.context.muiTheme : getMuiTheme(MyRawTheme);
    return {
      muiTheme: theme,
      location: this.props.location,
    };
  }
}
