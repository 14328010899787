import React from 'react';
import * as mui from 'material-ui';
//import connectToStores from 'alt-utils/lib/connectToStores';
import CardBase from './CardBase';
import PropTypes from 'prop-types';
import {gettext} from 'i18n';
import _ from 'lodash';
import history from '../utils/history'
import TextInput from './TextInput';
import ReportActions from '../actions/ReportActions'
import {doParseFloat} from '../utils/utils';

//@connectToStores
export default class ConsumptionReadingCard extends CardBase {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {data: {}};
  }

  componentWillMount() {
    this.setInitialStyles();
    this.setState({data: this.props.data})
  }

  componentWillReceiveProps(props) {
    this.setState(_.assign(this.state.data, props.data));
    this.setState({saving: false});
  }

  onChange(value, id, textField) {
    /*
    let val = parseFloat(value.replace(',', '.'));
    if (val !== '' && isNaN(val)) {
      textField.setErrorText(gettext('ui_notNumber'));
    } else {

      textField.setErrorText('');
      this.setState(_.assign(this.state.data, {value: val}));
    }
    */
    this.setState(_.assign(this.state.data, {value}));
  }

  onSave() {
    if (!this.state.saving) {
      let val = doParseFloat(this.state.data.value);
      //this.refs.input.setErrorText('');
      if (isNaN(val)) {
        //this.refs.input.setErrorText(gettext('ui_notNumber'));
        this.setState({errorText:gettext('ui_notNumber')})
      } else {
        const data = _.assign(this.state.data, {value: val});
        ReportActions.generateManualReport(data.premiseId, new Date(Date.UTC(data.year, data.month - 1)), data.value);
        this.setState({data, saving: true, errorText:''});
      }
    }
  }

  render() {

    let mergedRootStyles = this.state.mergedRootStyles;
    //mergedRootStyles.cardtext1.fontSize = '20px';
    
    let cont = (
      <div>
        <div className="row center-xs" style={mergedRootStyles.cardtext1}>
          {gettext('ui_giveConsumptionReading')}
        </div>
        <div className="row center-xs">
          <div className="col-xs-8">
            <TextInput id="ConsumptionReading" validations={{minLength:1}} value={this.state.data.value} onChange={this.onChange.bind(this)}/>
          </div>
        </div>
        <div className="row center-xs">
          <div className="col-xs-8">
            <span className="error-text">{this.state.errorText}</span>
          </div>
        </div>
        <div className="row center-xs">
          <div className="col-xs-8">
            <mui.RaisedButton label={gettext('ui_saveValue')} secondary={true} onClick={this.onSave.bind(this)}  />
          </div>
        </div>
      </div>
    );

    return (
      <div style={mergedRootStyles.cardSize}>
      <mui.Card style={mergedRootStyles}>
        <div style={mergedRootStyles.cardbackgrounds.header}/>
        <div style={mergedRootStyles.cardSize}>
          <div style={mergedRootStyles.headerSize}>
            <span style={mergedRootStyles.title}>{gettext('title_ConsumptionReading').toUpperCase()}</span>
            {this.props.onCancel && <mui.Avatar className="clickable" style={mergedRootStyles.button} icon={<i className="mdi mdi-window-close" onClick={this.props.onCancel}/>} />}
          </div>
          <hr style={mergedRootStyles.line}/>
          <center>
            <div className="">
              {cont}
              {this.state.saving && <p>{gettext('ui_saving')}</p>}
            </div>
          </center>
          </div>
        <div style={mergedRootStyles.cardbackgrounds.header}/>
        </mui.Card>
      </div>
    );

  }
}
