import React from 'react';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import moment from 'moment';
import Utils from '../utils/utils';
import ReactJson from 'react-json-view'; 

export default class premiseEditLogDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  open(data) {
    this.setState({ dialogOpen: true });
  }

  dialogData() {
    return this.data;
  }

  close() {
    this.setState({ dialogOpen: false });
  }


  render() {

    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }
    let log = this.props.log || []
    let text = []


    let title = this.props.title ? this.props.title : gettext('Changes');
    let showClose = this.props.showClose !== undefined ? this.props.showClose : true
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div style={dialogStyle}>
          <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell >Added</TableCell>
            <TableCell >Deleted</TableCell>
            <TableCell >Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {log.map((row) => (
            <TableRow key={JSON.stringify(row.timestamp)}>
              <TableCell>{moment(row.timestamp).format('DD.MM.YYYY HH:mm')}</TableCell>
              <TableCell><ReactJson src={row.diff.added} quotesOnKeys={false} sortKeys={true} name={false} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} theme='summerfruit:inverted' /></TableCell>
              <TableCell><ReactJson src={row.diff.deleted} quotesOnKeys={false} sortKeys={true} name={false} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} theme='summerfruit:inverted' /></TableCell>
              <TableCell><ReactJson src={row.diff.updated} quotesOnKeys={false} sortKeys={true} name={false} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} theme='summerfruit:inverted' /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
          </div>
        </DialogContent>
        <DialogActions>
          {showClose && <Button onClick={() => (this.close())} >{gettext('ui_close')}</Button>}
        </DialogActions>
      </Dialog>
    );

  }
}
