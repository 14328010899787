import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import AnalyzeActions from '../actions/AnalyzeActions';
//import { History, PropTypes} from 'react-router'
//let Icon = mui.Icon;
var { CircularProgress, DatePicker } = mui;
import * as Colors from 'material-ui/styles/colors';
import config from '../utils/config';
import Utils from '../utils/utils';
import InfiniteScroll from 'react-infinite-scroller';
var { Table, TableBody, TableHeader, TableRow, TableHeaderColumn, TableRowColumn, Toggle } = mui;
import moment from 'moment';
var _page = 0;
//var _dialogOpen = false;
import ViewLoader from './ViewLoader';
import { gettext } from 'i18n';

//new material ui
import AutoIcon from '@material-ui/icons/CheckCircleOutline';
import ImageIcon from '@material-ui/icons/Image';
import EditedIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';

////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
const MyRawTheme = require('./Theme');

const styles = {
  avatar: {
    margin: 10,
  },
  autoAvatar: {
    margin: 'auto',
    color: '#fff',
    backgroundColor: green[500],
  },
  editedAvatar: {
    margin:'auto',
    color:'#fff',
    backgroundColor: yellow[500],
  },
  imageIcon: {
    backgroundColor: blue[500],
    color: '#fff',
  },
  media: {
    //height: 244,
    //paddingTop: '56.25%', // 16:9
    //width:550,
  },
  manualValueInput: {
    fontSize: 25,
  }
};

class DeviceHistoryTable extends React.Component {

  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme,
      dialogOpen: false,
      manualInputValue: '',
    };
    this.hourFormUpdate = this.hourFormUpdate.bind(this);
  }

  /*static getStores() {
    return [AnalyzeStore];
  }

  static getPropsFromStores() {
    return AnalyzeStore.getState();
  }*/

  static propTypes = {
    history: PropTypes.array,
    //measurements: PropTypes.object,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    onShowImagesChange: PropTypes.func,
    onUseLimitsChange: PropTypes.func,
    updateEndDate: PropTypes.func,
    updateStartDate: PropTypes.func,
    startDate: PropTypes.object,
    endDate: PropTypes.object
  }

  static contextTypes = {
    muiTheme: PropTypes.object
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  hourFormUpdate(e) {
    e.preventDefault();
    this.props.onUpdate();
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentWillMount() {
    console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
    let newMuiTheme = this.state.muiTheme
    newMuiTheme.tableHeaderColumn.textColor = this.state.muiTheme.rawTheme.palette.primary1Color;
    newMuiTheme.tableRow.stripeColor = this.state.muiTheme.rawTheme.palette.accent2Color;
    newMuiTheme.toggle.trackOffColor = this.state.muiTheme.rawTheme.palette.accent3Color;
    newMuiTheme.toggle.trackOnColor = this.state.muiTheme.rawTheme.palette.primary1Color;

    console.log("palettethis", this.state.muiTheme)
    this.setState({
      muiTheme: newMuiTheme,
    });

    _page = 0;
  }

  handleClick(historyItem, index) {
    console.log('DeviceHistoryTable handleClick', historyItem, index);
    var picpath = 0;
    if (historyItem.token) {
      picpath = config.DATASERVER + '/file/' + historyItem.token;
    } else { picpath = null }
    var datetime = Utils.getDateString(historyItem.timestamp);
    //_dialogOpen = true;
    var oldValue = (historyItem.value) ? historyItem.value : '';
    this.setState({ editTime: datetime, historyItem: historyItem, editpic: picpath, editindex: index, dialogOpen: true, manualInputValue: oldValue });
    //this.refs.editDialog.show();
    //setTimeout(() => this.refs.manualValueInput.setValue(oldValue));
  }

  openNext() {
    var index, historyItems, picpath, datetime, oldValue;
    if (this.state.editindex != null && this.state.editindex < this.props.history.length - 1) {
      index = this.state.editindex + 1;
      historyItems = this.props.history;
      if (historyItems[index].token) {
        picpath = config.DATASERVER + '/file/' + historyItems[index].token;
      } else { picpath = null }
      datetime = Utils.getDateString(historyItems[index].timestamp);
      oldValue = (historyItems[index].value) ? historyItems[index].value : '';
      this.setState({ historyItem: historyItems[index], editpic: picpath, editindex: index, editTime: datetime, manualInputValue: oldValue });
      //this.refs.manualValueInput.setValue(oldValue);
    }
  }

  openPrev() {
    console.log("openPrev")
    var index, historyItems, picpath, datetime, oldValue;
    if (this.state.editindex && this.state.editindex > 0) {
      index = this.state.editindex - 1;
      historyItems = this.props.history;
      if (historyItems[index].token) {
        picpath = config.DATASERVER + '/file/' + historyItems[index].token;
      } else { picpath = null }
      datetime = Utils.getDateString(historyItems[index].timestamp);
      oldValue = (historyItems[index].value) ? historyItems[index].value : '';
      this.setState({ historyItem: historyItems[index], editpic: picpath, editindex: index, editTime: datetime, manualInputValue: oldValue });
      //this.refs.manualValueInput.setValue(oldValue);
    }
  }

  onDialogAccept() {
    console.log('DeviceHistoryTable onDialogAccept');
    var newVal = this.state.manualInputValue;//this.refs.manualValueInput.getValue();
    if (newVal === "" || newVal===null ){
      this.setState({ dialogOpen: false });
      return;
    }

    var timestamp = new Date().getTime();
    var upObj = { _id: this.state.historyItem._id, value: newVal, checked: timestamp };

    AnalyzeActions.updateMeasurement(this.state.historyItem.deviceId, upObj);
    //_dialogOpen = false;
    //this.refs.editDialog.dismiss();
    this.setState({ dialogOpen: false });
  }

  onDialogCancel() {
    console.log('DeviceHistoryTable onDialogCancel');
    //_dialogOpen = false;
    //this.refs.editDialog.dismiss();
    this.setState({ dialogOpen: false });
  }

  loadMore() {
    console.log('DeviceHistoryTable loadMore');
    if (!this.state.dialogOpen && this.props.hasMore && this.props.history.length > 0) {
      this.props.loadMore(++_page)
    }
  }

  render() {
    //console.log("History RENDER", this.props)
    console.log("palettethis", this.state.muiTheme.rawTheme)
    var self = this;
    var day_temp = null;
    const { classes } = this.props;
    if (!this.props.history) {
      return <ViewLoader />;
    }
    console.log("DeviceHistoryTable:",this);
    // TODO: for some reason calling setToggled in render crashes it now.
    /*if (this.props.history.length < 1 && this.refs['ShowImagesToggle'] && !loadimagesDefaultSet) {
      if (!this.refs['ShowImagesToggle'].isToggled()){
        this.refs['ShowImagesToggle'].setToggled(true);
        this.props.onShowImagesChange(this, true);
        loadimagesDefaultSet = true;
      }
    }*/

    //var historyItems = this.props.history.slice(0,100);
    var items = this.props.history;
    //var editdActions = [{ text: gettext('ui_accept'), onClick: this.onDialogAccept.bind(this) }, { text: gettext('ui_cancel'), onClick: this.onDialogCancel.bind(this) }];

    var editpic = (this.state && this.state.editpic) ? this.state.editpic : '';
    var timeToEdit = (this.state && this.state.editTime) ? this.state.editTime : '';
    let imageWidth

    /*if (window.innerWidth > 1200) {
      dialogHeight = 415;
    } else if (window.innerWidth > 980) {
      dialogHeight = 415;
    } else*/ if (window.innerWidth > 768) {
      imageWidth = 550;
      //} else if (window.innerWidth > 480) {
    } else {
      imageWidth = 250;
    }
    //console.log("editPic:", editpic, typeof (editpic));
    var dialog = (
      <Dialog
        maxWidth='lg'
        fullWidth={true}
        open={this.state.dialogOpen}>
        <DialogContent>
          <div className="row">
            <div className="col-md-7">

              <img
                className={classes.media}
                style={{ width: imageWidth }}
                src={editpic}
                media='picture'
                title="Kuva"
              />

            </div>
            <div className="col-md-5">
              <CardHeader
                title={gettext('ui_setMetervalue')}
                subheader={timeToEdit}
              />
              <TextField
                id="manualValueInput"
                label={gettext('ui_setMetervalue')}
                value={this.state.manualInputValue}
                className={classes.manualValueInput}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e)=>{
                  this.setState({manualInputValue:e.target.value});
                }}
              />
              <div>ML Value:{this.state && this.state.historyItem && this.state.historyItem.mlvalue}</div>
              <CardActions>
                <Button onClick={this.openNext.bind(this)} color="primary">{gettext('ui_older')}</Button>
                <Button onClick={this.openPrev.bind(this)} color="primary">{gettext('ui_newer')}</Button>
              </CardActions>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDialogCancel.bind(this)}>{gettext('ui_cancel')}</Button>
          <Button color="primary" onClick={this.onDialogAccept.bind(this)}>{gettext('ui_accept')}</Button>
        </DialogActions>
      </Dialog>
    )

    return (
      <InfiniteScroll loadMore={this.loadMore.bind(this)}
        hasMore={this.props.hasMore} threshold={20}
        loader={<div style={{ textAlign: 'center' }}><CircularProgress mode="indeterminate" /></div>}>
        <Table onRowSelection={function (row) { self.handleClick(items[row[0]], row[0]) }} >
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn colSpan="3" >
                <h2>Historia</h2>
                <div className="text-right">
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    {<div style={{ width: '100px' }}>

                      <Toggle
                        toggled={this.props.showData}
                        labelStyle={{ width: 'auto' }}
                        onToggle={this.props.onShowDataChange}
                        label={gettext('Data')} />
                    </div>}
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <div style={{ width: '150px' }}>
                      <Toggle
                        toggled={this.props.showImages}
                        labelStyle={{ width: 'auto' }}
                        onToggle={this.props.onShowImagesChange}
                        label={gettext('Images')} />
                    </div>
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    {/*<DatePicker disabled={!this.props.useLimits} className="datePicker" style={{display:'inline-block'}} textFieldStyle={{width:'100px'}} hintText="Alku"
                      formatDate={function(d) { return moment(d).format('D.M.YYYY'); }} defaultDate={this.props.startDate.toDate()} onChange={(t, e) => {_page = 0; this.props.updateStartDate(t, e)}} />
                    <div style={{display:'inline-block', marginLeft:'8px', marginRight:'8px'}}>-</div>*/}
                    <span style={{ color: "black" }}>{gettext("Päivä")}:</span> <DatePicker className="datePicker" style={{ display: 'inline-block' }} textFieldStyle={{ width: '100px' }} hintText="End"
                      formatDate={function (d) { return moment(d).format('D.M.YYYY'); }} defaultDate={this.props.endDate.toDate()} onChange={(t, e) => { _page = 0; this.props.updateEndDate(t, e) }} />
                    <form onSubmit={this.hourFormUpdate} style={{ display: "inline" }}>
                      <span style={{ color: "black", marginRight: '5px' }}>{gettext("Tunti")}:</span>
                      <input style={{ color: "black", marginRight: '5px' }}
                        value={this.props.hour}
                        size="2"
                        onChange={(e) => this.props.onHourChange(e.target.value)} />
                      <button style={{ color: "black" }} type="submit">{gettext("ui_update")}</button>
                    </form>
                  </div>
                </div>
              </TableHeaderColumn>
            </TableRow>
            <TableRow>
              {/*<TableHeaderColumn style={{textAlign: 'center'}}><h3>Kuva</h3></TableHeaderColumn>*/}
              <TableHeaderColumn style={{ textAlign: 'center' }}><h3>{gettext('ui_timestamp')}</h3></TableHeaderColumn>
              <TableHeaderColumn style={{ textAlign: 'center' }}><h3>{gettext('ui_meterValue')}</h3></TableHeaderColumn>
              <TableHeaderColumn style={{textAlign: 'center'}}><h3>{gettext('ML Value')}</h3></TableHeaderColumn>
              <TableHeaderColumn style={{ textAlign: 'center' }}><h3>{gettext('ui_read_written')}</h3></TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} stripedRows={true} showRowHover={true}>
            {items.map((item, i) => {
              var datetime = Utils.getDateString(item.timestamp);
              var bg = "";
              if (moment(item.timestamp).day() !== moment(day_temp).day() && day_temp) bg = 'first-day';
              day_temp = item.timestamp;
              var picture = ((item.mimetype) ? <ImageIcon className={classes.imageIcon} /> : "");
              var checked, analyzenValue;
              if (item.checked) {
                checked = <Avatar title={gettext('ui_handwrittenMetervalue')} className={classes.editedAvatar} ><EditedIcon /></Avatar>;
              }

              if (item.value && !item.checked) {
                analyzenValue = <Avatar title={gettext('ui_MetervalueReadAutomatically')} className={classes.autoAvatar} ><AutoIcon /></Avatar>;
              }

              var valueString = ((item.value === undefined) ? "" : item.value + " m³");
              let mlvalueString = ((item.mlvalue === undefined) ? "" : item.mlvalue);
              let mldigits = ((item.mldigits === undefined) ? null : item.mldigits);

              if(item.mlvalue === undefined && mldigits ){
                mlvalueString = <Icon title= {JSON.stringify(mldigits)} className="premisesIcons">battery_alert</Icon>
              }

              let unreliable = item.unreliable ? <Icon title={gettext('unreliable value')} className="premisesIcons" >warning</Icon> : null
              return (
                <TableRow className={bg} key={i}>
                  {/*<TableRowColumn style={{textAlign: 'center', cursor:'pointer'}}>{picture}</TableRowColumn>*/}
                  <TableRowColumn style={{ textAlign: "center", cursor: "pointer" }}>{datetime}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: "center", cursor: "pointer" }}><span style={{ marginRight: 10 }}>{picture}</span>{unreliable}{valueString}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: "center", cursor:"pointer"}}>{mlvalueString}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: "center", cursor: "pointer" }}>{checked}{analyzenValue}</TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {dialog}
      </InfiniteScroll>
    );
  }
}

export default withStyles(styles)(DeviceHistoryTable);