import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
import qs from 'qs';
import moment from 'moment';

class PremiseActions {
  constructor() {
    this.generateActions('reset', 'setPremise', 'resetEvents', 'loading', 'resetAlarmLimits', 'savingInfo');
  }

  query(mongoQuery) {
    if (mongoQuery && mongoQuery.savingsGuarantee) { mongoQuery.savingsGuarantee = (mongoQuery.savingsGuarantee === 'true') ? true : false }

    let b = new UrlBuilder();
    let b2 = new UrlBuilder();
    const filter = {
      history: 0
    };

    const query = {
      query: JSON.stringify(mongoQuery.query),
      filter: JSON.stringify(filter),
      start: mongoQuery.start,
      end: mongoQuery.end
    }
    return function (dispatch) {
      api.get(b2.path('premises/totalsavings?' + qs.stringify(query))).then(savingsRes => {
        let totalSavings = savingsRes.data;
        if (totalSavings && totalSavings.count < mongoQuery.skip) {
          // go to last page
          mongoQuery.skip = Math.floor(totalSavings.count / mongoQuery.limit) * mongoQuery.limit
        }

        const limitedQuery = Object.assign({
          sort: JSON.stringify(mongoQuery.sort),
          limit: mongoQuery.limit,
          skip: mongoQuery.skip
        }, query);

        return api.get(b.path('premises?' + qs.stringify(limitedQuery))).then(premiseRes => {
          let data = premiseRes.data;
          //setTimeout(() => {
          dispatch({ data, totalSavings, mongoQuery });
          //});
        })
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ res: null, err });
      });

    }
  }

  getSavings(premiseId, start, end) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.get(b.path('premises').id(premiseId + '/totalsavings').start(start).end(end))
        .then((res) => {
          dispatch({ start, end, _id: premiseId, ...res.data });
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch(null);
        });
    }
  }

  queryById(id, filter) {
    return function (dispatch) {
      let b = new UrlBuilder();
      return api.get(b.path('premises').id(id).filter(filter)).then((res) => {
        dispatch(res.data);
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ res: null, err });
      });
    }
  }

  queryPremiseLinks(links) {
    var checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
    const promises = [];
    const linked = {};

    links.filter((f) => checkForHexRegExp.test(f.premiseId)).forEach((ld) => {
      let c = new UrlBuilder();
      const p = api.get(
        c.path('premises')
          .id(ld.premiseId)
          .filter({ _id: 1, devicesinfo: 1, name: 1 })
      )
        .then((r) => {
          linked[r.data._id] = r.data;
        });
      promises.push(p);
    });
    return function (dispatch) {
      return Promise.all(promises)
        .then(() => ({ linkedPremises: linked }))
        .then(o => {
          dispatch(o);
        })
        .catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: null, err });
        });
    }
  }

  queryDeviceWithLinks(id, filter) {
    return function (dispatch) {
      let b = new UrlBuilder();
      return api.get(b.path('premises').id(id).filter(filter))
        .then((res) => {
          const linked = {};

          if (res.data && res.data.linkedDevices) {
            const promises = [];

            var checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");

            res.data.linkedDevices.filter((f) => checkForHexRegExp.test(f.premiseId)).forEach((ld) => {
              let c = new UrlBuilder();
              const p = api.get(
                c.path('premises')
                  .id(ld.premiseId)
                  .filter({ _id: 1, devicesinfo: 1, name: 1 })
              )
                .then((r) => {
                  linked[r.data._id] = r.data;
                });
              promises.push(p);
            });

            return Promise.all(promises)
              .then(() => ({ premise: res.data, linkedPremises: linked }));
          }
          return { premise: res.data };
        })
        .then(o => {
          dispatch(o);
        })
        .catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: null, err });
        });
    }
  }

  edit(data) {
    return function (dispatch) {
      var b = new UrlBuilder();
      // proper way of sending data would be to define valid properties explicitly
      // currently we send everything we receive from server and interesting
      // things can happen, this is why data.devicesinfo is deleted
      delete data.devicesinfo;
      data.floorArea = Number(data.floorArea)
      if (data.serviceType !== 'fiksuvesi') {
        data.noHistory = false;
      }

      return api.post(b.path('premises'), data).then(() => {
        //console.log('premises write res', res)
        dispatch({});
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ err });
      })
    }
  }

  editDeviceIfo(data) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('premises'), data).then(() => {
        //console.log('premises write res', res)
        dispatch({});
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ err });
      })
    }
  }

  beginEdit() {
    return function (dispatch) {
      let b = new UrlBuilder();
      return api.get(b.path('heatingtypes')).then((res) => {
        //console.log('heatingtypes', res)
        dispatch(res.data);
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ res: null, err });
      });
    }
  }

  unLinkDevice(pid, did) {
    return function (dispatch) {
      let b = new UrlBuilder();
      return api.get(b.path('deviceinstall/' + did + '/uninstall/' + pid)).then((res) => {
        dispatch({ deviceId: did, pId: pid, res });
      }).catch((err) => {
        console.log(err.stack || err)
        dispatch({ res: null, err });
      })
    }
  }

  moveDevice(deviceId, data, event) {
    return function (dispatch) {
      let b = new UrlBuilder();

      let path = b.path(`deviceinstall/${deviceId}/change-premise`);
      return api.post(path, data)
        .then(() => {
          b = new UrlBuilder();
          path = b.path('events');
          return api.post(path, event)
        })
        .catch((err) => {
          console.log(err.stack || err)
          dispatch({ err });
        })
        .then(() => {
          dispatch({});
        })
    }
  }

  removeDevice(premise, deviceId) {
    return function (dispatch) {
      let b = new UrlBuilder();
      return api.get(b.path('deviceinstall/' + deviceId + '/uninstall/' + premise._id))
        .then((res) => {
          console.log('DEVICE DELETE res: ', res)
          setTimeout(() => { dispatch({ deviceId, err: null }); }, 0)
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: null, err });
        })
    }
  }

  deactivateDevice(premise, deviceId) {
    return async (dispatch) => {
      try {
        let b = new UrlBuilder();
        let res = await api.get(b.path('deviceinstall/' + deviceId + '/deactivate/' + premise._id));
        setTimeout(() => { dispatch({ deviceId, err: null }); }, 0)
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ res: null, err });
      }
    }
  }

  deactivateDevice(premise, deviceId) {
    let b = new UrlBuilder();
    api.get(b.path('deviceinstall/' + deviceId + '/deactivate/' + premise._id))
      .then((res) => {
        setTimeout(() => { this.dispatch({ deviceId, err: null }); }, 0)
      }).catch((err) => {
        console.log(err.stack || err)
        this.dispatch({ res: null, err });
      })
  }

  getEarliestValue(deviceId) {
    return function (dispatch) {
      let b = new UrlBuilder();
      return api.get(b.path('data/' + deviceId).query({ value: { $exists: 1 } }).sort({ timestamp: 1 }).limit(1))
        .then((res) => {
          dispatch({ earliestData: res.data[0], err: null });
        }).catch((err) => {
          console.log(err.stack)
          dispatch({ res: null, err });
        })
    }
  }

  setMeterValue(deviceId, value, timestamp) {
    return function (dispatch) {
      let b = new UrlBuilder();
      const path = b.path('data').id(deviceId);
      const time1 = moment(timestamp).startOf('month').set('minute', 10);
      const time2 = moment(timestamp).subtract(1, 'month').endOf('month').set('minute', 50).set('second', 0).set('millisecond', 0);
      return Promise.all([
        api.post(path, { value, timestamp: time1.toDate() }),
        api.post(path, { value, timestamp: time2.toDate() })
      ])
        .then(() => {
          dispatch({ res: "ok", err: null });
        })
        .catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: null, err });
        })
    }
  }

  getEvents(obj) {
    return function (dispatch) {
      let b = new UrlBuilder();
      let q = {};
      if (obj.premiseId && obj.showall) {
        q.premiseId = obj.premiseId;
      } else if (obj.premiseId) {
        q.premiseId = obj.premiseId;
        q.action = { $ne: "hour_data_export" };
      } else if (obj.action) {
        q.action = obj.action;
      } else return;

      if (obj.start || obj.end) {
        q.timestamp = {};
        if (obj.start) q.timestamp.$gte = moment(obj.start).toISOString();
        if (obj.end) q.timestamp.$lte = moment(obj.end).toISOString();
      }
      if (obj && obj.filter) {
        const split = obj.filter.split('=');
        try {
          q[split[0]] = JSON.parse(split[1]);
        } catch (e) {
          q[split[0]] = Number(split[1]) || split[1];
        }

      }

      return api.get(b.path('events/?query=' + encodeURI(JSON.stringify(q))))
        .then((res) => {
          dispatch(res.data);
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: [], err });
        })
    }
  }

  getEvents2(obj) {
    return function (dispatch) {
      this.actions.loading.defer();
      let b = new UrlBuilder();

      let q = {};

      if (obj.search) {
        //const s = { $regex: obj.search, $options: 'i' };
        const s = obj.search
        q.$or = [{ action: s }, { source: s }, { 'data.deviceId': s }, { 'data.newPremiseId': s }, { 'data.oldPremiseId': s }];
      }

      if (obj.premiseId) {
        q.premiseId = obj.premiseId;
        q.action = { $ne: "hour_data_export" };
      }

      if (obj.start || obj.end) {
        q.timestamp = {};
        if (obj.start) q.timestamp.$gte = moment(obj.start).toISOString();
        if (obj.end) q.timestamp.$lte = moment(obj.end).toISOString();
      }

      const body = {
        query: q,
        projection: {},
        sort: { timestamp: -1 },
        limit: obj.limit,
        skip: obj.skip,
      };

      let count;
      return api.post(b.path('events/count'), body)
        .then((res) => {
          count = res.data;
          let c = new UrlBuilder();
          return api.post(c.path('events/find'), body);
        })
        .then((res) => {
          dispatch({ events: res.data, count });
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: [], err });
        })
    }
  }

  getDevice(deviceId) {
    return function (dispatch) {
      dispatch({});
      let b = new UrlBuilder();
      return api.get(b.path('devices/' + deviceId))
        .then((res) => {
          dispatch(res.data);
        }).catch((err) => {
          console.log(err.stack || err)
          dispatch({ res: [], err });
        })
    }
  }

  highflowRecalculation(deviceId) {
    return async (dispatch) => {
      if (!deviceId) throw new Error('DeviceId missing!');
      let b = new UrlBuilder();
      dispatch({ loading: true });

      try {
        let res = await api.get(b.path('alarmsv2/sethighflowlimit?deviceId=' + deviceId));
        dispatch({ newHighflowLimits: res.data, loading: false });
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ res: [], err, loading: false });
      }
    }
  }

  weekendHighflowRecalculation(deviceId) {
    return async (dispatch) => {
      if (!deviceId) throw new Error('DeviceId missing!');
      let b = new UrlBuilder();
      dispatch({ loading: true });

      try {
        let res = await api.get(b.path('alarmsv2/setweekendhighflowlimit?deviceId=' + deviceId));
        dispatch({ newWeekEndHighflowLimits: res.data, loading: false });
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ res: [], err, loading: false });
      }
    }
  }

  getLatestImages(devices) {
    return function (dispatch) {
      if (!devices) throw new Error('devices missing!');
      let b = new UrlBuilder();
      dispatch([]);
      return api.get(b.path('getlatestimages?devices=' + encodeURI(JSON.stringify(devices)))).
        then((res) => {
          dispatch(res.data);
        }).catch((err) => {
          console.log("getLatesImages error:", err.stack || err);
          dispatch([]);
        })
    }
  }

  addnewSmartDevice(settings, premiseId) {
    return async (dispatch) => {
      let b = new UrlBuilder();
      dispatch({ loading: true });
      if (!settings || !settings.DevEUI || !premiseId) throw new Error('Data missing!');
      if (!premiseId) throw new Error('premiseId missing!');
      let DevEUI = settings.DevEUI
      let level = settings.serviceLevel && settings.serviceLevel.length ? settings.serviceLevel : "hourlevel"
      let mes = {
        settings: {
          data: {
            valueToUse: 4
          },
          serviceLevel: {
            level
          },
          smartMeter: {
            precision: settings.precision || 0.001
          },
          tz: "Europe/Helsinki"
        },
        meta: {
          AppEUI: "",
          AppKey: "",
          DevEUI: settings.DevEUI,
          Manufacturer: settings.Manufacturer,
          Model: settings.Model,
          Serial: settings.Serial
        }
      }

      if(settings.replace){
        mes.replace = settings.replaceDevice
      }

      try {
        let res = await api.post(b.path(`smartmeter/add/${DevEUI}/${premiseId}`), mes);
        dispatch({ loading: false });
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ err, loading: false });
      }

    }
  }

  getDeliveryStatus(query) {
    return async (dispatch) => {
      let b = new UrlBuilder();
      try {
        let req = { parentAccount: { $regex: "Bou" } }
        let res = await api.post(b.path(`premises/deliverystatus`), query);
        dispatch({ data: res.data, loading: false });
      } catch (err) {
        console.log(err.stack || err)
        dispatch({ err, loading: false });
      }
    }
  }

  addInfoRow(q) {
    return async (dispatch) => {
      var res = null
      try {
        for (let index = 0; index < q.length; index++) {
          let b = new UrlBuilder();
          const info = q[index];
          res = await api.post(b.path(`premises/adddeliverystatusinfo`), info);
        }
        dispatch({ data: res, err: null });
      } catch (err) {
        console.log("addInfoRow error", err.stack || err)
        dispatch({ err, data: null });
      }
    }
  }

  getChangesLog(id) {
    return async function (dispatch) {
      let b = new UrlBuilder();
      let res;
      try {
        res = await api.get(b.path('premises/changelog/'+id));
      } catch (err) {
        console.error(err);
        dispatch({ err });
        const res = undefined;
        return;
      }
      return dispatch(res);
    }
  }

  getDistinctCountries() {
    return async function (dispatch) {
      //dispatch({isLoading:true});

      let b = new UrlBuilder();
      let res;
      try {
        res = await api.get(b.path('servicerequests/distinctcountries'));
      } catch (err) {
        console.error(err);
        dispatch({ err });
        const res = undefined;
        return;
      }
      return dispatch({ countries: res.data });
    }
  }

  getPremisesMissingData(q) {
    return async function (dispatch) {
      console.log("getting getPremisesMissingData...");
      //dispatch({isLoading:true});

      let b = new UrlBuilder();
      let res;
      try {
        res = await api.post(b.path(`premises/getPremisesWithMissingProperties`), q);
      } catch (err) {
        console.error(err);
        dispatch({ err });
        const res = undefined;
        return;
      }
      console.log("getPremisesMissingData:", res);
      return dispatch({ data: res.data });
    }
  }
  
  saveDisabledAlarmsSettings(alarms){
    return async function(dispatch){
      //console.log("saving settings...",alarms);
      let b = new UrlBuilder();
      let res;
      try{
        res = await api.post(b.path(`devices/disabledalarms`),alarms);
      }catch(err){
        console.error(err);
        dispatch({err});
        return;
      }
      //console.log("Settings saved:",res);
      return dispatch(res);
    }
  }

}

export default alt.createActions(PremiseActions);
