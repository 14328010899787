import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
let UA
class UserActions {
  constructor() {
    this.generateActions('ObjectsQuery', 'change');
  }

  query(q) {
    return async (dispatch) => {
      console.log('will query', q);
      var b = new UrlBuilder()
      /*
      ### example usage ###
      b.path('data')
        .id(opts.id)
        .start(opts.start && opts.start.toISOString())
        .end(opts.end && opts.end.toISOString())
        .query(q)
        .limit(opts.limit)
        .skip(opts.skip)
        .sort({timestamp: -1})
       */

      /*return api.get(b.path('users').query(q)).then((res) => {
        console.log('query res', res)
        dispatch(res.data);
      }).catch((err) => {
        console.error(err.stack);
        dispatch(null);
      });
    }*/
      try {
        let res = await api.get(b.path('users').query(q));
        //console.log('query res:', res);
        dispatch(res.data);
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
    }
  }

  salePersonQuery(q) {
    return async (dispatch) => {
      try {
        var b = new UrlBuilder();
        let res = await api.get(b.path('users').query(q));
        dispatch(res.data);
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
    }
  }

  editUser(data) {
    return async (dispatch) => {
      console.log("addUser", data);
      var b = new UrlBuilder();
      if (data.errors) {
        delete data.errors;
      }

      try {
        let res = await api.post(b.path('users'), data);
        console.log('users write res:', res);
        await UA.query();
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
      /*api.post(b.path('users'), data).then((res) => {
        console.log('users write res', res)
        UA.query();
      }).catch((err) => {
        console.error(err.stack);
        return (null);
      })*/
    }
  }

  removeUser(data) {
    return async (dispatch) => {
      console.log("removeUser", data._id);
      var b = new UrlBuilder();
      try {
        let res = await api.delete(b.path('users').id(data._id));
        console.log('query res:', res);
        await UA.query();
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
      /*api.delete(b.path('users').id(data._id)).then((res) => {
        console.log('query res', res);
        UA.query();
      }).catch((err) => {
        console.error(err.stack);
        return (null);
      });*/
    }
  }
}

UA = (alt.createActions(UserActions));
export default UA;
