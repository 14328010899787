import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import {gettext} from 'i18n';
//import {Colors, Spacing, Typography} from 'material-ui/lib/styles';
import * as Colors from 'material-ui/styles/colors';
import * as Spacing from 'material-ui/styles/spacing';
import * as Typography from 'material-ui/styles/typography';
//const Colors = mui.muiTheme.Colors;
//const Spacing = mui.Styles.Spacing;
//const Typography = mui.muiTheme.Typography;
//let ImmutabilityHelper = require('material-ui/lib/utils/immutability-helper');
import * as ImmutabilityHelper from 'material-ui/'
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';

export default class CardBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }
  componentWillMount(){
    this.setInitialStyles();
  }

  getChildContext() {
    let theme = this.context.muiTheme ? this.context.muiTheme : getMuiTheme(MyRawTheme);
    return {
      muiTheme: theme,
    };
  }

  setInitialStyles() {

    let palette = MyRawTheme.palette;
    let fiksuvesiStyle = MyRawTheme.fiksuvesi;

    const textColor = (color) => color;

    let style = {
      backgroundColor: 'white',
      borderRadius: '3px',
      cardSize: {height: '350px'},
      headerSize: {height: '64px', display:'block'},
      border: '1px solid #aaa',
      title:{
        color: textColor(palette.primary1Color),
        fontSize: '16px',
        lineHeight: Spacing.desktopKeylineIncrement + 'px',
        fontWeight: Typography.fontWeightLight,
        paddingLeft: Spacing.desktopGutter,
        paddingTop: '20px',
        paddingLeft: '24px',
        display:'inline-block'
      },
      cardbackgrounds:{
        header: {
          backgroundColor: palette.primary1Color,
          height: '5px'
        },
        footer: {
          backgroundColor: palette.primary1Color,
          height: '5px',
          float: 'bottom'
        }
      },
      line:{
        color: palette.primary1Color,
        borderColor: palette.primary1Color,
        backgroundColor: palette.primary1Color,
        height: '1px',
        border: 'none',
        marginLeft: '15px',
        marginRight: '15px',
        marginTop: '0px',
      },
      button:{
        float: 'right',
        margin: '10px',
        backgroundColor : palette.primary1Color,
        color: Colors.white
      },
      meterbutton:{
        backgroundColor : palette.primary1Color,
        color: Colors.white
      },
      cardtext1:{
        color: textColor(palette.primary1Color),
        paddingTop: '0px'
      },
      cardtext2:{
        color: textColor(palette.primary1Color),
        paddingTop: '0px',
      },
      cardtext3:{
        color: textColor(palette.primary3Color),
      },
      cardtext4:{
        color: textColor(Colors.darkBlack),
        paddingTop: '0px',
        paddingBottom: '3px',
        fontSize: '14px'
      }
    };

    let mergedRootStyles = Object.assign({},style,fiksuvesiStyle.cardbase);//ImmutabilityHelper.merge(style, fiksuvesiStyle.cardbase);
    console.log("mergetRootStyles",mergedRootStyles);
    this.setState ({
      muiTheme: this.context.muiTheme,
      mergedRootStyles: mergedRootStyles,
      palette: palette
    });
  }

}
