import React from 'react';
//import Resizable from './Resizable';
import {getScale} from '../utils/scale';
import Currencies from '../utils/currencies';
import {gettext} from 'i18n';

export default class YearGraph extends React.Component {

  render() {
    return (
        <canvas ref="canvas" className="graph year-graph" />
    );
  }

  componentDidMount() {
    this.resize();
  }

  componentDidUpdate() {
    this.resize();
  }

  resize() {
    let canvas = this.refs.canvas;
    this.pixelDensity =  2;
    canvas.width = canvas.clientWidth * this.pixelDensity;
    canvas.height = canvas.clientHeight * this.pixelDensity;
    this.updateCanvas();
  }

  updateCanvas() {
    const curr = this.props.report && this.props.report.currency || 'eur';
    let canvas = this.refs.canvas;
    if (!canvas) return;

    let c = canvas.getContext('2d');
    const canvasWidth = canvas.width / this.pixelDensity;
    const canvasHeight = canvas.height / this.pixelDensity;

    var color1="#0D429B";
    var positiveColor="#77cb5c";
    var negativeColor="#e1484b";
    var bgColor="#ffffff";
    // var bgColor="#fffff0";
    var fontColor="#777777";
    var ltGrayColor="#e9e9e9";
    var grayColor="#e0e0e0";
    var darkGrayColor="b2b2b2";

    c.save();
    c.scale(this.pixelDensity, this.pixelDensity);
    c.clearRect(0, 0, canvasWidth, canvasHeight);
    c.fillStyle=bgColor;
    c.beginPath();
    c.rect(0,0,canvasWidth,canvasHeight);
    c.fill();

    var min=null;
    var max=null;
    if(this.props.data&&this.props.data.length){
      for(var i=0;i<this.props.data.length;i++){
        var d=this.props.data[i];
        if(d.values&&d.values.length)for(var j=0;j<d.values.length;j++){
          if(min===null||d.values[j]<min)min=d.values[j];
          if(max===null||d.values[j]>max)max=d.values[j];
        }
      }
    }
    if(!isNaN(this.props.min))min=this.props.min;
    if(!isNaN(this.props.max))max=this.props.max;
    var scale=getScale(min,max,5);
    while(scale.max>max){
      scale.ticks-=1;
      scale.max-=scale.spacing;
    }
    var margins = this.props.data.length+3;

    var scaleWidth=0;
    if(this.props.unit)scaleWidth=c.measureText(this.props.unit).width;
    var i=scale.min;
    while((i<=scale.max&&scale.max>scale.min)||(i>=scale.max&&scale.max<scale.min)){
      var t=c.measureText(i+"");
      if(t>scaleWidth)scaleWidth=t;
      i+=scale.spacing;
    }
    var fontSize=12;
    var fontSize15=fontSize;
    var margin=Math.floor(canvasWidth/(this.props.data.length*1.5+margins));
    var itemWidth=Math.floor((canvasWidth-scaleWidth-margin*margins)/this.props.data.length);

    c.transform(1,0,0,1,itemWidth/2,0);

    c.textBaseline="middle";
    c.textAlign="right";
    c.font = "bold 12px sans-serif";
    c.fillStyle=fontColor;
    var hmargin=canvasHeight/(scale.ticks+3); // unit, ticks, names, legends
    c.fillText(Currencies(curr),margin+scaleWidth,hmargin/2);
    c.font = "12px sans-serif";
    c.strokeStyle=ltGrayColor;
    c.lineWidth=1;
    var i=scale.max;
    var th=1.5*hmargin;
    var t=scale.ticks;
    while(t){
      c.fillText(i+"",margin+scaleWidth,th);
      c.beginPath();
      c.moveTo(margin+scaleWidth,th);
      c.lineTo(canvasWidth-2*margin,th);
      c.stroke();
      i-=scale.spacing;
      t--;
      th+=hmargin;
    }

    var pxMin = canvasHeight-hmargin*2.5;
    var pxMax = hmargin*1.5;
    var pxValue = (pxMax-pxMin)/(scale.max-scale.min);

    c.textAlign="center";

    for(var i=0;i<this.props.data.length;i++){
      var d=this.props.data[i];
      if(d.name){
        c.font = "12px sans-serif";
        c.fillStyle=fontColor;
        c.fillText(d.name,2*margin+scaleWidth+i*(margin+itemWidth)+itemWidth/2,canvasHeight-hmargin*2);
      }
    }

    var m=0;
    c.lineWidth=this.props.lineWidth||2;
    c.strokeStyle=this.props.color1||color1;
    c.fillStyle=this.props.color1||color1;
    c.setLineDash(this.props.lineDash1||[8,8]);
    c.beginPath();
    for(var i=0;i<this.props.data.length;i++){
      var d=this.props.data[i];
      if(d.values&&(d.values[0]!==undefined&&d.values[0]!==null)){
        var tx=2*margin+scaleWidth+i*(margin+itemWidth)+itemWidth/2;
        var ty=pxMin+(d.values[0]-scale.min)*pxValue;
        if(!m)c.moveTo(tx,ty); else c.lineTo(tx,ty);
        m=1;
      }
    }
    c.stroke();
    for(var i=0;i<this.props.data.length;i++){
      var d=this.props.data[i];
      if(d.values&&(d.values[0]!==undefined&&d.values[0]!==null)){
        var tx=2*margin+scaleWidth+i*(margin+itemWidth)+itemWidth/2;
        var ty=pxMin+(d.values[0]-scale.min)*pxValue;
        c.beginPath();
        c.arc(tx,ty,fontSize/2,0,2*Math.PI);
        c.fill();
      }
    }
    var legends = [gettext('ui_before'),gettext('ui_now')];
    var tw=c.measureText(legends[0]).width+itemWidth+4;
    c.fillStyle=fontColor;
    c.beginPath();
    c.moveTo(canvasWidth/3-tw/2,canvasHeight-hmargin);
    c.lineTo(canvasWidth/3-tw/2+itemWidth,canvasHeight-hmargin);
    c.stroke();
    c.textAlign="left";
    c.fillText(legends[0],canvasWidth/3-tw/2+itemWidth+4,canvasHeight-hmargin);

    m=0;
    c.lineWidth=this.props.lineWidth||2;
    c.setLineDash(this.props.lineDash2||[]);

    // c.strokeStyle=positiveColor;
    tw=c.measureText(legends[1]).width+itemWidth+4;
    var g=c.createLinearGradient(canvasWidth/3*2-tw/2,0,canvasWidth/3*2-tw/2+itemWidth,0);
    g.addColorStop(0,negativeColor);
    g.addColorStop(0.4,negativeColor);
    g.addColorStop(0.6,positiveColor);
    g.addColorStop(1,positiveColor);
    c.strokeStyle=g;
    c.fillStyle=fontColor;
    c.beginPath();
    c.moveTo(canvasWidth/3*2-tw/2,canvasHeight-hmargin);
    c.lineTo(canvasWidth/3*2-tw/2+itemWidth,canvasHeight-hmargin);
    c.stroke();
    c.textAlign="left";
    c.fillText(legends[1],canvasWidth*2/3-tw/2+itemWidth+4,canvasHeight-hmargin);

    g=c.createLinearGradient(0,0,canvasWidth,0);
    g.addColorStop(0,"rgba(127,127,127,0)");

    c.beginPath();
    for(var i=0;i<this.props.data.length;i++){
      var d=this.props.data[i];
      if(d.values&&!isNaN(d.values[1])){
        var tx=2*margin+scaleWidth+i*(margin+itemWidth)+itemWidth/2;
        var ty=pxMin+(d.values[1]-scale.min)*pxValue;
        if(!m)c.moveTo(tx,ty); else c.lineTo(tx,ty);
        m=1;
        if(!isNaN(d.values[0])){
          if(d.values[1]>d.values[0])g.addColorStop(tx/canvasWidth,negativeColor);
          else if(d.values[1]<d.values[0])g.addColorStop(tx/canvasWidth,positiveColor)
            }
      }
    }
    g.addColorStop(1,"rgba(127,127,127,0)");
    c.strokeStyle=g;
    c.stroke();
    c.textAlign="center";
    c.font="bold 12px sans-serif";
    for(var i=0;i<this.props.data.length;i++){
      var d=this.props.data[i];
      if(d.values&&!isNaN(d.values[1])){
        var tx=2*margin+scaleWidth+i*(margin+itemWidth)+itemWidth/2;
        var ty=pxMin+(d.values[1]-scale.min)*pxValue;
        if(!isNaN(d.values[0])){
          if(d.values[1]>d.values[0]){
            c.fillStyle=negativeColor;
            c.fillText("+"+Math.round(d.values[1]-d.values[0]),tx,ty-fontSize15);
          }
          else if(d.values[1]<d.values[0]){
            c.fillStyle=positiveColor;
            c.fillText(Math.round(d.values[1]-d.values[0]),tx,ty+fontSize15);
          }
        }
        c.beginPath();
        c.arc(tx,ty,fontSize/2,0,2*Math.PI);
        c.fill();
      }
    }
    c.font="12px sans-serif";
    c.fillStyle=fontColor;
    c.fillText(gettext('annual5percentincrease'), canvasWidth / 2, canvasHeight - 10)
    c.restore();
  }
}

YearGraph.defaultProps = {
  data: [
    {name:"2015", values:[20000,15200]},
    {name:"2016", values:[41000,31160]},
    {name:"2017", values:[63050,47918]},
    {name:"2018", values:[86202,65513]},
    {name:"2019", values:[110512,83989]}
  ],
  unit: Currencies('eur'),
  legends:[gettext('ui_before'),gettext('ui_now')]
};


