import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//const ThemeManager = require('material-ui/lib/styles/theme-manager');
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
//import MyRawTheme from './Theme';
import { gettext } from 'i18n';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default class ConfirmationDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //  let muiTheme = getMuiTheme(MyRawTheme);
    this.dialogData = null;
    this.state = {
      //  muiTheme: muiTheme,
      dialogOpen: false,
    };
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  open(data) {
    this.dialogData = data;
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true })
  }

  dialogData() {
    return this.data;
  }

  close() {
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false })
  }


  render() {

    /*let dialogStyle = {
      //backgroundColor: this.state.muiTheme.rawTheme.palette.accent2Color,
      border: '1px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      borderTop: '4px solid ' + this.state.muiTheme.rawTheme.palette.primary1Color,
      padding: '10px'
    }*/


    let title = this.props.title ? this.props.title : gettext('ui_confirm_title');
    let confirmbuttontext = this.props.confirmButtonText ? this.props.confirmButtonText : gettext('ui_confirm_button');
    let secondaryconfirmbuttontext = this.props.secondaryConfirmButtonText ? this.props.secondaryConfirmButtonText : gettext('ui_confirm_secondary_button');

    return (

      <Dialog
        open={this.state.dialogOpen}
      >
        <DialogTitle>{title.toUpperCase()}</DialogTitle>
        <DialogContent>

          {this.props.text}
          <DialogActions>
            <Button
              color='primary'
              onClick={() => (this.close())} >
              {gettext('ui_cancel')}
            </Button>
            {this.props.onSecondaryAccepted && <Button
              color='secondary'
              onClick={() => (this.props.onSecondaryAccepted())}>{secondaryconfirmbuttontext}</Button>}
            <Button
              color='secondary'
              onClick={() => (this.props.onAccepted())} >
              {confirmbuttontext}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );

  }
}


