import React from 'react';

import { CardRow } from '../../components/ReportViewRows';
import { Graphs, Tables, Alarms } from './ReportViewPartials';

import PremiseInfoCard from '../../components/PremiseInfoCard';
import ConsumptionChangesCard from '../../components/ConsumptionChangesCard';
import CostEffectCard from '../../components/CostEffectCard';
import CumulativeChangeCard from '../../components/CumulativeChangeCard';

export default class AutomaticReportView extends React.Component {

  render() {
    const data = this.props;
    const report = data.selectedReport;
    const premise = data.premise;

    return (
      <div>
        <CardRow>
          <PremiseInfoCard premiseData={premise} />
          {
            report &&
            <ConsumptionChangesCard
              serviceType={premise.serviceType}
              reportData={report}
              premiseId={premise._id}
              manual={data.manualInput} />
          }
          {
            report &&
            <CostEffectCard reportData={report} />
          }
          {
            report && data.fiksuvesi &&
            <CumulativeChangeCard
              reportData={report}
              data={data.totalSavings}
              message={data.savingsError}
              serviceTakenUseDate={premise.installationDate} />
          }
        </CardRow>

        <Graphs {...data} />
        <Tables {...data} />
        <Alarms {...data} />
      </div>
    );
  }
}
