import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
let WCA
class WaterCompaniesActions {
  constructor() {
    this.generateActions('change');
  }

  query(q) {
    return function (dispatch) {
      var b = new UrlBuilder()
      return api.get(b.path('watercompanies').query(q)).then((res) => {
        dispatch(res.data);
      }).catch((err) => {
        console.error(err.stack);
        dispatch(null);
      });
    }
  }

  editWCompany(data) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('watercompanies'), data).then((res) => {
        return WCA.query();
      }).catch((err) => {
        console.error(err.stack);
        dispatch(null);
      })
    }
  }
}

WCA = alt.createActions(WaterCompaniesActions);
export default WCA;
