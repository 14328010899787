import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
import SelectBox from './SelectBox';
import AppStore from '../stores/AppStore';
import AppActions from '../actions/AppActions';
//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class LanguageSelectionDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.dialogData = null;
    this.state = {
      selectedLanguage: AppStore.getState().locale || 'en-us',
      dialogOpen: false,
    };
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }
  getChildContext() {
    return { /*muiTheme: this.state.muiTheme*/ };
  }

  open() {
    console.log('open call');
    this.setState({ dialogOpen: true });
  }

  close() {
    this.setState({ dialogOpen: false });
  }

  onAccepted() {
    AppActions.setLocale(this.state.selectedLanguage);
    this.setState({ dialogOpen: false });
  }
  onSelectionChange(value, id, selectBox) {
    this.setState({ selectedLanguage: value.value });
  }

  render() {
    let title = this.props.title ? this.props.title : gettext('Select Language');
    let confirmbuttontext = this.props.confirmButtonText ? this.props.confirmButtonText : gettext('ui_confirm_button')

    let selectablelanguages = [
      { value: 'en-us', label: 'English' },
      { value: 'fi-fi', label: 'Suomi' },
      //{ value: 'sv-se', label: 'Svenska' }
    ];

    return (

      <Dialog open={this.state.dialogOpen} maxWidth='sm' fullWidth={true}>
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div style={{minHeight:200}}>
            <SelectBox value={selectablelanguages.filter(({value})=>value===this.state.selectedLanguage)}
              placeholder={gettext('ui_choose_language')}
              name="selectedLanguage"
              label={gettext('ui_choose_language')}
              options={selectablelanguages}
              onSelectionChange={this.onSelectionChange.bind(this)}
              iconName='mdi-settings'
              clearable={false} />
          </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
            <Button onClick={() => (this.onAccepted())} >{confirmbuttontext}</Button>
        </DialogActions>
      </Dialog>
    );

  }
}


