"use strict";

import React from 'react';
import PropTypes, { bool } from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import ApiKeyStore from '../stores/ApiKeyStore';
import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import ApiKeyActions from '../actions/ApiKeyActions';
import SearchFilter from '../components/SearchFilter';
import { gettext } from 'i18n';
import _ from 'lodash';
import history from '../utils/history';
import TextInput from '../components/TextInput';
import SelectBox from '../components/SelectBox';
import AsyncSelectBox from '../components/AsyncSelectBox';
import ViewLoader from '../components/ViewLoader';
import moment from 'moment';
import MyRawTheme from '../components/Theme';
import ConfirmationDialog from '../components/ConfirmationDialog';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import GroupIcon from '@material-ui/icons/GroupWork';
import EventNoteIcon from '@material-ui/icons/EventNote';

// Remove following row and corresponding test expression from render()
// when you want to enable this feature permanently.
const SUPPORT_ALLPREMISES_CHECKBOX = false;

const accessRightsString = (rights) => {
  if(!_.isObject(rights)){
    return ''
  }

  return Object.keys(rights)
    .map(key => key + ': ' + rights[key].join(', ').toLowerCase())
    .join('; ');
};

@connectToStores
export default class ApiKeysView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme,
      sortParams: { sortBy: 'name', order: 'asc' },
      filters: {},
      dialogError: false,
      dialogOpen: false,
      showUsage:false,
      deleteConfirmationDialog:false
    };
    this.filters = {};
  }

  

  static getStores() {
    return [ApiKeyStore/*, LoginStore*/];
  }

  static getPropsFromStores() {
    return {
      ...ApiKeyStore.getState()
      //...LoginStore.getState()
    }
  }

  static propTypes = {
    apiKeys: PropTypes.array,
    newApiKey: PropTypes.object
  }

  componentDidMount() {
    //let theme = this.state.muiTheme;
    //theme.raisedButton.textColor = "#1A237E";
    //this.setState({ muiTheme: theme })
    this.initialQuery = setTimeout(() => {
      ApiKeyActions.query();
      ApiKeyActions.getPremises();
      ApiKeyActions.getApiPartners();
    }, 100);
  }
  _onDialogRemove(data) {
    this.refs.confirmationDialog.close();
    ApiKeyActions.removeApiKey(this.props.newApiKey);
    ApiKeyActions.change({});
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
  }

  _onDialogCancel() {
    ApiKeyActions.change({});
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
  }

  _onDialogDeletePress() {
    this.refs.confirmationDialog.open();

  }

  _onDialogSubmit() {
    //this.refs.dialog.dismiss();
    ApiKeyActions.editApiKey(this.props.newApiKey)
    ApiKeyActions.change({});
    this.setState({ dialogOpen: false });
  }

  openDialog(data) {
    //if (this.refs.dialog) {
    if (data) {
      ApiKeyActions.change(data);
    }
    else {
      ApiKeyActions.createNewApiKey();
    }
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
    //}
  }

  sortBy(sb, e) {
    if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({ sortParams: { sortBy: sb, order: "desc" } });
    } else {
      this.setState({ sortParams: { sortBy: sb, order: "asc" } });
    }
  }

  onChange(value, id) {
    ApiKeyActions.change({ [id]: value.value });
  }

  onPremiseChange(premiseId, value, key) {
    ApiKeyActions.premiseDataChange({ premiseId, value, key })
  }

  onChangeMandatory(value, id, textField) {
    this.props.apiKeys.forEach(item => {
      if (item.name === value) textField.setErrorText("Ei saa olla kahta samannimistä");
    })
    if (textField.state.errorText) this.setState({ dialogError: true });
    else this.setState({ dialogError: false });

    let valueItem={value};

    this.onChange(valueItem, id);
  }

  onSelectionChange(value, id, selectBox) {
    var v = {};
    v[id] = value.value;
    ApiKeyActions.change(v);
    if (selectBox.isMandatory() && !value) {
      selectBox.setErrorText(gettext('ui_pakollinen_kentta'));
    } else {
      selectBox.setErrorText(null)
    }
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = {};
    } 
    
    ApiKeyActions.clear()
    
    //Use only name in query, other filters is made in UI side
    let q = {};
    if (filters.name) {
      q.name = filters.name;
    }
    
    let usage = false
    if(filters.showlastUsed){
      usage=filters.showlastUsed
    }

    ApiKeyActions.query(q, usage);
    this.filters = filters;
    this.setState({ showUsage : usage });
  }

  onAddPremise(id) {
    ApiKeyActions.addPremiseToEdited(id.value);
  }

  onRemovePremise(id) {
    ApiKeyActions.removePremiseFromEdited(id);
  }

  addCsvReceiver() {
    const email = this.refs.csvReceiver.getValue()
    if (email && (!this.props.newApiKey.csvReceivers || (this.props.newApiKey.csvReceivers.indexOf(email) === -1))) {
      this.refs.csvReceiver.setErrorText('');
      ApiKeyActions.addCsvReceiver(this.refs.csvReceiver.getValue())
    } else {
      this.refs.csvReceiver.setErrorText('Sähköposti löytyy jo tai kenttä on tyhjä!');
    }
  }

  isAclSelected(key){
    let acl = this.props.newApiKey.acl
    switch (key) {
      case 'CBalarmsData':
        return acl.hasOwnProperty('alarms')
        case 'CBhourData':
        return acl.hasOwnProperty('data/hour')
        case 'CBminuteData':
          return acl.hasOwnProperty('data/minute')
        case 'CBmonthData':
          return acl.hasOwnProperty('data/month')
        case 'CBmetersData':
            return acl.hasOwnProperty('data/meters')
        case 'CBHistoryMeterData':
              return acl.hasOwnProperty('history/meter')    
        case 'CBApikeyUI':
          if( acl.hasOwnProperty('info/all') &&  acl.hasOwnProperty('info/id') && acl.hasOwnProperty('info/login')) {return true}
          return false
      default:
        return false
    }
  }

  handleAclChange(event){
    let acl = this.props.newApiKey.acl
    //this.setState({ [event.target.name]: event.target.checked });
    //Add
    if(event.target.name === 'CBalarmsData' && event.target.checked) {
      acl['alarms'] = ['GET']
    }
    if(event.target.name === 'CBhourData' && event.target.checked) {
      acl['data/hour'] = ['GET']
    }
    if(event.target.name === 'CBminuteData' && event.target.checked) {
      acl['data/minute'] = ['GET']
    }
    if(event.target.name === 'CBmonthData' && event.target.checked) {
      acl['data/month'] = ['GET']
    }
    if(event.target.name === 'CBmetersData' && event.target.checked) {
      acl['data/meters'] = ['GET']
    }
    if(event.target.name === 'CBHistoryMeterData' && event.target.checked) {
      acl['history/meter'] = ['GET']
    }
    if(event.target.name === 'CBApikeyUI' && event.target.checked) {
      acl['info/all'] = ['GET']
      acl['info/id'] = ['GET']
      acl['info/login'] = ['GET']
    }
    //delete
    if(event.target.name === 'CBalarmsData' && !event.target.checked) {
      delete acl['alarms']
    }
    if(event.target.name === 'CBhourData' && !event.target.checked) {
      delete acl['data/hour']
    }
    if(event.target.name === 'CBminuteData' && !event.target.checked) {
      delete acl['data/minute']
    }
    if(event.target.name === 'CBmonthData' && !event.target.checked) {
      delete acl['data/month']
    }
    if(event.target.name === 'CBmetersData' && !event.target.checked) {
      delete acl['data/meters']
    }
    if(event.target.name === 'CBHistoryMeterData' && !event.target.checked) {
      delete acl['history/meter']
    }
    if(event.target.name === 'CBApikeyUI' && !event.target.checked) {
      delete acl['info/all']
      delete acl['info/id']
      delete acl['info/login']
    }
    ApiKeyActions.change({ acl });
  };

  render() {

    const filters = [
      {
        type: 'name',
        prop: "name",
        operator: '%',
        text: gettext('ui_name'),
        value: ''
      },
      {
        type: 'premises',
        prop: "premises",
        operator: '%',
        text: gettext('ui_allowedPremises'),
        value: ''
      },
      {
        type: 'exportType',
        prop: "exportType",
        operator: '%',
        text: gettext('ui_export_type'),
        value: ''
      },
      {
        type: 'showlastUsed',
        prop: "showlastUsed",
        operator: '=',
        text: gettext('Show last used'),
        options:[{value:true, label:"true"}]
      }

    ];

    const dialogStyle = {
      backgroundColor: MyRawTheme.palette.accent2Color,
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    const selectablePremises = this.props.allPremises
      ? [] // Do not waste time to compute something which is not shown
      : this.props.premises
        // Filter out premises which have already been added
        .filter(p => this.props.newApiKey.premises.findIndex(x => x._id === p._id) < 0)
        // Map {_id, name} -> {value, label}
        .map(p => ({ value: p._id, label: p.name }));

    const selectableExportTypes = [
      { value: '-', label: "Ei automaattista datansiirtoa" },
      { value: 'haahtela', label: "Haahtela" },
      { value: 'fimx', label: "FIMX" },
      { value: 'tampuuri', label: "Tampuuri" },
      { value: 'hus', label: "HUS" },
      { value: 'enerkey', label: 'Enerkey' },
      { value: 'grandlund', label: 'Grandlund' },
      { value: 'fatman', label: 'Fatman' },
      { value: 'domus', label: 'Domus' },
      { value: 'premis', label: 'Premis' },
      { value: 'hsy', label: 'HSY' },
      { value: 'unes', label: 'Unes' },
      { value: 'csv-mail', label: 'csv-mail' },
      { value: 'eners', label: 'Eners' },
      { value: 'kleemola', label: 'Kleemola' },
      { value: 'json-tuntidata', label: 'JSON-tuntidata' },
      { value: 'siemens', label: 'Siemens' }
    ]

    let apiPartnersSelection = this.props.apiPartners ? 
    this.props.apiPartners.map(p => ({ value: p._id, label: p.name })) : []
    

    const filterAddPremise = (inputValue) => {
      const io = inputValue.toLowerCase()
      return _.filter(selectablePremises, function(i) { 
        return i.label.replace(/\s/g, '').toLowerCase().indexOf(io.replace(/\s/g, '')) > -1
      });
    };

    const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(filterAddPremise(inputValue));
      }, 1000);
    };

    const dialog = (
      <Dialog open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}>
        <DialogTitle>
          {gettext('ui_editApiKey').toUpperCase()}
        </DialogTitle>

        <DialogContent>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <TextInput
                type="text"
                defaultValue={this.props.newApiKey.name}
                id="name"
                required={true}
                validations={{ onlyText: true }}
                label={gettext('ui_name') + gettext('name_used_for_filename')}
                onChange={this.onChangeMandatory.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
            <SelectBox
                value={apiPartnersSelection.filter(({ value }) => value === this.props.newApiKey.apiPartner)}
                placeholder={gettext('Select Api Partner')}
                name="apiPartner"
                label={gettext('Api Partner')}
                options={apiPartnersSelection}
                onSelectionChange={this.onChange.bind(this)}
              />
            </div>
          </div>




          <div className="row">
            <div className="col-md-12 col-xs-12">
              <SelectBox
                value={selectableExportTypes.filter(({ value }) => value === this.props.newApiKey.exportType)}
                placeholder={gettext('ui_choose_export_type')}
                name="exportType"
                label={gettext('ui_export_type')}
                options={selectableExportTypes}
                onSelectionChange={this.onChange.bind(this)}
              />
            </div>
          </div>
          {this.props.newApiKey.exportType === 'haahtela' &&
            (<div className="row">
              <div className="col-md-12 col-xs-12">
                <TextInput
                  disabled={false}
                  defaultValue={this.props.newApiKey.haahtelaId}
                  id="haahtelaId"
                  type="text"
                  label={gettext('ui_haahtelaId')}
                  onChange={this.onChange.bind(this)}
                  style={{ margin: 0 }} />
              </div>
            </div>)
          }
          {this.props.newApiKey.exportType === 'csv-mail' &&
            (
              <div>
                <div className="row">
                  <div className="col-md-10 col-xs-10">
                    <TextInput
                      disabled={false}
                      ref="csvReceiver"
                      type="text"
                      label={gettext('ui_receiver')}
                      validations={{ email: true }}
                      style={{ margin: 0 }} />
                  </div>
                  <div className="col-md-2 col-xs-2">
                    <mui.FlatButton
                      label={gettext('ui_add').toUpperCase()}
                      style={{ marginTop: '15px' }}
                      onClick={() => { this.addCsvReceiver() }} />
                  </div>
                </div>
                {
                  this.props.newApiKey && this.props.newApiKey.csvReceivers &&
                  this.props.newApiKey.csvReceivers.map((email, i) => {
                    return (<div className="row" key={i} style={{ padding: '5px', paddingLeft: '15px', marginBottom: '10px', fontSize: '0.9em' }}>
                      <div className="col-md-8 col-xs-8">{email}</div>
                      <button style={{ lineHeight: '13px' }} onClick={() => ApiKeyActions.removeCsvReceiver(email)}>{gettext('ui_remove')}</button>
                    </div>)
                  })
                }

              </div>)
          }
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <TextInput
                disabled={true}
                value={this.props.newApiKey.key}
                id="token"
                label={gettext('ui_apiKey')}
                onChange={this.onChangeMandatory.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <TextInput
                disabled={true}
                value={accessRightsString(this.props.newApiKey.acl)}
                id="acl"
                label={gettext('ui_acl')}
                onChange={this.onChange.bind(this)}
              />
            </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
              <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.isAclSelected("CBmonthData")}
                      onChange={this.handleAclChange.bind(this)}
                      name="CBmonthData"
                      color="primary"
                    />
                  }
                  label={gettext('Month Data')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.isAclSelected("CBhourData")}
                      onChange={this.handleAclChange.bind(this)}
                      name="CBhourData"
                      color="primary"
                    />
                  }
                  label={gettext('Hour Data')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.isAclSelected("CBmetersData")}
                      onChange={this.handleAclChange.bind(this)}
                      name="CBmetersData"
                      color="primary"
                    />
                  }
                  label={gettext('Meters List')}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.isAclSelected("CBHistoryMeterData")}
                      onChange={this.handleAclChange.bind(this)}
                      name="CBHistoryMeterData"
                      color="primary"
                    />
                  }
                  label={gettext('Meter History')}
                />
                
                 <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.isAclSelected("CBApikeyUI")}
                      onChange={this.handleAclChange.bind(this)}
                      name="CBApikeyUI"
                      color="primary"
                    />
                  }
                  label={gettext('Apikey Info UI')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.isAclSelected("CBalarmsData")}
                      onChange={this.handleAclChange.bind(this)}
                      name="CBalarmsData"
                      color="secondary"
                    />
                  }
                  label={gettext('Alarms Data')}
                />
              </div>
          </div>
          {
            !this.props.newApiKey.allPremises &&
            <div>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <AsyncSelectBox
                    label={gettext('ui_addPremise')}
                    placeholder={gettext('ui_choosePremise')}
                    name="premise"
                    options={selectablePremises}
                    onChange={id => this.onAddPremise(id)}
                    loadOptions={loadOptions}
                  />
                </div>
              </div>
              <table className="table">
                <tbody>
                  {
                    (this.props.newApiKey.premises || []).map(premise => (
                      <tr key={premise._id} align="center">
                        <td>{premise.name}</td>
                        {this.props.newApiKey.exportType === 'enerkey' &&
                          (<td>
                            <TextInput
                              disabled={false}
                              value={premise.enerkeyId}
                              id="enerkeyId"
                              label={gettext('ui_enerkeyId')}
                              onChange={this.onPremiseChange.bind(this, premise._id)}
                              style={{ margin: 0 }} />
                          </td>)
                        }
                        <td>
                          <Button color='secondary' onClick={() => this.onRemovePremise(premise._id)}>{gettext('ui_remove')}</Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          }
          {
            SUPPORT_ALLPREMISES_CHECKBOX &&
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <hr />
                <mui.Checkbox
                  defaultChecked={this.props.allPremises}
                  onCheck={(event, checked) => this.onChange(checked, 'allPremises')}
                  label={gettext('ui_accessToAllPremises')}
                />
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onDialogDeletePress.bind(this)} color='secondary' >{gettext('ui_removeApiKey').toUpperCase()}</Button>
          <Button onClick={this._onDialogCancel.bind(this)} color='primary' >{gettext('ui_cancel').toUpperCase()}</Button>
          <Button disabled={this.state.dialogError} onClick={this._onDialogSubmit.bind(this)} ref="submit" color='primary' >{gettext('ui_submit').toUpperCase()}</Button>
        </DialogActions>
      </Dialog>
    )

    let sortedRows = _.orderBy(
      this.props.apiKeys,
      i => i[this.state.sortParams.sortBy] && i[this.state.sortParams.sortBy].toLowerCase(),
      [this.state.sortParams.order]
    );

    //Filter in ui side
    if (this.filters) {
      _.map(this.filters, (filter, key) => {
        /*if( key === 'name' ) {
         sortedRows =  _.filter(sortedRows, function(o) {
           if(!o['name']) return false;
           return o['name'].toString().toLowerCase().match(filter['$regex'].toLowerCase())
           });
        } else*/
        if (key === 'premises') {
          sortedRows = _.filter(sortedRows, function (o) {
            if (!o.premises) return false;
            for (var index = 0; index < o.premises.length; index++) {
              let d = o.premises[index].name ? o.premises[index].name.toString() : null;
              if (d && d.toLowerCase().match(filter['$regex'].toLowerCase())) {
                return true;
              }
            }
            return false;
          });
        } else if (key === 'exportType') {
          sortedRows = _.filter(sortedRows, function (o) {
            if (!o.exportType) return false;
            return o.exportType.toString().toLowerCase().match(filter['$regex'].toLowerCase())
          });
        }
      })
    }
     
    const showUsed = this.state.showUsage=== true ? true : false
    const rows = _.map(sortedRows, (u) => {
      let latestUse = u.latestUse ? moment(u.latestUse).format('DD.MM.YYYY hh:mm:ss'): null
      let old = u.latestUse ? moment().diff(moment(u.latestUse), 'hours') > 24*30 : true
      let warningText = u.latestUse ?  `Last used ${moment().diff(moment(u.latestUse), 'days') } days ago`: 'Used over 3 months ago or never'
      let icon = old ? <span data-tooltip={warningText} data-tooltip-position="bottom"><WarningIcon color='error' fontSize="small"></WarningIcon></span> :""
      
      let apiPartner = this.props.apiPartners && this.props.apiPartners.length ? _.findIndex(this.props.apiPartners, { '_id' : u.apiPartner}) : null
      let apiPartnerName = this.props.apiPartners && this.props.apiPartners[apiPartner] ?  this.props.apiPartners[apiPartner].name : '-'

      return (
        <tr className="pointer" key={u._id} onClick={() => this.openDialog(u)}>
          <td>{u.name}</td>
          <td>{apiPartnerName}</td>
          <td>{u.exportType}</td>
          <td>{u.key}</td>
          {showUsed && <td>{latestUse}<br/>{icon}</td>}
          <td>
            <AccessRights rights={u.acl} />
          </td>
          <td>
            <PremiseList premises={u.premises || []} />
          </td>
        </tr>
      )
    })


    const newApiKeyButton = (
      <div>
        {gettext('ui_add_new')}
      </div>
    );


    return (
      <div>
        <ConfirmationDialog ref="confirmationDialog"
          title={gettext('ui_remove') + ` ${this.props.newApiKey.name}`}
          text={gettext('Are You sure that You want remove the Api-key?')}
          confirmButtonText={gettext('ui_remove')}
          onAccepted={this._onDialogRemove.bind(this)} />

        <AppBar title={gettext('title_apiKeys')} />

        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
          <div className="row">
            <div className="col-xs-7 col-md-8" style={{ paddingRight: 0 }}><SearchFilter localStorage="apiKeyFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} /></div>
            <div className="col-xs-5 col-md-4 end-xs" style={{borderBottom: '3px solid grey' }}>

            <ButtonGroup disableElevation variant='outlined' size="small" >
            <Button startIcon={<GroupIcon/>}  onClick={() => history.push('/apipartners')}>
              {gettext('Partners')}
            </Button>
              <Button startIcon={<EventNoteIcon/>} 
                onClick={() => history.push(encodeURI(`/events?action=data_export&cols=[premiseName,apikeyName,timestamp,data]&title=Api-avaimet&start=${moment().startOf('month').format('YYYY-MM-DDT00')}`))}
              >{gettext('events').toUpperCase()}</Button>
              <Button startIcon={<AddIcon/>}  onClick={() => this.openDialog()}>{gettext('ui_add_new')}</Button>
              </ButtonGroup>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th onClick={this.sortBy.bind(this, 'name')}>{gettext('ui_name')}</th>
                <th onClick={this.sortBy.bind(this, 'apiPartner')}>{gettext('Api Partner')}</th>
                <th onClick={this.sortBy.bind(this, 'exportType')}>{gettext('ui_export_type')}</th>
                <th onClick={this.sortBy.bind(this, 'key')}>{gettext('ui_apiKey')}</th>
                {showUsed && <th onClick={this.sortBy.bind(this, 'latestUse')}>{gettext('used last')}</th>}
                <th onClick={this.sortBy.bind(this, 'acl')}>{gettext('ui_acl')}</th>
                <th onClick={this.sortBy.bind(this, 'premises')}>{gettext('ui_allowedPremises')}</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {this.props.apiKeys !== null ? null : <ViewLoader blue />}
        </div>
        {dialog}
      </div>
    );
  }
}

const AccessRights = (props) => (
  <div>
    {Object.keys(props.rights).map(key => (
      <div key={key}>{key}: {props.rights[key].join(', ').toLowerCase()}</div>
    ))}
  </div>
);

class PremiseList extends React.Component {

  static propTypes = {
    premises: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      showAll: false
    };
  }

  render() {
    const viewLimit = this.state.showAll ? Infinity : 3;
    const premises = this.props.premises.slice(0, viewLimit);
    const allVisible = this.props.premises.length === premises.length;
    const hasPremises = premises.length > 0;

    return (
      <div>
        {premises.map(premise => <div key={premise._id}>{premise.name}</div>)}
        {
          hasPremises && !allVisible &&
          <a href="#" onClick={(evt) => { evt.preventDefault(); evt.stopPropagation(); this.setState({ showAll: true }) }}>
            {gettext('ui_showAll')}
          </a>
        }
        {!hasPremises && '-'}
      </div>
    );
  }
}
