import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
import TextInput from './TextInput';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class EditCustomCalcDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.state = {
      //muiTheme: muiTheme,
      calculationType: '',
      fn: '',
      title: '',
      variableMap: {},
      calculation: {},
      dialogOpen: false,
    };
  }

  getChildContext() {
    return {};
  }

  open(title, calculationType, fn, variables) {
    const variableMap = {};
    variables.forEach((v) => {
      variableMap[v] = 0;
    });

    this.setState({ title, calculationType, fn, variableMap, dialogOpen: true });
    //this.refs.dialog.show();
    setTimeout(this.calculate, 100);
  }

  close = () => {
    this.setState({ fn: 'null', variableMap: {}, calculationType: '', title: '', calculation: {}, dialogOpen: false });
    //this.refs.dialog.dismiss();
  }

  calculate = () => {
    let fn = this.state.fn;

    let sort = Object.keys(this.state.variableMap).sort((a, b) => {
      if (a.indexOf('@') > -1 && b.indexOf('@') === -1) {
        return -1;
      }
      if (b.indexOf('@') > -1 && a.indexOf('@') === -1) {
        return 1;
      }
      return 0;
    });

    sort.forEach(v => {
      fn = fn.replace(v, this.state.variableMap[v]);
    });


    try {
      this.setState({ calculation: { result: eval(fn), fn } });
    } catch (e) {
      this.setState({ calculation: { error: e, fn } });
    }
  }

  onChange(value, id) {
    const variableMap = this.state.variableMap;
    variableMap[id] = value;
    this.setState({ variableMap });
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      this.calculate();
    }, 500);
  }

  onChangeFn = (value) => {
    this.setState({ fn: value })
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      this.calculate();
    }, 100);
  }

  render() {
    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    let title = this.state.title;

    const map = this.state.variableMap;

    return (
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
        <div >
          <div className="row" style={{ marginTop: '10px' }}>
            <div className="col-xs-12">
              <TextInput
                defaultValue={this.state.fn}
                id="fns.consumption.fn"
                inputType="textarea"
                label={gettext('Formula')}
                onChange={this.onChangeFn}
              />
            </div>
          </div>


          <h6>{gettext('Test calculation')}</h6>
          <div className="row" style={{ marginTop: '10px' }}>
            <div className="col-xs-12" style={{ margin: '15px 0' }}>
              {(this.state.calculation.error) && <span style={{ color: '#f44336', fontSize: '16px' }}>
                {`${this.state.calculation.error}\n${gettext('Check formula: ')}${this.state.calculation.fn}`}
              </span>
              }
              {(!this.state.calculation.error && this.state.calculation.fn) && <span style={{ color: '#8bc34a', fontSize: '16px' }}>
                {`${this.state.calculation.fn} = ${this.state.calculation.result}`}
              </span>}
            </div>
            {Object.keys(map).map((v, i) =>
              <div className="col-xs-12 col-md-6" key={`input-${i}`}>
                <div style={{ color: MyRawTheme.palette.primary2Color }}>{v}</div>
                <TextInput defaultValue={map[v]} required={false} id={v} onChange={this.onChange.bind(this)} />
              </div>
            )}
          </div>
          </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={this.close} >{gettext('ui_cancel')}</Button>
              <Button onClick={() => this.props.onAccepted(this.state.calculationType, this.state.fn)} >{gettext('ui_ok')}</Button>
          </DialogActions>
      </Dialog>
    );

  }
}

