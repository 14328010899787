'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import AppStore from '../stores/AppStore';
import LoginActions from '../actions/LoginActions';
//import getMuiTheme from 'material-ui/styles/getMuiTheme';
//const MyRawTheme = require('./Theme').default;
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PremisesView from '../views/PremisesView';
import ReportView from '../views/ReportView';
import NewPremiseView from '../views/NewPremiseView';
import WaterCompaniesView from '../views/WaterCompaniesView';
import HeatingCompaniesView from '../views/HeatingCompaniesView';
import UsersView from '../views/UsersView';
import ApiKeysView from '../views/ApiKeysView';
import LoginView from '../views/LoginView';
import AnalyzeView from '../views/AnalyzeView';
import AlarmsView2 from '../views/AlarmsView2';
import EventsView from '../views/EventsView';
import EventsView2 from '../views/EventsView2';
import DebugReportView from '../views/DebugReportView';
import ConfiguratorView from '../views/ConfiguratorView';
import DevicesView from '../views/DevicesView';
import DevicesContractEndView from '../views/DevicesContractEndView';
import TemperatureView from '../views/TemperatureView';
import restricted from '../utils/restricted';
import DeliveryStatusView from '../views/DeliveryStatusView';
import ApiPartnerView from '../views/ApiPartnerView';
import PremisesMissingDataView from '../views/PremisesMissingDataView';

//import '../normalize.css';
import '../styles/main.less';


import { HashRouter as Router, Route, Switch } from 'react-router-dom';

var slideLeft = {
  appear: {
    opacity: [1, 0],
    translateX: [0, '100%'],
    height: [0, 0]
  },
  enter: {
    opacity: [1, 0],
    translateX: [0, '100%'],
    height: [0, 0]
  },
  leave: {
    opacity: [0, 1],
    translateX: ['-100%', 0],
    height: [0, 0]
  }
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    location: PropTypes.object
  }

  componentWillMount() {
    LoginActions.loadLocalUser();
    let mql = window.matchMedia(`(min-width: 768px)`);
    mql.addListener(this.mediaQueryChanged.bind(this));

    let mql2 = window.matchMedia(`(min-width: 1024px)`);
    mql2.addListener(this.mediaQueryChanged.bind(this));

    this.setState({ mql: mql, mql2: mql2, mediaqueryResolution: mql.matches ? (mql2.matches ? 1024 : 768) : 0 });
    AppStore.listen((state) => this.setState({ locale: state.locale }));
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
    this.state.mql2.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged() {
    this.setState({ mediaqueryResolution: this.state.mql.matches ? (this.state.mql2.matches ? 1024 : 768) : 0 });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/premises/edit/:id" component={restricted(NewPremiseView)} />
          <Route path="/premises" component={restricted(PremisesView)} />
          <Route path="/report/:id/:date" component={restricted(ReportView)} />
          <Route path="/report/:id" component={restricted(ReportView)} />
          <Route path="/newpremise/:id" component={restricted(NewPremiseView)} />
          <Route path="/newpremise" component={restricted(NewPremiseView)} />
          <Route path="/watercompanies" component={restricted(WaterCompaniesView)} />
          <Route path="/heatingcompanies" component={restricted(HeatingCompaniesView)} />
          <Route path="/analyze/:id" component={restricted(AnalyzeView)} />
          <Route path="/analyze/:id/:device" component={restricted(AnalyzeView)} />
          <Route path="/debugreport/:deviceId" component={restricted(DebugReportView)} />
          <Route path="/debugreport" component={restricted(DebugReportView)} />
          <Route path="/alarms/:id" component={restricted(AlarmsView2)} />
          <Route path="/alarms" component={restricted(AlarmsView2)} />
          <Route path="/events" component={restricted(EventsView)} />
          <Route path="/events2" component={restricted(EventsView2)} />
          <Route path="/users" component={restricted(UsersView)} />
          <Route path="/apikeys" component={restricted(ApiKeysView)} />
          <Route exact path='/login' component={LoginView} />
          <Route path="/login/callback/:token" component={LoginView} />
          <Route exact path="/login/callback" component={LoginView} />
          <Route path="/configurator" component={restricted(ConfiguratorView)} />
          <Route path="/devices/:filter" component={restricted(DevicesView)} />
          <Route path="/devices" component={restricted(DevicesView)} />
          <Route path="/unreturneddevices" component={restricted(DevicesContractEndView)} />
          <Route path="/temperature" component={restricted(TemperatureView)} />
          <Route exact path="/" component={restricted(PremisesView)} />
          <Route exact path="/deliverystatus" component={restricted(DeliveryStatusView)} />
          <Route exact path="/apipartners" component={restricted(ApiPartnerView)} />
          <Route exact path="/unvalidpremises" component={restricted(PremisesMissingDataView)} />
          
          
        </Switch>
      </Router>
    );
  }
}


