import alt from '../utils/alt';
import AppActions from '../actions/AppActions';
import {setLocale} from 'i18n';

var localStorage = {};
if ('localStorage' in window && window['localStorage'] !== null) {
  localStorage = window.localStorage;
}

class AppStore {

  constructor() {
    this.bindActions(AppActions);

    this.locale = localStorage.locale || 'en-us';
    this.localeList = [
      { payload: 'en-us', text: 'English' },
      { payload: 'fi-fi', text: 'Suomi' }
    ];
    if(this.locale === 'en-us' || this.locale === 'fi-fi' || this.locale === 'sv-se' || this.locale === 'nl-nl' ){
      setLocale(this.locale);
    }
  }

  onSetLocale(locale) {
    if(locale === 'en-us' || locale === 'fi-fi' || locale === 'sv-se' || this.locale === 'nl-nl' ){
      localStorage.locale = locale;
      this.setState({locale});
      setLocale(locale);
    }
  }
}

//module.exports = (alt.createStore(AppStore));
export default alt.createStore(AppStore);
