import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'

let AA
class Alarmv2Actions {
  constructor() {
    this.generateActions('resetAlarmData', 'resetSingleAlarmdata');
  }

  _getActiveAlarms(premiseId, types, premiseFilters) {
    let b = new UrlBuilder();
    console.log("_getActiveAlarms");
    let param = {};
    if (types) param.name = { $in: types }
    param.end = null;
    return api.post(b.path('alarmsv2/get'), { premiseId, query: param, sort: { start: -1 }, premiseFilters })
  }

  _getActiveAlarmsFilter(premiseId,types,premiseFilters, filter){
    let b = new UrlBuilder();
    filter.end=null;
    return api.post(b.path('alarmsv2/get'), {premiseId, query: filter, sort: {start: -1}, premiseFilters});
  }

  _getPassiveAlarms(premiseId, types, limit, premiseFilters) {
    let b = new UrlBuilder();
    console.log("_getPassiveAlarms");
    let param = {};
    if (types) param.name = { $in: types }
    param.end = { $ne: null };
    return api.post(b.path('alarmsv2/get'), { premiseId, query: param, sort: { end: -1 }, limit: limit, premiseFilters })
  }

  _getPassiveAlarmsFilter(premiseId,types,limit,premiseFilters,filter){
    let b= new UrlBuilder();
    return api.post(b.path('alarmsv2/get'), { premiseId, query: filter, sort: { end: -1 }, limit: limit, premiseFilters })
  }

  getInitialAlarms(premiseId, types, limit, premiseFilters = {}) {
    return async (dispatch) => {
      try {
        console.time("getInitialAlarms");
        let res1 = await this._getActiveAlarms(premiseId,types,premiseFilters);
        let res2 = await this._getPassiveAlarms(premiseId,types,limit,premiseFilters);
        let data={ activeAlarms:res1.data, passiveAlarms:res2.data}
        dispatch(data);
        console.timeEnd("getInitialAlarms");
      }catch(err){
        console.log("getInitialAlarms catch:", err);
        dispatch({data:{},err});
      }
      /*let promises = [this._getActiveAlarms(premiseId, types, premiseFilters), this._getPassiveAlarms(premiseId, types, limit, premiseFilters)];
      return Promise.all(promises)
        .then(res => {
          console.log("getInitialAlarms all promises done!",res);
          let data = { activeAlarms: res[0].data, passiveAlarms: res[1].data };
          console.log("getInitialAlarms:", data);
          dispatch(data);
        }).catch(err => {
          console.error(err.stack);
          console.log("getInitialAlarms catch:", err);
          dispatch(null);
        })*/
    }
  }

  getFilteredAlarms(premiseId, types, limit, premiseFilters, filter){
    return async(dispatch)=>{
      try{
        console.time("getFilteredAlarms");
        console.log("getFilteredAlarms:",filter);
        let res1= await this._getActiveAlarmsFilter(premiseId,types,premiseFilters,filter);
        let res2= await this._getPassiveAlarmsFilter(premiseId,types,limit,premiseFilters,filter);
        dispatch({activeAlarms:res1.data, passiveAlarms:res2.data})
        console.timeEnd("getFilteredAlarms");
      }catch(err){
        console.error("getFilteredAlarms error:",err);
      }
    }
  }

  getAlarmHistory(premiseId, types, limit) {
    return (dispatch) => {
      return this._getPassiveAlarms(premiseId, types, limit)
        .then(res => {
          dispatch(res);
        }).catch(err => {
          console.error(err.stack);
          dispatch(null);
        })
    }
  }

  ackAlarm(alarmId, premiseId, text, types, limit) {
    return (dispatch) => {
      var b = new UrlBuilder();
      return api.post(b.path('alarmsv2/ack'), { _id: alarmId, text: text })
        .then(res => {
          return this.getInitialAlarms(premiseId, types, limit);
        }).catch(err => {
          console.error(err.stack);
          dispatch(null);
        });
    }
  }

  sendEmail(opts, alarmId, action) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('alarmsv2/email'), { opts: opts, alarmId: alarmId, action: action })
        .then(res => {
          dispatch(res);
        }).catch(err => {
          console.error(err.stack);
          dispatch(null);
        });
    }
  }

  singleAlarmdata(deviceId, type, start) {
    return function (dispatch) {
      let b = new UrlBuilder();
      if (!deviceId || !type) return
      let param = { deviceId: deviceId, date: { $gte: start.format('YYYY-MM-DD') } };
      console.log("singleAlarmdata",param);
      return api.post(b.path('dailydata2/get'), param)
        .then(res => {
          dispatch(res);
        }).catch(err => {
          console.error(err.stack);
          dispatch(null);
        })
    }
  }

  alarmsReceivers(deviceId, priority) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('alarmsv2/receivers'), { sourceId: deviceId, priority })
        .then(res => {
          dispatch({ data: res.data, priority });
        }).catch(err => {
          console.error(err.stack);
          dispatch({ data: [], priority });
        });
    }
  }

  mailReadStatus(alarmId) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('alarmsv2/mailreadstatus'), { alarmId })
        .then(res => {
          dispatch(res.data);
        }).catch(err => {
          console.error(err.stack);
          dispatch(null);
        });
    }
  }

   getDeviceHourArray(deviceId, country="FIN"){
    return async function (dispatch) {
      try {
      var b = new UrlBuilder();
      let res = await api.get(b.path('alarmsv2/getnightlhyhourrundata?deviceId='+deviceId+'&country='+ country))
      dispatch(res.data)
      } catch (error) {
        console.log(error.message)
        dispatch(null);
      }
      
    }

  }
}



AA = (alt.createActions(Alarmv2Actions));
export default AA;
