import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import CardBase from './CardBase';
import {gettext} from 'i18n';
import ViewLoader from './ViewLoader';
import Utils from '../utils/utils';
import Currencies from '../utils/currencies';
//let {Colors, Spacing, Typography} = mui.Styles;

export default class CumulativeChangeCard extends CardBase {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.setInitialStyles();
  }

  render() {
    const curr = this.props.reportData && this.props.reportData.currency || 'eur';

    let values = this.props.data;
    let mergedRootStyles = this.state.mergedRootStyles;

    let cardtextGood = Object.assign({}, mergedRootStyles.cardtext1);
    let cardtextBad = Object.assign({}, cardtextGood);
    cardtextGood.color = this.state.palette.primary3Color;
    cardtextBad.color = this.state.palette.accent1Color;

    const row = (label, value, unit, isNeutral) => {
      let style = mergedRootStyles.cardtext1
      if (!isNeutral) style = value < 0 ? cardtextGood : cardtextBad
      return (
        <mui.CardText className="clearfix" style={Object.assign({lineHeight:'30px', padding: '10px 15px'}, mergedRootStyles.cardtext1)}>{label.toUpperCase()}
          <span style={Object.assign({float:'right'}, style)}><span style={{fontSize:'20px'}}>{value !== undefined && value !== null && !isNaN(value) ? value : '-'}</span> {unit}</span>
        </mui.CardText>
      );
    }

    const msg = this.props.message;

    return (
      <div style={mergedRootStyles.cardSize}>
        <mui.Card style={mergedRootStyles}>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
            <div style={mergedRootStyles.cardSize}>
              <div style={mergedRootStyles.headerSize}>
                <span style={mergedRootStyles.title}>{gettext('title_change_since_introduction').toUpperCase()}</span>
              </div>
              <hr style={mergedRootStyles.line}/>
              {msg && <mui.CardText style={cardtextGood}>{msg}<ViewLoader blue noMargins /></mui.CardText>}
              {!msg && values && row(gettext('ui_as_percentages'), values.changePercent, '%')}
              {!msg && values && row(gettext('ui_as_euros'), -values.changeEuro, Currencies(curr))}
              {!msg && values && row(gettext('ui_as_consumption'), values.change, gettext('unit_m3'))}
              {
                this.props.serviceTakenUseDate &&
                <mui.CardText style={{...mergedRootStyles.cardtext4, textAlign: 'center', marginTop: '20px'}}>
                  {gettext('Since taking the service into use in %s', Utils.displayDate(this.props.serviceTakenUseDate))}
                </mui.CardText>
              }
            </div>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
        </mui.Card>
      </div>
    );

  }
}
