import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import connectToStores from 'alt-utils/lib/connectToStores';
import AnalyzeActions from '../actions/AnalyzeActions';
import AnalyzeStore from '../stores/AnalyzeStore'
import Utils from '../utils/utils';
var { Table, TableBody, TableHeader, TableRow, TableHeaderColumn, TableRowColumn, TextField  } = mui;
import Button from '@material-ui/core/Button';
import moment from 'moment';
var _page = 0;
import ViewLoader from './ViewLoader';
import { gettext } from 'i18n';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

//new material ui

import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';
import Icon from '@material-ui/core/Icon';

////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
const MyRawTheme = require('./Theme');

const styles = {
  avatar: {
    margin: 10,
  },
  autoAvatar: {
    margin: 'auto',
    color: '#fff',
    backgroundColor: green[500],
  },
  editedAvatar: {
    margin:'auto',
    color:'#fff',
    backgroundColor: yellow[500],
  },
  imageIcon: {
    backgroundColor: blue[500],
    color: '#fff',
  },
  media: {
    //height: 244,
    //paddingTop: '56.25%', // 16:9
    //width:550,
  },
  manualValueInput: {
    fontSize: 25,
  }
};

@connectToStores
class PeriodDataTable extends React.Component {

  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme,
      dialogOpen:false,
      manualValueInput: '',
      editId:null
    };
  }

  static getStores() {
    return [AnalyzeStore];
  }

  static getPropsFromStores() {
    return AnalyzeStore.getState();
  }

  static propTypes = {
    deviceId: PropTypes.string,
  }

  static contextTypes = {
    muiTheme: PropTypes.object
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentWillMount() {
    let newMuiTheme = this.state.muiTheme
    newMuiTheme.tableHeaderColumn.textColor = this.state.muiTheme.rawTheme.palette.primary1Color;
    newMuiTheme.tableRow.stripeColor = this.state.muiTheme.rawTheme.palette.accent2Color;
    newMuiTheme.toggle.trackOffColor = this.state.muiTheme.rawTheme.palette.accent3Color;
    newMuiTheme.toggle.trackOnColor = this.state.muiTheme.rawTheme.palette.primary1Color;

    this.setState({
      muiTheme: newMuiTheme,
    });
    AnalyzeActions.getPeriodData(this.props.deviceId);
  }

  reCalculate(start, end) {
    AnalyzeActions.reCalculatePeriodData(this.props.deviceId, start, end);
    AnalyzeActions.resetPeriodData()
    setTimeout(
      ()=>{AnalyzeActions.getPeriodData(this.props.deviceId);},5000)
  }

  rowClicked(row){
    this.setState({ dialogOpen: true, editId:row._id, editTime:row.endTime, manualInputValue:row.last  })
  }

  dialogSave(){
    let last = this.state.manualInputValue;
    let deviceId = this.props.deviceId
    let _id = this.state.editId
    if (last === "" || last===null || deviceId===null || _id === null ){
      this.setState({ dialogOpen: false });
      return;
    }
  console.log(deviceId, last, _id)
  this.setState({ dialogOpen: false, editId:null, editTime:null, manualInputValue:''  })
  AnalyzeActions.editPeriodData(_id, deviceId, last )
  }

  render() {
    const { classes } = this.props;
    if (!this.props.periodData || this.props.loading) {
      return <ViewLoader />;
    }
    var timeToEdit = (this.state && this.state.editTime) ?  Utils.getDateString(this.state.editTime) : '';
    var dialog = (
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={this.state.dialogOpen}>
        <DialogContent>
          <div className="row">
            <div className="col-md-5">
              <CardHeader
                title={gettext('Set value')}
                subheader={timeToEdit}
              />
              <TextField
                id="valueInput"
                label={gettext('set value')}
                value={this.state.manualInputValue}
                className={classes.manualValueInput}
                onChange={(e)=>{
                  this.setState({manualInputValue:e.target.value});
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({ dialogOpen: false });}}>{gettext('ui_cancel')}</Button>
          <Button disabled={this.props.role === 'admin' ? false: true } color="primary" onClick={()=>{this.dialogSave()}}>{gettext('ui_accept')}</Button>
        </DialogActions>
      </Dialog>
    )

    var items = this.props.periodData;
    let latest = this.props.periodData && this.props.periodData[0] ? this.props.periodData[0].endTime :'-' 
    let recalcFrom = this.props.periodData && this.props.periodData[1] ? moment(this.props.periodData[1].startTime).add(1,'days').hours(3).toISOString() : moment().add(-14,'days').toISOString()
    let recalcFrom14 = moment().add(-14,'days').toISOString()
    let now = moment().add(-2,'hour').toISOString()
    let imageWidth
    if (window.innerWidth > 768) {
      imageWidth = 550;
    } else {
      imageWidth = 250;
    }
    
    return (
      <div>
      
      <div>
        <div>
        <Button disabled={this.props.role === 'admin' ? false: true } variant="contained" color="secondary" onClick={()=> {this.reCalculate(recalcFrom, now)}}>
          recalculate from latest
        </Button><span style={{fontSize: 10, paddingLeft:'5px'}} >{`${Utils.getDateString(recalcFrom)} -> ${Utils.getDateString(now)}`}</span>
        </div>
        <div style={{paddingTop:'5px'}}>
        <Button disabled={this.props.role === 'admin' ? false: true } variant="contained" color="secondary" onClick={()=> {this.reCalculate(recalcFrom14, now)}}>
          recalculate 14 days
        </Button><span style={{fontSize: 10, paddingLeft:'5px'}} >{`${Utils.getDateString(recalcFrom14)} -> ${Utils.getDateString(now)}`}</span>
        </div>
      </div>

            

        <Table onRowSelection={(row)=>{this.rowClicked(items[row]) }} >
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn style={{ textAlign: 'center' }}><h3>{gettext('ui_timestamp')}</h3></TableHeaderColumn>
              <TableHeaderColumn style={{ textAlign: 'center' }}><h3>{gettext('ui_meterValue')}</h3></TableHeaderColumn>
              <TableHeaderColumn style={{ textAlign: 'center' }}><h3>{gettext('ui_consumption')}</h3></TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} stripedRows={true} showRowHover={true}>
            {items.map((item, i) => {
              var datetime = Utils.getDateString(item.endTime);
              var bg = "";
              var valueString = ((item.last === undefined) ? "" : item.last + " m³");
              var runString = ((item.last === undefined) ? "" : _.round(item.run*1000,1) + " l");
              return (
                <TableRow className={bg} key={i}>
                  <TableRowColumn style={{ textAlign: "center", cursor: "pointer" }}>{datetime}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: "center", cursor: "pointer" }}>{valueString}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: "center", cursor: "pointer" }}>{runString}</TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>{dialog}</div>
    );
  }
}

export default withStyles(styles)(PeriodDataTable);