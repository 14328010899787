import alt from "../utils/alt";
import ApiPartnerActions from "../actions/ApiPartnerActions";
import _ from 'lodash';

class ApiPartnerStore {
  constructor() {
    this.bindActions(ApiPartnerActions);
    this.state = {
      apiPartners: [],
      newApiPartner: { },
      error:null
    };
  }

  onQuery(res) {
    this.setState({ apiPartners: res.data, error:res.error });
  }

  onChange(obj) {
    if (_.isEmpty(obj)) {
      this.state.newApiPartner = { }
    } else {
      _.assign(this.state.newApiPartner, obj)
    }
    this.setState({ newApiPartner: this.state.newApiPartner });
  }

  onCreateNewApiPartnerKey(payload) {
    if (payload.error) {
      this.error = payload.error
      return
    }
    this.setState({newApiPartner:{ partnerKey: payload.key, partnerType:'DataTransferPartner', language:'en-us' }});
  }
}

export default alt.createStore(ApiPartnerStore);

