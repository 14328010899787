"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import PremisesStore from '../stores/PremisesStore';
import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import SearchBar from '../components/SearchBar';
import PremiseActions from '../actions/PremiseActions';
//import { PropTypes} from 'react-proptypes'
import {gettext} from 'i18n';
// import _ from 'lodash';
import Moment from 'moment';
import history from '../utils/history';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
const MyRawTheme = require('../components/Theme');

import CircularProgress from '@material-ui/core/CircularProgress';

const EVENTS_INPAGE = 50;


@connectToStores
export default class EventsView2 extends BaseView {

  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme,
      activePage: 1,
    };
  }

  static getStores() {
    return [PremisesStore];
  }

  static getPropsFromStores() {
    return {
      ...PremisesStore.getState()
    }
  }

  static propTypes = {
    events: PropTypes.array
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
    //history: PropTypes.any.isRequired
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentWillMount() {
    const q = this.props.location.query;
    if (q) {
      if (q.search) {
        this.setState({ search: q.search })
      }

      setTimeout(() => this.refresh());
    }
  }

  componentWillUnmount() {
    PremiseActions.resetEvents();
  }

  isObject(obj) {
    return obj === Object(obj);
  }

  eventRows(cols) {
    if (this.props.events.length < 1) return;
    let array = [];
    this.props.events.forEach(e => {
      let temp = (
        <tr key={e._id} >
          {cols.map((col, i) => {
            let data = '';
            let style = {}, onClick = null;
            if (this.isObject(e[col]) || Array.isArray(e[col])) {
              data = JSON.stringify(e[col], null, 4).split(/"|{|}/g);
              if (data.indexOf('err') > -1) style.color = '#EB3E0F';
              if (data.indexOf('warning') > -1) style.color = '#EBB90F';
              style.width = '100%';
            } else if (col === 'timestamp') {
              data = Moment(e[col]).format('DD.MM.YYYY HH:mm');
              style.whiteSpace = 'nowrap';
            } else if (col.indexOf('.') > -1) {
              let arr = col.split('.');
              data = e;
              arr.forEach(o => {
                data = data[o];
              })
            } else if (col === 'premiseName') {
              data = e[col];
              onClick = () => history.pushState(null, '/premises/edit/' + e.premiseId);
              style.whiteSpace = 'nowrap';
              style.color = 'blue';
              style.cursor = 'pointer';
            } else {
              data = e[col];
              style.whiteSpace = 'nowrap';
            }
            return (<td key={i} onClick={onClick} style={style}>{data}</td>);
          })
          }
        </tr>
      )
      array.push(temp);
    })
    return array;
  }

  parseArray(str) {
    let temp = str, arr = [];
    temp = temp.replace(/\[|\]/g, '');
    arr = temp.split(',');
    return arr;
  }

  refresh(page) {
    let q = this.props.location.query;
    if (!q) q = {};

    q.search = this.state.search;
    const p = page ? page : this.state.activePage;
    q.skip = (p - 1) * EVENTS_INPAGE;
    q.limit = EVENTS_INPAGE;

    PremiseActions.getEvents2(q);
  }

  onSearchChange = (e) => {
    let page = this.state.activePage;
    if(!e.target.value) {
      page = 0;
    }

    clearTimeout(this._timer);
    this.setState({search: e.target.value})
    this._timer = setTimeout(() =>{
      this.refresh(page);
    }, 1000);
  }

  nextPage = () => {
    const activePage = this.state.activePage + 1;
    this.setState({ activePage });
    this.refresh(activePage);
  }

  previousPage = () => {
    const activePage = this.state.activePage - 1;
    this.setState({ activePage });
    this.refresh(activePage);
  }

  render() {
    let eventsTable = [];

    const cols = this.props.location.query.cols ? this.parseArray(this.props.location.query.cols) : ['action', 'timestamp', 'source', 'data'];
    if (this.props.events && this.props.events.length > 0) {
      eventsTable = (
        <table className="table events">
          <thead>
            <tr>
              {cols.map((col, i) => (<th key={i}>{ gettext(col) }</th>)) }
            </tr>
          </thead>
          <tbody>
            {this.eventRows(cols) }
          </tbody>
        </table>
      )
    } else if (this.props.events && this.props.events.length === 0) {
      eventsTable = <h3 style={{ textAlign: 'center' }}>{gettext('no_events')}</h3>;
    } else {
      eventsTable = <div style={{ marginLeft: '48vw' }}> <CircularProgress mode="indeterminate" /> </div>;
    }

    let title = this.props.location.query.title ? `${gettext('ui_events')} - ${this.props.location.query.title} ` : gettext('ui_events');

    return (
      <div className="container">
        <AppBar title={title}  backBtn/>
        <div>
          <SearchBar onChange={this.onSearchChange} disabled={this.props.loading}/>
          {this.props.loading && <div className="row center-xs" ><CircularProgress /></div>}
          {!this.props.loading && <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">{eventsTable}</div>}
          {!this.props.loading && <div className="row end-xs" >
            {this.state.activePage > 1 && <mui.FlatButton label={gettext('Previous').toUpperCase()} onClick={this.previousPage}/>}
            {this.state.activePage < (this.props.count / EVENTS_INPAGE) && <mui.FlatButton label={gettext('Next').toUpperCase()} onClick={this.nextPage} />}
          </div>}
        </div>
      </div>)
  }

}
