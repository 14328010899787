"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import DeviceStore from '../stores/DeviceStore';
//import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import deviceActions from '../actions/DeviceActions';
import SearchFilter from '../components/SearchFilter';
//import { PropTypes} from 'react-proptypes'
import { gettext } from 'i18n';
import _ from 'lodash';
import ViewLoader from '../components/ViewLoader';
import LoginStore from '../stores/LoginStore';
import moment from 'moment';
//import InfoDialog from '../components/InfoDialog';
//import history from '../utils/history';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

@connectToStores
class DevicesView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme: muiTheme,
      errors: {},
      device: null,
      dialogOpen: false,
      filter:'returned'
    };
  }

  static getStores() {
    return [LoginStore, DeviceStore];
  }

  static getPropsFromStores() {
    return {
      ...DeviceStore.getState(),
      ...LoginStore.getState()
    }
  }

  static propTypes = {
    devices: PropTypes.array,
  }

  componentWillMount() {
    //this.initialQuery = setTimeout(deviceActions.getDevices, 100);
    console.log(this.props.match)
    if (this.props.match && this.props.match.params && this.props.match.params.filter) {
      deviceActions.getDevices(this.props.match.params.filter)
      this.setState({ filter:this.props.match.params.filter });
    } else{
      deviceActions.getDevices(this.state.filter)
    }
    deviceActions.getAllPremiseNames()
  }

  loadDeviceEvents(device) {
    this.setState({ device, dialogOpen: true });
    deviceActions.getDeviceEvents(device)
    //this.refs.deviceInfoDialog.open()

  }

  render() {
    let devices = this.props && this.props.devices ? this.props.devices : [];
    let eventsTable = [];

    if (this.props.role === 'admin') {

    }



    if (this.state.device && this.props.events[this.state.device] && this.props.events[this.state.device].length > 0) {
      var eventrows = _.map(this.props.events[this.state.device], (d) => {
        let premise = d.premiseId && this.props.premiseNames[d.premiseId] || '-'
        let actionInfo = null
        let premiseprefix = ''
        if (d.action === 'move_device') {
          premise = d.data.toPremise ? this.props.premiseNames[d.data.toPremise] : '--'
          premiseprefix = gettext('TO premise: ')
        } else if (d.action === 'device-premise-change' && d.data.oldPremiseId) {
          premise = d.data.oldPremiseId ? this.props.premiseNames[d.data.oldPremiseId] : '--'
          premiseprefix = gettext('FROM premise: ')
        } else if (d.action === 'device-premise-change' && d.data.newPremiseId) {
          premise = d.data.newPremiseId ? this.props.premiseNames[d.data.newPremiseId] : '--'
          premiseprefix = gettext('TO premise: ')
        } else if (d.action === 'device-replace') {
          actionInfo = `replaced by ${d.data.deviceId}`
        }


        return (
          <tr className="pointer" key={d._id}
            onClick={() => window.open(`#/events?&cols=[action,timestamp,source,data]&title=${premise}&premiseId=${d.premiseId}`)} >
            <td>{moment(d.timestamp).format('LLL')}</td>
            <td>{d.action}{actionInfo && <div>{actionInfo}</div>}</td>
            <td><i>{premiseprefix}</i>{premise}</td>
            <td>{d.source || '-'}</td>
          </tr>
        )
      })

      eventsTable = (
        <table className="table events">
          <thead>
            <tr><th>{gettext('timestamp')}</th><th>{gettext('action')}</th><th>{gettext('premise')}</th><th>{gettext('source')}</th></tr>
          </thead>
          <tbody>
            {eventrows}
          </tbody>
        </table>
      )
    } else if (this.state.device && this.props.events[this.state.device] && this.props.events[this.state.device].length === 0) {
      eventsTable = <h3 style={{ textAlign: 'center' }}>{gettext('no_events')}</h3>;
    }

    var rows = _.map(devices, (u) => {
      let installed = u.installed ? "installed" : "uninstalled";
      if (u.name === 'replaced') installed = 'replaced'

      return (
        <tr className="pointer" key={u._id} onClick={() => this.loadDeviceEvents(u.deviceId)} >
          <td>{u.deviceId}</td>
          <td>{installed}</td>
          <td>{u && u.events && u.events[0] && u.events[0].updated ? moment(u.events[0].updated).format('LLL') : '-'}</td>
          <td>{u && u.events && u.events[0] && u.events[0].state ? u.events[0].state : '-'}</td>
          <td>{u.name}</td>

        </tr>
      )
    })

    return (
      <div>
        <AppBar title={gettext('ui_devices') + " (" + gettext(this.state.filter) + ")"} />
        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
          <table className="table">
            <thead>
              <tr>
                <th>{gettext('id')}</th>
                <th>{gettext('status')}</th>
                <th>{gettext('ui_timestamp')}</th>
                <th>{gettext('lifecycle state')}</th>
                <th>{gettext('lifecycle name')}</th>

              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {rows.length ? null : <ViewLoader blue />}
        </div>
        <Dialog open={this.state.dialogOpen}
          maxWidth='md'
          fullWidth={true}
          >
          <DialogTitle>
            {this.state.device || 'Laite'}
          </DialogTitle>
          <DialogContent>
            {eventsTable}
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={()=>this.setState({dialogOpen:false})}>{gettext('ui_close')}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DevicesView;