'use strict';

//import 'date-fns';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//import Select from 'react-select';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/ClearRounded';
import MomentFnsUtils from '@date-io/moment';
import AppStore from '../stores/AppStore';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { gettext } from 'i18n';

class FilterItem extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object,
    addFilterValue: PropTypes.func,
    onRemoveClick: PropTypes.func,
    addFilterItem: PropTypes.func,
    addDateFilter: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.item.value || null,
      added: false,
      locale: AppStore.getState().locale
    };
  }

  componentDidMount() {
    if (this.state.value) {
      this.setState({ added: true });
    }
    if (this.props.item.options && this.state.value === null) {
      this.setState({
        value: this.props.item.options[0] && this.props.item.options[0].value,
        added: true
      });
    }
  }

  sendFilterChange(value) {
    if (this.props.item.onFilterChange) {
      this.props.item.onFilterChange(value);
      this.props.updateFilter(value);
    } else {
      this.props.addFilterValue(value);
    }
  }

  handleKeyPress(e) {
    if (e.which === 13) { this.refs.textfield.blur() }
  }

  onChange() {
    var value = this.refs.textfield.getValue();
    setTimeout(() => { if (this.state) this.setState({ value: value, added: true }) }, 300);
    this.sendFilterChange(value);
  }

  onSelectChange(e) {
    const value = e.target.value;
    this.setState({ value, added: true });
    let sendValue=value;
    if(typeof(value)==='object') {
      sendValue=value.value
    }
    this.sendFilterChange(sendValue);
  }

  dateDismiss() {
    this.setState({ added: true });
  }

  iconHandle() {
    this.setState({ value: this.refs.textfield.getValue() })
  }

  render() {
    let filterStyle = null;
    let style = {
      filterW: {
        color: 'grey',
        margin: '4px',
        padding: '2px',
        fontSize: '16px',
        borderRadius: '5px',
        backgroundColor: 'white',
        border: '1px solid ' + this.context.muiTheme.rawTheme.palette.primary1Color,
        align: 'center',
        //maxHeight: '35px'
      },
      filterB: {
        color: 'black',
        margin: '4px',
        padding: '2px',
        fontSize: '16px',
        borderRadius: '5px',
        backgroundColor: this.context.muiTheme.rawTheme.palette.accent1Color,
        border: '1px solid ' + this.context.muiTheme.rawTheme.palette.primary1Color,
        align: 'center',
        //maxHeight: '35px'
      },
      textArea: {
        width: '30px',
        height: '32px'
      },
      textFieldStyle: {
        width: '90px',
        height: '70%',
        lineHeight: '32px'
      }
    };
    let field = null, icon = null;

    let iconButtonStyle = {
      padding: '0px',
      height: '35px',
      marginLeft: '15px'
    }

    if (this.props.item.component !== 'number') {
      style.textArea.width = '100px';
    }

    if (this.state.value && this.state.added || this.state.added) {
      if (this.props.item.options) {
        const menuItems = this.props.item.options.map((item) => {
          return <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>
        });
        field = (
          <Select
            ref="select"
            clearable={false}
            onChange={this.onSelectChange.bind(this)}
            value={this.state.value}>
            {menuItems}
          </Select>
        );
      } else if (this.props.item.component === 'datePicker') {
        field = (
          /*<DatePicker
            autoOk={true}
            hintText={gettext('ui_date')}
            inputStyle={{ color: 'white' }}
            textFieldStyle={style.textFieldStyle}
            style={{ display: 'inline-block' }}
            onFocus={() => this.setState({ added: false })} />*/

          <DatePicker
            margin="normal"
            autoOk={true}
            onChange={this.props.addDateFilter}
            value={this.props.item.value}
          />

        );
      } else {
        field = (
          <mui.TextField
            ref="textfield"
            inputStyle={{ color: 'white' }}
            style={style.textArea}
            defaultValue={this.props.item.value}
            onChange={this.iconHandle.bind(this)}
            onFocus={() => this.setState({ added: false })} />
        );
      }
      icon = (
        <DeleteIcon onClick={this.props.onRemoveClick} />
      );
      filterStyle = style.filterB;
    } else if (this.state.value) {
      if (this.props.item.component === 'datePicker') {
        field = (
          <mui.DatePicker
            autoOk={true}
            hintText="Ajankohta"
            inputStyle={{ color: 'white' }}
            textFieldStyle={style.textFieldStyle}
            style={{ display: 'inline-block' }} />
        );
      } else {
        field = (
          <mui.TextField
            ref="textfield"
            inputStyle={{ color: 'black' }}
            style={style.textArea}
            defaultValue={this.props.item.value}
            onBlur={this.onChange.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
            onChange={this.iconHandle.bind(this)}
            onFocus={() => this.setState({ value: null, added: false })} />
        );
      }
      icon = (
        <mui.IconButton
          tabIndex={-1}
          iconClassName="mdi mdi-checkbox-marked-circle"
          iconStyle={{ color: "green" }}
          style={iconButtonStyle}
          onClick={this.onChange.bind(this)} />
      );
      filterStyle = style.filterW;
    } else {
      if (this.props.item.options) {
        const menuItems = this.props.item.options.map((item) => {
          return <MenuItem value={item} key={item.value} >{item.label}</MenuItem>
        });
        field = (
          <Select
            ref="select"
            options={this.props.item.options}
            clearable={false}
            onChange={this.onSelectChange.bind(this)}
            value={this.state.value} >
            {menuItems}
          </Select>
        );
      } else if (this.props.item.component === 'datePicker') {
        console.log("DatePicker",this.props.item);
        field = (
          /*<mui.DatePicker
            onChange={this.props.addDateFilter}
            onDismiss={this.dateDismiss.bind(this)}
            autoOk={true}
            hintText="Ajankohta"
            textFieldStyle={style.textFieldStyle}
            style={{ display: 'inline-block' }} />*/
            <DatePicker
              margin="normal"
              autoOk={true}
              label={!this.props.item.value?'ajankohta':''}
              value={this.props.item.value?this.props.item.value:null}
              onChange={this.props.addDateFilter}
            />
        );
      } else {
        field = (
          <mui.TextField
            ref="textfield"
            inputStyle={{ textColor: 'black' }}
            defaultValue={this.props.item.value}
            onBlur={this.onChange.bind(this)}
            onChange={this.iconHandle.bind(this)}
            onFocus={() => this.setState({ value: null, added: false })}
            style={style.textArea} />
        );
      }
      icon = (
        /*<mui.IconButton
          tabIndex={-1}
          iconClassName="mdi mdi-close-circle"
          iconStyle={{ color: this.context.muiTheme.rawTheme.palette.primary1Color }}
          style={iconButtonStyle}
          onClick={this.props.onRemoveClick} />
      */
        <DeleteIcon onClick={this.props.onRemoveClick} />
      );
      filterStyle = style.filterW;
    }

    return (
      <div className={"box filter-item" + (this.state.added ? " filter-item-added" : "")} style={filterStyle}>
      <MuiPickersUtilsProvider locale={this.state.locale} utils={MomentFnsUtils}>
          <span style={{ marginLeft: '5px', marginRight: '7px' }}>
            {this.props.item.text}
          </span>
          {field}
          {icon}
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default FilterItem;