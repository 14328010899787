import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
//import connectToStores from 'alt-utils/lib/connectToStores';

import { gettext } from 'i18n';
import _ from 'lodash';
//import { Checkbox } from 'material-ui';
import TextInput from './TextInput';
import utils from '../utils/utils';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';


//var extraMails = [];
//var extraMailsSend = false;


class SendAlarmDialogV2 extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme: muiTheme,
      emails: "",
      editMessage: false,
      dialogOpen: false,
      extraMailsSend:false,
      extraMails:[],
    };
  }

  static contextTypes = {
    //muiTheme: PropTypes.object
  }

  componentWillMount() {
  }

  open() {
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
  }

  close() {
    //this.refs.dialog.dismiss();
    this.setState({ emails: "", editMessage: false, message: null, subject: null, dialogOpen: false });
    setTimeout(() => {
      this.props.onClose();
    })
  }

  onAlarmForward() {
    let receivers = [];
    if (this.props.interestGroups) {
      receivers = _.map(_.filter(this.props.interestGroups, 'sendReport'), 'email');
    }
    if (this.state.extraMailsSend) {
      let mails = this.state.emails.split(',');
      mails.map((e) => {
        if (e.trim().length > 3) {
          receivers.push(e.trim());
        }
      });
    }

    if (this.props.onAlarmForward && receivers.length > 0) {

      let sendHistory = this.props.sendHistory ? _.orderBy(this.props.sendHistory, ['time'], ['desc']) : [];
      let firstRoutedMessage = sendHistory.length ? _.findLast(sendHistory, ['state', this.props.data.state]) : null;
      let defaultSubject = firstRoutedMessage ? firstRoutedMessage.subject : this.props.data.subject;
      let defaultMessage = firstRoutedMessage ? firstRoutedMessage.text : this.props.data.message;

      let message, subject;
      if (this.state.editMessage) {
        subject = this.refs.subject.getValue();
        message = this.refs.message.getValue();
      } else {
        subject = this.state.subject ? this.state.subject : defaultSubject;
        message = this.state.message ? this.state.message : defaultMessage;
      }

      this.props.onAlarmForward(receivers, subject, message);
      this.setState({ emails: "", editMessage: false, message: null, subject: null });
    }
  }

  onChangeEmail(value, id, textField) {
    let mails = value.split(',');
    let error = '';
    mails.map((e) => {
      if (!utils.validateEmail(e.trim())) {
        error = error + ' ' + e.trim();
      }
    });
    if (error.length > 3) {
      textField.setErrorText(gettext('ui_check_email') + error);
    } else {
      textField.setErrorText(null);
    }
    this.setState({ emails: value });
  }


  render() {

    let sendHistory = this.props.sendHistory ? _.orderBy(this.props.sendHistory, ['time'], ['desc']) : [];
    let firstRoutedMessage = sendHistory.length ? _.findLast(sendHistory, ['state', this.props.data.state]) : null;
    let defaultSubject = firstRoutedMessage ? firstRoutedMessage.subject : this.props.data.subject;
    let defaultMessage = firstRoutedMessage ? firstRoutedMessage.text : this.props.data.message;
    let mailReadStatuses = this.props.mailReadStatus ? this.props.mailReadStatus : [];

    const subject = this.state.subject ? this.state.subject : defaultSubject;
    const message = this.state.message ? this.state.message : defaultMessage;
    let dialogStyle = {
      //backgroundColor: MyRawTheme.palette.accent2Color,
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '2px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    let input = (<TextInput iconName="mdi-account-plus" value={this.state.emails} id="emails" onChange={this.onChangeEmail.bind(this)} />)
    let self = this;
    return (

      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {gettext('ui_forward_alarm').toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div style={dialogStyle}>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <List>
                  {self.props.interestGroups.length && self.props.interestGroups.map(function (x, i) {
                    if (!x) return
                    return (<ListItem key={x + i} >
                      <Checkbox defaultChecked={x.sendReport} onChange={(e, checked) => {self.props.interestGroups[i].sendReport = checked; }} color='primary' />
                      <ListItemText primary={x.name} secondary={x.email} />
                    </ListItem>)
                  })
                  }
                  <ListItem key="freeText" alignItems="flex-start">
                    <Checkbox style={{ top: '40px' }} defaultChecked={this.state.extraMailsSend} onChange={(e, checked) => {
                      this.setState({extraMailsSend:true});
                    }} color='primary' />
                    <ListItemText primary={gettext('ui_email_comma_delimitted')} secondary={input} />
                  </ListItem>
                </List>
              </div>
            </div>

            <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '15px' }}>
              {gettext('ui_email_message').toUpperCase()}
            </span>

            <div style={dialogStyle}>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  {!this.state.editMessage && <h4>{subject}</h4>}
                  {this.state.editMessage && <TextInput ref="subject" iconName="mdi-email" label={gettext('ui_subject')} defaultValue={subject} />}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 col-xs-12">
                  {!this.state.editMessage && <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: message }} />}
                  {this.state.editMessage && <TextInput inputType="textarea" rows="9" iconName="mdi-message" label={gettext('ui_message')}
                    defaultValue={message} />}
                </div>
              </div>
              <div className="row">
                {!this.state.editMessage && <div className="col-md-2">
                  <Button variant='outlined' onClick={() => this.setState({ editMessage: true })} >{gettext('ui_edit')}</Button>
                </div>}
                {this.state.editMessage && <div className="col-md-2">
                  <Button onClick={() =>
                    this.setState({
                      editMessage: false,
                      message: this.refs.message.getValue(),
                      subject: this.refs.subject.getValue()
                    })} 
                    variant='outlined' >{gettext('Preview')}</Button>
                </div>}
              </div>
            </div>
          </div>

          <div className="row end-xs" style={{ padding: '20px' }}>
            <div className="col-md-2">

            </div>
            <div className="col-md-2">

            </div>
          </div>

          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '15px' }}>
            {gettext('ui_event_history').toUpperCase()}
          </span>

          <div style={dialogStyle}>
            <div className="row send-history">
              <div className="col-md-2 col-xs-2 header">{gettext('ui_pvm')}</div>
              <div className="col-md-1 col-xs-1 header">{gettext('Tapa')}</div>
              <div className="col-md-1 col-xs-1 header">{gettext('status')}</div>
              <div className="col-md-3 col-xs-3 header">{gettext('ui_made_by')}</div>
              <div className="col-md-4 col-xs-4 header">{gettext('ui_receivers')}</div>
              <div className="col-md-1 col-xs-1 header">{gettext('open')}</div>
            </div>
            {sendHistory && sendHistory.map(function (x, i) {
              var addresses = x.to.split(',').map(function (address, index) {
                return <p key={index}>{address}</p>;
              });
              console.log(x)
              let readItemIndex = _.findIndex(mailReadStatuses, ['routingId', x.routingId]);
              let readStatus = (readItemIndex > -1) && mailReadStatuses[readItemIndex] && mailReadStatuses[readItemIndex].read ? mailReadStatuses[readItemIndex].read : '-';
              //console.log(mailReadStatuses[readItemIndex])
              return (
                <div key={i} className="row middle-xs send-history" style={{ marginTop: '3px', marginBottom: '3px', borderBottom: '1px solid #ccc' }}>
                  <div className="col-md-2 col-xs-2 data" style={{ fontSize: '12px' }}>{utils.getDateString(x.time)}</div>
                  <div className="col-md-1 col-xs-1 data" style={{ fontSize: '12px' }}>{gettext(x.type)}</div>
                  <div className="col-md-1 col-xs-1 data" style={{ fontSize: '12px' }}>{gettext(x.status)}</div>
                  <div className="col-md-3 col-xs-3 data" style={{ fontSize: '12px' }}>{x.from}</div>
                  <div className="col-md-4 col-xs-4 data" style={{ fontSize: '12px' }}>{addresses}</div>
                  <div className="col-md-1 col-xs-1 data" style={{ fontSize: '12px' }}>{readStatus}</div>
                </div>
              )
            })
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => (this.close())} >{gettext('ui_close')}</Button>
          <Button onClick={() => (this.onAlarmForward())} >{gettext('ui_send')}</Button>
        </DialogActions>
      </Dialog>
    );

  }
}

export default SendAlarmDialogV2;