import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import { gettext } from 'i18n';

import ViewLoader from '../components/ViewLoader';

//import PremiseActions from '../actions/PremiseActions';
import ReportStore from '../stores/ReportStore';
import ReportActions from '../actions/ReportActions';
import LoginStore from '../stores/LoginStore';
//import history from '../utils/history'
import _ from 'lodash';
import moment from 'moment';
import Utils from '../utils/utils';
//import UrlBuilder from '../utils/urlbuilder';
import Algorithms from '../utils/algorithms';
//import { PropTypes } from 'react-proptypes'

import AutomaticReport from './reportviews/AutomaticReportView';
import ManualInputReport from './reportviews/ManualInputReportView';
import SendPDFDialog from '../components/SendReportDialog';
import {RefreshIndicator} from 'material-ui';
import ConfirmationDialog from '../components/ConfirmationDialog';
import MonthSelectionDialog from '../components/MonthSelectionDialog';

import connectToStores from 'alt-utils/lib/connectToStores';
const MyRawTheme = require('../components/Theme');
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import history from '../utils/history';
import Snackbar from '@material-ui/core/Snackbar';

@connectToStores
export default class ReportView extends BaseView {
  static getStores() {
    return [ReportStore, LoginStore];
  }

  static getPropsFromStores() {
    return {
      eventHistory: ReportStore.getState().reportHistory,
      dialogError: ReportStore.getState().dialogError,
      ...LoginStore.getState()
    }
  }

  static contextTypes = {
    //history: PropTypes.any.isRequired
  }

  constructor(props) {
    super(props);
    var dt = new Date();
    dt = new Date(Date.UTC(dt.getFullYear(), dt.getMonth() - 1)).toISOString()
    this.state = {
      selectedReportDate: dt,
      muiTheme: getMuiTheme(MyRawTheme),
      generating: false,
      generatingReport: false,
      downloadingReport: false,
    };
  }

  componentDidMount() {
    console.log("ReportView-componentDidMount",this);
    this.reportStoreBinding = this.calculateReportData.bind(this);
    ReportStore.listen(this.reportStoreBinding);

    if (this.props && this.props.match) {
      let date = this.state.selectedReportDate
      if (this.props.match.params.date) { // server sends date in YYYY-DD
        date = this.props.match.params.date + '-01T00:00:00.000Z';
        this.setState({ selectedReportDate: date });
      }
      if (this.props.match.params.id) {
        //PremiseActions.queryById(this.props.params.id);
        console.log("ReportActions.query");
        ReportActions.query(this.props.match.params.id, date, { premiseId: this.props.match.params.id });
      }
    }
    //this.exported = false;
  }

  componentWillUnmount() {
    ReportStore.unlisten(this.reportStoreBinding);
    ReportActions.reset();
  }

  /*componentDidUpdate() {
    // If view is ready for PDF export call the PhantomJS callback
    if (!this.exported) {
      if (this.state.ready) {
        this.exported = true;
        Utils.releaseViewForExport('report');
      }
    }
  }*/

  formatDate(month, year) {
    return `${month}/${year}`;
  }

  /**
  * Calculates and transforms data for view components
  */
  calculateReportData(data) {
    data = data || this.state;
    let state = Object.assign({
      ready: false,
      totalSavings: null,
      savingsError: null,
      fiveYearCostEffect: null,
      fiveYearCostEffectError: null
    }, data);

    if (data.premise && data.reports && state.premise._id === this.props.match.params.id) {
      // "Shortcuts" for most important switches
      state.ready = true;
      Utils.releaseViewForExport('report');

      state.fiksuvesi = data.premise.serviceType === 'fiksuvesi';
      state.manualInput = data.premise.consumptionSource === 'manual';
      state.installDate = data.premise.installationDate;

      // Find selected date
      let reportDate = this.state.selectedReportDate || (data.reports.length && data.reports[0].date);
      state.selectedReport = reportDate ? data.reports.find((x) => Utils.datesEqualByMonth(x.date, reportDate)) : null;
      state.selectedReportDate = reportDate;
      state.consumptionIsSet = state.selectedReport && state.selectedReport.currentPeriod.consumption !== undefined && state.selectedReport.currentPeriod.consumption !== null;

      // Create 12 months of monthly consumption data
      state.monthlyConsumption =
        Algorithms.monthlyConsumption(data.reports, reportDate)
          .map((report) => {
            return {
              source: report,
              values: report.graphValues,
              meters: Algorithms.arrayOfObjsToObject(report.meters, 'id', 'value'),
              month: report.month,
              year: report.year
            }
          });

      //state.monthlyConsumption.map((x) => console.log(x.values));
      // Create monthly consumption table data
      state.monthlyConsumptionTable = {
        // Table head: Month names & years
        head: state.monthlyConsumption.map((x) => ({ month: Utils.shortMonthName(x.source.month), year: x.source.year })),
        // Table body
        history: state.monthlyConsumption.map((x) => Utils.displayFloat(x.values ? x.values[0] : null, 2)),
        current: state.monthlyConsumption.map((x) => Utils.displayFloat(x.values ? x.values[1] : null, 2)),
        change: state.monthlyConsumption.map((x) => Utils.displayFloat((x.values && x.values[1]) ? x.values[1] - x.values[0] : null, 2)),
        changePc: state.monthlyConsumption.map((x) => Utils.displayFloat((x.values && x.values[1]) ? 100 * ((x.values[1] / x.values[0]) - 1) : null, 0, '%'))
      };

      // #OPTIWATER-446
      let showSummary = true;
      for (var index = 0; index < state.monthlyConsumptionTable.current.length; index++) {
        if (state.monthlyConsumptionTable.current[index] === "--") {
          showSummary = false;
        }
      }

      for (var i = 0; i < state.monthlyConsumptionTable.history.length; i++) {
        if (state.monthlyConsumptionTable.history[i] === "--") {
          showSummary = false;
        }
      }

      if (showSummary) {
        state.monthlyConsumptionTable.head.push({ label: gettext('12 KK') });
        let historySum = _.sum(state.monthlyConsumption.map((x) => x.values ? x.values[0] : null));
        let currentSum = _.sum(state.monthlyConsumption.map((x) => x.values ? x.values[1] : null));
        state.monthlyConsumptionTable.history.push(Utils.displayFloat(historySum, 2));
        state.monthlyConsumptionTable.current.push(Utils.displayFloat(currentSum, 2));
        state.monthlyConsumptionTable.changePc.push(Utils.displayFloat((historySum && currentSum) ? 100 * ((currentSum / historySum) - 1) : null, 0, '%'));
        state.monthlyConsumptionTable.change.push(Utils.displayFloat((historySum && currentSum) ? currentSum - historySum : null, 2));
      }

      // Add meters to table data
      let meterIds = state.monthlyConsumption.reduce((ids, col) => {
        Object.keys(col.meters).forEach((meterId) => {
          if (ids.indexOf(meterId) < 0) {
            ids.push(meterId);
          }
        });
        return ids;
      }, []).sort();
      state.monthlyConsumptionTable.meters = meterIds.map((id) => {
        let r = {
          id: Utils.getMeterSerial(id, ReportStore.getState().premise.devicesinfo),
          values: state.monthlyConsumption.map((x) => Utils.displayFloat(x.meters[id]))
        }
        // add empty value for summary column
        if (r.values && showSummary) r.values.push(" ")
        return r;
      });

      // Calculate five year estimation
      if (state.fiksuvesi) {
        if (!data.premise.history || !data.premise.history.length) {
          state.fiveYearCostEffectError = gettext('ui_no_history_data');
        } else if (!data.premise.installationDate) {
          state.fiveYearCostEffectError = gettext('ui_no_installation_date');
        } else if (!data.totalSavings) {
          state.fiveYearCostEffectError = gettext('ui_waiting_savings_data');
        } else if (!state.selectedReport) {
          state.fiveYearCostEffectError = gettext('ui_no_report_data');
        } else {

          let changePercent, change, changeEuro;
          if (data.totalSavings) {
            let totalSavings = data.totalSavings
            changeEuro = totalSavings.changeEuro
            change = totalSavings.change
            changePercent = totalSavings.changePercent
          }

          state.fiveYearCostEffect = Algorithms.costEffectEstimation(
            changePercent,
            data.premise.history.map((x) => x.value),
            data.premise.installationDate,
            data.selectedReportDate,
            state.selectedReport.referencePeriod.waterprice,
            state.selectedReport.referencePeriod.energyprice
          ).map((d) => ({
            name: d.name,
            values: [d.reference, d.estimation]
          }));

          state.fiveYearCostEffectTable = {
            // Table head: Years
            head: state.fiveYearCostEffect.map((x) => x.name),
            // Table body
            history: state.fiveYearCostEffect.map((x) => Utils.displayFloat(x.values[0])),
            current: state.fiveYearCostEffect.map((x) => Utils.displayFloat(x.values[1])),
            change: state.fiveYearCostEffect.map((x) => Utils.displayFloat(x.values[1] - x.values[0])),
          };
        }
      }

      // Query totalSavings if not done already
      if (!state.fiksuvesi) {
        state.savingsError = gettext('ui_not_fiksuvesi_report');
      } else if (!state.installDate) {
        state.savingsError = gettext('ui_no_installation_date');
      } else if (state.reports && !reportDate) {
        state.savingsError = gettext('ui_no_report_date');
      } else {
        /*const installDateParam = moment(state.installDate).format('YYYY-MM');
        const reportDateParam = moment(reportDate).format('YYYY-MM');
        if (!state.totalSavings) {
          state.totalSavings = null;
          state.savingsError = gettext('ui_loading');
          //ReportActions.getSavings(this.props.params.id, installDateParam, reportDateParam);
        }*/
      }
    }

    const currentDate = state.selectedReportDate ? moment(state.selectedReportDate).format(' MM/YYYY') : '';
    state.title = gettext('title_raportti') + currentDate;

    this.updateMenu(state);
    this.setState(state);
  }

  updateMenu(state) {
    let dates = [];

    if (state.fiksuvesi && state.manualInput && state.installDate) {
      let now = moment.utc({ day: 1, minute: 1 });
      let date = (state.reports && state.reports.length) ? moment(state.reports[0].date) : now;
      if (now.isAfter(date)) {
        date = now;
      }
      let firstDate = moment(state.installDate).utcOffset(0).day(1).hour(0).minute(0).second(0);
      while (date.isAfter(firstDate)) {
        dates.push({ year: date.year(), month: date.month() + 1 });
        date.subtract(1, 'month');
      }
    } else {
      dates = state.reports ? state.reports.map((r) => ({ year: r.year, month: r.month })) : [];
    }

    const menuItems = dates.map((date, index) => ({
      label: this.formatDate(date.month, date.year),
      value: index,
      date
    }));

    const index = state.selectedReportDate ? menuItems.findIndex((x) => Utils.datesEqualByMonth(x.date, state.selectedReportDate)) || 0 : 0;
    console.log("selected index:",index);
    state.dropdownMenu = {
      items: menuItems,
      selectedIndex: menuItems.length ? index : undefined,
      label: gettext('ui_chooseDate'),
      emptyText: gettext('ui_noReports')
    };
  }

  onReportSelectionChange(item, action) {
    //console.log("onReportSelectionChange:",item,action);
    const menuItem=this.state.dropdownMenu.items.find((i)=>i.value===item.target.value);
    const date = menuItem.date; // object => {month: 1, year: 2016}
    const momentDate = moment({ year: date.year, month: date.month - 1 });

    const report = this.state.reports.find((r) => Utils.datesEqualByMonth(r.date, date));
    const installDate = moment(this.state.premise.installationDate).format('YYYY-MM-DD');
    const reportDate = moment(report ? report.date : momentDate).format('YYYY-MM');

    this.setState({ selectedReportDate: reportDate })
    ReportActions.totalSavings(this.props.match.params.id, installDate, reportDate)
  }

  onOpenSendPDFDialog() {
    this.refs.sendDialog.open();
    if (this.state.premise) {
      ReportActions.eventHistory(this.state.selectedReport._id);
    }
  }

  onSendReport(receiverlist) {
    ReportActions.resetErrors();
    ReportActions.sendReport(this.state.selectedReport._id, this.state.premise._id, receiverlist, (err) => {
      if (!err) {
        console.log('Mail Receivers', receiverlist);
        this.refs.sendDialog.close();
      }
    });
  }

  onDeleteReport() {
    console.log("onDeleteReport")
    console.log(this.state.selectedReport)
    this.refs.confirmationDialog.open();
  }


  deleteReportAccepted() {
    console.log("onDeleteReportAccepted")
    console.log(this.state.selectedReport)
    if (this.state.selectedReport && this.state.selectedReport._id) {
      ReportActions.deleteReport(this.state.selectedReport._id.toString());
    }

    if (this.props && this.props.params) {
      let date = this.state.selectedReportDate
      if (this.props.params.date) { // server sends date in YYYY-DD
        date = this.props.params.date + '-01T00:00:00.000Z';
        this.setState({ selectedReportDate: date });
      }
      if (this.props.match.params.id) {
        //PremiseActions.queryById(this.props.params.id);
        setTimeout(() => {
          ReportActions.query(this.props.match.params.id, this.state.selectedReportDate, { premiseId: this.props.match.params.id });
          this.setState({ generating: false })
        }, 3000)
      }
    }


    this.refs.confirmationDialog.close();
  }

  onCancelSendReport() {
    this.refs.sendDialog.close();
  }

  onGenerateMonthSelected(month) {
    console.log(month, this.props.match.params.id)
    this.refs.generateReportMonthSelectionDialog.close();
    ReportActions.generateReport(this.props.match.params.id, month)
    this.setState({ generatingReport: true })
    setTimeout(() => {
      ReportActions.query(this.props.match.params.id, this.state.selectedReportDate, { premiseId: this.props.match.params.id });
      this.setState({ generatingReport: false })
    }, 15000)
  }

  render() {
    // Show loader if the data is not ready
    if (!this.state.ready) {
      return (
        <div>
          <AppBar title={gettext('title_raportti')} />
          <ViewLoader color={this.getMuiTheme().rawTheme.palette.primary1Color} />
        </div>
      );
    }
    
    // Constants
    const premise = this.state.premise;
    const childProps = {
      ...this.state,
      location: this.props.location,
      onOpenAnalysis: () => {
        if (this.state.premise._id) {
          history.push('/analyze/' + this.state.premise._id);
        }
      }
    }
    const token = LoginStore.getState().token;

    const reportView = () => {
      if (this.state.manualInput) {
        return <ManualInputReport {...childProps} />;
      }
      return <AutomaticReport {...childProps} />;
    };

    let sendHistory = this.props.eventHistory ? this.props.eventHistory : [];
    let interestGroups = this.state.premise.interestGroups ? this.state.premise.interestGroups : [];
    interestGroups = _.filter(interestGroups, function (o) { return (o.reports !== undefined) ? o.reports : true });

    let fileList = null;
    let pdfFound = false;
    if (this.state && this.state.selectedReport && this.state.selectedReport.pdf) {
      fileList = Object.keys(this.state.selectedReport.pdf).map((key) => {
        pdfFound = true;
        return (<mui.RaisedButton
          key={key}
          style={{ marginRight: '10px' }}
          label={gettext('ui_download_pdf') + ' ' + key}
          //linkButton={true}
          href={this.state.selectedReport.pdf[key]} />)
      });
    }
    let generatingIcon = this.state.generating ? <RefreshIndicator size={25} left={10} top={5} status="loading" /> : null;
    let generatingReportIcon = this.state.generatingReport ? <RefreshIndicator size={25} left={10} top={5} status="loading" /> : null;
    let downloadingReportIcon = this.state.downloadingReport ? <RefreshIndicator size={25} left={10} top={5} status="loading" /> : null;
    if (this.state.generating || this.state.generatingReport) {
      fileList = null;
      pdfFound = false;
    }

    const generateAutomaticReport = () => {
      this.refs.generateReportMonthSelectionDialog.open(null);
    }
    console.log(this);
    console.log("DUBUG:",this.state.queryError);
    return (
      
      <div>
        <AppBar
          title={this.state.title || gettext('title_raportti')}
          dropdown={this.state.dropdownMenu}
          onDropdownChange={this.onReportSelectionChange.bind(this)} />
        <div id="pdf-report" className="container" >
          {this.state.queryError && <Snackbar open={this.state.queryError} ref="ErrorBar" message={<span>{this.state.queryError}</span>} />}
          {reportView()}
          {this.state.fiksuvesi &&
            <div style={{ marginBottom: '30px' }}><center>{gettext('consumptioninfotext')}</center></div>
          }


          <div style={{ marginBottom: '50px' }}>
            {fileList}
            {(pdfFound) &&
              <mui.RaisedButton
                style={{ marginRight: '10px' }}
                label={gettext('ui_send_pdf')}
                onClick={(this.onOpenSendPDFDialog.bind(this))} 
                //linkButton={true} 
                />
            }
            <mui.RaisedButton
              disabled={this.state.generating}
              style={{ marginRight: '10px' }}
              label={gettext('ui_generate_pdf')}
              onClick={() => {
                //ReportActions.generatePdfs(premise.name, premise._id, this.state.selectedReportDate, token);
                ReportActions.generatePdfReport2(premise._id, this.state.selectedReportDate);
                setTimeout(() => {
                  ReportActions.query(this.props.match.params.id, this.state.selectedReportDate, { premiseId: this.props.match.params.id });
                  this.setState({ generating: false })
                }, 15000)
                this.setState({ generating: true })
              }
              } 
              //linkButton={true}
               >
              {generatingIcon}
            </mui.RaisedButton>
            {(this.props.root && this.state.selectedReport && this.state.selectedReport.month) &&
              <mui.RaisedButton
                style={{ marginRight: '10px' }}
                label={gettext('ui_delete_report')}
                onClick={(this.onDeleteReport.bind(this))} />
            }


            {(this.props.root && this.state.selectedReport && this.state.selectedReport.month) &&
              <ConfirmationDialog ref="confirmationDialog"
                title={gettext('ui_remove_report_title')}
                text={gettext('ui_remove_report_desc %s', this.state.selectedReport.month + '/' + this.state.selectedReport.year)}
                confirmButtonText={gettext('ui_delete_ok')}
                onAccepted={this.deleteReportAccepted.bind(this)} />
            }

            <mui.RaisedButton
              style={{ marginRight: '10px' }}
              label={gettext('ui_generate_report')}
              onClick={(generateAutomaticReport.bind(this))}  >{generatingReportIcon}</mui.RaisedButton>
          </div>


          <SendPDFDialog ref="sendDialog" report={this.state.selectedReport} error={this.props.dialogError} sendHistory={sendHistory} onSendReport={this.onSendReport.bind(this)} onCancel={this.onCancelSendReport.bind(this)} interestGroups={interestGroups} />
          <MonthSelectionDialog ref="generateReportMonthSelectionDialog"
            title={gettext('ui_select_report_generation_month')}
            text={gettext('ui_generate_report_info_text')}
            onAccepted={this.onGenerateMonthSelected.bind(this)} />
        </div>
      </div>
    );
  }
}
