import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import SearchFilter from '../components/SearchFilter';

import ApiPartnerStore from '../stores/ApiPartnerStore'
import PremiseActions from '../actions/PremiseActions';
import PremisesStore from '../stores/PremisesStore';

import { gettext } from 'i18n';
import _ from 'lodash';
import ViewLoader from '../components/ViewLoader';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

//new Material UI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField, Typography, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup } from '@material-ui/core';
import LoginStore from '../stores/LoginStore';
import utils from '../utils/utils'

//import Box from '@material-ui/core/Box';


@connectToStores
class PremisesMissingDataView extends BaseView {
  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme,
      filters: {},
      sortParams: { sortBy: 'name', order: 'asc' },
    };
  }

  static getStores() {
    return [PremisesStore, LoginStore];
  }

  static getPropsFromStores() {
    return { ...PremisesStore.getState(), ...LoginStore.getState() }
  }

  static propTypes = {
    companies: PropTypes.array,
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return { muiTheme: this.state.muiTheme };
  }

  componentDidMount() {
    this.initialQuery = setTimeout(PremiseActions.getPremisesMissingData, 100);
  }

  componentDidUpdate(prevProps) {
    //Show error
    if (prevProps.error !== this.props.error) {
      if (this.props.error) {
        this.setState({ openError: true })
      } else {
        this.setState({ openError: false })
      }
    }
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }
    PremiseActions.getPremisesMissingData(filters);
  }


  sortBy(sb, e) {
    if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({ sortParams: { sortBy: sb, order: "desc" } });
    } else {
      this.setState({ sortParams: { sortBy: sb, order: "asc" } });
    }
  }

  render() {
    console.log(this.props)
    let loading = this.props.unvalidPremises.length === 0;
    let errorMsg = this.props.error

    let filters = [
      { type: 'owner', prop: 'owner', operator: "%", text: gettext('owner'), value: '' },
    ];

    var rows = _.map(_.orderBy(this.props.unvalidPremises, [this.state.sortParams.sortBy], [this.state.sortParams.order]), (u, i) => {
      let wc = u.waterCompany ? MyRawTheme.palette.primary3Color : MyRawTheme.palette.accent1Color
      let rc = u.interestGroups && u.interestGroups.length ? MyRawTheme.palette.primary3Color : MyRawTheme.palette.accent1Color
      let dc = u.districtHeatingCompany ? MyRawTheme.palette.primary3Color : MyRawTheme.palette.accent1Color
      return (
        <tr className="pointer" key={u.name + i} onClick={()=> window.open(`#/premises/edit/${u._id}`, "_blank")}>
          <td>{u.name}</td>
          <td>{u.owner}</td>
          <td style={{backgroundColor:dc}}>{u.districtHeatingCompany ? gettext('yes') : gettext('no')}</td>
          <td style={{backgroundColor:wc}}>{u.waterCompany ? gettext('yes') : gettext('no')}</td>
          <td style={{backgroundColor:rc}}>{u.interestGroups && u.interestGroups.length || 0}</td>
        </tr>
      )
    });

    return (
      <div>
        <AppBar title={gettext('Premises Missing Data')}
        />
        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
        <div className="row">
          <div className="col-xs-8 col-md-8 align-self-end" style={{ paddingRight: 0 }}>
            <SearchFilter localStorage="PremisesMissingDataViewFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} />
          </div> 
        <div className="col-xs-4 col-md-4 end-xs" style={{ borderBottom: '3px solid grey' }}>
        
        </div>
          </div>  
          <table className="table">
            <thead>
              <tr>
                <th onClick={this.sortBy.bind(this, 'premise')}>{gettext('Premise')}</th>
                <th onClick={this.sortBy.bind(this, 'owner')}>{gettext('Smartvatten Owner')}</th>
                <th onClick={this.sortBy.bind(this, 'districtHeatingCompany')}>{gettext('Heating Company')}</th>
                <th onClick={this.sortBy.bind(this, 'waterCompany')}>{gettext('Water Company')}</th>
                <th onClick={this.sortBy.bind(this, 'receivers')}>{gettext('Receivers')}</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {loading && <ViewLoader blue />}
        </div>
        <Snackbar open={this.state.openError} autoHideDuration={6000} onClose={(e, r) => { this.setState({ openError: false }) }}>
          <Alert onClose={(e, r) => { this.setState({ openError: false }) }} severity="error" sx={{ width: '100%' }}>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
    );

  }
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default PremisesMissingDataView;