import React from 'react';
import * as mui from 'material-ui';

export class CardRow extends React.Component {
  render() {
    let children = this.props.children.filter((x) => x);
    let colClass = children.length >= 4 ? 'col-xs-12 col-sm-6 col-md-6 col-lg-3' : 'col-xs-12 col-sm-6 col-md-6 col-lg-4';

    return (
      <div className="row top-xs">
        {children.map((child, idx) => (<div className={colClass} key={idx} style={{marginBottom: '20px'}}>{child}</div>))}
      </div>
    );
  }
}

export class DataRow extends React.Component {
  render() {
    let children = this.props.children.filter((x) => x);
    let colClasses = children.length < 2 || this.props.forceVertical
      ? ['col-xs-12']
      : ['col-xs-12 col-md-7 col-lg-7', 'col-xs-12 col-md-5 col-lg-5'];

    return (
      <div className="row top-xs">
        {children.map((child, idx) => (<div className={colClasses[idx % colClasses.length]} key={idx}>{child}</div>))}
      </div>
    );
  }
}

export class DataCard extends React.Component {

  render() {
    return (
      <mui.Paper zDepth={1} rounded={false} style={{marginBottom: '20px', border: 'none'}} onClick={this.props.onClick}>
        <h3 className="cardHeading">{this.props.title}</h3>
        <div className="center">
          {this.props.children}
        </div>
      </mui.Paper>
    );
  }
}
