/*
TODO:
#-# hampurilainen => vasen reuna
*/

import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '../components/AppBar';
import BaseView from './BaseView';
import * as mui from 'material-ui';
import { gettext } from 'i18n';
import connectToStores from 'alt-utils/lib/connectToStores';
import DebugReportStore from '../stores/DebugReportStore';
import DebugReportActions from '../actions/DebugReportActions';
import Utils from '../utils/utils';
import TextInput from '../components/TextInput';
import history from '../utils/history'
import moment from 'moment';
import { MenuItem } from 'material-ui';
import SelectBox from '../components/SelectBox';
import SearchFilter from '../components/SearchFilter';
import Pager from '../components/Pager';
import _ from 'lodash';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';
import queryString from 'query-string';


//new Material UI
//import IconButton from '@material-ui/core/IconButton';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ViewLoader from '../components/ViewLoader';


const debug_fields = [
  { name: 'run', numeric: 1, display: 'run' }, // kulutus
  { name: 'minRunNight', numeric: 1, display: 'min night' },
  { name: 'minRunHourNight', numeric: 1, display: 'min night h' },
  { name: 'serverNoFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'server noflow' },
  { name: 'deviceNoFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'device noflow' },
  { name: 'deviceFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'device flow' },
  { name: 'undefFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'undef flow' },
  { name: 'diffFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'diff flow' },
  { name: 'diffNoFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'diff noflow' },
  { name: 'diffUndefFlow', numeric: 1000, format: (v) => Utils.msToTime(v * 1000), display: 'diff undef flow' },
  { name: 'countNight', numeric: 1, display: 'count night' },
  { name: 'minRunHour', numeric: 1, display: 'minrun/h' },
  { name: 'spins', numeric: 1, display: 'spins' },
  { name: 'maxrun', numeric: 1, display: 'maxrun' },
  { name: 'maxrun4', numeric: 1, display: 'maxrun4' },
  { name: 'count', numeric: 1, display: 'count' },
  { name: 'longestStableTime', numeric: 1, format: (v) => Utils.msToTime(v), display: 'max stable' }
]

const DBG_PAGE_SIZE = 100

@connectToStores
class DebugReportView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.pageCount = 1
    this.state = {
      //muiTheme: muiTheme,
      sortField: '',
      sortOrder: false,
      numericSort: false,
      filter: '',
      query: {},
      page: 0,
      minFilters: {},
      maxFilters: {}
    };
  }

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static getStores() {
    return [DebugReportStore]
  }

  static getPropsFromStores() {
    let data = DebugReportStore.getState()
    return {
      data: data.data,
      loading: data.loading
    }
  }

  componentDidMount() {
    this.doQuery()
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.deviceId!= this.props.match.params.deviceId){
      this.doQuery();
    }
  }

  doQuery() {
    const params = queryString.parse(this.props.location.search);
    if (this.props.match.params.deviceId) {
      DebugReportActions.query({
        date: {
          '$lte': moment(params.date).format('YYYY-MM-DD'),
          '$gt': moment(params.date).subtract(30, 'days').format('YYYY-MM-DD')
        },
        'deviceId': this.props.match.params.deviceId
      });
      console.log("deviceId", this.props.match.params.deviceId, params.date);
    } else {
      DebugReportActions.query({ date: { '$eq': moment(params.date).format('YYYY-MM-DD') } })
    }
  }

  doDateQuery(deviceId, date) {
    if (deviceId) {
      DebugReportActions.query({
        date: {
          '$lte': moment(date).format('YYYY-MM-DD'),
          '$gt': moment(date).subtract(30, 'days').format('YYYY-MM-DD')
        },
        deviceId
      });
      console.log("deviceId", deviceId, date);
    } else {
      DebugReportActions.query({ date: { '$eq': moment(date).format('YYYY-MM-DD') } })
    }
  }

  setDate(d) {
    this.gotoUrl(this.props.match.params.deviceId, d)
    this.doDateQuery(this.props.match.params.deviceId, moment(d).format('YYYY-MM-DD'));
  }

  setSortField(sortField, numericSort) {
    let sortOrder = this.state.sortOrder
    if (sortField === this.state.sortField) {
      sortOrder = !sortOrder
    }
    this.setState({ sortField, numericSort, sortOrder })
  }

  gotoPage(page) {
    this.setState({ page: Math.min(Math.max(0, page), this.pageCount - 1) })
  }

  gotoUrl(device, date) {
    device = (device && '/' + device) || ''
    date = (date && '?date=' + moment(date).format('YYYY-MM-DD')) || ''
    history.replace({
      pathname: `/debugreport${device}`,
      search: `${date}`
    });
    //setTimeout(() => this.doQuery(), 100)
    //this.doQuery();
  }

  gotoAnalyze(premise, device) {
    //console.log('gotourl:', `/analyze/${premise}/${device}?endDate=${this.props.location.query.date}&frequency=hour`)
    const params = queryString.parse(this.props.location.search);
    history.push(`/analyze/${premise}/${device}?endDate=${params.date}&frequency=hour`)
  }

  render() {
    //console.log("DebugReportView-Render:", this.props.loading);
    
    const params = queryString.parse(this.props.location.search);
    const date = moment(params.date);
    let table = null
    let iconButtonElement = (<mui.IconButton style={{ height: '16px', padding: '0px' }}><MoreVertIcon /></mui.IconButton>);
    let data = this.props.data
    const device = this.props.match.params.deviceId;
    if (!device && this.state.filter) {
      const f = this.state.filter.toLocaleLowerCase()
      data = data.filter((item) => (item.premiseName && item.premiseName.toLocaleLowerCase().indexOf(f) > -1) || (item.deviceId && item.deviceId.toLocaleLowerCase().indexOf(f) > -1))
    }
    const minf = this.state.minFilters
    for (let p in minf) {
      const value = parseFloat(minf[p])
      if (!isNaN(value)) {
        data = data.filter((a) => a[p] >= value)
      }
    }
    const maxf = this.state.maxFilters
    for (let p in maxf) {
      const value = parseFloat(maxf[p])
      if (!isNaN(value)) {
        data = data.filter((a) => a[p] <= value)
      }
    }
    if (this.state.sortField) {
      const sortField = this.state.sortField
      if (this.state.numericSort && this.state.sortOrder) {
        data = data.sort((a, b) => (parseFloat(b[sortField]) || 0) - (parseFloat(a[sortField]) || 0))
      } else if (this.state.numericSort && !this.state.sortOrder) {
        data = data.sort((a, b) => (parseFloat(a[sortField]) || 0) - (parseFloat(b[sortField]) || 0))
      } else if (!this.state.sortOrder) {
        data = data.sort((a, b) => ('' + a[sortField]).toLocaleLowerCase().localeCompare(('' + b[sortField]).toLocaleLowerCase()))
      } else {
        data = data.sort((a, b) => -1 * ('' + a[sortField]).toLocaleLowerCase().localeCompare(('' + b[sortField]).toLocaleLowerCase()))
      }
    }
    // const pageCount = this.props.pageCount
    const pageCount = this.pageCount = Math.ceil(data.length / DBG_PAGE_SIZE) || 1
    const pageIndex = Math.max(Math.min(this.state.page, pageCount - 1), 0)
    data = data.slice(pageIndex * DBG_PAGE_SIZE, pageIndex * DBG_PAGE_SIZE + DBG_PAGE_SIZE)
    let items = [];
    if (data && this.props.data.length) {
      const rows = data.map((item, i) => {

        const dataFields = debug_fields.map((field, j) => {
          return (<td key={'df' + j} style={{ height: "20px", padding: "2px" }}>{(field.format && field.format(item[field.name])) || item[field.name]}</td>)
        })
        const items = []
        if (item.premiseId) {
          items.push(<MenuItem key="analyze" primaryText={gettext("ui_analyze")} onClick={() => this.gotoAnalyze(item.premiseId, item.deviceId)} />);
          items.push(<MenuItem key="premiseAlarms" primaryText={gettext("ui_premise_alarms")} onClick={() => history.push(`/alarms/${item.premiseId}`)} />);
          items.push(<MenuItem key="edit" primaryText={gettext("ui_edit_premise")} onClick={() => history.push('/premises/edit/' + item.premiseId)} />);
        }
        if (!device) {
          items.push(<MenuItem key="showDev" primaryText={gettext("ui_show_device")} onClick={() => this.gotoUrl(item.deviceId, date)} />);
        }
        return (
          <tr key={'dbg' + i} style={{ height: "20px" }}>
            {device && <td className="pointer" style={{ color: '#0844F6', fontWeight: 500, height: "20px", padding: "2px" }} onClick={() => this.gotoAnalyze(item.premiseId, item.deviceId)}>{item.date}</td>}
            {!device && <td style={{ height: "20px", padding: "2px" }}>{item.premiseName}</td>}
            {!device && <td className="pointer" style={{ color: '#0844F6', fontWeight: 500, height: "20px", padding: "2px" }} onClick={() => this.gotoUrl(item.deviceId, date)}>{item.deviceId}</td>}
            {dataFields}
            <td style={{ height: "20px", padding: "2px" }}>
              <mui.IconMenu iconButtonElement={iconButtonElement}>
                {items}
              </mui.IconMenu>
            </td>
          </tr>
        )
      })

      const dataFieldHeaders = debug_fields.map((item, k) => {
        return (<th style={{ padding: "2px" }} key={'dh' + k} onClick={() => this.setSortField(item.name, item.numeric)}>{gettext(item.display || ('ui_' + item.name))}</th>)
      })

      const dataFieldMinFilters = debug_fields.map((item, k) => {
        return (
          <th style={{ padding: '2px' }} key={'min_' + k}>
            <mui.TextField
              style={{ width: 'auto', height: '32px' }}
              inputStyle={{ textColor: 'black' }}
              hintText={gettext('min')}
              defaultValue={this.state.minFilters[item.name]}
              value={this.state.minFilters[item.name]}
              onChange={(e) => {
                const t = this.state.minFilters
                t[item.name] = e.target.value
                this.setState({ minFilters: t })
              }}
            />
          </th>
        )
      })

      const dataFieldMaxFilters = debug_fields.map((item, k) => {
        return (
          <th style={{ padding: '2px' }} key={'max_' + k}>
            <mui.TextField
              style={{ width: 'auto', height: '32px' }}
              inputStyle={{ textColor: 'black' }}
              hintText={gettext('max')}
              defaultValue={this.state.maxFilters[item.name]}
              value={this.state.maxFilters[item.name]}
              onChange={(e) => {
                const t = this.state.maxFilters
                t[item.name] = e.target.value
                this.setState({ maxFilters: t })
              }}
            />
          </th>
        )
      })

      table = (
        <table className="table activeAlarms">
          <thead>
            <tr>
              {
                (device && (
                  <th style={{ padding: "2px" }} onClick={() => this.setSortField('date')}>{gettext('ui_date')}</th>
                )) || (
                  <th style={{ padding: "2px" }} onClick={() => this.setSortField('premiseName')}>{gettext('ui_premise')}</th>
                )
              }
              {!device && <th style={{ padding: "2px" }} onClick={() => this.setSortField('deviceId')}>{gettext('ui_device')}</th>}
              {dataFieldHeaders}
              <th></th>
            </tr>
            <tr>
              <th></th>
              {!device && <th></th>}
              {dataFieldMinFilters}
            </tr>
            <tr>

              {(!device &&
                <th colSpan="2" style={{ padding: '2px' }}>
                  <mui.TextField
                    style={{ width: 'auto', height: '32px' }}
                    inputStyle={{ textColor: 'black' }}
                    hintText={gettext('ui_find')}
                    defaultValue={this.state.filter}
                    onChange={(e) => this.setState({ filter: e.target.value })}
                  />
                </th>) || (<th></th>)
              }
              {dataFieldMaxFilters}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    } else if (data && !data.length) {
      table = <h3 style={{ color: '#7ed632' }}>{gettext('ui_no_data_found')}</h3>;
    } else {
      table = <div style={{ marginLeft: '48vw' }}> <mui.CircularProgress mode="indeterminate" /> </div>;
    }

    const pages = []
    for (let i = 0; i < pageCount; i++) {
      pages.push(
        <li
          key={i}
          className={i === pageIndex && 'active'}
          onClick={() => this.gotoPage(i)}>
          <a>{i + 1}</a>
        </li>
      )
    }
        
    return (
      <div className="container debugReportView" style={{ marginLeft: "8px", marginRight: "8px", overlay: "auto", maxWidth: "16384px" }}>
        <AppBar title={gettext('ui_title_debug_report')} />
        <div style={{ marginTop: '0px' }}>
          <div className="clearFix header">
            <h2 style={{ color: '#555', display: 'inline-block' }}>{gettext('ui_title_debug_report')}</h2>
            {device &&
              <div
                className="pointer"
                onClick={() => this.gotoUrl(null, date)}
                style={{ marginLeft: '10px', display: 'inline', fontSize: '16px', borderRadius: '4px', backgroundColor: '#dddddd', padding: '3px' }}>
                {device + ' X'}
              </div>
            }
            <mui.DatePicker
              onChange={(e, d) => this.setDate(d)}
              autoOk={true}
              hintText={gettext('ui_date')}
              style={{ display: 'inline-block', marginLeft: '10px' }}
              value={date.toDate()}
            />
          </div>
          {this.props.loading &&<ViewLoader blue />}
          {table}
        </div>
        <div>
          <ul className="pagination">
            <li className={pageIndex <= 0 && 'disabled'}><a onClick={() => this.gotoPage(this.state.page - 1)}><span>«</span></a></li>
            {pages}
            <li className={pageIndex >= pageCount - 1 && 'disabled'}><a onClick={() => this.gotoPage(this.state.page + 1)}><span>»</span></a></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default DebugReportView;