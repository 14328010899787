import alt from "../utils/alt";
import AnalyzeActions from "../actions/AnalyzeActions";
import PremiseActions from "../actions/PremiseActions";
import _ from 'lodash';

class AnalyzeStore {
  constructor() {

    this.bindListeners({
      onQueryById: PremiseActions.queryById,
      onMeasurements: AnalyzeActions.measurements,
      onMine: AnalyzeActions.mine,
      onMineTemperature: AnalyzeActions.mineTemperature,
      //onMineHistogram: AnalyzeActions.mineHistogram,
      onResetMinedData: AnalyzeActions.resetMinedData,
      onResetMeasurements: AnalyzeActions.resetMeasurements,
      onUpdateMeasurement: AnalyzeActions.updateMeasurement,
      onActivateLiveData: AnalyzeActions.activateLiveData,
      onResetLiveData: AnalyzeActions.resetLiveData,
      onResetTemperatures: AnalyzeActions.resetTemperatures,
      onResetPeriodData: AnalyzeActions.resetPeriodData,
      onGetPeriodData: AnalyzeActions.getPeriodData,
      onEditPeriodData: AnalyzeActions.editPeriodData
    });

    this.state = {
      devices: [],
      mineData: {},
      periodData:[],
      measurements: [],
      histogramData: []
    };
  }

  onQueryById(data) {
    this.setState({ premise: data });
  }

  onMeasurements(payload) {

    var measures = this.state.measurements;
    if (!measures) {
      measures = [];
    }
    if (payload.reload) {
      measures = payload.data;
    } else {
      measures = measures.concat(payload.data);
    }

    this.setState({
      hasMore: payload.data.length === payload.limit,
      measurements: measures
    });
  }

  onUpdateMeasurement(payload) {
    if (!payload.data) return;

    var itemId = _.findIndex(this.state.measurements, { _id: payload.data._id });
    var meas = this.state.measurements.slice(0); // slice is for cloning the array!!!
    meas[itemId].value = payload.data.value
    meas[itemId].checked = payload.data.checked

    this.setState({ measurements: meas });
  }

  onMine(data) {
    //this.objects = data;
    // this.setState({mineData: data });
    console.log(`MINEDATA` ,data)

    var md = this.state.mineData || {};
    if (data) md[data.deviceId] = data.data;
    this.setState({ mineData: md, loading:data.loading });
  }

  onGetPeriodData(data){
    var pd = this.state.periodData || {};
    this.setState({ periodData: data.data, loading:data.loading });
  }

  /*onMineHistogram(data) {
    console.log("histogramData", data)
    this.setState({histogramData: data });
  }*/

  onResetMinedData() {
    this.setState({ mineData: {} });
    //this.setState({histogramData: [] });
  }

  onEditPeriodData(){
    this.setState({ periodData: [], loading:true });
  }

  onResetMeasurements() {
    this.setState({ measurements: [] })
  }

  onActivateLiveData(payload) {
    this.setState({ activating: false, liveDataMsg: payload.data });
    if (payload.data === 'ok') {
      this.setState({ liveDataActive: true, liveDataStart: new Date().getTime() });
    } else if (payload.status === 500) {
      this.setState({ liveDataActive: false });
    }
  }

  onMineTemperature(arr) {
    // console.log(arr);
    let receivedData = {};
    arr.data.forEach(e => {
      if (!Array.isArray(receivedData[e.datapointId])) {
        receivedData[e.datapointId] = [{ value: e.value, timestamp: e.timestamp }];
      } else {
        receivedData[e.datapointId].push({ value: e.value, timestamp: e.timestamp });
      }
    });
    const mineData = Object.assign(this.state.mineData, receivedData);
    this.setState({ mineData });
  }

  onResetLiveData() {
    this.setState({ liveDataActive: false, liveDataStart: 0, liveDataMsg: null });
  }

  onResetPeriodData(){
    this.setState({ periodData: [], loading:true });
  }

  onResetTemperatures(apps) {
    let md = this.state.mineData;
    let keys = Object.keys(md);
    keys.forEach(k => {
      if (apps.indexOf(k) === -1) delete md[k];
    })
    this.setState({ mineData: md });
  }

}

//module.exports = alt.createStore(AnalyzeStore);
export default alt.createStore(AnalyzeStore);
