import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
//const MyRawTheme = require('./Theme');
//import {RefreshIndicator} from 'material-ui';
import { gettext } from 'i18n';
import config from '../utils/config';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class LatestImagesDialog extends React.Component {

    constructor(props) {
        super(props);
        //let muiTheme = getMuiTheme(MyRawTheme);
        this.dialogData = null;
        this.state = {
            //muiTheme: muiTheme
            dialogOpen: false,
        };
    }

    open(data) {
        this.dialogData = data;
        //this.refs.dialog.show();
        this.setState({ dialogOpen: true });
    }

    dialogData() {
        return this.data;
    }

    openConfigure(meterId) {
        let confLink = config.CONFIGSERVER + "#/" + meterId;
        window.open(confLink, "_blank");
    }

    close() {
        //this.refs.dialog.dismiss();
        this.setState({ dialogOpen: false });
    }
    getImages() {
        if (this.props.data && this.props.data.length) {
            let key = 0;
            //setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 500); //ugly way to get dialog resize its contents.

            return (
                <div >
                    {this.props.data.map(meter => {
                        key++;
                        return (<div key={key} >
                            <div style={{ paddingTop: 20 }}>
                                <h4>{gettext("ui_meter")}: {meter.meterId}</h4> {gettext("ui_pvm")}: {moment(meter.timestamp).format("D.M.YYYY  HH:mm")}
                            </div>
                            <img src={config.DATASERVER + '/file/' + meter.token} />
                            {(this.props.role && this.props.role === "admin") ? <Button variant='outlined' onClick={() => (this.openConfigure(meter.meterId))} >{gettext("ui_configureButton")}</Button> : ''}
                            <hr />
                        </div>)
                    })}
                </div>
            )
        }
    }

    render() {
        const data = this.props.data ? this.getImages() : <div style={{marginLeft:'auto',marginRight:'auto',textAlign:'center'}}><CircularProgress /></div>;

        return (
            <Dialog
                open={this.state.dialogOpen}
                maxWidth='md'
                fullWidth={true} >
                <DialogTitle><span>{gettext("latest_images")}:</span></DialogTitle>
                <DialogContent>
                    {data}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => (this.close())} >{gettext('ui_close')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default LatestImagesDialog;