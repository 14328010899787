import PropTypes from 'prop-types';
import React from 'react';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import { gettext } from 'i18n';
import TextInput from './TextInput';
import MyRawTheme from './Theme';
//new Material UI
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import SelectBox from './SelectBox';

const DisabledAlarmsOptions = [
  { label: gettext('flowone-zero-monthly-consumption'), value: 'flowone-zero-monthly-consumption' }
]

export default class EditDeviceinfosDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }


  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.state = {
      //muiTheme: muiTheme,
      customerNumber: null,
      locationNumber: null,
      meterSerial: null,
      isSubmeter: false,
      dialogOpen: false,
      disabledAlarms: [],
      measured: 0,
      alarmsUpdated:false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.device && nextProps.device.settings && nextProps.device.settings.alarms && nextProps.device.settings.alarms.disabledAlarms && !state.alarmsUpdated ) {
      //console.log("nextProps:", nextProps);
      //console.log("state:",state);
      let dAlarms =  nextProps.device.settings.alarms.disabledAlarms ? nextProps.device.settings.alarms.disabledAlarms : [];
      let disabledAlarms = [];
      //console.log("dAlarms:", dAlarms);
      if (dAlarms.length) {
        for (const alarm of dAlarms) {
          let item = DisabledAlarmsOptions.find((a) => a.value === alarm);
          if (item) {
            disabledAlarms.push(item);
          }
        }
        //console.log("disabledAlarms:", disabledAlarms);
        return {
          disabledAlarms,
          alarmsUpdated:true
        }
      }
    }
  }

  getChildContext() {
    return { /*muiTheme: this.state.muiTheme*/ };
  }

  open(device, deviceInfo, apikeys) {
    let customerNumber = deviceInfo && deviceInfo.wcCustomerNumber ? deviceInfo.wcCustomerNumber : null
    let locationNumber = deviceInfo && deviceInfo.wcLocationNumber ? deviceInfo.wcLocationNumber : null
    let meterSerial = deviceInfo && deviceInfo.meterSerial ? deviceInfo.meterSerial : null
    let isSubmeter = deviceInfo && deviceInfo.isSubmeter ? deviceInfo.isSubmeter : false
    let measured = deviceInfo && deviceInfo.measured && deviceInfo.measured > -1 ? deviceInfo.measured : 0
    let alias = deviceInfo && deviceInfo.alias ? deviceInfo.alias : null
    let customIDStr = deviceInfo && deviceInfo.customIDStr ? deviceInfo.customIDStr : null
    this.setState({ measured, isSubmeter, customerNumber, locationNumber, meterSerial, alias, apikeys, dialogOpen: true, customIDStr, alarmsUpdated:false, disabledAlarms:[] });
    //this.refs.dialog.show();
  }

  close() {
    this.setState({ value: null, dialogOpen: false });
    //this.refs.dialog.dismiss();
  }

  onChange(value, id) {
    let v = (value === "" || value === undefined) ? null : value
    this.setState({ [id]: v })
  }

  onChangeSubmeter(v) {
    this.setState({ isSubmeter: !this.state.isSubmeter })
  }

  onChangeMeasured(v) {
    this.setState({ measured: v.value })
  }

  onChangeDisabledAlarms(value, newValue) {
    this.setState({ disabledAlarms: newValue });
  }

  render() {
    let dialogStyle = {
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    let title = this.props.title ? this.props.title : ""

    let apikeyName = this.state.apikeys && this.state.apikeys.apiKeys && this.state.apikeys.apiKeys[0] ? this.state.apikeys.apiKeys.map((i) => { return i.name }) : null

    let Measured = [gettext('cold'), gettext('hot')]
    return (
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div >
            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col-xs-6 col-md-6">
                <TextInput defaultValue={this.state.customerNumber} required={false} id="customerNumber" label={gettext('ui_wc_customernumber')} onChange={this.onChange.bind(this)} />
              </div>
              <div className=" col-xs-6 col-md6">
                <TextInput defaultValue={this.state.locationNumber} required={false} id="locationNumber" label={gettext('ui_wc_locationnumber')} onChange={this.onChange.bind(this)} />
              </div>
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col-xs-6 col-md-6">
                <TextInput defaultValue={this.state.meterSerial} required={false} id="meterSerial" label={gettext('ui_device_meterSerial')} onChange={this.onChange.bind(this)} />
              </div>
              <div className="col-xs-6 col-md-6">
                <TextInput defaultValue={this.state.alias} required={false} id="alias" label={gettext('Meter variable')} onChange={this.onChange.bind(this)} />
              </div>
              <div className="col-xs-6 col-md-6">
                <TextInput defaultValue={this.state.customIDStr} required={false} id="customIDStr" label={gettext('Custom Identification String')} onChange={this.onChange.bind(this)} />
              </div>
              <div className="col-xs-6 col-md-6">
                <Autocomplete
                  multiple
                  id="disabled-alarms"
                  options={DisabledAlarmsOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) => option.value === value.value}
                  onChange={(e, v) => this.onChangeDisabledAlarms(e, v)}
                  value={this.state.disabledAlarms}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={gettext('DISABLE ALARMS')}
                    />
                  )}
                />
              </div>
              <div className="col-xs-6 col-md-6">
                <SelectBox
                  value={{ value: this.state.measured, label: Measured[this.state.measured] }}
                  name="measured"
                  label={gettext('Measured data')}
                  options={[{ value: 0, label: Measured[0] }, { value: 1, label: Measured[1] }]}
                  onSelectionChange={this.onChangeMeasured.bind(this)} />
              </div>
              <div className="col-xs-6 col-md-6">
                <FormControlLabel
                  control={<Checkbox checked={this.state.isSubmeter}
                    onChange={this.onChangeSubmeter.bind(this)}
                    name="isSubmeter"
                    color="secondary"
                  />}
                  label={gettext('submeter')}
                />
              </div>
            </div>
            {apikeyName &&
              <div className="row start-md start-xs">
                <div className="col-xs-10 col-md-10">
                  {gettext('Kohde on Liitetty API avaimeen', apikeyName)}
                </div>
              </div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
          <Button onClick={() => (this.props.onAccepted(this.state.customerNumber, this.state.locationNumber, this.state.meterSerial, this.state.alias, this.state.isSubmeter, this.state.measured, this.state.customIDStr, this.state.disabledAlarms))} >{gettext('ui_ok')}</Button>
        </DialogActions>
      </Dialog >
    );

  }
}

