import _ from 'lodash';
import axios from 'axios';
import LoginActions from '../actions/LoginActions';
const api = {};
import utils from '../utils/utils'

/*
TODO use interceptors for token

axios.interceptors.request.use(function (config) {

  if (api.token) {
    config.headers['authorization'] = 'Bearer ' + api.token;
  }

  return config;

}, function (err) {

  return Promise.reject(err);
});

 */

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401 && window.location.hash.indexOf('/login') < 0) {
      console.warn('Unauthorized. Sending user back to login screen...', error);
      setTimeout(LoginActions.logout);
    }
    return Promise.reject(error);
  }
);

api.token = "";

api.get = function(url, params) {
  var p = _.assign(params || {}, {headers: {Authorization: 'Bearer ' + api.token}})
  return axios.get(url, p);
}

api.post = function(url, data, params) {
  var p = _.assign(params || {}, {headers: {Authorization: 'Bearer ' + api.token}})
  return axios.post(url, data, p).catch(err => {
    //for (let key in utils.mongoErrorCodes) {
      //let mErr = utils.mongoErrorCodes[key]
     // let len = mErr.length
      console.log("err",err);
      //if (err.data.substr(0, len) === mErr) err.data = mErr
      throw err;
    //}
  });
}

api.delete = function(url, params) {
  var p = _.assign(params || {}, {headers: {Authorization: 'Bearer ' + api.token}})
  return axios.delete(url, p);
}

api.updateToken = function (token) {
  console.log('updateToken', token)
  api.token = token;
}

export default api;
