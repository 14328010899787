// TEST
let config = {
  // LOCAL TEST
//APISERVER: "http://localhost:3007",
//DATASERVER: 'http://localhost:3006',
//CONFIGSERVER:'https://config.testflowone.io/'
//TAG: 'envera/flowone',



  // TEST
  //APISERVER : 'https://api.testflowone.io',
  //APISERVER : 'http://localhost:3007',
  //DATASERVER: 'https://iot-data.testflowone.io',
  //CONFIGSERVER:'https://config.testflowone.io/',
  //TAG: 'envera/test-flowone'
  

  // PRODUCTION
  
   APISERVER : 'https://api.flowone.io',
   DATASERVER: 'https://iot-data.flowone.io',
   CONFIGSERVER:'https://config.flowone.io/',
   TAG: 'envera/flowone'
  
}

// read config from GLOBAL scope if avaliable
// these are defined in gruntfile and index.html
/*if (typeof APISERVER === 'string' && APISERVER.indexOf('@@') < 0) config.APISERVER = APISERVER
if (typeof DATASERVER === 'string' && DATASERVER.indexOf('@@') < 0) config.DATASERVER = DATASERVER*/
if (typeof TAG === 'string' && TAG.indexOf('@@') < 0) config.TAG = TAG
if (typeof UIVERSION === 'string' && UIVERSION.indexOf('@@') < 0) config.UIVERSION = UIVERSION

export default config
export const conf=config;
