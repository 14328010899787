import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//import connectToStores from 'alt-utils/lib/connectToStores';
import CardBase from './CardBase';
import {gettext} from 'i18n';
//const ImmutabilityHelper =mui.Utils.immutabilityHelper;
//let ImmutabilityHelper = require('material-ui/lib/utils/immutability-helper');
import history from '../utils/history'
import utils from '../utils/utils';

//new Material UI
import PenIcon from '@material-ui/icons/Edit';

//@connectToStores
export default class PremiseInfoCard extends CardBase {
  /*
  static getStores() {
    return [];
  }

  static propTypes = {

  }

  static getPropsFromStores() {
    return Store.getState();
  }
  */
  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
     this.setInitialStyles(); 
  }

  openInfo() {
    history.push('/newpremise/' + this.props.premiseData._id);
  }

  render() {
    console.log("PremiseInfoCard",this);
    const premise = this.props.premiseData;
    const premiseName = (premise.name) ? premise.name : '-';
    const address = (premise.address) ? premise.address : '';
    const zipCode = (premise.zipCode) ? premise.zipCode : '';
    const postOffice = (premise.postOffice) ? premise.postOffice : '';
    const premiseAddress = address + ', ' + zipCode + '  ' + postOffice;
    const apartmentCount = (premise.apartmentCount) ? premise.apartmentCount : '-';
    const residentCount = (premise.residentCount) ? premise.residentCount : '-';
    const constructionYear = (premise.constructionYear) ? premise.constructionYear : '-';
    const serviceTakenUseDate = (premise.installationDate) ? utils.displayDate(premise.installationDate) : '-';
    const installationDate = (premise.deviceInstallationDate) ? utils.displayDate(premise.deviceInstallationDate) : '-';
    const heatingType = (premise.heatingType) ? gettext(premise.heatingType) : '-';

    let mergedRootStyles = this.state.mergedRootStyles?this.state.mergedRootStyles:{};

    let smallcardtext1 ={} //was empty in style //mergedRootStyles.cardtext1;
    smallcardtext1.paddingTop = '0px';
    smallcardtext1.fontSize = '12px';
    smallcardtext1.display = 'block';

    //const Row = (props) => <mui.CardText style={mergedRootStyles.cardtext4}>{props.label}<span style={{float:'right'}}>{props.value}</span></mui.CardText>;
    const Row = (props) => <tr><th>{props.label}</th><td>{props.value}</td></tr>;

    return (
      <div style={mergedRootStyles.cardSize}>
        <mui.Card style={mergedRootStyles}>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
            <div style={mergedRootStyles.cardSize}>
              <div style={mergedRootStyles.headerSize}>
                <span style={mergedRootStyles.title}>{gettext('ui_premise_info').toUpperCase()}</span>
                <mui.Avatar className="clickable" style={mergedRootStyles.button}  onClick={this.openInfo.bind(this)} ><PenIcon /></mui.Avatar>
              </div>
              <hr style={mergedRootStyles.line}/>
              <mui.CardText style={mergedRootStyles.cardtext1}>
                {premiseName.toUpperCase()}
                <span style={smallcardtext1}>{premiseAddress}</span>
              </mui.CardText>
              <table className="card-table">
                <tbody>
                  <Row label={gettext('ui_apartment_count')} value={apartmentCount} />
                  <Row label={gettext('ui_resident_count')} value={residentCount} />
                  <Row label={gettext('ui_construction_year')} value={constructionYear} />
                  <Row label={gettext('ui_service_taken_use_date')} value={serviceTakenUseDate} />
                  <Row label={gettext('ui_monitoring_installation_date')} value={installationDate} />
                  <Row label={gettext('ui_energy_type')} value={heatingType} />
                </tbody>
              </table>
            </div>
          <div style={mergedRootStyles.cardbackgrounds.header}/>
        </mui.Card>
      </div>
    );
  }
}
