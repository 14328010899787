import alt from "../utils/alt";
import DeviceActions from "../actions/DeviceActions";

class DeviceStore {
  constructor() {

    this.bindActions(DeviceActions);

    this.state = {
      devices: null,
      isLoading: false,
      events: {},
      premiseNames:{}
    };
  }

  onReset() {
    this.setState({ devices: null });
  }

  onGetDevices(data) {
    this.setState(data);
  }

  onGetDeviceEvents(d) {
    this.state.events[d.deviceId] = d.res.data || null; 
  }

  onGetAllPremiseNames(r){
    let names = []
    r.premises.forEach(({_id, name})=>{
       names[_id] = name;
    })
    this.state.premiseNames = names;

  }

  onGetAllEndDatePremises(r){
    if(r.devices && r.premises){
      for (let d = 0; d < r.devices.length; d++) {
        let e = r.devices[d];
        e.premise = _.find(r.premises,(p)=>{
          return p.devices.indexOf(e.deviceId) > -1
          })
        e.managers = []
        if(e.premise && e.premise.interestGroups && e.premise.interestGroups.length > 0  ){
          for (let i= 0; i < e.premise.interestGroups.length; i++) {
            const m = e.premise.interestGroups[i];
            if(m.type === 'manager'){
              e.managers.push(m.name)
            }
          }
        }  
        e.endDate = e.premise.endDate;  
      }
      r.sorted = _.sortBy(r.devices, 'endDate')
    }
    this.setState(r);

  }

}

//module.exports = alt.createStore(DeviceStore);
export default alt.createStore(DeviceStore);
