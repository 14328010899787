import alt from "../utils/alt";
import UserActions from "../actions/UserActions";
import _ from 'lodash';

class UserStore {
  constructor() {
    this.bindActions(UserActions);
    this.state = {users:[],
                  newUser: {},
                  salePersons:[]};
  }

  onQuery(data) {
    this.setState({ users: data });
  }
  onSalePersonQuery(data) {
    this.setState({ salePersons: data });
  }

  onChange(obj) {
    console.log("onChange: ", obj)
    if (_.isEmpty(obj)) {
      this.state.newUser = {}
    } else {
      _.assign(this.state.newUser, obj)
    }
    this.setState({newUser: this.state.newUser});
  }
}

//module.exports = alt.createStore(UserStore);
export default alt.createStore(UserStore);
