import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import LoginStore from '../stores/LoginStore';
import LoginActions from '../actions/LoginActions';
import { TextField } from 'material-ui';
import { changeHandler } from '../utils/utils';
import BaseView from './BaseView';
import { gettext } from 'i18n';
import config from '../utils/config';
import logo from "../images/Smartvatten_rgb_wide.svg"
import Icon from '@material-ui/core/Icon';
import {Button} from '@material-ui/core';

@connectToStores
@changeHandler
export default class LoginView extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      login: {}
    };
  }
  static contextTypes = {
    router: PropTypes.func
  }
  static propTypes = {
    error: PropTypes.string,
    routeParams: PropTypes.object,
    location: PropTypes.object
  }
  static getStores() {
    return [LoginStore];
  }
  static getPropsFromStores() {
    return LoginStore.getState();
  }
  componentDidMount() {
    /*this.state = {
      login: {}
    };*/
    //console.log("login view",this.state,this.props,this.context);
    const { match: { params } } = this.props;
    if (params.token) {
      let redirect = window.location.href.match(/.*?\?.*?redirect=([^&]+)/);

      LoginActions.login({
        token: params.token,
        redirect: redirect && redirect[1]
      })
    } else if (this.props.location.pathname === '/login/callback/') {
      console.log('unauthorized google login')
    }
  }

  login() {
    var l = this.state.login
    LoginActions.login({
      username: l.username,
      password: l.password
    });
  }

  render() {
    return (
      <div className="login">
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-3">
            <div className="center">
              <img style={{ height: '40px'}} src={'assets/' + logo} />
            </div>

            <button style={{ height: '50px', marginTop: '40px' }} className="button raised google-btn" onClick={() => { window.location.href = config.APISERVER + "/auth/google" }}>
              <i className="mdi mdi-google-plus" style={{ marginRight: '10px' }} />
              {gettext('Google login')}
            </button>

            <div className="or-spacer">
              <div className="mask"></div>
              <span><i>{gettext('or')}</i></span>
            </div>

            {<button style={{ height: '50px', marginTop: '40px' }} className="button raised ms-btn" onClick={() => { window.location.href = config.APISERVER + "/auth/msauth" }}>
             {gettext('Microsoft login')}
            </button>}

            <div className="or-spacer">
              <div className="mask"></div>
              <span><i>{gettext('or')}</i></span>
            </div>

            <form onSubmit={e => { e.preventDefault(); this.login(this) }}>
              <TextField style={{ width: '100%' }}
                hintText=""
                type="email"
                name="username"
                id="username"
                value={this.state.login.username}
                onChange={this.changeHandler.bind(this, 'login', 'username')}
                placeholder={gettext('ui_email')}
              />

              <TextField style={{ width: '100%', marginBottom: '10px' }}
                type="password"
                placeholder={gettext('ui_password')}
                id="password"
                ref="password"
                onChange={this.changeHandler.bind(this, 'login', 'password')}
              />
              <small className="error-text" >{this.props.error && gettext(this.props.error)}</small>
              <button style={{ height: '50px' }} className="button raised login-btn" type="submit">{gettext('Smartvatten login')}</button>
            </form>

            <div style={{ color: '#696969', marginTop: '10px' }} className="center">&copy; Smartvatten 2023</div>
          </div>
        </div>
      </div>
    );
  }
}
