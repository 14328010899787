import React from 'react';
//import * as mui from 'material-ui';
import {gettext} from 'i18n';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ViewLoader extends React.Component {
  render() {
    let color = this.props.color || (this.props.blue ? '#0D429B' : 'white');
    let style = !this.props.noMargins ? {
      padding: '50px',
      textAlign: 'center'
    } : {
      padding: '15px',
      margin: 0,
      textAlign: 'center'
    };

    return (
      <div className="container" style={style}>
        <CircularProgress mode="indeterminate"  />
      </div>
    );
  }
}
