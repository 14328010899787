import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '../components/AppBar';
import BaseView from './BaseView';
import * as mui from 'material-ui';
import { gettext } from 'i18n';
import connectToStores from 'alt-utils/lib/connectToStores';
import AlarmActions from '../actions/Alarmv2Actions';
import UserStore from '../stores/UserStore';
//import UserActions from '../actions/UserActions';
import AlarmStore from '../stores/AlarmStorev2';
import Utils from '../utils/utils';
import LoginStore from '../stores/LoginStore';
import TextInput from '../components/TextInput';
import history from '../utils/history'
import SendAlarmDialog from '../components/SendAlarmsDialogv2';
import { Bar } from "react-chartjs-2";
import moment from 'moment';
import { MenuItem } from 'material-ui';
//import SelectBox from '../components/SelectBox';
import SearchFilter from '../components/SearchFilter';
//import Pager from '../components/Pager';
import PremiseStore from '../stores/PremisesStore';
import PremiseActions from '../actions/PremiseActions'
import _ from 'lodash';

//new material ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
const MyRawTheme = require('../components/Theme');
import MenuIcon from 'material-ui/svg-icons/navigation/menu';

@connectToStores
export default class AlarmView2 extends BaseView {

  constructor(props) {
    super(props);
    let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      muiTheme: muiTheme,
      forwardData: {
        premiseId: null,
        message: '',
        subject: '',
      },
      eventHistory: [],
      selectedFullAlarm: {},
      query: {},
      alarmHistoryDialogOpen: false,
      ackAlarmDialogOpen: false,
      fullAlarmDialogOpen: false,
    };
    this.historyLimit = 20;
    this.comment = '';
    this.alarmId = null;
    //TODO: filters does not support multiselection
    this.alarmTypes = ['night-flow'];
    this.singleAlarmHistoryType = null;
  }

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static getStores() {
    return [AlarmStore, PremiseStore, LoginStore, AlarmStore/*, UserStore*/];
  }

  static getPropsFromStores() {
    return {
      ...AlarmStore.getState(),
      ...LoginStore.getState(),
      ...PremiseStore.getState(),
      //salepersons: UserStore.getState().salePersons
    }
  }

  componentWillMount() {
    //limit default alarms shown
    //dirty way of doing this. But SearchFilter uses sessionStorage to store filter states...
    let filter = sessionStorage.getItem('filter');
    if (!filter) {
      sessionStorage.setItem('filter', JSON.stringify({ startAfter: { type: "startAfter", value: moment().subtract(1, 'day').startOf('day').toISOString() } }));
    }
  }

  componentDidMount() {
    let json = sessionStorage.getItem('query');
    if (json) {
      this.state.query = JSON.parse(json);
      if (this.state.query.alarmType) this.alarmTypes = [this.state.query.alarmType];
    }
    let premiseId = (this.props.match.params.id) ? this.props.match.params.id : null;
    //SearchFilter will initiate alarm fetch. No need to do it here.
    if (premiseId) {
      PremiseActions.queryById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    let oldId = (prevProps.match.params.id) ? prevProps.match.params.id : null;
    let newId = (this.props.match.params.id) ? this.props.match.params.id : null;
    if (newId !== oldId) {
      AlarmActions.getInitialAlarms(this.props.match.params.id, this.alarmTypes, this.historyLimit);
    }
  }

  componentWillUnmount() {
    AlarmActions.resetAlarmData();
    this.historyLimit = 20;
  }

  onAckAlarm() {
    //this.refs.ackAlarmDialog.dismiss();
    this.setState({ ackAlarmDialogOpen: false });
    if (this.props.params && this.props.params.id) {
      AlarmActions.ackAlarm(this.alarmId, this.props.params.id, this.comment, this.alarmTypes, this.historyLimit)
    }
    this.alarmId = null;
    this.comment = '';
  }

  open(id) {
    this.alarmId = id;
    //this.refs.ackAlarmDialog.show();
    this.setState({ ackAlarmDialogOpen: true });
  }

  close() {
    //this.refs.ackAlarmDialog.dismiss();
    this.setState({ ackAlarmDialogOpen: false });
    this.alarmId = null;
    this.comment = '';
  }

  onCommentChange(value) {
    this.comment = value;
  }

  onOpenSendAlarmDialog(id, subject, message, premiseId, alarmId, action, state) {
    let temp = {};
    let eventHistory = []
    if (id) temp.premiseId = id;
    if (message) temp.message = message;
    if (subject) temp.subject = subject;
    if (alarmId) temp.alarmId = alarmId;
    if (action) temp.action = action;
    if (state) temp.state = state; //1 active, 2 on deactive
    if (this.props.activeAlarms && state === 1) {
      let h = _.find(this.props.activeAlarms, { _id: alarmId });
      eventHistory = h && h.routingHistory ? h.routingHistory : [];
    } else if (this.props.historyAlarms && state === 2) {
      let h = _.find(this.props.historyAlarms, { _id: alarmId });
      eventHistory = h && h.routingHistory ? h.routingHistory : [];
    }
    this.setState({ forwardData: temp, eventHistory });
    this.refs.sendDialog.open();
    if (alarmId) {
      AlarmActions.mailReadStatus(alarmId);
    }
  }

  onSendAlarmClose() {
    this.setState({ forwardData: {}, eventHistory: [] });
    if (this.props.match.params && this.props.match.params.id) {
      AlarmActions.getInitialAlarms(this.props.match.params.id, this.alarmTypes, this.historyLimit);
    }
  }

  onAlarmForward(receiverlist, subject, message) {
    let receivers = receiverlist.map(d => ({ email: d }))
    this.refs.sendDialog.close();
    AlarmActions.sendEmail({ recipients: receivers, subject: subject, html: message }, this.state.forwardData.alarmId, this.state.forwardData.action);
    this.setState({ forwardData: {}, eventHistory: [] });
  }

  onSinglehistoryClick(deviceId, type) {
    console.log("onSinglehistoryClick:", deviceId, type);
    let start = moment().startOf('day');
    this.singleAlarmHistoryType = type;
    if (type === 'night-flow') {
      start.subtract(14, 'days');
    } else if (type === 'night-flow-low-service') {
      start.subtract(5, 'days');
    } else {
      start.subtract(10, 'days');
    }
    AlarmActions.singleAlarmdata(deviceId, type, start);
    //this.refs.alarmHistoryDialog.show()
    this.setState({ alarmHistoryDialogOpen: true })
  }
  onShowFullAlarmClick(alarm) {
    console.log("onShowFullAlarmClick", alarm)
    this.setState({ selectedFullAlarm: alarm, fullAlarmDialogOpen: true });
  }


  activeAlarmRows() {
    let array = [];
    if (this.props.activeAlarms.length < 1) return;
    const q = {}
    for (let p in this.state.query) {
      q[p] = this.state.query[p]
    }
    if (q.startAfter) {
      q.startAfter = moment(q.startAfter).format()
    }
    if (q.startBefore) {
      q.startBefore = moment(q.startBefore).format()
    }
    if (q.endAfter) {
      q.endAfter = moment(q.endAfter).format()
    }
    if (q.endBefore) {
      q.endBefore = moment(q.endBefore).format()
    }
    // console.log('filter alarms by query:', q)
    this.props.activeAlarms.forEach(u => {
      // console.log('alarm:', u)
      for (let p in q) {
        if (p === 'startBefore' && u.start >= q[p]) {
          return
        } else if (p === 'startAfter' && u.start < q[p]) {
          return
        } else if (p === 'endBefore' && u.end && u.end >= q[p]) {
          return
        } else if (p === 'endAfter' && u.end && u.end < q[p]) {
          return
        } else if (p === 'alarmType' && u.name !== q[p]) {
          //TODO: filters does not support multiselection
          return
        }
      }
      let email_body = '', email_subject = '', alarm_text = '';

      email_body = `Smartvatten kohteessa ${u.premise.name} on ${u.name} hälytys`;
      email_subject = `Hälytys: ${u.premise.name}`;

      if (u.name.indexOf("night-flow") !== -1) {
        let minRunNight = u.daily && u.daily.minRunNight !== null ? u.daily.minRunNight : null;
        if (!minRunNight) {
          minRunNight = (u.eventHistory && u.eventHistory[0] && u.eventHistory[0].event
            && u.eventHistory[0].event.data && u.eventHistory[0].event.data.minRunNight) ? parseFloat(u.eventHistory[0].event.data.minRunNight) : '-';
        }
        alarm_text = (minRunNight && !isNaN(minRunNight)) ? `${gettext(u.name)} ${minRunNight.toFixed(2)} l/min` : gettext(u.name);
      } else if (u.name === "high-flow") {
        //TODO: find data
        //alarm_text = `${gettext(u.name)} ${u.old[0].toFixed(1)} -> ${u.new.toFixed(1)} l/min`;
        let text = (u.eventHistory && u.eventHistory[0] && u.eventHistory[0].event
          && u.eventHistory[0].event.data && u.eventHistory[0].event.data.text) ? ' ' + u.eventHistory[0].event.data.text : '';
        alarm_text = gettext('high-flow') + text;
      } else {
        alarm_text = `${u.name}-hälytys`;
      }


      //let iconButtonElement = (<mui.IconButton style={{ height: '42px', padding: '0px' }}><MenuIcon /></mui.IconButton>);
      let items = [];
      items.push(<MenuItem key={"forwardAlarm" + u._id} primaryText={gettext("ui_forward_alarm")} onClick={() => this.onOpenSendAlarmDialog(u.premise._id, email_subject, email_body, u.premise._id, u._id, "send_alarm", 1)} />);
      if (!u.ack) items.push(<MenuItem key={"ack" + u._id} primaryText={gettext("ui_acknowledge")} onClick={() => this.open(u._id)} />);
      items.push(<MenuItem key={"singleAlarmDialog" + u._id} primaryText={gettext("ui_single_alarm_history")} onClick={() => this.onSinglehistoryClick(u.sourceId, u.name)} />);
      if (!(this.props.params && this.props.params.id)) items.push(<MenuItem key={"premiseAlarms" + u._id} primaryText={gettext("ui_premise_alarms")} onClick={() => history.replace(`/alarms/${u.premise._id}`)} />);
      if (u.premise._id) items.push(<MenuItem key={"edit" + u._id} primaryText={gettext("ui_edit_premise")} onClick={() => history.push('/premises/edit/' + u.premise._id)} />);
      if (u && this.props.root) items.push(<MenuItem key={"fullAlarm" + u._id} primaryText={gettext("ui_show_full_alarm")} onClick={() => this.onShowFullAlarmClick(u)} />);

      let reasons = [];
      if (u.reasonClasses) { u.reasonClasses.forEach((reason) => { reasons.push(<div className="leakReasonDiv" key={reason}>-{gettext(reason)}</div>); }) }

      let temp = (
        <tr key={u._id}>
          <td className="pointer" style={{ color: '#0844F6', fontWeight: 500 }} onClick={() => { history.push(`/analyze/${u.premise._id}/${u.sourceId}`) }}>
            {`${u.premise.name} (id:${u.sourceId})`}
          </td>
          <td className="pointer" style={this.getColor(u.name)} onClick={this.onSinglehistoryClick.bind(this, u.sourceId, u.name)} >{alarm_text}</td>
          <td>{Utils.getDateString(u.start)}</td>
          <td>{u.ack ? (`${Utils.getDateString(u.ack)} (${u.acker})`) : (
            <div className="box">
              <mui.FlatButton
                backgroundColor="lightblue"
                style={{ height: '20px', lineHeight: '0px' }}
                label={gettext('ui_acknowledge').toUpperCase()}
                onClick={() => this.open(u._id)} />
            </div>)}
          </td>
          <td>{u.text ? u.text : ''} {reasons}</td>
          <td>
            <mui.IconMenu desktop={true} iconButtonElement={<mui.IconButton><MenuIcon /></mui.IconButton>}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {items}
            </mui.IconMenu>
          </td>
        </tr>
      )
      array.push(temp);
    })
    return array;
  }

  getColor(name) {
    if (name.indexOf("night-flow") !== -1) return { color: '#F60844' };
    if (name.indexOf("high-flow") !== -1) return { color: '#F6BA08' };
  }

  passiveAlarmRows() {
    let array = [];
    const q = {}
    for (let p in this.state.query) {
      q[p] = this.state.query[p]
    }
    if (q.startAfter) {
      q.startAfter = moment(q.startAfter).format()
    }
    if (q.startBefore) {
      q.startBefore = moment(q.startBefore).format()
    }
    if (q.endAfter) {
      q.endAfter = moment(q.endAfter).format()
    }
    if (q.endBefore) {
      q.endBefore = moment(q.endBefore).format()
    }
    // console.log('filter alarms by query:', q)
    this.props.historyAlarms.forEach(u => {
      // console.log('alarm:', u)
      for (let p in q) {
        if (p === 'startBefore' && u.start >= q[p]) {
          return
        } else if (p === 'startAfter' && u.start < q[p]) {
          return
        } else if (p === 'endBefore' && u.end && u.end >= q[p]) {
          return
        } else if (p === 'endAfter' && u.end && u.end < q[p]) {
          return
        } else if (p === 'alarmType' && u.name !== q[p]) {
          return
        }
      }
      let email_body = '', email_subject = '', alarm_text = '';

      email_body = `Kohteen ${u.premise.name} ${u.name}-hälytys on poistunut.`;
      email_subject = `Hälytys poistunut: ${u.premise.name}`;

      if (u.name.indexOf("night-flow") !== -1) {
        let minRunNight = u.daily && u.daily.minRunNight !== null ? u.daily.minRunNight : null;
        if (!minRunNight) {
          minRunNight = (u.eventHistory && u.eventHistory[0] && u.eventHistory[0].event
            && u.eventHistory[0].event.data && u.eventHistory[0].event.data.minRunNight !== 0) ? parseFloat(u.eventHistory[0].event.data.minRunNight) : 0;
        }

        alarm_text = (minRunNight && (minRunNight !== 0)) ? `${gettext(u.name)} ${minRunNight.toFixed(2)} l/min` : gettext(u.name);
      } else if (u.name === "high-flow") {
        //TODO: find data
        //alarm_text = `${gettext(u.name)} ${u.old[0].toFixed(1)} -> ${u.new.toFixed(1)} l/min`;
        alarm_text = gettext('high-flow');
      } else {
        alarm_text = `${u.name}-hälytys`;
      }

      //let iconButtonElement = (<mui.IconButton style={{ height: '42px', padding: '0px' }}><MenuIcon /></mui.IconButton>);
      let items = [];
      items.push(<MenuItem key={"forwardAlarm_h_" + u._id} primaryText={gettext("ui_forward_alarm")} onClick={() => this.onOpenSendAlarmDialog(u.premise._id, email_subject, email_body, u.premise._id, u._id, "send_alarm", 2)} />);
      if (!u.ack) items.push(<MenuItem key={"ack_h_" + u._id} primaryText={gettext("ui_acknowledge")} onClick={() => this.open(u._id)} />);
      items.push(<MenuItem key={"singleAlarmDialog_h_" + u._id} primaryText={gettext("ui_single_alarm_history")} onClick={() => this.onSinglehistoryClick(u.sourceId, u.name)} />);
      if (!(this.props.params && this.props.params.id)) items.push(<MenuItem key={'premiseAlarms_h_' + u._id} primaryText={gettext("ui_premise_alarms")} onClick={() => history.push(`/alarms/${u.premise._id}`)} />);
      if (u.premise._id) items.push(<MenuItem key={"edit_h_" + u._id} primaryText={gettext("ui_edit_premise")} onClick={() => history.push('/premises/edit/' + u.premise._id)} />);
      if (u && this.props.root) items.push(<MenuItem key={"fullAlarm_h_" + u._id} primaryText={gettext("ui_show_full_alarm")} onClick={() => this.onShowFullAlarmClick(u)} />);

      let temp = (
        <tr key={u._id}>
          <td className="pointer" style={{ color: '#0844F6', fontWeight: 500 }} onClick={() => { history.push(`/analyze/${u.premise._id}/${u.sourceId}`) }}>
            {`${u.premise.name} (id:${u.sourceId})`}
          </td>
          <td className="pointer" style={this.getColor(u.name)} onClick={this.onSinglehistoryClick.bind(this, u.sourceId, u.name)} >{alarm_text}</td>
          <td>{Utils.getDateString(u.start)}</td>
          <td>{Utils.getDateString(u.end)}</td>
          <td>{u.ack ? (`${Utils.getDateString(u.ack)} (${u.acker})`) : (
            <div className="box">
              <mui.FlatButton
                backgroundColor="lightblue"
                style={{ height: '20px', lineHeight: '0px' }}
                label={gettext('ui_acknowledge').toUpperCase()}
                onClick={() => this.open(u._id)} />
            </div>)}
          </td>
          <td>{u.text ? u.text : ''}</td>
          <td>
            <mui.IconMenu desktop={true} iconButtonElement={<mui.IconButton><MenuIcon /></mui.IconButton>}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {items}
            </mui.IconMenu>
          </td>
        </tr>
      )
      array.push(temp);
    })
    return array;
  }

  onLoadMore() {
    this.historyLimit += 100;
    let premiseId = (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : null;
    AlarmActions.getAlarmHistory(premiseId, this.alarmTypes, this.historyLimit);
  }

  onFilterChange(filters) {
    if (filters === this.state.query) return
    let premiseFilters = {};
    if (filters && filters.interestGroups) {
      premiseFilters.interestGroups = filters.interestGroups
      this.historyLimit = 200
    }
    if (filters && filters.postOffice) {
      premiseFilters.postOffice = filters.postOffice
      this.historyLimit = 20
    }

    if (filters && filters.zipCode) {
      premiseFilters.zipCode = filters.zipCode
      this.historyLimit = 20
    }

    if (filters && filters.owner) {
      premiseFilters.owner = filters.owner
      this.historyLimit = 200
    }

    if (filters && filters.alarmType && (this.state.alarmType !== filters.alarmType)) {
      this.alarmTypes = [filters.alarmType]
      AlarmActions.resetAlarmData();
      AlarmActions.getInitialAlarms((this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : null, this.alarmTypes, this.historyLimit, premiseFilters);
    } else {
      //TODO: filters does not support multiselection
      this.alarmTypes = ['night-flow'];
      filters.alarmType = 'night-flow';
      AlarmActions.resetAlarmData();
      let fil = {};
      if (filters.startAfter) {
        fil.start = { $gt: { "$date": filters.startAfter } };
        fil.name = { $in: ["night-flow"] }
        AlarmActions.getFilteredAlarms((this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : null, this.alarmTypes, this.historyLimit, premiseFilters, fil);
        return;
      }
      AlarmActions.getInitialAlarms((this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : null, this.alarmTypes, this.historyLimit, premiseFilters);
    }
    sessionStorage.setItem('query', JSON.stringify(filters));
    this.setState({ query: filters })
  }

  onRemoveFilterItem(filters) {
    sessionStorage.removeItem('query');
    sessionStorage.setItem('query', JSON.stringify(filters));
  }

  render() {
    let activeTable = '', historyTable = '';
    let selectedPremiseName = null;
    if (this.props.params && this.props.params.id) {
      selectedPremiseName = (this.props.activeAlarms && this.props.activeAlarms[0]
        && this.props.activeAlarms[0].premise && this.props.activeAlarms[0].premise.name) ?
        this.props.activeAlarms[0].premise.name : null;
      if (!selectedPremiseName) {
        selectedPremiseName = (this.props.historyAlarms && this.props.historyAlarms[0]
          && this.props.historyAlarms[0].premise && this.props.historyAlarms[0].premise.name) ?
          this.props.historyAlarms[0].premise.name : null;
      }
      if (!selectedPremiseName && (this.props.premise && this.props.premise.name)) {
        selectedPremiseName = this.props.premise.name;
      }
    }

    //TODO: filters does not support multiselection
    let alarmTypesString = null
    if (this.alarmTypes) {
      let alarmTypesStringArray = this.alarmTypes.map((t, i) => {
        return <span key={i}>{gettext(t)}</span>
      })
      alarmTypesString = (alarmTypesStringArray.length > 0 ? <span style={{ fontSize: '12px', marginLeft: '10px' }}>( {alarmTypesStringArray} )</span> : '');
    }


    if (this.props.activeAlarms && this.props.activeAlarms.length > 0) {
      activeTable = (
        <table className="table activeAlarms">
          <thead>
            <tr>
              <th style={{ width: '15%' }}>{gettext('ui_premise')}</th>
              <th style={{ width: '20%' }}>{gettext('ui_cause')}</th>
              <th style={{ width: '20%' }}>{gettext('ui_timestamp_start')}</th>
              <th style={{ width: '20%' }}>{gettext('ui_acknowledgment')}</th>
              <th style={{ width: '25%' }}>{gettext('ui_comment')}</th>
            </tr>
          </thead>
          <tbody>
            {this.activeAlarmRows()}
          </tbody>
        </table>
      )
    } else if (this.props.activeAlarms && this.props.activeAlarms.length === 0) {
      activeTable = <h3 style={{ color: '#7ed632' }}>{gettext('no_alarms')}</h3>;
    } else {
      activeTable = <div style={{ marginLeft: '48vw' }}> <mui.CircularProgress mode="indeterminate" /> </div>;
    }

    if (this.props.historyAlarms && this.props.historyAlarms.length > 0) {
      historyTable = (
        <table className="table alarms">
          <thead>
            <tr>
              <th style={{ width: '15%' }}>{gettext('ui_premise')}</th>
              <th style={{ width: '15%' }}>{gettext('ui_cause')}</th>
              <th style={{ width: '15%' }}>{gettext('ui_timestamp_start')}</th>
              <th style={{ width: '15%' }}>{gettext('ui_timestamp_end')}</th>
              <th style={{ width: '10%' }}>{gettext('ui_acknowledgment')}</th>
              <th style={{ width: '25%' }}>{gettext('ui_comment')}</th>
            </tr>
          </thead>
          <tbody>
            {this.passiveAlarmRows()}
          </tbody>
        </table>
      )
    } else if (this.props.historyAlarms && this.props.historyAlarms.length === 0) {
      historyTable = <h3 style={{ color: '#7ed632' }}>{gettext('no_ended_alarms')}</h3>;
    } else {
      historyTable = <div style={{ marginLeft: '48vw' }}> <mui.CircularProgress mode="indeterminate" /> </div>;
    }
    var dialog = (
      <Dialog open={this.state.ackAlarmDialogOpen} maxWidth='md' fullWidth={true}>
        <DialogTitle>
          <h3>{gettext('ui_acknowledge_alarm')}</h3>
        </DialogTitle>
        <DialogContent>
          <TextInput
            inputType="textarea"
            label={gettext('ui_alarm_comment')}
            onChange={this.onCommentChange.bind(this)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
          <Button onClick={() => (this.onAckAlarm())} >{gettext('ui_send_alarm')}</Button>
        </DialogActions>
      </Dialog>
    )

    var fullAlarmDialog = (
      <Dialog
        open={this.state.fullAlarmDialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogContent>
          <div className="row middle-md">
            <div className="col-md-12 col-xs-12">
              <pre>{this.state.selectedFullAlarm ? JSON.stringify(this.state.selectedFullAlarm, null, 2) : ''}</pre>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => (this.setState({ fullAlarmDialogOpen: false }))} >{gettext('ui_close')}</Button>
        </DialogActions>
      </Dialog>
    )

    let alarmHistoryDialog = '';
    if (this.props.singleAlarmdata) {
      let labels = [];
      this.props.singleAlarmdata.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      let data = [];
      this.props.singleAlarmdata.forEach((item) => {
        if (this.singleAlarmHistoryType === "night-flow") {
          let minRunNight = !isNaN(item.minRunNight) && item.minRunNight;
          if (isNaN(item.minRunNight) || minRunNight === 0 && item.minRunNightHourAvg > 0) { minRunNight = item.minRunNightHourAvg; }
          if (!isNaN(minRunNight)) {
            labels.push(Utils.displayDate(item.date));
            data.push(minRunNight);
          }
        } else if (this.singleAlarmHistoryType === 'high-flow') {
          if (!isNaN(item.maxrun4)) {
            labels.push(Utils.displayDate(item.date));
            data.push(item.maxrun4);
          }
        } else if (this.singleAlarmHistoryType === 'night-flow-low-service') {
          if (item.nightHourRuns && item.nightHourRuns.length > 0) {
            for (let i = 0; i < item.nightHourRuns.length; i += 1) {
              labels.push(moment(item.nightHourRuns[i].timestamp).subtract(1, 'h').format('DD/MM HH:00'));
              data.push(item.nightHourRuns[i].runHour.toFixed(1));
            }
          }
        } else {
          // Jos halutaan hakea muita historiadatoja
        }
      })

      let options = {
        animation: false,
        tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value %> l/min",
        tooltipFillColor: "rgba(33,150,243,0.8)",
        multiTooltipTemplate: "<%= value %> l/min",
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      };

      if (this.singleAlarmHistoryType === 'night-flow-low-service') {
        options.tooltipTemplate = "<%if (label){%><%=label%>: <%}%><%= value %> l/h";
        options.multiTooltipTemplate = "<%= value %> l/h";
      }

      var chartData = {
        labels: labels,
        datasets: [{
          data: data,
          label: gettext("Consumption"),
          backgroundColor: "rgba(33,150,243,0.5)",
          borderColor: this.state.muiTheme.rawTheme.palette.primary1Color,
          pointColor: "rgba(151,187,205,1)",
          borderWidth: 2,
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(151,187,205,1)"
        }]
      };

      let graph = data.length ? (<Bar data={chartData} options={options} redraw={true} />) : "Ei historiatietoja";

      alarmHistoryDialog = (
        <Dialog
          ref="alarmHistoryDialog"
          modal={false}
          maxWidth='lg'
          fullWidth={true}
          open={this.state.alarmHistoryDialogOpen}
          autoDetectWindowHeight={false}
          autoScrollBodyContent={true}>
          <DialogTitle>{gettext('ui_history')}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-md-10 col-xs-10 col-md-offset-1 col-xs-offset-1 center">
                {graph}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ alarmHistoryDialogOpen: false })} color="primary">
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      )
    }

    let interestGroups = [];
    if (this.state.forwardData && this.state.forwardData.alarmId && this.state.forwardData.state) {
      let a = []
      if (this.state.forwardData.state === 1) {
        a = _.find(this.props.activeAlarms, { _id: this.state.forwardData.alarmId })
      } else if (this.state.forwardData.state === 2) {
        a = _.find(this.props.historyAlarms, { _id: this.state.forwardData.alarmId })
      }
      if (a && a.premise.interestGroups) interestGroups = a.premise.interestGroups;
    }

    let sendHistory = this.state.eventHistory ? this.state.eventHistory : [];

    // filters -->
    let salePersonsArray = []
    if (this.props.salepersons) {
      salePersonsArray = this.props.salepersons.map(person => {
        return { value: person._id, label: person.name }
      })
      salePersonsArray.sort((a, b) => {
        if (!a.label || !b.label) return -1;
        return a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
      }
      )
    }

    const alarmTypes = [
      { value: 'night-flow', label: gettext('night-flow') },
      { value: 'high-flow-client', label: gettext('high-flow') },
      { value: 'high-flow-smart', label: gettext('high-flow') + "-smart" },
      { value: 'flowone-zero-monthly-consumption', label: gettext('flowone-zero-monthly-consumption') },
      { value: 'no-period-data', label: gettext('no-period-data') },
      { value: 'smart-meter-leak', label: gettext('smart-meter-leak') },
      { value: 'smart-meter-burst', label: gettext('smart-meter-burst') },
      { value: 'smart-meter-no-data', label: gettext('smart-meter-no-data') },
      { value: 'night-flow-low-service', label: gettext('night-flow-low-service') },
      { value: 'night-flow-low-service-low-count', label: gettext('night-flow-low-service-mika') }

    ]

    if (this.props.root) {
      alarmTypes.push({ value: 'post-device-connected', label: gettext('post-device-connected') });
      alarmTypes.push({ value: 'high-flow', label: gettext('high-flow-debug') });
      alarmTypes.push({ value: 'digitsfail', label: gettext('digitsfail') });
      alarmTypes.push({ value: 'digitsIntegerMissing', label: gettext('digitsIntegerMissing') });
      alarmTypes.push({ value: 'no-data', label: gettext('no-data') });
      alarmTypes.push({ value: 'night-flow-v2', label: gettext('night-flow-v2') });
      alarmTypes.push({ value: 'night-flow-v3', label: gettext('night-flow-v3') });
      alarmTypes.push({ value: 'night-flow-v4', label: gettext('night-flow-v4') });
      alarmTypes.push({ value: 'test-night-flow-low-service', label: gettext('test-night-flow-low-service') });
    }

    const filters = [
      //{type: 'endBefore', prop: "endBefore", operator: '=', text: gettext('ui_end_before'), value: '', component: 'datePicker'},
      //{type: 'endAfter', prop: "endAfter", operator: '=', text: gettext('ui_end_after'), value: '', component: 'datePicker'},
      { type: 'alarmType', prop: "alarmType", text: gettext('ui_alarm_type'), operator: '=', options: alarmTypes },
      { type: 'startBefore', prop: "startBefore", operator: '=', text: gettext('ui_start_before'), value: '', component: 'datePicker' },
      { type: 'startAfter', prop: "startAfter", operator: '=', text: gettext('ui_start_after'), value: '', component: 'datePicker' },
      { type: 'maintenance', prop: "name", operator: '%', text: gettext('maintenance'), value: '', elem: 'interestGroups', additionalElem: { type: 'maintenance' } },
      { type: 'manager', prop: "name", operator: '%', text: gettext('ui_manager'), value: '', elem: 'interestGroups', additionalElem: { type: 'manager' } },
      { type: 'zipCode', prop: "zipCode", operator: '-%', text: gettext('ui_zipCode'), value: '' },
      { type: 'postOffice', prop: "postOffice", operator: '%', text: gettext('ui_postOffice'), value: '' },
      { type: 'owner', prop: "owner", operator: '%', text: gettext('CRM Owner'), value: '' }
    ]
    // <-- filters
    return (
      <div className="container alarmsView">
        <AppBar title={(selectedPremiseName) ? `${gettext('title_alarms')} - ${selectedPremiseName}` : gettext('title_alarms') + " v2"} backBtn />
        <div className="row top-xs">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <SearchFilter menuitems={filters} filters={alarmTypes.find((x) => x.value === this.state.query)} localStorage="filter" onChange={this.onFilterChange.bind(this)} onRemoveFilterItem={this.onRemoveFilterItem.bind(this)} />
          </div>
        </div>
        <div className="row top-xs" style={{ marginTop: '0px' }}>
          <div className="clearFix header">
            <h2 style={{ color: '#555', display: 'inline-block' }}>{gettext('active_alarms')} </h2>{alarmTypesString}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">{activeTable}</div>
        </div>
        <div className="row top-xs" style={{ marginTop: '50px' }}>
          <div className="clearFix">
            <h2 style={{ color: '#555', display: 'inline-block' }}>{gettext('alarm_history')} </h2>{alarmTypesString}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">{historyTable}</div>
        </div>
        {dialog}
        {alarmHistoryDialog}
        {fullAlarmDialog}
        <SendAlarmDialog
          ref="sendDialog"
          data={this.state.forwardData}
          onAlarmForward={this.onAlarmForward.bind(this)}
          interestGroups={interestGroups}
          sendHistory={sendHistory}
          mailReadStatus={this.props.mailReadStatus}
          onClose={this.onSendAlarmClose.bind(this)} />
        <div className="row">
          {this.historyLimit && this.props.historyAlarms && (this.historyLimit === this.props.historyAlarms.length) &&
            <Button
              style={{ margin: '50px auto 0px' }}
              variant='contained'
              onClick={() => (this.onLoadMore())} >{gettext('ui_load_more')}</Button>
          }
        </div>
        {this.props.fetchError && <Snackbar className="errorSnack" open={this.props.fetchError !== null} ref="ErrorBar" message={<span>{this.props.fetchError}</span>} />}
      </div>
    )
  }
}
