import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
let AP
class ApiPartnerActions {
  constructor() {
    this.generateActions('change', 'addinforow');
  }

  query(q) {
    return function (dispatch) {
      var b = new UrlBuilder()
      return api.get(b.path('apipartners').query(q)).then((res) => {
        dispatch({data:res.data, error:null});
      }).catch((err) => {
        console.error(err.stack);
        dispatch({data:[], error:err.message});
      });
    }
  }

  createNewApiPartnerKey() {
    return async (dispatch) => {
      var b = new UrlBuilder();
      try {
        let res = await api.get(b.path('apikeys/generatepartner'));
        dispatch({ key: res.data });
      } catch (err) {
        console.error(err.stack);
        dispatch({ error: err });
      }
    }
  }


  editApiPartner(data) {
    return (dispatch) => {
      var b = new UrlBuilder();
      return api.post(b.path('apipartners'), data).then((res) => {
        return AP.query();
      }).catch((err) => {
        console.error(err.stack);
        dispatch(null);
      })
    }
  }
}


AP = (alt.createActions(ApiPartnerActions));
export default AP;

