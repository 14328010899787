"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import UserStore from '../stores/UserStore';
import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import UserActions from '../actions/UserActions';
import SearchFilter from '../components/SearchFilter';
//import PropTypes from 'react-proptypes'
import { gettext } from 'i18n';
import _ from 'lodash';
import TextInput from '../components/TextInput';
import SelectBox from '../components/SelectBox';
import ViewLoader from '../components/ViewLoader';
import LoginStore from '../stores/LoginStore';
//import history from '../utils/history';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


@connectToStores
class UsersView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme: muiTheme,
      sortParams: { sortBy: 'name', order: 'asc' },
      errors: {},
      dialogOpen: false,
    };
  }

  static getStores() {
    return [UserStore, LoginStore];
  }

  static getPropsFromStores() {
    return {
      ...UserStore.getState(),
      ...LoginStore.getState()
    }
  }

  static propTypes = {
    users: PropTypes.array,
    newUser: PropTypes.object
  }

  componentDidMount() {
    this.initialQuery = setTimeout(UserActions.query, 100);
  }
  _onDialogRemoveUser(data) {
    UserActions.removeUser(this.props.newUser);
    UserActions.change({});
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
  }

  _onDialogCancel() {
    UserActions.change({});
    //this.refs.dialog.dismiss();
    this.setState({ dialogOpen: false });
  }

  _onDialogSubmit() {
    //this.refs.dialog.dismiss();
    console.log('onDialogSubmit', this.props.newUser);
    UserActions.editUser(this.props.newUser)
    UserActions.change({});
    this.setState({ dialogOpen: false });
  }

  openDialog(data) {
    console.log("OpenDialog: ", data)
    //if (this.refs.dialog) {
    if (data) UserActions.change(data);
    //this.refs.dialog.show();
    this.setState({ dialogOpen: true });
    //}
  }

  sortBy(sb, e) {
    if (sb === this.state.sortParams.sortBy && this.state.sortParams.order === "asc") {
      this.setState({ sortParams: { sortBy: sb, order: "desc" } });
    } else {
      this.setState({ sortParams: { sortBy: sb, order: "asc" } });
    }
  }

  onChange(value, id, textField, error) {
    var v = {};
    v[id] = value;
    UserActions.change(v);
    const errors = this.state.errors;
    errors[id] = error;
    // console.log('onchange value:', value, 'id:', id, 'error:', error, 'errors:', errors)
    this.setState({ errors });
  }

  onChangeMandatory(value, id, textField, error) {
    // console.log("onChange: ", value, id)
    if (textField.isMandatory() && !value) {
      textField.setErrorText(gettext('ui_pakollinen_kentta'));
    } else {
      textField.setErrorText(error)
    }
    this.onChange(value, id, textField, error);
  }

  onSelectionChange(value, id, selectBox) {
    var v = {};
    v[id] = value.value;
    UserActions.change(v);
    if (selectBox.isMandatory() && !value) {
      selectBox.setErrorText(gettext('ui_pakollinen_kentta'));
    } else {
      selectBox.setErrorText(null)
    }
  }

  onFilterChange(filters) {
    if (this.initialQuery) {
      clearTimeout(this.initialQuery);
      this.initialQuery = null;
    }
    UserActions.query(filters);
  }

  render() {

    let districtOptions = [
      { value: 'Kuopio', label: 'Kuopio' },
      { value: 'Tampere', label: 'Tampere' },
      { value: 'Helsinki', label: 'Helsinki' },
    ];
    if (this.props.role === 'admin') {
      districtOptions.push({ value: 'system', label: 'system' })
    }

    let roles = [
      { value: 'salesperson', label: gettext('ui_salesperson') },
      { value: 'foreman', label: gettext('ui_foreman') },
      { value: 'mechanic', label: gettext('ui_mechanic') },
      { value: 'configurator', label: gettext('ui_configurator') },
    ];

    if (this.props.role === 'admin') {
      roles.push({ value: 'admin', label: gettext('ui_admin') })
    }

    let filters = [
      {
        type: 'name',
        prop: "name",
        operator: '%',
        text: gettext('ui_name'),
        value: ''
      },
      {
        type: 'district',
        prop: "district",
        operator: '%',
        text: gettext('ui_district'),
        options: districtOptions
      },
      {
        type: 'role',
        prop: "role",
        operator: '%',
        text: gettext('ui_role'),
        options: roles
      }
    ];

    let dialogStyle = {
      backgroundColor: MyRawTheme.palette.accent2Color,
      border: '1px solid ' + MyRawTheme.palette.primary1Color,
      borderTop: '4px solid ' + MyRawTheme.palette.primary1Color,
      padding: '10px'
    }

    const roleName = (key) => {
      let role = roles.find((x) => x.value === key);
      return role ? role.label : '-';
    }

    let valid = this.props.newUser && this.props.newUser.name && this.props.newUser.username
    const errors = this.state.errors
    if (!this.props.newUser || !this.props.newUser.phonenumber) {
      delete errors.phonenumber
    }
    for (let p in errors) {
      if (errors[p]) {
        valid = false;
        break;
      }
    }

    let userDiabled=this.props.newUser.userdisabled;
    if(userDiabled!==1){
      userDiabled=0;
    }
    let userActiveOptions=[
      { value: 1, label: 'Disabled' },
      { value: 0, label: 'Active' }
    ]
    //console.log("DEBUG: ",userDiabled,this.props.newUser.userdisabled);

    let dialog = (
      <Dialog open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}>
        <DialogTitle>
          {(this.props.newUser.name) ? this.props.newUser.name : gettext('ui_editUser').toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-12 col-xs-12"><TextInput value={this.props.newUser.username} id="username" label={gettext('ui_username')} onChange={this.onChangeMandatory.bind(this)} /></div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12"><TextInput value={this.props.newUser.name} id="name" mandatory={true} label={gettext('ui_name')} onChange={this.onChangeMandatory.bind(this)} /></div>
          </div>
          <div className="row">
            <div className="col-md-5 col-xs-12"><TextInput value={this.props.newUser.address} id="address" label={gettext('ui_address')} onChange={this.onChangeMandatory.bind(this)} /></div>
            <div className="col-md-3 col-xs-12"><TextInput value={this.props.newUser.zipCode} id="zipCode" label={gettext('ui_zipCode')} onChange={this.onChangeMandatory.bind(this)} /></div>
            <div className="col-md-4 col-xs-12"><TextInput value={this.props.newUser.postOffice} id="postOffice" label={gettext('ui_postOffice')} onChange={this.onChangeMandatory.bind(this)} /></div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12"><TextInput value={this.props.newUser.phonenumber} id="phonenumber" label={gettext('ui_phonenumber')} onChange={this.onChangeMandatory.bind(this)} validations={{ phone: true }} /></div><div className="col-md-6"></div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12"><SelectBox label={gettext('ui_role')} value={roles.filter(({value})=>value===this.props.newUser.role)} placeholder={gettext('ui_choose')} name="role" options={roles} onSelectionChange={this.onSelectionChange.bind(this)} /></div>
            <div className="col-md-6 col-xs-12"><SelectBox label={gettext('ui_district')} value={districtOptions.filter(({value})=>value===this.props.newUser.district)} placeholder={gettext('ui_choose')} name="district" options={districtOptions} onSelectionChange={this.onSelectionChange.bind(this)} /></div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12"><TextInput value={this.props.newUser.password} id="password" validations={{ minLength: 6 }} type="password" label={gettext('ui_password')} onChange={this.onChange.bind(this)} /></div>
            <div className="col-md-6 col-xs-12"><SelectBox value={userActiveOptions.filter(({value})=>value===userDiabled)} options={userActiveOptions} name="userdisabled"  type="password" label={gettext('User Active')} onSelectionChange={this.onSelectionChange.bind(this)} /></div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onDialogRemoveUser.bind(this)} color='secondary'>{gettext('ui_removeUser').toUpperCase()}</Button>
          <Button onClick={this._onDialogCancel.bind(this)} color='primary'>{gettext('ui_cancel').toUpperCase()}</Button>
          <Button onClick={this._onDialogSubmit.bind(this)} ref="submit" color='primary' disabled={!valid} >{gettext('ui_submit').toUpperCase()}</Button>
        </DialogActions>
      </Dialog>
    )
    let users = this.props.users;
    if (!this.props.root) {
      users = _.remove(this.props.users, function (n) {
        return n.role !== 'configurator'
      })
    }

    var rows = _.map(_.orderBy(users, (i) => { return i[this.state.sortParams.sortBy] && i[this.state.sortParams.sortBy].toLowerCase(); }, [this.state.sortParams.order]), (u) => {
      return (
        <tr className="pointer" key={u._id} onClick={() => this.openDialog(u)}>
          <td>{u.name}</td>
          <td>{u.username}</td>
          <td>{roleName(u.role)}</td>
          <td>{u.district}</td>
        </tr>
      )
    })

    const newUserButton = (
      <div>
        <i className="mdi mdi-plus-box" />
        {gettext('ui_add_new')}
      </div>
    );

    return (
      <div>
        <AppBar
          title={gettext('title_kayttajat')}
          actionBtn={newUserButton}
          onActionBtnClick={() => this.openDialog()} />

        <div className="container" style={MyRawTheme.fiksuvesi.mainContainer}>
          <SearchFilter localStorage="userFilters" menuitems={filters} onChange={this.onFilterChange.bind(this)} />
          <table className="table">
            <thead>
              <tr>
                <th onClick={this.sortBy.bind(this, 'name')}>{gettext('ui_name')}</th>
                <th onClick={this.sortBy.bind(this, 'username')}>{gettext('ui_username')}</th>
                <th onClick={this.sortBy.bind(this, 'role')}>{gettext('ui_role')}</th>
                <th onClick={this.sortBy.bind(this, 'district')}>{gettext('ui_district')}</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {rows.length ? null : <ViewLoader blue />}
        </div>
        {dialog}
      </div>
    );
  }
}

export default UsersView;