"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import connectToStores from 'alt-utils/lib/connectToStores';
import PremisesStore from '../stores/PremisesStore';
//import * as mui from 'material-ui';
import BaseView from './BaseView';
import AppBar from '../components/AppBar';
import PremiseActions from '../actions/PremiseActions';
//import { PropTypes} from 'react-proptypes'
import {gettext} from 'i18n';
// import _ from 'lodash';
import Moment from 'moment';
import history from '../utils/history';

//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from '../components/Theme';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';

@connectToStores
class EventsView extends BaseView {

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme)
    this.state = {
      //muiTheme: muiTheme
    };
  }

  static getStores() {
    return [PremisesStore];
  }

  static getPropsFromStores() {
    return {
      ...PremisesStore.getState()
    }
  }

  static propTypes = {
    events: PropTypes.array
  }

  static contextTypes = {
    muiTheme: PropTypes.object,
  }
  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  getChildContext() {
    return {  };
  }

  componentDidMount() {
    let params=queryString.parse(this.props.location.search);
    if (params) PremiseActions.getEvents(params);
  }

  componentWillUnmount() {
    PremiseActions.resetEvents();
  }

  isObject(obj) {
    return obj === Object(obj);
  }
  eventRows(cols) {
    if (this.props.events.length < 1) return;
    let array = [];
    this.props.events.forEach(e => {
      let temp = (
        <tr key={e._id} >
          {cols.map((col, i) => {
            let data = '';
            let style = {}, onClick = null;
            if (this.isObject(e[col]) || Array.isArray(e[col])) {
              data = JSON.stringify(e[col], null, 4).split(/"|{|}/g);
              if (data.indexOf('err') > -1) style.color = '#EB3E0F';
              if (data.indexOf('warning') > -1) style.color = '#EBB90F';
              style.width = '100%';
            } else if (col === 'timestamp') {
              data = Moment(e[col]).format('DD.MM.YYYY HH:mm');
              style.whiteSpace = 'nowrap';
            } else if (col.indexOf('.') > -1) {
              let arr = col.split('.');
              data = e;
              arr.forEach(o => {
                data = data[o];
              })
            } else if (col === 'premiseName') {
              data = e[col];
              onClick = () => history.pushState(null, '/premises/edit/' + e.premiseId);
              style.whiteSpace = 'nowrap';
              style.color = 'blue';
              style.cursor = 'pointer';
            } else {
              data = e[col];
              style.whiteSpace = 'nowrap';
            }
            return (<td key={i} onClick={onClick} style={style}>{data}</td>);
          })
          }
        </tr>
      )
      array.push(temp);
    })
    return array;
  }
  parseArray(str) {
    let temp = str, arr = [];
    temp = temp.replace(/\[|\]/g, '');
    arr = temp.split(',');
    return arr;
  }

  render() {
    let eventsTable = [];
    console.log("EventsView",this);
    let params=queryString.parse(this.props.location.search);
    console.log("params:",params);
    const cols = params.cols ? this.parseArray(params.cols) : [];
    if (this.props.events && this.props.events.length > 0) {
      eventsTable = (
        <table className="table events">
          <thead>
            <tr>
              {cols.map((col, i) => (<th key={i}>{ gettext(col) }</th>)) }
            </tr>
          </thead>
          <tbody>
            {this.eventRows(cols) }
          </tbody>
        </table>
      )
    } else if (this.props.events && this.props.events.length === 0) {
      eventsTable = <h3 style={{ textAlign: 'center' }}>{gettext('no_events')}</h3>;
    } else {
      eventsTable = <div style={{ marginLeft: '48vw' }}> <CircularProgress mode="indeterminate" /> </div>;
    }

    if (cols.length <= 0) eventsTable = <div style={{ marginLeft: '48vw' }}> No cols </div>;

    let title = params.title ? `${gettext('ui_events')} - ${params.title} ` : gettext('ui_events');

    return (
      <div className="container">
        <AppBar title={title}  backBtn/>
        <div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">{eventsTable}</div>
        </div>
      </div>)
  }

}

export default EventsView;