import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
let HCA
class HeatingCompanyActions {
  constructor() {
    this.generateActions('change');
  }

  query(q) {
    return function (dispatch) {
      var b = new UrlBuilder()
      return api.get(b.path('heatingcompanies').query(q)).then((res) => {
        dispatch(res.data);
      }).catch((err) => {
        console.error(err.stack);
        dispatch(null);
      });
    }
  }

  editHCompany(data) {
    return (dispatch) => {
      var b = new UrlBuilder();
      return api.post(b.path('heatingcompanies'), data).then((res) => {
        return HCA.query();
      }).catch((err) => {
        console.error(err.stack);
        dispatch(null);
      })
    }
  }
}

HCA = (alt.createActions(HeatingCompanyActions));
export default HCA;

