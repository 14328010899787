import React from 'react';
import PropTypes from 'prop-types';
import * as mui from 'material-ui';
//import connectToStores from 'alt-utils/lib/connectToStores';
import CardBase from './CardBase';
import {gettext} from 'i18n';
import _ from 'lodash';
import history from '../utils/history'


//@connectToStores
export default class MetersCard extends CardBase {
  /*
  static getStores() {
    return [];
  }

  static propTypes = {

  }

  static getPropsFromStores() {
    return Store.getState();
  }
  */
  static contextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.setInitialStyles();
  }

  onMeterClick(id) {
    if (this.props.reportData.premiseId && id) {
      history.pushState(null, '/analyze/' + this.props.reportData.premiseId + '/' + id);
    }
  }

  render() {


    let meters = [];// [{id:'30421', value:2435},{id:'30422', value:2436} ];
    if (this.props.reportData) {
      _.forEach(this.props.reportData.lastMeterValues, function (n) {
        _.mapKeys(n, function (value, key) {
          meters.push({
            id: key,
            value: value
          });
        });
      });
    }

    let mergedRootStyles = this.state.mergedRootStyles;
    let t = this;
    return (
      <div style={mergedRootStyles.cardSize}>
      <mui.Card style={mergedRootStyles}>
        <div style={mergedRootStyles.cardbackgrounds.header}/>
        <div style={mergedRootStyles.cardSize}>
        <div style={mergedRootStyles.headerSize}>
        <span style={mergedRootStyles.title}>{gettext('title_Mittarilukema_jakson_lopussa').toUpperCase()}</span>
        </div>
        <hr style={mergedRootStyles.line}/>
        <center>

        { meters.map(function(m, i) {
          return (
             <mui.ListItem
              key={m.id}
              leftAvatar={<mui.Avatar style={mergedRootStyles.meterbutton} icon={<i className="mdi mdi-view-list"/>} />}
              primaryText={ <div>{m.value} {gettext('unit_m3')}</div> }
              secondaryText= { <div>ID: {m.id}</div>}
              onClick={t.onMeterClick.bind(t, m.id)}/>
             )  })
        }
        </center>

        </div>
        <div style={mergedRootStyles.cardbackgrounds.header}/>
        </mui.Card>
      </div>
    );

  }
}
