import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
import {gettext} from 'i18n';
//import DefaultIcon from 'material-ui/svg-icons/action/exit-to-app';
import DefaultIcon from '@material-ui/icons/ExitToApp';

var reg = /\W/;

function isValid(value) {
  return value || value === 0;
}

var isNotNull = function (value) {
  return value !== null && value !== undefined;
};

var isEmpty = function (value) {
  return value === '';
};

var rules = {
  maxLength: function (value, length) {
    return !isNotNull(value) || value.trim().length <= length;
  },
  minLength: function (value, length) {
    return !isNotNull(value) || isEmpty(value) || value.trim().length >= length;
  },
  onlyText: function (text) {
    if (text.match(reg)) {
      return false;
    }
    return true;
  },
  email: function(text) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(text)
  },
  phone: function(text) {
    const re = /^\+(?:[0-9]●?){6,14}[0-9]$/;
    return text && re.test(text)
  }
}

export default class TextInput extends React.Component {

  static contextTypes = {
    router: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {errorText: ""};
  }

  componentWillMount() {
  }

  static propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.object,
    multiLine: PropTypes.string,
    ref: PropTypes.string,
    text: PropTypes.string,
    validations: PropTypes.object,
    errorText: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  handleInputChange(e) {
    var value = e.target.value
    let eState = { errorText: null };
    if (!value) {
      this.setState(eState);
    } else if (this.props.validations || this.props.required) {
      let vals = this.props.validations;
      if (vals && vals.minLength && !rules.minLength(value, vals.minLength)) {
        eState = { errorText: gettext('Minimum length is %d characters', vals.minLength) };
      }
      if (vals && vals.maxLength && !rules.maxLength(value, vals.maxLength)) {
        eState = { errorText: gettext('Maximum lengths is %d characters', vals.maxLength) };
      }
      if (vals && vals.onlyText && !rules.onlyText(value)) {
        eState = { errorText: gettext("dont_use_special_characters") };
      }
      if (vals && vals.email && !rules.email(value, vals.maxLength)) {
        eState = { errorText: gettext('Invalid email address') };
      }
      if (vals && vals.phone && !rules.phone(value, vals.maxLength)) {
        eState = { errorText: gettext('Invalid phone number') };
      }

      if (this.props.required) {
        if (value === '' || !value) {
          eState = { errorText: gettext('ui_mandatory_field') };
        }
      }

      this.setState(eState);
    } else if (this.props.errorText) {
      this.setState({ errorText: this.props.errorText });
    }

    if (this.props.onChange) {
      setTimeout(() => {this.props.onChange(value, this.props.id, this, eState.errorText)});
    }
  }

  getRef() {
    return this.props.ref ? this.props.ref : 'input';
  }

  getValue() {
    return this.refs.input.value;
  }

  getInputNode() {
    // TODO ReactDOM is not defined! Is this dead code, since it works?
    return (this.props.children || this.props.multiLine) ?
      this.refs[this._getRef()].getInputNode() : ReactDOM.findDOMNode(this.refs[this._getRef()]);
  }

  focus() {
    this.refs.input.focus();
  }

  render() {

    var props = {
      id: this.props.id,
      type: this.props.type || 'text',
      value: this.props.value,
      name: this.props.name,
      defaultValue: this.props.defaultValue,
      required: this.props.required
    }

    if (this.props.inputType === "textarea" && this.props.rows) {
      props.rows = this.props.rows;
    }
    //let icon = this.props.iconName ? 'mdi ' + this.props.iconName : 'mdi mdi-exit-to-app';
    const iconStyle ={
      height: 16,
      width: 16,
      paddingTop:3,
      color: 'white'
    }
    let placeholder = this.props.label ? this.props.label : '';
    let label = null;
    var r = this.props.required ? '*' : ''
    if (this.props.label || this.state.errorText) {
      label = (
        <div className="InputLabel">
          {this.props.label ? this.props.label + r : '' + r}
          {this.state.errorText && <span className="InputError">{this.state.errorText}</span>}
        </div>
      );
    }
    let input = (this.props.inputType === "textarea") ? (
      <textarea
            {...props}
            ref="input"
            placeholder={placeholder}
            className="Inputaddonfield"
            disabled={this.props.disabled}
            onChange={this.handleInputChange.bind(this)}
            onBlur={this.props.onBlur}
            onKeyDown={(evt) => {if (this.props.onEnterKeyDown && evt.which === 13) this.props.onEnterKeyDown()}}>
            </textarea>
    ) : (
      <input
            {...props}
            ref="input"
            placeholder={placeholder}
            className="Inputaddonfield"
            disabled={this.props.disabled}
            onChange={this.handleInputChange.bind(this)}
            onBlur={this.props.onBlur}
            onKeyDown={(evt) => {if (this.props.onEnterKeyDown && evt.which === 13) this.props.onEnterKeyDown()}}/>
    )

    return (
      <div className="InputElem">
      {label}
       <div className="Inputaddon">
          <span className="Inputaddonitem"><DefaultIcon style={iconStyle} /></span>
          {input}
      </div>
      </div>
    );

  }

  setValue(newValue) {
    if (this.isMounted()) {
      if (this.props.multiLine) {
        this.refs[this._getRef()].setValue(newValue);
      } else {
        this.getInputNode().value = newValue;
      }
      this.setState({hasValue: isValid(newValue)});
    }
  }

  setErrorText(newErrorText) {
    this.setState({errorText: newErrorText});
  }

  isMandatory() {
    return this.props.required ? true : false;
  }

  getName() {
    if (this.props.name) {
      return this.props.name
    } else if (this.props.id) {
      return this.props.id
    }
    return null;
  }

}
