import React from 'react';
import * as mui from 'material-ui';

function SearchBar(props) {
  return (
    <mui.Paper style={{ width: '100%', height: 35 }}>
      <div className="row" style={{ }}>
        <div className="col-xs-12" style={{position: 'relative'}}>
          <i className="mdi mdi-magnify" style={{position:'absolute', top: 3, left: 12, fontSize: '30px', color: '#555'}}/>
          <input disabled={props.disabled} className="search-input-box" type="text" style={{ width: '90%', paddingLeft:'40px', lineHeight: '33px', border: 0 }} onChange={props.onChange}/>
        </div>
      </div>
    </mui.Paper>
  );
}

export default SearchBar;
