import React from 'react';
import PropTypes from 'prop-types';

import {DataRow, DataCard} from '../../components/ReportViewRows';
import MonthGraph from '../../components/MonthGraph';
import YearGraph from '../../components/YearGraph';
import Table from '../../components/Table';
//import * as mui from 'material-ui';
import Utils from '../../utils/utils';
import Currencies from '../../utils/currencies';
import {gettext} from 'i18n';
import Paper from '@material-ui/core/Paper';


export class Graphs extends React.Component {

  static propTypes = {
    selectedReport: PropTypes.object,
    monthlyConsumption: PropTypes.array,
    fiveYearCostEffect: PropTypes.array,
    onOpenAnalysis: PropTypes.func
  }

  render() {
    if (!this.props.selectedReport) {
      return null;
    }

    return (
      <DataRow>
        <DataCard title={gettext('ui_monthly_consumption')} onClick={this.props.onOpenAnalysis}>
          <MonthGraph data={this.props.monthlyConsumption} refreshTrigger={!!this.props.fiveYearCostEffect}/>
        </DataCard>
        {this.props.fiveYearCostEffect && <DataCard title={gettext('ui_5y_estimated_cost_impact')}>
          <YearGraph data={this.props.fiveYearCostEffect} report={this.props.selectedReport}/>
        </DataCard>
}
      </DataRow>
    );
  }
}

export class Tables extends React.Component {

  static propTypes = {
    selectedReport: PropTypes.object,
    onOpenAnalysis: PropTypes.func,
    monthlyConsumption: PropTypes.array,
    premise: PropTypes.object,
    alarms: PropTypes.array,
    fiveYearCostEffect: PropTypes.array,
    monthlyConsumptionTable: PropTypes.object,
    verticalAlign: PropTypes.bool,
    onClick: PropTypes.func,
    fiveYearCostEffectTable: PropTypes.object,
    fiksuvesi: PropTypes.bool
  }


  render() {
    console.log('render Tables (partials):', this.props.selectedReport);
    console.log('fiksuvesi:', this.props.fiksuvesi);
    console.log('verticalAlign:', this.props.verticalAlign);
    const curr = this.props.selectedReport && this.props.selectedReport.currency || 'eur';
    if (!this.props.selectedReport) {
      return null;
    }

    return (
      <div>
        <DataRow forceVertical={this.props.verticalAlign}>
          <DataCard title={gettext('ui_consumption_history') + ' (m³)'}>
            <Table data={this.props.monthlyConsumptionTable} formatHeadCell={(x) =>
              x.label ?
              <div>{x.label}</div> :
              <div>{x.month}<br/>{x.year}</div>
              }>
              <div label={gettext('ui_previous_consumption')} property="history"/>
              <div label={gettext('ui_last_12m')} property="current"/>
              <div label={gettext('ui_change')} property="change"/>
              <div label={gettext('ui_change') + ' %'} property="changePc"/> {this.props.monthlyConsumptionTable.meters.map((meter, idx) => (<div key={idx} label={gettext('ui_meter_reading %s', meter.id)} values={meter.values}/>))}
            </Table>
          </DataCard>
          {this.props.fiveYearCostEffectTable && <DataCard title={gettext('ui_5y_estimated_cost_impact') + ' ' + Currencies(curr)}>
            <Table data={this.props.fiveYearCostEffectTable}>
              <div label={gettext('ui_previous_consumption')} property="history"/>
              <div label={gettext('ui_current')} property="current"/>
              <div label={gettext('ui_change')} property="change"/>
            </Table>
          </DataCard>}
        </DataRow>
      </div>
    );
  }
}

export class Alarms extends React.Component {

  static propTypes = {
    selectedReport: PropTypes.object,
    onOpenAnalysis: PropTypes.func,
    monthlyConsumption: PropTypes.array,
    premise: PropTypes.object,
    alarms: PropTypes.array,
    fiveYearCostEffect: PropTypes.array,
    monthlyConsumptionTable: PropTypes.object,
    verticalAlign: PropTypes.bool,
    onClick: PropTypes.func,
    fiveYearCostEffectTable: PropTypes.object,
    fiksuvesi: PropTypes.bool
  }

  getAlarmRows() {
    const array = [];
    console.log('render tables alarms:', this.props.alarms);
    if (this.props.selectedReport && this.props.selectedReport.alarms) {
      for (let i in this.props.selectedReport.alarms) {
        const u = this.props.selectedReport.alarms[i];
        let alarm_text = '';
        //Only night-flow = vuotohälytys
        if (u.name === "night-flow") {
          let minRunNight = (u.eventHistory && u.eventHistory[0] && u.eventHistory[0].event
          && u.eventHistory[0].event.data && u.eventHistory[0].event.data.minRunNight !== 0) ? parseFloat(u.eventHistory[0].event.data.minRunNight) : 0;

          alarm_text = (minRunNight && (minRunNight !== 0)) ? `${gettext(u.name)} ${minRunNight.toFixed(2)} l/min` : gettext(u.name);
        }

        let temp = (
          <tr key={u._id}>
            <td className="pointer" style={{
              paddingLeft: '10px'
            }}>{alarm_text}</td>
            <td>{Utils.getDateString(u.start)}</td>
            <td>{u.end ? Utils.getDateString(u.end) : ''}</td>
            <td/><td/>
            {/*<td>{u.ack
                ? (`${Utils.getDateString(u.ack)} (${u.acker})`)
                : (
                  <div className="box"></div>
                )}
            </td>
            <td>{u.text
                ? u.text
                : ''}</td>*/}
          </tr>
        )
        array.push(temp);
      }
    }
    return array;
  }

  render() {
    console.log('render Tables (partials), alarms:', (this.props.selectedReport && this.props.selectedReport.alarms));
    console.log('fiksuvesi:', this.props.fiksuvesi);
    console.log('verticalAlign:', this.props.verticalAlign);
    if (!this.props.selectedReport) {
      return null;
    }
    let alarms = this.getAlarmRows();
    if (!alarms || !alarms.length) {
      alarms = null;
    }

    return (
      <div>
        {alarms &&
        <div className="row top-xs">
          <div className="col-xs-12">
            <Paper zDepth={1} rounded={false} style={{
              marginBottom: '30px',
              border: '1px solid #aaa'
            }} onClick={this.props.onClick}>
              <h3 className="cardHeading">{gettext('title_alarms')}</h3>
              <div className="center">
                <table className="table tight">
                  <thead>
                    <tr>
                      <th style={{
                        paddingLeft: '10px',
                        width: '20%'
                      }}>{gettext('ui_cause')}</th>
                      <th style={{
                        width: '15%'
                      }}>{gettext('ui_timestamp_start')}</th>
                      <th style={{
                        width: '15%'
                      }}>{gettext('ui_timestamp_end')}</th><th style={{width: '25%'}}/><th style={{width: '25%'}}/>
                      {/*<th style={{
                        width: '23%'
                      }}>{gettext('ui_acknowledgment')}</th>
                      <th style={{
                        width: '31%'
                      }}>{gettext('ui_comment')}</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {alarms}
                  </tbody>
                </table>
              </div>
            </Paper>
          </div>
        </div>
      }
      </div>
    );
  }
}
