import alt from "../utils/alt";
import AlarmActions from "../actions/Alarmv2Actions";
// import _ from 'lodash';


class AlarmStoreV2 {
  constructor() {

    this.bindActions(AlarmActions);

    this.state = {
      activeAlarms: null,
      historyAlarms: null,
      eventHistory: [],
      singleAlarmdata: [],
      alarmReceivers: { low:[], normal:[], high:[]},
      mailReadStatus:[],
      fetchError:null,
      nightlyhourrundata:[]
    };
  }

  onGetDeviceHourArray(data){
    if(data){
       this.setState({nightlyhourrundata: data, fetchError:null});
    } else {
      this.setState({nightlyhourrundata: [], fetchError:"Error"});
    }
   
  }

  onResetAlarmData() {
    this.setState({alarms: [], activeAlarms: null, historyAlarms: null, alarmReceivers: [], fetchError:null});
  }

  onGetInitialAlarms(data) {
    console.log("Active alarms:", data)
    if(data.err){
      this.setState({fetchError:data.err.message});
      return;
    }
    if (!data) return;
    this.setState({activeAlarms: data.activeAlarms, historyAlarms: data.passiveAlarms,fetchError:null});
  }

  onGetInitialAlarms2(data){
    console.log("Active alarms2:", data)
    if(data.err){
      this.setState({fetchError:data.err.message});
      return;
    }
    if (!data) return;
    this.setState({activeAlarms: data.activeAlarms, historyAlarms: data.passiveAlarms,fetchError:null});
  }

  onGetFilteredAlarms(data){
    console.log("Filtered alarms:",data);
    if(!data) return;
    if(data.err){
      this.setState({fetchError:data.err.message});
      return;
    }
    this.setState({activeAlarms: data.activeAlarms, historyAlarms: data.passiveAlarms,fetchError:null});
  }

  onGetAlarmHistory(data) {
    //console.log("history alarm:", data)
    this.setState({historyAlarms: data.data});
  }

  onSingleAlarmdata(data) {
    //console.log('onSingleAlarmdata', data);
    this.setState({singleAlarmdata: data.data})
  }

  onResetSingleAlarmdata() {
    //console.log('onResetEventHistory');
    this.setState({singleAlarmdata: []});
  }

  onAlarmsReceivers(data){
    if(data && data.data && data.priority) {
    let d = this.state.alarmReceivers;
    d[data.priority] = data.data;
    this.setState({alarmReceivers:d});
    }
  }

  onMailReadStatus(data){
    this.setState({mailReadStatus: data});
  }
}

//module.exports = alt.createStore(AlarmStoreV2);
export default alt.createStore(AlarmStoreV2);
