import React from 'react';
import _ from 'lodash';

export default class Table extends React.Component {
  render() {
    let data = this.props.data;
    let children = _.flatten(this.props.children);
    return (
      <table className="table tight">
        <TableHead content={data.head} format={this.props.formatHeadCell} />
        <tbody>
          {children.map((child, idx) => child.props && (
            <TableRow
              key={idx}
              label={child.props.label}
              content={child.props.property ? data[child.props.property] : child.props.values}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

class TableHead extends React.Component {
  render() {
    let format = this.props.format || ((x) => x);
    return (
      <thead>
        <tr>
          <th>{this.props.label}</th>
          {this.props.content.map((x, idx) => <th key={idx}>{format(x)}</th>)}
        </tr>
      </thead>
    );
  }
}

class TableRow extends React.Component {
  render() {
    return (
      <tr>
        <th>{this.props.label}</th>
        {this.props.content && this.props.content.map((x, idx) => <td key={idx}>{x}</td>)}
      </tr>
    );
  }
}
