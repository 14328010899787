import React from 'react';
import PropTypes from 'prop-types';
//import * as mui from 'material-ui';
////import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
//import getMuiTheme  from 'material-ui/styles/getMuiTheme';
import MyRawTheme from './Theme';
import { gettext } from 'i18n';
//import TextInput from './TextInput';

// import Select from 'react-select';
import Async from 'react-select/async';
import Select from 'react-select';

import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'

//new Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class AddDeviceLinkDialog extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static childContextTypes = {
    muiTheme: PropTypes.object
  }

  constructor(props) {
    super(props);
    //let muiTheme = getMuiTheme(MyRawTheme);
    this.state = {
      //muiTheme: muiTheme,
      alias: null,
      premiseId: null,
      dialogOpen: false,
    };
  }

  getChildContext() {
    return {};
  }

  open() {
    let premiseId = '';
    let alias = '';
    this.setState({ premiseId, alias, dialogOpen: true });
    //this.refs.dialog.show();
  }

  close() {
    this.setState({ alias: null, premiseId: null, premiseDevices: [], selectedPremise: null, selectedDevice: null, dialogOpen: false });
    //this.refs.dialog.dismiss();
  }

  onChange(value, id) {
    console.log("onChange:",value,id);
    this.setState({ [id]: value })
  }

  onSearch = async (search) => {
    /*if (!search) {
      return Promise.resolve([]);
    }

    let b = new UrlBuilder();
    const s = { $regex: search.label || search, $options: 'i' };
    const query = { $or: [{ name: s }, { address: s }] };

    const body = {
      query,
      projection: { name: 1, address: 1, _id: 1, devicesinfo: 1 }
    };
    const premiseMap = {};
    this._premiseMap = premiseMap;
    return api.post(b.path('premises/find'), body)
      .then((result) => {
        const options = result.data.map((r) => {
          premiseMap[r._id] = r;
          return { value: r._id, label: `${r.name} ${r.address}` }
        })
        return { options };
      });*/
    if (!search) return;
    let b = new UrlBuilder();
    const s = { $regex: search.label || search, $options: 'i' };
    const query = { $or: [{ name: s }, { address: s }] };

    const body = {
      query,
      projection: { name: 1, address: 1, _id: 1, devicesinfo: 1 }
    };
    const premiseMap = {};
    this._premiseMap = premiseMap;
    try {
      let result = await api.post(b.path('premises/find'), body)
      const options = result.data.map((r) => {
        premiseMap[r._id] = r;
        return { value: r._id, label: `${r.name} ${r.address}` }
      })
      return options;
    } catch (err) {
      return [];
    }
  }

  onPremiseSelect = (v) => {
    console.log("onPremiseSelect",v);
    if (!v) {
      return this.setState({ premiseDevices: [], selectedPremise: null, selectedDevice: null });
    }
    const premiseDevices = [];
    const p = this._premiseMap[v.value] || {};
    const info = p.devicesinfo || {};
    const selectedPremise = { value: p._id, label: `${p.name} ${p.address}` };

    let selectedDevice = null;
    Object.keys(info).forEach((v) => {
      if (info[v].alias) {
        premiseDevices.push({ value: info[v].alias, label: info[v].alias });
        selectedDevice = info[v].alias
      }
    });

    this.setState({ premiseDevices, selectedPremise, selectedDevice })
  }

  render() {
    let title = gettext('Link device');
    let premiseDevices=this.state.premiseDevices?this.state.premiseDevices:[];
    return (
      <Dialog
        open={this.state.dialogOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle>
          <span style={{ color: MyRawTheme.palette.primary1Color, margin: '5px', fontSize: '20px' }}>
            {title.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent>
          <div >
            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col-xs-12 col-md-6">
                <Async
                  name="premise-search"
                  placeholder={gettext('Search premise')}
                  value={this.state.selectedPremise}
                  loadOptions={this.onSearch}
                  onChange={this.onPremiseSelect}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
              <div className="col-xs-6">
                <Select
                  name="device-select"
                  placeholder={gettext('Select device')}
                  value={premiseDevices.filter(({value})=>value===this.state.selectedDevice)}
                  options={premiseDevices}
                />
              </div>
            </div>
            {!this.state.selectedDevice && <div className="row">
              <div className="col-xs-12" style={{ fontSize: '12px' }}>{gettext('Please select device.')}</div>
            </div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => (this.close())} >{gettext('ui_cancel')}</Button>
          <Button disabled={!this.state.selectedDevice} onClick={() => (this.props.onAccepted(this.state.selectedPremise.value, this.state.selectedDevice))} >{gettext('ui_ok')}</Button>
        </DialogActions>
      </Dialog>
    );

  }
}

export default AddDeviceLinkDialog;