'use strict';

import React from 'react';
import PropTypes from 'prop-types';
// import {Router} from 'react-router';
import * as mui from 'material-ui';
//import * as Colors from 'material-ui/styles/colors';
//let DropDownMenu = mui.DropDownMenu;
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//import * as ThemeManager from 'material-ui/styles/MuiThemeProvider';
const MyRawTheme = require('./Theme');
// import LoginActions from '../actions/LoginActions';
import FilterItem from './FilterItem';
import _ from 'lodash';
import { gettext } from 'i18n';
import { doParseFloat } from '../utils/utils'
import PlusIcon from 'material-ui/svg-icons/content/add';

function createFilter(obj) {
  var ret = {};
  for (var k in obj) {
    var o = obj[k];
    var query
    if (o.value !== '' && o.value !== undefined) {
      switch (o.operator) {
        case '-%':
          query = { $regex: '^' + o.value + '.*', $options: 'i' }
          break;
        case '%':
          query = { $regex: o.value, $options: 'i' }
          break;
        case '<':
          query = { $lt: o.component === 'datePicker' ? o.value && new Date(o.value) : doParseFloat(o.value) }
          break;
        case '>':
          query = { $gt: o.component === 'datePicker' ? o.value && new Date(o.value) : doParseFloat(o.value) }
          break;
        case '<=':
          query = { $lte: o.component === 'datePicker' ? o.value && new Date(o.value) : doParseFloat(o.value) }
          break;
        case '>=':
          query = { $gte: o.component === 'datePicker' ? o.value && new Date(o.value) : doParseFloat(o.value) }
          break;
        case '$e':
          query = { $exists: o.value }
          break;
        case '$nullcheck':
          if(o.value==1){
            query={$ne:null};
          }else {
            query={$eq:null};
          }
          
          console.log("$nullcheck:",query);
          break;
        default:
          query = o.value
          break;
      }
      if (o.elem) {
        if (o.additionalElem) {
          query = { '$elemMatch': { [o.prop]: query } }
          for (let p in o.additionalElem) {
            query['$elemMatch'][p] = o.additionalElem[p]
          }
        }
      }
      if (o.elem && (ret['$and'] || ret[o.elem])) {
        if (!ret['$and']) {
          ret['$and'] = []
          if (ret[o.elem]) {
            ret['$and'].push({ [o.elem]: ret[o.elem] })
            delete ret[o.elem]
          }
        }
        ret['$and'].push({ [o.elem]: query })
      }
      else if (typeof query === 'object') ret[o.elem || o.prop] = _.assign(ret[o.elem || o.prop] || {}, query)
      else ret[o.prop] = query;
    }
  }
  return ret;
}

export default class SearchFilter extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static propTypes = {
    menuitems: PropTypes.array,
    onChange: PropTypes.func,
    onRemoveFilterItem: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = { filters: {} };

    let filters
    if (props.filters) {
      let propFilters = props.filters

      filters = {}
      for (let key in propFilters) {
        let val = propFilters[key]

        let value = val.$regex || val.$lt || val.$gt || val.$lte || val.$gte || val

        const filter = { type: key, value };

        const spec = this.props.menuitems.find(item => item.type === filter.type);
        filters[key] = spec ? { ...spec, ...filter } : filter;
      }

    } else if (props.localStorage) {
      var json = sessionStorage.getItem(props.localStorage);
      if (json) {
        filters = JSON.parse(json);
        // Append unserialized keys back to filter data
        for (let key in filters) {
          const filter = filters[key];
          const spec = this.props.menuitems.find(item => item.type === filter.type);
          filters[key] = spec ? { ...spec, ...filter } : filter;
        }
        if (props.onChange) {
          props.onChange(createFilter(filters));
        }
      }
    }
    this.state.filters = filters;
  }

  setFilters(filters, silentUpdate) {
    console.log("setFilters:", filters, silentUpdate);
    if (this.props.localStorage) {
      let saveData = {};
      for (let key in filters) {
        const filter = filters[key];
        saveData[key] = {
          type: filter.type,
          value: filter.value
        };
      }
      sessionStorage.setItem(this.props.localStorage, JSON.stringify(saveData));
    }
    this.setState({ filters });
    if (this.props.onChange && !silentUpdate) {
      this.props.onChange(createFilter(filters));
    }
  }

  addFilter(e) {
    console.log("addFiler ", e, menuItem);
    const menuItem=e.target.value;
    if (menuItem.type == null) return;
    let filters = Object.assign({}, this.state.filters);
    filters[menuItem.type] = menuItem;
    let mOpts = menuItem.options
    if (mOpts && mOpts[0] && mOpts[0].value !== null && mOpts[0].value !== undefined) {
      filters[menuItem.type].value = mOpts[0].value
    }
    //use silent update if no value is set.
    let silent=filters[menuItem.type].value===null || filters[menuItem.type].value==="";
    this.setFilters(filters, silent);
  }

  addFilterValue(key, value) {
    let filters = Object.assign({}, this.state.filters);
    filters[key].value = value;
    this.setFilters(filters);
  }

  updateFilterValue(key, value) {
    let filters = Object.assign({}, this.state.filters);
    filters[key].value = value;
    this.setFilters(filters, true);
  }

  addDateFilter(key, value) {
    let filters = Object.assign({}, this.state.filters);
    filters[key].value = (value && (new Date(value)).toISOString()) || null
    this.setFilters(filters);
  }

  removeFilter(data, callback) {
    let filters = Object.assign({}, this.state.filters);
    filters[data].value = '';
    delete filters[data];
    this.setFilters(filters);
    if (this.props.onRemoveFilterItem) this.props.onRemoveFilterItem(filters);
    if (callback) {
      callback();
    }
  }

  render() {
    let style = {
      paddingBottom: '10px',
      borderBottom: '3px solid grey',
      margin: '0px'
    }

    /* const AddFilterItem = (
       <div key={'the1st'}>
         <mui.FontIcon color={Colors.blue500} className="mdi mdi-plus-box" style={{fontSize: '18px', marginRight: '10px'}} />
         {gettext('ui_new_filter')}
       </div>
     );*/

    //let menuItems = [{text: AddFilterItem, type: null}];
    //menuItems = menuItems.concat(this.props.menuitems);

    let menuItems = [];
    let newVal = {
      type: null,
    }
    const sel = <MenuItem value={newVal} key={1234}  >
      
      {gettext('ui_new_filter')}
    </MenuItem>
    menuItems.push(sel);
    menuItems = menuItems.concat(this.props.menuitems.map((item) => {
      return <MenuItem value={item} key={item.text} >{item.text}</MenuItem>
    }))
    return (
      <div>
        <div className="row start-xs" style={style}>
          <div className="col-xs">
            <div className="row middle-xs">
              {_.map(this.state.filters,
                (item, k) => (
                  <FilterItem
                    key={k}
                    item={item}
                    addDateFilter={this.addDateFilter.bind(this, item.type)}
                    onRemoveClick={this.removeFilter.bind(this, item.type, item.onFilterRemove)}
                    addFilterValue={this.addFilterValue.bind(this, item.type)}
                    updateFilter={this.addFilterValue.bind(this, item.type)}
                    />
                )
              )}
              <div style={{   }}>
                <div className="row middle-xs withMargin">
                  <Select
                    ref="menu"
                    value={newVal}
                    onChange={this.addFilter.bind(this)}
                     >
                    {menuItems}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
