import alt from '../utils/alt';
import api from '../utils/api'
import UrlBuilder from '../utils/urlbuilder'
import utils from '../utils/utils'
import moment from 'moment'

let AA
class AnalyzeActions {
  constructor() {
    this.generateActions('resetMinedData', 'resetMeasurements', 'resetLiveData', 'resetTemperatures', 'resetPeriodData');
  }

  measurements(opts) {
    var b = new UrlBuilder();
    var q = {};

    if (!opts.includeImages) {
      q = { gcspath: { $exists: 0 } }
    }
    if (!opts.includeData) {
      Object.assign(q, { value: { $exists: 0 } });
    }
    return async (dispatch) => {
      try {
        let res = await api.get(b.path('data')
          .id(opts.id)
          .start(opts.start && opts.start.toISOString())
          .end(opts.end && opts.end.toISOString())
          .query(q)
          .limit(opts.limit)
          .skip(opts.skip).sort({ timestamp: -1 }));
        var dis = { limit: opts.limit, skip: opts.skip, data: res.data };
        if (opts.reload) dis.reload = true;
        dispatch(dis);
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
    };
  }

  updateMeasurement(device, obj) {
    //console.log('updateMeasurement', device, obj);
    var b = new UrlBuilder();
    obj.value = utils.doParseFloat(obj.value);
    return async (dispatch) => {
      try {
        await api.post(b.path('data').id(device), obj);
        var dis = { data: obj, device: device/*, res:res*/ };
        dispatch(dis);
      } catch (err) {
        console.error(err.stack);
        dispatch(null);
      }
    }
  }
 
 getPeriodData(deviceId) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.get(b.path('period-data/'+deviceId))
        .then(res => {
          dispatch(res);
        }).catch(err => {
          dispatch(err);
        });
    }
  }

  reCalculatePeriodData(deviceId, start, end) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('recalculate'), {deviceId, start, end})
        .then(res => {
          dispatch(res);
        }).catch(err => {
          dispatch(err);
        });
    }
  }

  editPeriodData(_id, deviceId, last) {
    var b = new UrlBuilder();
    last= utils.doParseFloat(last);
    return async (dispatch) => {
      try {
        await api.post(b.path('period-data/update'), {_id, deviceId, last});
        dispatch();
        this.getPeriodData(deviceId)
      } catch (err) {
        dispatch(null);
      }
    }
  }
  

  mine(deviceId, start, end, frequency) {
    return function (dispatch) {
      dispatch({ deviceId: deviceId, data: [], loading:true });
      var b = new UrlBuilder()
      let query = null;
      if (frequency === 'month') {
        let periods = [];
        let months = end.diff(start, 'months') + 1;
        for (let i = 0; i < months; i++) {
          let s = moment(start);
          s.add(i, 'month');
          let e = moment(s);
          e.endOf('month');
          periods.push({ startTime: s.format(), endTime: e.format(), name: i })
        }
        query = api.post(b.path('mine').id(deviceId), {
          param: "value",
          interpolation: "linear",
          fill: "linear",
          decimals: 5,
          aggregates: ["run", "date"],
          periods: periods
        })
      } else if (frequency === 'week') {
        let weeks = end.diff(start, 'weeks') + 1;
        query = api.post(b.path('mine').id(deviceId).start(start.format()).end(end.format()).frequency(frequency), {
          param: "value",
          interpolation: "linear",
          length: 1000 * 60 * 60 * 24 * 7, // Week in milliseconds
          count: weeks,
          decimals: 5,
          aggregates: ["run", "date"]
        })
      } else if (frequency === 'day') {
        let periods = [];
        let days = end.diff(start, 'days') + 1;
        for (let i = 0; i < days; i++) {
          let s = moment(start);
          s.add(i, 'day');
          let e = moment(s);
          e.endOf('day');
          periods.push({ startTime: s.format(), endTime: e.format(), name: i })
        }
        query = api.post(b.path('mine').id(deviceId), {
          param: "value",
          interpolation: "linear",
          fill: "linear",
          decimals: 5,
          aggregates: ["run", "date"],
          periods: periods
        })
      } else if (frequency === 'samples') {
        query = api.post(b.path('mine').id(deviceId).start(start.format()).end(end.format()).frequency('all'), {
          param: 'value',
          interpolation: 'linear',
          decimals: 5,
          aggregates: ['date', 'startValue', 'endValue', 'runStartValue', 'runEndValue'],
          includeData: true
        })
      } else {
        query = api.post(b.path('mine').id(deviceId).start(start.format()).end(end.format()).frequency(frequency), {
          param: "value",
          interpolation: "linear",
          fill: "linear",
          decimals: 5,
          aggregates: ["run", "date"]
        })
      }
      return query.then(res => {
        dispatch({ deviceId: deviceId, data: res.data, loading:false });
      }).catch(err => {
        console.error(err.stack);
        dispatch(null);
      });
    }
  }

  activateLiveData(deviceId) {
    return function (dispatch) {
      var b = new UrlBuilder();
      return api.post(b.path('livedata/activate'), { device: deviceId })
        .then(res => {
          console.log(res);
          dispatch(res);
        }).catch(err => {
          console.error(err);
          dispatch(err);
        });
    }
  }

  /*mineHistogram(deviceId) {
    if (!deviceId) return;
    let frequency = "all";
  
    let start = moment().startOf('day');
    let end = moment().startOf('day');
    end.add(6, 'hours');
  
    function createRequest(start, end) {
      let b = new UrlBuilder();
      return api.post(b.path('mine').id(deviceId).start(start.format()).end(end.format()).frequency(frequency), {
        param: "value",
        interpolation: "linear",
        fill: "linear",
        decimals: 5,
        filter: { digitsValue: { $exists: false } },
        aggregates: ["histogram"]
      });
    }
  
    var req1 = createRequest(start, end);
  
    let start2 = moment().startOf('day');
    start2.subtract(1, 'days');
    let end2 = moment().startOf('day');
    end2.subtract(1, 'days').add(6, 'hours');
  
    var req2 = createRequest(start2, end2);
  
    return Promise.all([req1, req2])
      .then(res => {
        let data = [];
        if (res[0].data.length && res[1].data.length) {
          data = res.map(d => { return { data: d.data[0].histogram, end: d.config.url.end } })
        }
        dispatch({ deviceId, data });
      }).catch(err => {
        console.error(err.stack);
        dispatch(null);
      });
  }*/

  mineTemperature(datapointIds, hours = 2) {
    return function (dispatch) {
      let frequency = "minute";
      const e = moment();
      const s = moment(e).subtract(hours, 'h');
      var b = new UrlBuilder();
      return api.post(b.path('temperature'), { datapointIds: datapointIds })
        .then((res) => {
          //console.log(res);
          const data = res.data || [];
          dispatch({ data });
        })
    }
  }
}

AA = (alt.createActions(AnalyzeActions));
export default AA;
