
import { gettext } from 'i18n';

function Currencies(c) {
  switch (c) {
    case 'eur': return '€';
    case 'sek': return 'kr';
    case 'gbp': return '£';
    case 'usd': return '$';
    case 'nok': return 'kr';
    case 'dkk': return 'kr';
    case 'rub': return '₽';
    case 'chf': return 'Fr';
    case 'cad': return 'CA$';
    case 'all': return [
      { value: 'eur', label: gettext('Euro') },
      { value: 'sek', label: gettext('Ruotsin kruunu') },
      { value: 'gbp', label: gettext('Punta') },
      { value: 'usd', label: gettext('Dollari') },
      { value: 'nok', label: gettext('Norjan kruunu') },
      { value: 'dkk', label: gettext('Tanskan kruunu') },
      { value: 'rub', label: gettext('Rupla') },
      { value: 'chf', label: gettext('Sveitsin frangi') },
      { value: 'cad', label: gettext('Canadan dollari') }
    ];
    default: return '€'
  }
}
export default Currencies;