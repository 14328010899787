import alt from '../utils/alt';
import axios from 'axios';
import config from '../utils/config';

class LoginActions {
  constructor() {
    this.generateActions('logout', 'loadLocalUser');
  }
}

LoginActions.prototype.login =  function (data) {
  //console.log('LoginActions.login', data);
  return async (dispatch)=> {
      try {
        /*return axios.post(config.APISERVER + '/login', data).then((response)=>{
          console.log('LoginActions.dispatch', {ok: true, user: response.data});
          dispatch(  {ok: true, user: response.data, redirect: data.redirect} );
        });*/
        console.log("axios call");
        const response = await axios.post(config.APISERVER + '/login', data);
        console.log('LoginActions.dispatch', {ok: true, user: response.data});
        dispatch(  {ok: true, user: response.data, redirect: data.redirect} );
      
    } catch (err) {
      console.log(err);
      let errorObject=JSON.parse(JSON.stringify(err));
      console.log('LoginActions.dispatch catch',errorObject);//, {ok: false, error: err});
      dispatch( {ok: false, error: errorObject.response.data} );
    }
      console.log("axios call complete");
      //console.log("return");
  }
}

export default alt.createActions(LoginActions);

