'use strict';

import React from 'react';
import PropTypes from 'prop-types';

export default class MonthPicker extends React.Component {

  static contextTypes = {
    muiTheme: PropTypes.object
  }

  static propTypes = {
    
  }

  constructor(props) {
    super(props);
    let now = new Date();
    this.month = ((this.props.date.month() + 1)<10)?'0'+(this.props.date.month() + 1):this.props.date.month() + 1; //now.getUTCMonth() + 1;
    this.year = this.props.date.year(); //now.getFullYear();
    this.selections = {months: [], years: []}
  }

  onMonthChange(e) {
    this.month = e.target.value;
    this.props.onChange(null, `${this.year}-${this.month}-01`);
  }

  onYearChange(e) {
    this.year = e.target.value;
    this.props.onChange(null, `${this.year}-${this.month}-01`);
  }
  
  componentWillMount() {
    for (let i = 1; i <= 12; i++) { 
      let val=(i<10)?'0'+i:i;
      this.selections.months.push(<option key={i} value={val}>{i}</option>)
    }
    for (let i = this.year; i >= this.year - 5; i--) {
      this.selections.years.push(<option key={i} value={i}>{i}</option>)
    }
  }

  render() {
    console.log("month:",this.month);
    return (
      <div style={{display:'inline-block'}}>
        <select defaultValue={this.month} onChange={this.onMonthChange.bind(this)} name="month">{this.selections.months}</select>
        /
        <select defaultValue={this.year} onChange={this.onYearChange.bind(this)} name="year">{this.selections.years}</select>
      </div>
    )
  }

}