import React from 'react';
//import Resizable from './Resizable';
import { getScale } from '../utils/scale';
import { gettext } from 'i18n';
import utils from '../utils/utils';

export default class MonthGraph extends React.Component {

  render() {
    return (
        <canvas ref="canvas" className="graph month-graph" />
    );
  }

  componentDidMount() {
    this.resize();
  }

  componentDidUpdate() {
    this.resize();
  }

  resize() {
    let canvas = this.refs.canvas;
    this.pixelDensity =  2;
    canvas.width = canvas.clientWidth * this.pixelDensity;
    canvas.height = canvas.clientHeight * this.pixelDensity;
    this.updateCanvas();
  }

  updateCanvas() {
    let canvas = this.refs.canvas;
    if (!canvas) return;

    let c = canvas.getContext('2d');
    const canvasWidth = canvas.width / this.pixelDensity;
    const canvasHeight = canvas.height / this.pixelDensity;

    var dimmedBarColor1 = "#f4f4f4";
    var dimmedBarColor2 = "#e1e1e1";
    var barColor1 = "#2CB4DF";
    var barColor2 = "#0D429B";
    var positiveColor = "#77cb5c";
    var negativeColor = "#e1484b";
    var bgColor = "#ffffff";
    // var bgColor="#fffff0";
    var fontColor = "#777777";
    var ltGrayColor = "#e9e9e9";
    // var grayColor="#e0e0e0";
    //var darkGrayColor="b2b2b2";

    c.save();
    c.scale(this.pixelDensity, this.pixelDensity);
    c.fillStyle = bgColor;
    c.beginPath();
    c.rect(0, 0, canvasWidth, canvasHeight);
    c.fill();

    var min = null;
    var max = null;
    if (this.props.data && this.props.data.length) {
      for (let i = 0; i < this.props.data.length; i++) {
        let d = this.props.data[i];
        if (d.values && d.values.length) {
          for (let j = 0; j < d.values.length; j++) {
            if (min === null || d.values[j] < min) min = d.values[j];
            if (max === null || d.values[j] > max) max = d.values[j];
          }
        }
      }
    }
    if (!isNaN(this.props.min)) min = this.props.min;
    if (!isNaN(this.props.max)) max = this.props.max;
    var scale = getScale(min, max, 5);
    while (scale.max - scale.spacing > max) {
      scale.ticks -= 1;
      scale.max -= scale.spacing;
    }
    var margins = this.props.data.length + 3;

    var scaleWidth = 0;
    if (this.props.unit) scaleWidth = c.measureText(this.props.unit).width;
    var i = scale.min;
    while ((i <= scale.max && scale.max > scale.min) || (i >= scale.max && scale.max < scale.min)) {
      let t = c.measureText(i + "");
      if (t > scaleWidth) scaleWidth = t;
      i += scale.spacing;
    }
    var legendsWidth = 0;
    var fontSize = 12;
    var fontSize15 = fontSize;
    var legends = [ // Jos käytetään default-propseista niin lokalisaatio ei toimi
      gettext('ui_previous_consumption').toUpperCase().split("\n"),
      gettext('ui_consumption_last_12m').toUpperCase().split("\n")
    ]
    for (i = 0; i < legends.length; i++) {
      var l = legends[i];
      if (typeof l == "string" && l) {
        let t = c.measureText(l).width;
        if (t > legendsWidth) legendsWidth = t;
      } else if (l.length) {
        for (var j = 0; j < l.length; j++) {
          let t = c.measureText(l[j]).width;
          if (t > legendsWidth) legendsWidth = t;
        }
      }
    }
    var margin = Math.floor(canvasWidth / (this.props.data.length * 1.5 + margins));
    var itemWidth = Math.floor((canvasWidth - scaleWidth - legendsWidth - margin * margins) / this.props.data.length);

    c.textAlign = "center";
    c.textBaseline = "middle";
    c.font = "12px sans-serif";

    var t = typeof legends[0] == "string" ? 2 : 1 + legends[0].length;
    c.fillStyle = barColor1;
    c.beginPath();
    c.arc(canvasWidth - margin - legendsWidth / 2, canvasHeight / 3 - t * fontSize15 / 2 + fontSize15 / 2, fontSize / 2, 0, 2 * Math.PI);
    c.fill();
    c.fillStyle = fontColor;
    for (let i = 1; i < t; i++) {
      c.fillText(typeof legends[0] == "string" ? legends[0] : legends[0][i - 1], canvasWidth - margin - legendsWidth / 2, canvasHeight / 3 - t * fontSize15 / 2 + i * fontSize15 + fontSize15 / 2 + 5);
    }

    t = typeof legends[1] == "string" ? 2 : 1 + legends[1].length;
    c.fillStyle = barColor2;
    c.beginPath();
    c.arc(canvasWidth - margin - legendsWidth / 2, canvasHeight / 3 * 2 - t * fontSize15 / 2 + fontSize15 / 2, fontSize / 2, 0, 2 * Math.PI);
    c.fill();
    c.fillStyle = fontColor;
    for (let i = 1; i < t; i++) {
      c.fillText(typeof legends[1] == "string" ? legends[1] : legends[1][i - 1], canvasWidth - margin - legendsWidth / 2, canvasHeight / 3 * 2 - t * fontSize15 / 2 + i * fontSize15 + fontSize15 / 2 + 5);
    }

    c.textAlign = "right";

    c.font = "bold 12px sans-serif";
    var hmargin = canvasHeight / (scale.ticks + 1.5); // unit, bottom legend, 2*margin
    c.fillText(this.props.unit, margin + scaleWidth, 0.5 * hmargin);

    c.font = "12px sans-serif";
    c.fillStyle = fontColor;
    c.strokeStyle = ltGrayColor;
    c.lineWidth = 1;
    i = scale.max;
    var th = 1.5 * hmargin;
    while ((i >= scale.min && scale.max > scale.min) || (i <= scale.min && scale.max < scale.min)) {
      c.fillText(i + "", margin + scaleWidth, th);
      c.beginPath();
      c.moveTo(margin + scaleWidth, th);
      c.lineTo(canvasWidth - 2 * margin - legendsWidth, th);
      c.stroke();
      i -= scale.spacing;
      th += hmargin;
    }

    var pxMin = canvasHeight - hmargin;
    var pxMax = 1.5 * hmargin;
    var pxValue = (pxMin - pxMax) / (scale.max - scale.min);

    c.textAlign = "center";
    for (let i = 0; i < this.props.data.length; i++) {
      var d = this.props.data[i];
      var mh = null;
      if (d.name || utils.shortMonthName(d.month)) {
        c.font = "12px sans-serif";
        c.fillStyle = fontColor;
        c.fillText(d.name || utils.shortMonthName(d.month), 2 * margin + scaleWidth + i * (margin + itemWidth) + itemWidth / 2, canvasHeight - hmargin * 0.5);
      }
      if (!d.values || d.values[0] === undefined || d.values[0] === null) {
        c.fillStyle = dimmedBarColor1;
        c.beginPath();
        c.rect(2 * margin + scaleWidth + i * (margin + itemWidth), hmargin * 0.5, itemWidth / 2, scale.ticks * hmargin);
        c.fill();
      } else {
        c.fillStyle = barColor1;
        c.beginPath();
        c.rect(2 * margin + scaleWidth + i * (margin + itemWidth), canvasHeight - hmargin * 1 - (d.values[0] - scale.min) * pxValue, itemWidth / 2, (d.values[0] - scale.min) * pxValue);
        mh = canvasHeight - hmargin - (d.values[0] - scale.min) * pxValue;
        c.fill();
      }
      if (!d.values || d.values[1] === undefined || d.values[1] === null) {
        c.fillStyle = dimmedBarColor2;
        c.beginPath();
        c.rect(2 * margin + scaleWidth + i * (margin + itemWidth) + itemWidth / 2, hmargin * 0.5, itemWidth / 2, scale.ticks * hmargin);
        c.fill();
      } else {
        c.fillStyle = barColor2;
        c.beginPath();
        c.rect(2 * margin + scaleWidth + i * (margin + itemWidth) + itemWidth / 2, canvasHeight - hmargin - (d.values[1] - scale.min) * pxValue, itemWidth / 2, (d.values[1] - scale.min) * pxValue);
        if (canvasHeight - hmargin - (d.values[1] - scale.min) * pxValue < mh) mh = canvasHeight - hmargin - (d.values[1] - scale.min) * pxValue;
        c.fill();
      }
      if (d.values && d.values[0] !== undefined && d.values[0] !== null && d.values[1] !== undefined && d.values[1] !== null) {
        var dif = Math.round((d.values[1] - d.values[0]) / d.values[0] * 100);
        if (dif < 0) {
          c.font = "bold 12px sans-serif";
          c.fillStyle = positiveColor;
          c.fillText(dif + "%", 2 * margin + scaleWidth + i * (margin + itemWidth) + itemWidth / 2, mh - fontSize15 / 2);
        } else if (dif > 0) {
          c.font = "bold 12px sans-serif";
          c.fillStyle = negativeColor;
          c.fillText("+" + dif + "%", 2 * margin + scaleWidth + i * (margin + itemWidth) + itemWidth / 2, mh - fontSize15 / 2);
        }
      }
    }
    c.restore();
  }
}

MonthGraph.defaultProps = {
  data: [
    { month: 1 },
    { month: 2 },
    { month: 3 },
    { month: 4, values: [180, 160] },
    { month: 5, values: [205, 180] },
    { month: 6, values: [175, 147] },
    { month: 7, values: [155, 160] },
    { month: 8, values: [175, 150] },
    { month: 9, values: [185, 155] },
    { month: 10, values: [205, 165] },
    { month: 11, values: [215, 175] },
    { month: 12, values: [225, 185] }
  ],
  unit: "m³",
  legends: [
    gettext('ui_previous_consumption').toUpperCase().split("\n"),
    gettext('ui_consumption_last_12m').toUpperCase().split("\n")
  ],
  min: 0,
  display: "sideBySide"
};


